import {
  signOut as firebaseSignOut,
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth';

import { UserId } from 'common/models/db/vo.interface';
import { apiCall } from 'pages/vo/vo-react/api';
import { getFirebaseApp } from 'utils/firebase-app';

import { createSlice } from '../../redux/create-slice';
import { pushLocation } from '../router/router.slice';

export type AuthSlice = {
  id?: UserId;
  email?: string;
  displayName?: string | null;
  avatarUrl?: string | null;
  isSignedIn?: boolean;
};

const initialState: AuthSlice = {};
const { createReducer, createSelector, createAction, createThunk } = createSlice('auth', initialState);

export const attemptSignIn = createThunk(
  'attemptSignIn',
  async (dispatch, _getState, { email, password, to }: { email: string; password: string; to: string }) => {
    try {
      await signInWithEmailAndPassword(getAuth(getFirebaseApp()), email, password);
      dispatch(pushLocation(to ?? '/vo'));
      return { isOk: true };
    } catch (error: any) {
      return { isOk: false, error: 'Login failed' };
    }
  },
);

export const signInWithFirebaseAuthToken = createThunk(
  'signInWithFirebaseAuthToken',
  async (dispatch, _getState, { customToken }: { customToken: string }) => {
    try {
      await signInWithCustomToken(getAuth(getFirebaseApp()), customToken);
      return { isOk: true };
    } catch (error) {
      return { isOk: false, error: 'Login failed' };
    }
  },
);

export const attemptEmailVerification = createThunk(
  'attemptEmailVerification',
  async (
    dispatch,
    _getState,
    { email, verificationCode, to }: { email: string; verificationCode: string; to: string },
  ) => {
    try {
      const { customToken, error } = await apiCall('verifyCode', {
        verificationCode,
        email,
      });
      if (error) return { isOk: false, error };

      await signInWithCustomToken(getAuth(getFirebaseApp()), customToken!);
      dispatch(pushLocation(to ?? '/vo'));
      return { isOk: true };
    } catch {
      return { isOk: false, error: 'Email verification failed' };
    }
  },
);

export const attemptLinkAccount = createThunk(
  'attemptLinkAccount',
  async (
    dispatch,
    _getState,
    { email, password, code }: { email: string; password: string; code: string },
  ) => {
    try {
      const { customToken, error } = await apiCall('linkAccount', { email, password, code });
      if (error) return { isOk: false, error };
      await signInWithCustomToken(getAuth(getFirebaseApp()), customToken!);
      dispatch(pushLocation('/vo'));
      return { isOk: true };
    } catch {
      return { isOk: false, error: 'Failed to link account' };
    }
  },
);

export const createAccount = createThunk(
  'attemptEmailVerification',
  async (
    dispatch,
    _getState,
    { displayName, email, password }: { displayName: string; email: string; password: string },
  ) => {
    try {
      const { customToken, error } = await apiCall('createAccount', { displayName, email, password });
      if (error) return { isOk: false, error };

      await signInWithCustomToken(getAuth(getFirebaseApp()), customToken!);
      dispatch(pushLocation('/vo'));
      return { isOk: true };
    } catch (error) {
      return { isOk: false, error: 'Failed to create account' };
    }
  },
);

export const sendPasswordResetEmail = createThunk(
  'sendPasswordResetEmail',
  async (_dispatch, _getState, { email }: { email: string }) => {
    try {
      return apiCall('sendPasswordResetEmail', { email });
    } catch (error) {
      return { isOk: false, error: 'Failed to send reset email' };
    }
  },
);

export const resetPassword = createThunk(
  'resetPassword',
  async (
    dispatch,
    _getState,
    { email, password, code }: { email: string; password: string; code: string },
  ) => {
    try {
      const { customToken, error } = await apiCall('resetPassword', { email, password, code });
      if (error) return { isOk: false, error };

      await signInWithCustomToken(getAuth(getFirebaseApp()), customToken!);
      dispatch(pushLocation('/vo'));
      return { isOk: true };
    } catch (error) {
      return { isOk: false, error: 'Failed to reset password' };
    }
  },
);
export const signOut = () => firebaseSignOut(getAuth(getFirebaseApp()));
export const signedIn = createAction<{
  id: UserId;
  email: string;
  displayName?: string | null;
  avatarUrl?: string | null;
}>('signedIn');
export const signedOut = createAction('signedOut');
export const setSelfDisplayName = createAction<string>('setSelfDisplayName');
export const setSelfEmail = createAction<{ email: string; password: string }>('setSelfEmail');
export const setSelfPassword = createAction<{ old: string; new: string }>('setSelfPassword');
export const deleteAccount = createAction('deleteAccount');
export const loginBlocked = createAction('loginBlocked');

export default createReducer()
  .on(signedIn, (state, { payload: auth }) => {
    state.id = auth.id;
    state.email = auth.email;
    state.displayName = auth.displayName;
    state.avatarUrl = auth.avatarUrl;
    state.isSignedIn = true;
  })
  .on(signedOut, (state) => {
    delete state.id;
    delete state.email;
    delete state.displayName;
    delete state.avatarUrl;
    state.isSignedIn = false;
  });

export const getSelfDisplayName = createSelector((state) => state.auth.displayName);
export const getSelfUserId = createSelector((state) => state.auth.id);
export const getSelfEmail = createSelector((state) => state.auth.email);
export const getIsSignedIn = createSelector((state) => state.auth.isSignedIn);
