import { assertExhaustedType } from 'common/utils/ts-utils';
import { isMajorVersionUpdate, isVersionUpToDate } from 'pages/vo/vo-react/features/version/version.utils';
import { isApp } from 'utils/client-utils';

import { createSlice } from '../../redux/create-slice';

export type VersionSlice = {
  clientInstalled?: string;
  nativeInstalled?: string;
  clientAvailable?: string;
  nativeAvailable?: string;
  clientExpected?: string;
  nativeExpected?: string;
  clientAvailableReleaseTs?: number;
  nativeAvailableReleaseTs?: number;
  hasWaitedMinTimeAfterUpdateReady?: boolean;
  hasWaitedMaxTimeAfterUpdateReady?: boolean;
  didDownloadTakeLong?: boolean;
  didNativeUpdateDownload?: boolean;
  didUserRequestUpdate?: boolean;
};

const initialState: VersionSlice = {
  clientInstalled: __CLIENT_VERSION__,
};

export const { createReducer, createSelector, createMemoizedSelector, createAction } = createSlice(
  'version',
  initialState,
);

export const setAvailableVersion =
  createAction<{ type: 'client' | 'native'; version: string; releaseTs?: number }>('setAvailableVersion');
export const setInstalledVersion =
  createAction<{ type: 'client' | 'native'; version: string }>('setInstalledVersion');
export const setExpectedVersion =
  createAction<{ type: 'client' | 'native'; version: string }>('setExpectedVersion');
export const setDidUserRequestUpdate = createAction('setDidUserRequestUpdate');
export const setHasWaitedMinTimeAfterUpdateReady = createAction<boolean>(
  'setHasWaitedMinTimeAfterUpdateReady',
);
export const setHasWaitedMaxTimeAfterUpdateReady = createAction<boolean>(
  'setHasWaitedMaxTimeAfterUpdateReady',
);
export const setDidNativeUpdateDownload = createAction('setDidNativeUpdateDownload');
export const setDidDownloadTakeLong = createAction<boolean>('setDidDownloadTakeLong');
export const userRequestedRestartAfterDownloadComplete = createAction(
  'userRequestedRestartAfterDownloadComplete',
);

export default createReducer()
  .on(setAvailableVersion, (state, { payload: { type, version, releaseTs } }) => {
    if (type === 'client') {
      state.clientAvailable = version;
      state.clientAvailableReleaseTs = releaseTs;
    } else if (type === 'native') {
      state.nativeAvailable = version;
      state.nativeAvailableReleaseTs = releaseTs;
    } else assertExhaustedType(type);
  })
  .on(setInstalledVersion, (state, { payload: { type, version } }) => {
    if (type === 'client') {
      state.clientInstalled = version;
    } else if (type === 'native') {
      state.nativeInstalled = version;
    } else assertExhaustedType(type);
  })
  .on(setExpectedVersion, (state, { payload: { type, version } }) => {
    if (type === 'client') {
      state.clientExpected = version;
    } else if (type === 'native') {
      state.nativeExpected = version;
    } else assertExhaustedType(type);
  })
  .on(setDidUserRequestUpdate, (state) => {
    state.didUserRequestUpdate = true;
    state.didDownloadTakeLong = false;
  })
  .on(setHasWaitedMinTimeAfterUpdateReady, (state, { payload: hasWaitedMinTimeAfterUpdateReady }) => {
    state.hasWaitedMinTimeAfterUpdateReady = hasWaitedMinTimeAfterUpdateReady;
  })
  .on(setHasWaitedMaxTimeAfterUpdateReady, (state, { payload: hasWaitedMaxTimeAfterUpdateReady }) => {
    state.hasWaitedMaxTimeAfterUpdateReady = hasWaitedMaxTimeAfterUpdateReady;
  })
  .on(setDidNativeUpdateDownload, (state) => {
    state.didNativeUpdateDownload = true;
  })
  .on(setDidDownloadTakeLong, (state, { payload: didDownloadTakeLong }) => {
    state.didDownloadTakeLong = didDownloadTakeLong;
  });

export const getClientInstalledVersion = createSelector((state) => state.version.clientInstalled);
export const getNativeInstalledVersion = createSelector((state) => state.version.nativeInstalled);
export const getClientAvailableVersion = createSelector((state) => state.version.clientAvailable);
export const getNativeAvailableVersion = createSelector((state) => state.version.nativeAvailable);
export const getClientExpectedVersion = createSelector((state) => state.version.clientExpected);
export const getNativeExpectedVersion = createSelector((state) => state.version.nativeExpected);
export const getClientAvailableReleaseTs = createSelector((state) => state.version.clientAvailableReleaseTs);
export const getNativeAvailableReleaseTs = createSelector((state) => state.version.nativeAvailableReleaseTs);
export const getIsClientUpdateAvailable = createSelector(
  (state) => !isVersionUpToDate(state.version.clientInstalled, state.version.clientAvailable),
);
export const getAreNativeVersionsReady = createSelector(
  (state) => isApp && !!state.version.nativeInstalled && !!state.version.nativeAvailable,
);
export const getIsNativeUpdateAvailable = createSelector(
  (state) => isApp && !isVersionUpToDate(state.version.nativeInstalled, state.version.nativeAvailable),
);
export const getIsNativeUpdateAvailableAndIsNotDownloaded = createSelector(
  (state) =>
    isApp &&
    !state.version.didNativeUpdateDownload &&
    !isVersionUpToDate(state.version.nativeInstalled, state.version.nativeAvailable),
);
export const getIsUpdateAvailable = createSelector(
  (state) => getIsClientUpdateAvailable(state) || getIsNativeUpdateAvailable(state),
);
export const getHasWaitedMinTimeAfterUpdateReady = createSelector(
  (state) => state.version.hasWaitedMinTimeAfterUpdateReady,
);
export const getHasWaitedMaxTimeAfterUpdateReady = createSelector(
  (state) => state.version.hasWaitedMaxTimeAfterUpdateReady,
);
export const getDidNativeUpdateDownload = createSelector((state) => state.version.didNativeUpdateDownload);
export const getDidUserRequestUpdate = createSelector((state) => state.version.didUserRequestUpdate);
export const getIsMajorClientUpdateReady = createSelector(
  (state) =>
    getIsClientUpdateAvailable(state) &&
    isMajorVersionUpdate(getClientInstalledVersion(state)!, getClientAvailableVersion(state)!),
);
export const getIsMajorNativeUpdateReady = createSelector(
  (state) =>
    getDidNativeUpdateDownload(state) &&
    isMajorVersionUpdate(getNativeInstalledVersion(state)!, getNativeAvailableVersion(state)!),
);
export const getIsMajorUpdateReady = createSelector(
  (state) => getIsMajorClientUpdateReady(state) || getIsMajorNativeUpdateReady(state),
);
export const getDidDownloadTakeLong = createSelector((state) => state.version.didDownloadTakeLong);
