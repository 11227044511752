// from https://raw.githubusercontent.com/joonhocho/firebase-encode/master/src/index.js
const escapeRegExp = (str: any) => str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');

function create(chars: any) {
  const charCodes = chars.map((c: any) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);

  const charToCode = {};
  const codeToChar = {};
  chars.forEach((c: any, i: any) => {
    charToCode[c] = charCodes[i];
    codeToChar[charCodes[i]] = c;
  });

  const charsRegex = new RegExp(`[${escapeRegExp(chars.join(''))}]`, 'g');
  const charCodesRegex = new RegExp(charCodes.join('|'), 'g');

  const escape = (str: any) => str.replace(charsRegex, (match: any) => charToCode[match]);
  const unescape = (str: any) => str.replace(charCodesRegex, (match: any) => codeToChar[match]);

  return [escape, unescape];
}

export const [escapeFirebaseKey, unescapeFirebaseKey] = create('.$[]#%/'.split(''));
export const [escapeFirebasePath, unescapeFirebasePath] = create('.$[]#%'.split('')); // Without `/`
