export function zIndex(type: 'popover' | 'modal' | 'toast') {
  switch (type) {
    case 'modal':
      return 200;
    case 'popover':
      return 100;
    case 'toast':
      return 50;
    default:
      return 0;
  }
}
