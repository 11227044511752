import { useRef } from 'react';

/**
 * An UpdateRef is just a ref that always holds the up-to-date value. This can
 * be useful when you don't want a memo or callback to reevaluate but you would
 * like it to have the latest value when it runs next on some other trigger.
 */
export const useUpdateRef = <T>(state: T) => {
  const updateRef = useRef(state);
  updateRef.current = state;
  return updateRef;
};
