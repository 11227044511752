import { debounceTime, groupBy, ignoreElements, map, merge, mergeMap, of, tap } from 'rxjs';

import { serializeQualifiedPeerId } from 'common/models/db/vo.interface';
import { mapSelector, ofActionPayload } from 'common/utils/custom-rx-operators';
import { observeUnsubscribe } from 'common/utils/observe-unsubscribe';
import { getFloofConnection } from 'utils/floof-sdk/floof-sdk';
import { getEnhancedTrackForTrack } from 'utils/react/audio-context';

import { sendIpc } from '../../ipc/send-ipc';
import { Epic } from '../../redux/app-store';
import { mergeMapDuringSession } from '../floof/floof.utils';
import { getMicrophoneTrack } from '../media/media.slice';
import { mirrorActionToAllPeers } from '../remote-actions/remote-actions.actions';

import { clearCaption, gotSelfCaption, ingestCaption } from './captions.slice';

export const broadcastCaptionEpic: Epic = (action$) =>
  action$.pipe(
    mergeMapDuringSession((sessionId) =>
      action$.pipe(
        ofActionPayload(gotSelfCaption),
        mergeMap(({ caption }) =>
          merge(
            // Broadcast this caption to others as a remote peer...
            of(
              mirrorActionToAllPeers(
                ingestCaption({
                  peerId: getFloofConnection(sessionId).getSelfPeerId(),
                  caption,
                }),
              ),
            ),
            // ...and broadcast it to ourselves as a self peer.
            of(
              ingestCaption({
                peerId: 'self',
                sessionId,
                caption,
              }),
            ),
          ),
        ),
      ),
    ),
  );

export const removeCaptionAfterInactivity: Epic = (action$) =>
  action$.pipe(
    ofActionPayload(ingestCaption),
    groupBy((payload) => serializeQualifiedPeerId(payload)),
    mergeMap((groupedByPeer$) =>
      groupedByPeer$.pipe(
        debounceTime(5_000),
        map((payload) => clearCaption(payload)),
      ),
    ),
  );

export const transcribeAudioEpic: Epic = (action$, state$) =>
  action$.pipe(
    mergeMapDuringSession(() => {
      void sendIpc('toggleTranscriber', true);

      return merge(
        observeUnsubscribe(() => {
          void sendIpc('toggleTranscriber', false);
        }),
        state$.pipe(
          mapSelector(getMicrophoneTrack, 'self' as const),
          tap((track) => {
            void getEnhancedTrackForTrack(track)?.startTranscript();
          }),
          ignoreElements(),
        ),
      );
    }),
  );
