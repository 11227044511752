import { map } from 'rxjs';

import { IpcToClient } from 'common/models/ipc-messages';
import { filterIsTruthy, ofActionPayload } from 'common/utils/custom-rx-operators';
import { assertExhaustedType } from 'common/utils/ts-utils';
import { onKeyboardShortcut } from 'pages/vo/vo-react/features/shortcuts/shortcuts.slice';
import { initializeReceiveIpc } from 'pages/vo/vo-react/ipc/receive-ipc';

import { onActivityDetected, setIsLocked, setIsSuspended } from '../features/activity/activity.slice';
import { gotSelfCaption } from '../features/captions/captions.slice';
import { setIsFullscreenReadonly } from '../features/desktop-windows/desktop-windows.slice';
import { onIagoLocalEvent } from '../features/iago/iago-state';
import { updateScreensFromDesktop } from '../features/screens/screens.slice';
import { handleProtocolUrl } from '../protocol-url';
import { EpicWithDeps, RootReduxDispatch } from '../redux/app-store';

import { ipcToClient } from './send-ipc';

initializeReceiveIpc();

const handlers: {
  [k in keyof IpcToClient]: (
    dispatch: RootReduxDispatch,
    ...args: Parameters<IpcToClient[k]>
  ) => ReturnType<IpcToClient[k]>;
} = {
  updateScreens: (dispatch, payload) => {
    dispatch(updateScreensFromDesktop(payload));
  },
  onIagoEvent: (dispatch, event) => {
    dispatch(onIagoLocalEvent(event));
  },
  onPowerMonitorEvent: (dispatch, eventName) => {
    switch (eventName) {
      case 'suspend':
      case 'resume':
        return dispatch(setIsSuspended(eventName === 'suspend'));
      case 'lock-screen':
      case 'unlock-screen':
        return dispatch(setIsLocked(eventName === 'lock-screen'));
    }
    assertExhaustedType(eventName);
  },
  onActivityDetected: (dispatch) => {
    dispatch(onActivityDetected());
  },
  onKeyboardShortcut: (dispatch, shortcutId) => {
    dispatch(onKeyboardShortcut(shortcutId));
  },
  onTranscript: (dispatch, caption) => {
    dispatch(gotSelfCaption({ caption }));
  },
  onFullscreen: (dispatch, windowId, isFullscreen) => {
    dispatch(setIsFullscreenReadonly({ windowId, isFullscreen }));
  },
  onProtocolUrl: (dispatch, url) => {
    void dispatch(handleProtocolUrl({ url }));
  },
};

export const handleIpcFromMainEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(ipcToClient),
    map(({ type, handlerArgs }) => {
      let actionToDispatch;
      // Do some goofiness to allow a `handlers` object that has similar
      // semantics to the ipc handlers in the main process.
      const fakeDispatch = (val: any) => (actionToDispatch = val);
      handlers[type]?.(fakeDispatch, ...handlerArgs);
      return actionToDispatch as any;
    }),
    filterIsTruthy(),
  );
