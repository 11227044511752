import { CalendarId } from '../../models/db/calendar.interface';
import { EventId, EventInstanceId } from '../../models/db/event.interface';
import { InstantMeetingId, SpaceId, TeamId } from '../../models/db/vo.interface';

export type SessionInitiatorType = 'event' | 'event-instance' | 'space' | 'team' | 'instant-meeting';
export type SessionInitiatorId = TeamId | EventId | SpaceId | InstantMeetingId;

export type AnySessionInitiator =
  | EventSessionInitiator
  | EventInstanceSessionInitiator
  | SpaceSessionInitiator
  | TeamSessionInitiator
  | InstantMeetingSessionInitiator;

export type JoinableSessionInitiator =
  | EventInstanceSessionInitiator
  | SpaceSessionInitiator
  | TeamSessionInitiator
  | InstantMeetingSessionInitiator;

export type SessionInitiator =
  | EventSessionInitiator
  | SpaceSessionInitiator
  | TeamSessionInitiator
  | InstantMeetingSessionInitiator;

export interface EventSessionInitiator {
  type: 'event';
  id: EventId;
}

export const isEventSessionInitiator = (
  sessionInitiator?: AnySessionInitiator,
): sessionInitiator is EventSessionInitiator => sessionInitiator?.type === 'event';

export interface EventInstanceSessionInitiator {
  type: 'event-instance';
  id: EventInstanceId;
  calendarId: CalendarId;
}

export const isEventInstanceSessionInitiator = (
  sessionInitiator?: AnySessionInitiator,
): sessionInitiator is EventInstanceSessionInitiator => sessionInitiator?.type === 'event-instance';

export interface SpaceSessionInitiator {
  type: 'space';
  id: SpaceId;
}

export const isSpaceSessionInitiator = (
  sessionInitiator?: AnySessionInitiator,
): sessionInitiator is SpaceSessionInitiator => sessionInitiator?.type === 'space';

export interface TeamSessionInitiator {
  type: 'team';
  id: TeamId;
}

export const isTeamSessionInitiator = (
  sessionInitiator?: AnySessionInitiator,
): sessionInitiator is TeamSessionInitiator => sessionInitiator?.type === 'team';

export interface InstantMeetingSessionInitiator {
  type: 'instant-meeting';
  id: InstantMeetingId;
}

export const isInstantMeetingSessionInitiator = (
  sessionInitiator?: AnySessionInitiator,
): sessionInitiator is InstantMeetingSessionInitiator => sessionInitiator?.type === 'instant-meeting';
