import { memoize } from 'lodash';

import { sendIpc } from 'pages/vo/vo-react/ipc/send-ipc';
import { isDesktopApp } from 'utils/client-utils';

import transcriptProcessorWorkletUrl from '../workers/transcript-processor?url';

import { exposeToGlobalConsole } from './expose-to-global-console';

export const getAudioContext = memoize(() => {
  const context = new AudioContext({
    sampleRate: 16000,
  });
  if (isDesktopApp) {
    void context.audioWorklet.addModule(transcriptProcessorWorkletUrl);
  }
  return context;
});
exposeToGlobalConsole({ getAudioContext });

export const ensureAudioContextActive = (): void => {
  document.removeEventListener('click', ensureAudioContextActive);

  switch (getAudioContext().state) {
    case 'closed':
      getAudioContext.cache.clear?.();
      return ensureAudioContextActive();
    case 'suspended':
      if ((navigator as any).userActivation?.hasBeenActive) {
        void getAudioContext().resume();
      } else {
        document.addEventListener('click', ensureAudioContextActive);
      }
  }
};

export const getEnhancedTrackForTrack = (track?: MediaStreamTrack) =>
  track ? EnhancedMicrophoneTrack.enhancedTracksById[track.id] : undefined;

export const makeEnhancedTrackForTrack = (track: MediaStreamTrack) => new EnhancedMicrophoneTrack(track);

/**
 * Enhanced tracks encapsulate the audio analysis processing of audio tracks
 * such that if you have a handle to the track, you can get a handle to the
 * EnhancedTrack and easily grab the latest audio level.
 */
class EnhancedMicrophoneTrack {
  static enhancedTracksById: { [id: string]: EnhancedMicrophoneTrack } = {};

  private source = getAudioContext().createMediaStreamSource(new MediaStream([this.inputTrack]));
  private analyzer = getAudioContext().createAnalyser();
  private transcriptionWorklet: AudioWorkletNode | undefined;

  constructor(private inputTrack: MediaStreamTrack) {
    this.source.connect(this.analyzer);
    EnhancedMicrophoneTrack.enhancedTracksById[this.inputTrack.id] = this;
  }

  public getAudioLevel() {
    const frequencyData = new Uint8Array(1);
    this.analyzer.getByteFrequencyData(frequencyData);
    return frequencyData[0];
  }

  public getNormalizedFrequencyBins(numBins = 4) {
    const bufferLength = this.analyzer.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    this.analyzer.getByteFrequencyData(dataArray);

    const binSize = Math.floor(bufferLength / numBins);
    const bins = [];
    for (let i = 0; i < numBins; i++) {
      let sum = 0;
      for (let j = 0; j < binSize; j++) {
        sum += dataArray[i * binSize + j];
      }
      // Average and normalize to 0 to 1.
      bins.push(sum / binSize / 255);
    }
    return bins;
  }

  public async startTranscript() {
    ensureAudioContextActive();
    await getAudioContext().audioWorklet.addModule(transcriptProcessorWorkletUrl);
    this.transcriptionWorklet = new AudioWorkletNode(getAudioContext(), 'transcript-processor', {
      processorOptions: { channelCount: 1, reportSize: 3072 },
    });
    this.transcriptionWorklet.onprocessorerror = console.trace;
    this.transcriptionWorklet.port.onmessage = async (e: MessageEvent<{ recordBuffer: Float32Array[] }>) => {
      const { recordBuffer } = e.data;
      if (recordBuffer[0].length === 0) return;
      void sendIpc('transcribeAudio', recordBuffer[0]);
    };
    this.analyzer.connect(this.transcriptionWorklet);
  }

  public destroy() {
    this.source.disconnect();
    this.analyzer.disconnect();
    this.transcriptionWorklet?.disconnect();
    delete EnhancedMicrophoneTrack.enhancedTracksById[this.inputTrack.id];
  }
}

exposeToGlobalConsole({ EnhancedMicrophoneTrack });
