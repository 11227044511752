/**
 * BufferProxy allows us to optionally delay the execution of a function calls (provided to `.proxy()`)
 * until we want to play the calls out in sequence with elapsed time between calls preserved.
 */
export class BufferProxy {
  private buffer: [number, () => any][] = [];
  private isBuffering = false;
  private lastBufferMs: number | undefined;

  public getIsBuffering() {
    return this.isBuffering;
  }

  public toggleBuffer(toggled: boolean) {
    this.isBuffering = toggled;
    if (!toggled) delete this.lastBufferMs;
  }

  public clear() {
    this.buffer = [];
    delete this.lastBufferMs;
  }

  public async flush() {
    const flushingBuffer = this.buffer;
    this.clear();

    return new Promise<void>((resolve) => {
      function queueNext() {
        if (!flushingBuffer.length) {
          resolve();
          return;
        }
        const [nextWaitMs, nextFn] = flushingBuffer.shift()!;
        setTimeout(() => {
          nextFn();
          queueNext();
        }, nextWaitMs);
      }

      queueNext();
    });
  }

  public async proxy<Fn extends () => any>(fn: Fn): Promise<ReturnType<Fn>> {
    return new Promise((resolve) => {
      if (!this.getIsBuffering()) {
        resolve(fn());
        return;
      }

      this.buffer.push([this.lastBufferMs ? Date.now() - this.lastBufferMs! : 0, () => resolve(fn())]);
      this.lastBufferMs = Date.now();
    });
  }
}
