import autoBind from 'auto-bind';
import { each, find, keyBy, mapValues } from 'lodash';
import { DistributiveOmit } from 'react-redux';

import { MediaType } from 'common/models/connection.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import {
  FloofMessages,
  MediasoupConsumerId,
  MediasoupProducerId,
  OnTrackSentInfo,
  P2pGenericSignalingMessage,
  Transport,
  TransportContents,
  allTransports,
} from 'common/models/floof.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { allMediaTypes } from 'utils/client-utils';
import { debugCheck } from 'utils/debug-check';
import { FloofAnalyticsDelegate } from 'utils/floof-sdk/floof-sdk';
import { P2pMediaConnectionManager } from 'utils/floof-sdk/media-connection/p2p/p2p-media-connection-manager';
import { SfuMediaConnectionManager } from 'utils/floof-sdk/media-connection/sfu/sfu-media-connection-manager';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';

export interface MediaConnectionDelegate {
  onTrackSent: (info: OnTrackSentInfo) => void;
  onTrackReceived: (info: {
    peerId: RemotePeerId;
    track: MediaStreamTrack;
    mediaType: MediaType;
    transport: Transport;
    trackTransportId: string;
  }) => any;
  onTrackRemoved: (peerId: RemotePeerId, mediaType: MediaType, trackId: string) => void;
  onPeerMessageReceived: (peerId: RemotePeerId, peerMessage: PeerMessage) => any;
  onRoomSignalChannelMessageRequested: <T extends FloofMessages['requests']['client-to-server']['room']>(
    message: DistributiveOmit<T, 'response'>,
  ) => T['response'];
  onP2pSignalChannelMessageRequested: <T extends FloofMessages['requests']['client-to-server']['p2p']>(
    message: DistributiveOmit<T, 'response'>,
  ) => T['response'];
  onSfuSignalChannelMessageRequested: <T extends FloofMessages['requests']['client-to-server']['sfu']>(
    message: DistributiveOmit<T, 'response'>,
  ) => T['response'];
  onDisconnectRequested: () => any;
}

export class DualMediaConnection {
  private isSignalingChannelConnected = false;
  private trackForMediaType: { [mediaType in MediaType]?: MediaStreamTrack } = {};
  private preferredTransportForTransportContents: { [transportContents in TransportContents]?: Transport } =
    {};
  private mediaConnectionManagers: { p2p?: P2pMediaConnectionManager; sfu?: SfuMediaConnectionManager } = {};
  constructor(
    private selfPeerId: RemotePeerId,
    private delegate: MediaConnectionDelegate,
    private analyticsDelegate?: FloofAnalyticsDelegate,
  ) {
    autoBind(this);
    exposeToGlobalConsole({ dualMediaConnection: this });
  }

  public setPreferredTransportForTransportContents(
    transportContents: TransportContents,
    preferredTransport: Transport,
  ) {
    this.preferredTransportForTransportContents[transportContents] = preferredTransport;
    this.maybeCreateConnectionManagers();
  }

  public getPreferredTransportForTransportContents(transportContents: TransportContents) {
    return this.preferredTransportForTransportContents[transportContents];
  }

  public async sendPeerMessage(peerMessage: PeerMessage) {
    if (this.preferredTransportForTransportContents.data === 'sfu')
      this.delegate.onRoomSignalChannelMessageRequested({
        method: 'req-c2s-room-send-peer-message',
        data: {
          peerMessage,
        },
      });
    else if (this.preferredTransportForTransportContents.data === 'p2p')
      await this.mediaConnectionManagers.p2p?.sendPeerMessage(peerMessage);
  }

  public async sendMediaTrack({
    track: maybePromiseTrack,
    mediaType,
  }: {
    track: Promise<MediaStreamTrack> | MediaStreamTrack;
    mediaType: MediaType;
  }) {
    const track = await maybePromiseTrack;
    this.trackForMediaType[mediaType] = track;
    const preferredTransport = this.preferredTransportForTransportContents[mediaType];
    if (!preferredTransport) return;
    await this.mediaConnectionManagers[preferredTransport]?.sendTrack(track, mediaType);
  }

  public setShouldShowRemoteHdVideo(opts: { [peerId: string]: boolean; default: boolean }) {
    console.log('todo', { opts });
  }

  public disconnect() {
    each(allTransports, (transport) => this.deleteConnectionManager(transport));
  }

  public setIsSignalChannelConnected(isConnected: boolean) {
    this.isSignalingChannelConnected = isConnected;
    this.maybeCreateConnectionManagers();
  }

  private maybeCreateConnectionManagers() {
    if (this.isSignalingChannelConnected) {
      const isTransportNeeded = mapValues(
        keyBy(allTransports),
        (transport) =>
          !!find(
            this.preferredTransportForTransportContents,
            (preferredTransport) => preferredTransport === transport,
          ),
      ) as { [transport in Transport]: boolean };
      each(isTransportNeeded, (isNeeded, transport: Transport) => {
        if (isNeeded) {
          if (!this.mediaConnectionManagers[transport]) this.createConnectionManager(transport);
          allMediaTypes.map(
            (mediaType) =>
              void this.mediaConnectionManagers[transport]?.sendTrack(
                this.preferredTransportForTransportContents[mediaType] === transport
                  ? this.trackForMediaType[mediaType]!
                  : undefined,
                mediaType,
              ),
          );
        } else if (!isNeeded && this.mediaConnectionManagers[transport])
          this.deleteConnectionManager(transport);
      });
    } else {
      allTransports.map((transport) => this.deleteConnectionManager(transport));
    }
  }

  private deleteConnectionManager(transport: Transport) {
    this.mediaConnectionManagers[transport]?.disconnect();
    delete this.mediaConnectionManagers[transport];
  }

  private createConnectionManager(transport: Transport) {
    // Create the actual media connection manager.
    if (transport === 'p2p') {
      this.mediaConnectionManagers.p2p = new P2pMediaConnectionManager(
        this.selfPeerId,
        this.analyticsDelegate,
        {
          onTrackSent: (info) => this.delegate.onTrackSent({ ...info, transport: 'p2p' }),
          onTrackReceived: (info) => this.delegate.onTrackReceived({ ...info, transport: 'p2p' }),
          onTrackRemoved: () => {},
          onMessageReceived: (peerMessage, peerId) =>
            this.delegate.onPeerMessageReceived(peerId, peerMessage),
        },
        this.delegate.onP2pSignalChannelMessageRequested,
      );
    } else if (transport === 'sfu') {
      this.mediaConnectionManagers.sfu = new SfuMediaConnectionManager(
        this.selfPeerId,
        this.analyticsDelegate,
        {
          onTrackSent: (info) => this.delegate.onTrackSent({ ...info, transport: 'sfu' }),
          onTrackReceived: (info) => this.delegate.onTrackReceived({ ...info, transport: 'sfu' }),
          onTrackRemoved: () => {},
          onMessageReceived: (peerMessage, peerId) =>
            this.delegate.onPeerMessageReceived(peerId, peerMessage),
          onSfuSignalChannelMessageSendRequested: this.delegate.onSfuSignalChannelMessageRequested,
          onDisconnectRequested: this.delegate.onDisconnectRequested,
        },
      );
    }
  }

  public createP2pMediaConnection(remotePeerId: RemotePeerId) {
    this.mediaConnectionManagers.p2p?.createMediaConnection(remotePeerId);
  }

  public deleteP2pMediaConnection(remotePeerId: RemotePeerId) {
    this.mediaConnectionManagers.p2p?.deleteMediaConnection(remotePeerId);
  }

  public receiveP2pGenericMessage(peerId: RemotePeerId, message: P2pGenericSignalingMessage) {
    void this.mediaConnectionManagers.p2p?.receiveGenericMessage(peerId, message);
  }

  public createSfuConsumer(
    req: {
      peerId: RemotePeerId;
      producerId: MediasoupProducerId;
      id: MediasoupConsumerId;
      kind: any;
      rtpParameters: any;
      appData: any;
    },
    accept: () => void,
    reject: () => void,
  ) {
    debugCheck(!!this.mediaConnectionManagers.sfu, 'createSfuConsumer called when sfu is undefined!');
    void this.mediaConnectionManagers.sfu?.createConsumer(req, accept, reject);
  }

  public deleteSfuConsumer(consumerId: MediasoupConsumerId) {
    this.mediaConnectionManagers.sfu?.deleteConsumer(consumerId);
  }
}
