import { getDay, millisecondsToMinutes, millisecondsToSeconds, minutesToMilliseconds } from 'date-fns';
import {
  filter,
  groupBy,
  last,
  map,
  mapValues,
  maxBy,
  memoize,
  omit,
  orderBy,
  pull,
  reduce,
  sortBy,
  uniqBy,
} from 'lodash';

import {
  isSessionHistoryEntryDetailsJoinedOrLeft,
  SessionHistoryEntryId,
  SessionHistoryId,
} from 'common/models/db/session-history.interface';
import { OrgId, SessionId } from 'common/models/db/vo.interface';
import { AnyPeerId } from 'common/models/db/vo.interface';
import { DateTimeBlock, getSliceForBoundaryInSortedArray } from 'common/utils/date-time';
import { debugCheck } from 'utils/debug-check';

import { createSlice } from '../../redux/create-slice';

import {
  isSessionHistoryEntryTranscription,
  LONG_DELAY_THRESHOLD_MINUTES,
  ProcessedTranscript,
  SessionHistory,
  SessionHistoryEntry,
  SessionHistoryEntryTranscription,
  SHORT_DELAY_THRESHOLD_SECONDS,
} from './session-history.types';
import { isTranscriptTsFresh } from './session-history.utils';

export type SessionHistorySlice = {
  sessionHistories: { [sessionHistoryId: string]: SessionHistory };
  allSessionHistoryIdsByOrgId: { [orgId: string]: SessionHistoryId[] };
  sessionHistoryIdsBySessionId: { [sessionId: string]: SessionHistoryId[] };
};

const initialState: SessionHistorySlice = {
  sessionHistories: {},
  allSessionHistoryIdsByOrgId: {},
  sessionHistoryIdsBySessionId: {},
};
export const {
  createReducer,
  createSelector,
  createAction,
  createParametricMemoizedSelector,
  createMemoizedSelector,
  createAsyncAction,
} = createSlice('sessionHistory', initialState);

export const listenToSessionHistory = createAction<{
  payload: {
    sessionHistoryId: SessionHistoryId;
    orgId: OrgId;
  };
  type: 'create' | 'destroy';
}>('listenToSessionHistory');
export const dbSessionHistoryCreated = createAction<{
  sessionHistory: Omit<SessionHistory, 'entries'>;
  orgId: OrgId;
}>('dbSessionHistoryCreated');
export const dbSessionHistoryDeleted = createAction<{
  id: SessionHistoryId;
  orgId: OrgId;
}>('dbSessionHistoryDeleted');
export const dbSessionHistoryEntryCreatedOrUpdated = createAction<SessionHistoryEntry>(
  'dbSessionHistoryEntryCreatedOrUpdated',
);
export const dbSessionHistoryEntryDeleted = createAction<{
  id: SessionHistoryEntryId;
  sessionHistoryId: SessionHistoryId;
}>('dbSessionHistoryEntryDeleted');

export default createReducer()
  .on(dbSessionHistoryCreated, (state, { payload: { sessionHistory, orgId } }) => {
    if (!state.allSessionHistoryIdsByOrgId[orgId])
      state.allSessionHistoryIdsByOrgId[orgId] = [sessionHistory.id];
    else if (!state.allSessionHistoryIdsByOrgId[orgId].includes(sessionHistory.id))
      state.allSessionHistoryIdsByOrgId[orgId].push(sessionHistory.id);
    if (!state.sessionHistoryIdsBySessionId[sessionHistory.sessionId])
      state.sessionHistoryIdsBySessionId[sessionHistory.sessionId] = [];
    state.sessionHistoryIdsBySessionId[sessionHistory.sessionId].push(sessionHistory.id);
    state.sessionHistories[sessionHistory.id] = {
      ...sessionHistory,
      entries: {},
    };
  })
  .on(dbSessionHistoryDeleted, (state, { payload: { id, orgId } }) => {
    debugCheck(
      state.allSessionHistoryIdsByOrgId[orgId].includes(id),
      `dbSessionHistoryDeleted: session history ${id} doesn't exist!`,
    );
    pull(state.allSessionHistoryIdsByOrgId[orgId], id);
    pull(state.sessionHistoryIdsBySessionId[state.sessionHistories[id].sessionId], id);
    delete state.sessionHistories[id];
  })
  .on(dbSessionHistoryEntryCreatedOrUpdated, (state, { payload: entry }) => {
    const sessionHistory = state.sessionHistories[entry.sessionHistoryId];
    debugCheck(
      !!sessionHistory,
      `dbSessionHistoryEntryCreatedOrUpdated: ${entry.sessionHistoryId} not found!`,
    );
    sessionHistory.entries[entry.id] = entry;
    if (isSessionHistoryEntryDetailsJoinedOrLeft(entry.details) && entry.details.action === 'left')
      sessionHistory.endTs = Math.max(sessionHistory.endTs ?? 0, entry.ts);
  })
  .on(dbSessionHistoryEntryDeleted, (state, { payload: { id, sessionHistoryId } }) => {
    debugCheck(
      !!state.sessionHistories[sessionHistoryId].entries[id],
      `dbSessionHistoryEntryDeleted: id: ${id}, sessionHistoryId: ${sessionHistoryId} not found!`,
    );
    delete state.sessionHistories[sessionHistoryId].entries[id];
  });

export const getStoredNameForSessionHistory = createSelector(
  (state, sessionHistoryId: SessionHistoryId) =>
    state.sessionHistory.sessionHistories[sessionHistoryId]?.name,
);
export const getSessionHistories = createSelector((state) => state.sessionHistory.sessionHistories);

export const getSessionHistoryIdsForOrgId = createSelector(
  (state, orgId: OrgId) => state.sessionHistory.allSessionHistoryIdsByOrgId[orgId],
);
export const getSessionHistoryIdsForSessionId = createSelector(
  (state, sessionId: SessionId) => state.sessionHistory.sessionHistoryIdsBySessionId[sessionId],
);

export const getOrderedSessionHistoryTimestampsForOrgId = createParametricMemoizedSelector(
  getSessionHistories,
  getSessionHistoryIdsForOrgId,
  (sessionHistories, sessionHistoryIds) =>
    sortBy(sessionHistoryIds, (sessionHistoryId) => new Date(sessionHistories[sessionHistoryId].startTs)),
)((_state, orgId) => orgId);

export const getOrderedSessionHistoryIdsBetweenBoundaryForOrgId = createParametricMemoizedSelector(
  getSessionHistories,
  (state: { sessionHistory: SessionHistorySlice }, { orgId }: { orgId: OrgId; boundary: DateTimeBlock }) =>
    getOrderedSessionHistoryTimestampsForOrgId(state, orgId),
  (_state, { boundary }) => boundary,
  (sessionHistories, sortedInstanceIds, boundary) =>
    getSliceForBoundaryInSortedArray(
      sortedInstanceIds || [],
      boundary,
      (instanceId) => new Date(sessionHistories[instanceId].startTs),
    ),
)((_state, { orgId, boundary }) => `${orgId}-${Number(boundary.start)}-${Number(boundary.end)}`);

export const getSessionHistoryWithSerializedDateTimeBlock = createSelector(
  (state, sessionHistoryId: SessionHistoryId) => state.sessionHistory.sessionHistories[sessionHistoryId],
);
export const getSessionInitiatorForSessionHistory = createSelector(
  (state, sessionHistoryId: SessionHistoryId) =>
    state.sessionHistory.sessionHistories[sessionHistoryId]?.sessionInitiator,
);

const stablePickDateTimeBlockFromSerializedSessionHistory = memoize(
  ({ id, startTs, endTs }: SessionHistory) => ({
    id,
    start: new Date(startTs),
    ...(endTs && { end: new Date(endTs) }),
  }),
  ({ id, startTs, endTs }) => `${id}-${startTs}-${endTs}`,
);

export const getSessionHistory = createParametricMemoizedSelector(
  getSessionHistoryWithSerializedDateTimeBlock,
  (sessionHistory) =>
    !sessionHistory
      ? undefined
      : {
          ...omit(sessionHistory, ['startTs', 'endTs']),
          ...stablePickDateTimeBlockFromSerializedSessionHistory(sessionHistory),
        },
)((_state, sessionHistoryId) => sessionHistoryId);

export const getPeersForSessionHistory = createParametricMemoizedSelector(
  getSessionHistoryWithSerializedDateTimeBlock,
  (sessionHistory) =>
    orderBy(
      uniqBy(
        map(sessionHistory?.entries, ({ peer }) => peer),
        ({ userId, peerId }) => userId ?? peerId,
      ),
    ),
)((_state, sessionHistoryId) => sessionHistoryId);
export const getTranscriptsForSessionHistory = createParametricMemoizedSelector(
  getSessionHistoryWithSerializedDateTimeBlock,
  (sessionHistory) =>
    !sessionHistory
      ? undefined
      : orderBy(
          filter(sessionHistory.entries, (entry) =>
            isSessionHistoryEntryTranscription(entry),
          ) as SessionHistoryEntryTranscription[],
          ({ ts }) => ts,
        ),
)((_state, sessionHistoryId) => sessionHistoryId);

export const getFreshTranscriptsForSessionHistory = createParametricMemoizedSelector(
  getTranscriptsForSessionHistory,
  (sessionHistoryEntries) => filter(sessionHistoryEntries, ({ ts }) => isTranscriptTsFresh(ts)),
)((_state, sessionHistoryId) => `${sessionHistoryId}-${Math.floor(Date.now() / 1000)}`);

export const getFreshTranscriptsGroupedByPeerId = createParametricMemoizedSelector(
  getFreshTranscriptsForSessionHistory,
  (freshTranscripts) => groupBy(freshTranscripts, (transcript) => transcript.peer.peerId),
)((_state, sessionHistoryId) => sessionHistoryId);

export const getMostRecentTranscriptsByPeerId = createParametricMemoizedSelector(
  getFreshTranscriptsGroupedByPeerId,
  (transcriptsByPeerId) =>
    mapValues(transcriptsByPeerId, (transcripts) => maxBy(transcripts, (transcript) => transcript.ts)),
)((_state, sessionHistoryId) => sessionHistoryId);

export const getMostRecentTranscriptForPeerId = createSelector(
  (state, { peerId, sessionHistoryId }: { peerId: AnyPeerId; sessionHistoryId: SessionHistoryId }) =>
    getMostRecentTranscriptsByPeerId(state, sessionHistoryId)?.[peerId],
);

export const getProcessedTranscriptsForSessionHistory = createParametricMemoizedSelector(
  getFreshTranscriptsForSessionHistory,
  (freshTranscripts) =>
    reduce(
      freshTranscripts,
      (processedTranscripts, transcript) => {
        const lastProcessedTranscript = last(processedTranscripts);
        if (!lastProcessedTranscript) {
          processedTranscripts.push({ isNewDay: true, isDifferentPeer: true, transcript });
        } else {
          const isShortDelay =
            millisecondsToSeconds(transcript.ts - lastProcessedTranscript.transcript.ts) <
            SHORT_DELAY_THRESHOLD_SECONDS;
          const isLongDelay =
            millisecondsToMinutes(transcript.ts - lastProcessedTranscript.transcript.ts) >
            LONG_DELAY_THRESHOLD_MINUTES;
          const isNewDay = getDay(transcript.ts) !== getDay(lastProcessedTranscript.transcript.ts);
          const isDifferentPeer = transcript.peer.peerId !== lastProcessedTranscript.transcript.peer.peerId;
          processedTranscripts.push({
            isShortDelay,
            isLongDelay,
            isNewDay,
            isDifferentPeer,
            transcript,
          });
        }
        return processedTranscripts;
      },
      [] as ProcessedTranscript[],
    ),
)((_state, sessionHistoryId) => `${sessionHistoryId}-${Math.floor(Date.now() / minutesToMilliseconds(1))}`);

export const getRecentTranscriptForSessionHistory = createParametricMemoizedSelector(
  getFreshTranscriptsForSessionHistory,
  (freshTranscripts) => last(freshTranscripts),
)((_state, sessionHistoryId) => `${sessionHistoryId}-${Math.floor(Date.now() / minutesToMilliseconds(1))}`);
