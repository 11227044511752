/**
 * Takes a `count` and a `discriminator`, and emits `onChanged` when the count
 * crosses the discriminator. If the value is less than or equal to the
 * discriminator, the first value of `possibleValues` is used, greater than: the
 * second value.
 */
export class CountDiscriminator<T extends any> {
  private value: T;
  private discriminator: number;
  private valueWhenLessThanOrEqualTo: T;
  private valueWhenGreaterThan: T;
  private onChanged: (changed: T) => void;

  constructor({
    discriminator,
    valueWhenLessThanOrEqualTo,
    valueWhenGreaterThan,
    onChanged,
  }: {
    discriminator: number;
    valueWhenLessThanOrEqualTo: T;
    valueWhenGreaterThan: T;
    onChanged: (changed: T) => void;
  }) {
    this.discriminator = discriminator;
    this.onChanged = onChanged;
    this.valueWhenLessThanOrEqualTo = valueWhenLessThanOrEqualTo;
    this.valueWhenGreaterThan = valueWhenGreaterThan;
  }

  updateCount(count: number) {
    const nextValue =
      count <= this.discriminator ? this.valueWhenLessThanOrEqualTo : this.valueWhenGreaterThan;
    if (nextValue === this.value) return;
    this.value = nextValue;
    this.onChanged(this.value);
  }

  getValue() {
    return this.value;
  }
}
