import { UniqueType } from '../../utils/ts-utils';

import { Region } from './fleet.interface';

export type MediaServerType = 'floof' | 'zoom';
export type ExternalMediaServerType = Omit<MediaServerType, 'floof'>;
export type MediaServerConnectionType = 'floof-p2p' | 'floof-sfu' | 'zoom';
export type ZoomMeetingId = UniqueType<string, 'ZoomMeetingId'>;

export interface MediaServer {
  type: MediaServerType;
}

export interface FloofMediaServer extends MediaServer {
  type: 'floof';
  isAssigned?: boolean;
}
export const isFloofMediaServer = (mediaServer?: MediaServer): mediaServer is FloofMediaServer =>
  mediaServer?.type === 'floof';

export interface FloofMediaServerAssigned extends FloofMediaServer {
  type: 'floof';
  isAssigned: true;
  hostname: string;
  region: Region;
  ip: string;
  ts?: number;
  podId?: string;
}
export const isFloofMediaServerAssigned = (
  floofMediaServer?: FloofMediaServer,
): floofMediaServer is FloofMediaServerAssigned => !!floofMediaServer?.isAssigned;

export interface ZoomMediaServer extends MediaServer {
  type: 'zoom';
  joinUri: string;
  zoomMeetingId: ZoomMeetingId;
  password?: string;
  iconUri?: string;
}
export const isZoomMediaServer = (mediaServer?: MediaServer): mediaServer is ZoomMediaServer =>
  mediaServer?.type === 'zoom';
