import produce from 'immer';
import { BehaviorSubject, distinctUntilChanged, map } from 'rxjs';

import { SessionId } from 'common/models/db/vo.interface';
import { AnyPeerId } from 'common/models/db/vo.interface';
import { Point } from 'common/models/geometry.interface';
import {
  ControlChangeEvent,
  IncompleteRemoteIagoEvent,
  LocalIagoEvent,
  RemoteIagoEvent,
  isControlChangeEvent,
  isCursorChangeEvent,
} from 'common/models/iago-event.interface';
import { BufferProxy } from 'common/utils/buffer-proxy';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';
import { useObservable } from 'utils/react/use-observable';

import { createGlobalAction } from '../../redux/create-slice';

export const broadcastEvent = createGlobalAction<{
  sessionId: SessionId;
  event: IncompleteRemoteIagoEvent;
}>('broadcastEvent');
export const onIagoLocalEvent = createGlobalAction<LocalIagoEvent>('onIagoEvent');
export const toggleBroadcastKeyboardEventsToTrackId = createGlobalAction<{
  isEnabled: boolean;
  win: Window;
  trackId: string;
}>('toggleBroadcastKeyboardEventsToTrackId');

// In dev, we can optionally buffer these broadcast to make testing easier with yourself.
export const iagoBroadcastBufferProxy = new BufferProxy();

// Key state by host PeerId for multi-share reasons: e.g. Alice shares two
// monitors with Betty, Betty's guest cursor can only be in control on one of
// the screens at a time, so trackId is not the right differentiator.
let iagoState: {
  inControlPeerIdByHostPeerId: {
    [hostPeerId: string]: {
      inControlPeerId: AnyPeerId;
    };
  };
  cursorByPeerId: {
    [peerId: string]: {
      position?: Point;
      // trackId is needed to differentiate which track the cursor was last
      // updated on, in the case of multiple screen shares from the same host.
      trackId?: string;
      representations?: { url: string; hotspot: Point }[];
    };
  };
} = { inControlPeerIdByHostPeerId: {}, cursorByPeerId: {} };
const iagoState$ = new BehaviorSubject(iagoState);
exposeToGlobalConsole({ iagoState$ });

const notifyIagoStateUpdate = () => {
  iagoState$.next(iagoState);
};

const ensureHostState = (state: typeof iagoState, hostPeerId: AnyPeerId) => {
  if (!state.inControlPeerIdByHostPeerId[hostPeerId]) {
    state.inControlPeerIdByHostPeerId[hostPeerId] = {
      inControlPeerId: hostPeerId,
    };
  }
};

const ensureCursorState = (state: typeof iagoState, cursorPeerId: AnyPeerId) => {
  if (!state.cursorByPeerId[cursorPeerId]) {
    state.cursorByPeerId[cursorPeerId] = {};
  }
};

export const renderIagoEvent = ({
  event,
  eventPeerId,
  hostPeerId,
}:
  | {
      event: RemoteIagoEvent | LocalIagoEvent;
      eventPeerId: AnyPeerId;
      hostPeerId: AnyPeerId;
    }
  | { event: ControlChangeEvent; eventPeerId: undefined; trackId: undefined; hostPeerId: AnyPeerId }) => {
  iagoState = produce(iagoState, (draft) => {
    if (isControlChangeEvent(event)) {
      ensureHostState(draft, hostPeerId);

      const oldInControlPeerId = draft.inControlPeerIdByHostPeerId[hostPeerId].inControlPeerId;
      const newInControlPeerId = event.peerId;

      draft.inControlPeerIdByHostPeerId[hostPeerId].inControlPeerId = newInControlPeerId;
      ensureCursorState(draft, newInControlPeerId);

      // Swap cursors
      if (oldInControlPeerId && draft.cursorByPeerId[oldInControlPeerId]?.representations) {
        draft.cursorByPeerId[newInControlPeerId].representations =
          draft.cursorByPeerId[oldInControlPeerId].representations;
        delete draft.cursorByPeerId[oldInControlPeerId].representations;
      }
    } else {
      if (isCursorChangeEvent(event)) {
        ensureHostState(draft, hostPeerId);
        const inControlPeerId = draft.inControlPeerIdByHostPeerId[hostPeerId].inControlPeerId;
        ensureCursorState(draft, inControlPeerId);
        draft.cursorByPeerId[inControlPeerId].representations?.forEach(({ url }) => URL.revokeObjectURL(url));
        draft.cursorByPeerId[inControlPeerId].representations = event.representations.map(
          ({ data, hotspot }) => ({
            hotspot,
            url: URL.createObjectURL(new Blob([new Uint8Array(data)], { type: 'image/png' })),
          }),
        );
      } else {
        if (!eventPeerId) return;
        ensureCursorState(draft, eventPeerId);
        draft.cursorByPeerId[eventPeerId].position = event.location;
        draft.cursorByPeerId[eventPeerId].trackId = event.trackId;
      }
    }
  });
  notifyIagoStateUpdate();
};

export const useCursorState = ({ trackId, peerId }: { trackId: string; peerId: AnyPeerId }) =>
  useObservable<typeof iagoState['cursorByPeerId'][string] | undefined>(
    () =>
      iagoState$.pipe(
        map((state) => state.cursorByPeerId[peerId]),
        map((cursor) => (cursor?.trackId === trackId ? cursor : undefined)),
        distinctUntilChanged(),
      ),
    undefined,
    [peerId],
  );

export const cleanupHostState = ({ hostPeerId }: { hostPeerId: AnyPeerId }) => {
  iagoState = produce(iagoState, (draft) => {
    delete draft.inControlPeerIdByHostPeerId[hostPeerId];
  });
  notifyIagoStateUpdate();
};

export const cleanupPeerState = ({ peerId }: { peerId: AnyPeerId }) => {
  iagoState = produce(iagoState, (draft) => {
    draft.cursorByPeerId[peerId].representations?.forEach(({ url }) => URL.revokeObjectURL(url));
  });
  notifyIagoStateUpdate();
};
