import { at, filter, reduce, reject } from 'lodash';

import { OrgId, TeamId, UserId } from 'common/models/db/vo.interface';
import { VoTeam } from 'pages/vo/vo-react/features/teams/teams.types';

import { createSlice } from '../../redux/create-slice';

export type TeamsSlice = {
  byId: { [id: string]: VoTeam };
  allIds: TeamId[];
};

const initialState: TeamsSlice = {
  byId: {},
  allIds: [],
};
export const {
  createReducer,
  createSelector,
  createAction,
  createMemoizedSelector,
  createParametricMemoizedSelector,
} = createSlice('teams', initialState);

export const connectToTeam = createAction<{ teamId: TeamId }>('connectToTeam');

export const dbTeamCreatedOrUpdated = createAction<VoTeam>('dbTeamCreatedOrUpdated');
export const dbTeamDeleted = createAction<{ teamId: TeamId; orgId: OrgId }>('dbTeamDeleted');

export const createTeam = createAction<Omit<VoTeam, 'id' | 'sessionGroupId'>>('createTeam');
export const updateTeam = createAction<VoTeam>('updateTeam');
export const deleteTeam = createAction<{ teamId: TeamId; orgId: OrgId }>('deleteTeam');

export const setLastSeenTranscriptTs =
  createAction<{ teamId: TeamId; lastSeenTranscriptTs: number }>('setLastSeenTranscriptTs');

export default createReducer()
  .on(dbTeamCreatedOrUpdated, (state, { payload: team }) => {
    if (!state.byId[team.id]) state.allIds.push(team.id);
    state.byId[team.id] = {
      ...(state.byId[team.id] ?? { userIds: [] }),
      ...team,
    };
  })
  .on(dbTeamDeleted, (state, { payload: { teamId } }) => {
    state.allIds = reject(state.allIds, (id) => id === teamId);
    delete state.byId[teamId];
  })
  .on(setLastSeenTranscriptTs, (state, { payload: { teamId, lastSeenTranscriptTs } }) => {
    state.byId[teamId].lastSeenTranscriptTs = lastSeenTranscriptTs;
  });

export const getAllTeamIds = createSelector((state) => state.teams.allIds);
export const getTeamById = createSelector((state, teamId: TeamId) => state.teams.byId[teamId]);
export const getTeamsById = createSelector((state) => state.teams.byId);
export const getTeamNameById = createSelector((state, teamId: TeamId) => state.teams.byId[teamId]?.name);
export const getTeamEmojiById = createSelector((state, teamId: TeamId) => state.teams.byId[teamId]?.emoji);
export const getTeamMemberIdsByTeamId = createSelector(
  (state, teamId: TeamId) => state.teams.byId[teamId]?.userIds,
);
export const getTeamMemberCountById = createSelector(
  (state, teamId: TeamId) => state.teams.byId[teamId].userIds.length,
);
export const getIsUserAMemberOfTeam = createSelector((state, teamId: TeamId, userId: UserId) =>
  state.teams.byId[teamId]?.userIds.includes(userId),
);
export const getAllTeamNamesByIds = createMemoizedSelector(getTeamsById, (teamsById) =>
  reduce(
    teamsById,
    (memo, { name }, teamId) => {
      memo[teamId] = name;
      return memo;
    },
    {} as { [teamId: string]: string },
  ),
);

export const getLastSeenTranscriptTsByTeamId = createSelector(
  (state, teamId: TeamId) => getTeamById(state, teamId)?.lastSeenTranscriptTs,
);

// TODO: If this is going to be called often, maybe we should partition the
// teams by users one time instead of per-user query.
export const getTeamIdsForUserId = createParametricMemoizedSelector(
  getTeamsById,
  getAllTeamIds,
  (_state: { teams: TeamsSlice }, userId: UserId) => userId,
  (allTeamsById, allTeamIds, userId) =>
    filter(allTeamIds, (teamId) => allTeamsById[teamId].userIds.includes(userId)),
)((_state, userId) => userId);

export const getTeamsForUserId = createParametricMemoizedSelector(
  getTeamsById,
  getTeamIdsForUserId,
  (allTeamsById, teamIds) => at(allTeamsById, teamIds),
)((_state, userId) => userId);
export const getSessionGroupIdForTeam = createSelector(
  (state, teamId: TeamId) => state.teams.byId[teamId]?.sessionGroupId,
);
