import { size, head, keys, minBy, map as lodashMap, filter as lodashFilter } from 'lodash';
import { BehaviorSubject, filter, firstValueFrom, switchMap, timer } from 'rxjs';

import { Region } from 'common/models/db/fleet.interface';
import { sleep } from 'common/utils/ts-utils';
import { isLocalhost } from 'utils/client-utils';
import { db } from 'utils/firebase-db-wrapper-client';
import { storage } from 'utils/storage';

const _region$ = new BehaviorSubject<Region | undefined>(undefined);
export const region$ = _region$.asObservable();

export const setRegionForSdk = (region: Region) => _region$.next(region);
export const getRegion = async () =>
  await firstValueFrom(_region$.pipe(filter((region) => region !== 'unknown-unknown' || isLocalhost)));

export const getAvailableRegionsFromFleet = async () => {
  const pingHostnames = await db.from('fleet/pingHostnames').value();
  const availableRegions = keys(pingHostnames) as Region[];
  return availableRegions;
};

export const runRegionTest = async () => {
  const pingHostnames = await db.from('fleet/pingHostnames').value();

  if (!pingHostnames || storage.get('should-use-local-floof')) return 'unknown-unknown' as Region;

  if (size(pingHostnames) === 1) return head(keys(pingHostnames)) as Region;

  // dry run, fetch dns, etc.
  const startTs = Date.now();
  await Promise.all(lodashMap(pingHostnames, (hostname) => fetch(`https://${hostname}/ping`)));
  const dryRunTimeMs = Date.now() - startTs;

  const NUM_REPETITIONS = 5;

  // actual speed test

  // DEBUG: uncomment these helpful console logs to debug timing easily
  // console.log('start region test');

  // Assume that the region test should take no longer than running the
  // dry run repeatedly. In fact, it should be much faster, since the dry
  // run waits for the slowest ping, while the region test concludes with
  // the fastest one. If this fails, we still have the 5s retry logic
  // below as a fallback.
  const bestTimeMs$ = new BehaviorSubject(dryRunTimeMs * NUM_REPETITIONS);
  const results = await Promise.all(
    lodashMap(pingHostnames, async (hostname, region) => {
      const startTs = Date.now();
      const timeMs = await Promise.race([
        (async () => {
          for (let i = 0; i < NUM_REPETITIONS; i++)
            await Promise.race([fetch(`https://${hostname}/ping`), sleep(2000)]);
          const timeMs = Date.now() - startTs;
          // console.log(region, timeMs);
          return timeMs;
        })(),
        // As the best time reduces, everyone has to beat that time or concede.
        firstValueFrom(
          bestTimeMs$.pipe(switchMap((bestTimeMs) => timer(bestTimeMs! - (Date.now() - startTs)))),
        ),
      ]);
      if (timeMs && timeMs < bestTimeMs$.value) bestTimeMs$.next(timeMs);
      return { region: region as Region, timeMs };
    }),
  );
  const passedResults = lodashFilter(results, ({ timeMs }) => !!timeMs);
  const region = minBy(passedResults, ({ timeMs }) => timeMs)?.region;
  // console.log('result', region, Date.now() - startTs - dryRunTimeMs);
  if (!region && Math.random() < 0.001)
    console.error(
      'region test failed, trying in 5s…!',
      JSON.stringify({ results, bestTimeMs: bestTimeMs$.value, dryRunTimeMs }),
    );

  return region;
};
