import type { EmptyObject } from '@reduxjs/toolkit';
import { assign, compact, fill, forEach, merge, omit, some } from 'lodash';
import { ForwardedRef, Ref, useMemo, useState } from 'react';
import { mergeProps } from 'react-aria';
import { matchPath, useLocation } from 'react-router-dom';

import { useResultDeepMemo } from './react/use-result-deep-memo';

export type ReactChildId = 'vo-root' | 'call-header' | 'call-footer';
export type ReactChildren = React.ReactNode | React.ReactNodeArray;
export type WithoutEmptyObject<T> = T extends EmptyObject & infer U ? U : T;

export const blockAllForwardedPropsConfig = (propsToBlock: string[]) => {
  const set = new Set(propsToBlock);
  return {
    shouldForwardProp: (prop: string | number | symbol, _defaultValidatorFn: any) => !set.has(prop as string),
  };
};

/**
 * If a property defined, map it to the predicate associated with its key.
 *
 * ```
 * mapDefinedValuesToPredicates(
 *  { thing: 5 },
 *  {
 *    thing: (props) => props.thing + 1,
 *    other: (props) => 10
 *  }
 * ); // > [6]
 * ```
 */
export const mapDefinedValuesToPredicates = <T, R>(
  properties: T,
  predicates: { [key in keyof T]?: (props: T) => R },
): R[] =>
  Object.keys(predicates).map(
    (key) => properties[key] !== undefined && properties[key] !== false && predicates[key](properties),
  );

export function useEventToSetValue<
  V,
  EventName extends string = 'onClick',
  PropName extends string = 'isEnabled',
  E = Event,
>({
  initialValue,
  eventName = 'onClick' as any,
  propName = 'isEnabled' as any,
  predicate,
}: UseEventToSetValueParameters<V, EventName, PropName, E>): { [k in EventName]: (event: E) => void } & {
  [k in PropName]: V;
} {
  const [value, setValue] = useState(initialValue);

  return {
    [propName]: value,
    [eventName]: (event) => setValue(predicate(value, event)),
  } as any;
}
type UseEventToSetValueParameters<
  V,
  EventName extends string = string,
  PropName extends string = string,
  E = Event,
> = {
  eventName?: EventName;
  propName?: PropName;
  initialValue: V;
  predicate: (prevValue: V, event: E) => V;
};

export const useEventToSetBoolean = ({
  initialValue = false,
  predicate = (value) => !value,
  eventName,
  propName,
}: Partial<UseEventToSetValueParameters<boolean>> = {}) =>
  useEventToSetValue({ initialValue, predicate, eventName, propName });

export const mergeRefs =
  (...refs: ForwardedRef<any>[]) =>
  (val) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(val);
      } else if (ref !== null) {
        ref.current = val;
      }
    });
  };

export const setRef = <T, RefVal extends T>(ref: Ref<RefVal>, val: T) => mergeRefs(ref)(val);

export const mergeFuncs =
  (...funcs: ((...args: any[]) => any)[]) =>
  (val) =>
    funcs.forEach((ref) => ref(val));

export const useMatchFirstRoute = (routeMap: { [path: string]: any }) => {
  const location = useLocation();

  const [idOrIdFactory, params] = useMemo(() => {
    let found: any[] = [];
    forEach(routeMap, (idOrIdFactory, path) => {
      const match = matchPath(path, location.pathname);
      if (!match) return;
      found = [idOrIdFactory, match.params];
      // Abort loop early.
      return false;
    });
    return found;
  }, [routeMap, location.pathname]);

  if (typeof idOrIdFactory === 'function') return idOrIdFactory(params as any);
  return idOrIdFactory;
};

/**
 * Usage:
 * ```
 * padArray({
 *   array: [1,2],
 *   length: 4,
 *   padding: 0,
 * });
 * // [1,2,0,0]
 * ```
 */
export const padArray = <Arr extends any[], Pad>({
  array,
  length,
  padding,
}: {
  array: Arr;
  length: number;
  padding: Pad;
}) => assign(fill(new Array(length), padding), array) as (Arr[number] | Pad)[];

export const mergePropsAndRefs = (...sources: any[]) => ({
  ...mergeProps(...sources.map((source) => omit(source, ['ref', 'hover']))),
  ...(some(sources, (source) => source?.ref) && {
    ref: mergeRefs(...compact(sources.map((source) => source?.ref as Ref<any> | undefined))),
  }),
  ...(some(sources, (source) => source?.hover) && {
    hover: merge({}, ...compact(sources.map((source) => source?.hover))),
  }),
});

/**
 * In the case that we don't want to obliterate props every render and it's more
 * ergonomic to generate them inline, stabalize the merge with isEqual to avoid
 * spurious renders.
 */
export const useMergePropsAndRefs = (...sources: any[]) => {
  const stableSources = useResultDeepMemo(() => sources);
  return useMemo(() => mergePropsAndRefs(...stableSources), [stableSources]);
};
