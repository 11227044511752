import { of, mapTo, distinctUntilChanged, map, pluck, filter, timer, skip, mergeMap, NEVER } from 'rxjs';

import { filterIsTruthy, ofActionPayload, switchMapIfTruthy } from 'common/utils/custom-rx-operators';

import { EpicWithDeps } from '../../redux/app-store';
import { getIsActive } from '../activity/activity.slice';
import {
  getIsCurrentSessionNonTeamLobby,
  getIsCurrentSessionWalkieTalkie,
} from '../common/session-selectors';
import { getCurrentSessionId, leaveSession } from '../sessions/sessions.slice';

import { getSelfUserStatus, setSelfUserStatus, setSelfUserStatusOverride } from './users.slice';

// Set `away` as the status override if we're offline. Otherwise, if we're
// online, reset it if it was set to away.
export const setIsOnlineEpic: EpicWithDeps = (action$, state$) =>
  state$
    .pipe(
      map((state) => getIsActive(state)),
      distinctUntilChanged(),
    )
    .pipe(
      mergeMap((isActive) =>
        !isActive
          ? of(setSelfUserStatusOverride('away'))
          : getSelfUserStatus(state$.value) === 'away'
          ? of(setSelfUserStatusOverride(undefined))
          : NEVER,
      ),
    );

// Run the timer for resetting DND status
export const handleUserStatusClearMsEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(setSelfUserStatus),
    pluck('selfUserStatusClearMs'),
    switchMapIfTruthy((selfUserStatusClearMs) => timer(selfUserStatusClearMs)),
    mapTo(
      setSelfUserStatus({
        status: 'available' as const,
        statusReason: undefined,
        selfUserStatusClearMs: undefined,
      }),
    ),
  );

// When we enter or leave a session, set/unset our status override
export const unsetSelfInSessionStatusEpic: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    map((state) => getIsCurrentSessionNonTeamLobby(state)),
    distinctUntilChanged(),
    skip(1), // It'll start off false
    map((isInSession) => setSelfUserStatusOverride(isInSession ? 'in-session' : undefined)),
  );

// When we switch to DND or Away, if we're in a walkie-talkie session, leave
export const leaveWalkieTalkieSessionOnDndOrAwayEpic: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    map(getSelfUserStatus),
    distinctUntilChanged(),
    filter(
      (status) => (status === 'dnd' || status === 'away') && !!getIsCurrentSessionWalkieTalkie(state$.value),
    ),
    map(() => leaveSession({ sessionId: getCurrentSessionId(state$.value)! })),
  );

// When we enter a walkie-talkie session, if we're in DND mode, switch to Available
export const clearStatusWhenInWalkieTalkieSessionEpic: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    map((state) => getIsCurrentSessionWalkieTalkie(state)),
    distinctUntilChanged(),
    filterIsTruthy(),
    filter(() => getSelfUserStatus(state$.value) === 'dnd'),
    mapTo(setSelfUserStatus({ status: 'available' })),
  );
