import { chain, keyBy, pull, sortBy, values } from 'lodash';
import { Dispatch } from 'redux';

import { storage } from 'utils/storage';

import type { RootReduxState } from '../../redux/app-store';
import { createSlice } from '../../redux/create-slice';
import { makeKeyedMetaSelector } from '../../redux/utils';

import {
  allDeviceTypes,
  allInputDeviceTypes,
  Device,
  DeviceMap,
  deviceToNoneDeviceMap,
  DeviceType,
  InputDevice,
  InputDeviceType,
  OutputDevice,
  OutputDeviceType,
  UniqueDeviceId,
} from './devices.types';

export type DeviceSlice = {
  allDevices: Device[];
  devicePreferenceOrder: UniqueDeviceId[];
} & {
  [k in DeviceType]: {
    isPermissionGranted?: boolean;
    isEnabled: boolean;
  };
};

const initialState: DeviceSlice = {
  allDevices: [],
  devicePreferenceOrder: storage.get('device-preference-order') ?? [],
  microphone: { isEnabled: false },
  camera: { isEnabled: false },
  speakers: { isEnabled: true },
};
export const { createReducer, createSelector, createMemoizedSelector, createAction, createToggleThunk } =
  createSlice('devices', initialState);

export const osUpdatedMediaDevices = createAction('osUpdatedMediaDevices');
export const clobberMediaDevices = createAction<Device[]>('clobberMediaDevices');
export const markUniqueDeviceIdAsPreferred = createAction<UniqueDeviceId>('markUniqueDeviceIdAsPreferred');
export const grantPermission =
  createAction<{ deviceType: DeviceType; isPermissionGranted: boolean }>('setHasPermission');
export const setMicrophoneIsEnabled = createAction<boolean>('setMicrophoneIsEnabled');
export const toggleMicMute = createToggleThunk({
  actionCreator: setMicrophoneIsEnabled,
  selector: (state) => getIsMicrophoneEnabled(state),
});
export const toggleCamera = () => (dispatch: Dispatch, getState: () => { devices: DeviceSlice }) =>
  dispatch(setCameraIsEnabled(!getIsCameraEnabled(getState())));
export const setCameraIsEnabled = createAction<boolean>('setCameraIsEnabled');
export const toggleScreenIsEnabled = createAction('toggleScreenIsEnabled');

export default createReducer()
  .on(clobberMediaDevices, (state, { payload: devices }) => {
    state.allDevices = devices;
    allDeviceTypes.map((deviceType) => state.allDevices.push(deviceToNoneDeviceMap[deviceType]));
  })
  .on(markUniqueDeviceIdAsPreferred, (state, { payload: uniqueDeviceId }) => {
    pull(state.devicePreferenceOrder, uniqueDeviceId);
    state.devicePreferenceOrder.unshift(uniqueDeviceId);
    storage.set('device-preference-order', values(state.devicePreferenceOrder));
  })
  .on(grantPermission, (state, { payload: { deviceType, isPermissionGranted } }) => {
    state[deviceType].isPermissionGranted = isPermissionGranted;
  })
  .on(setMicrophoneIsEnabled, (state, { payload: shouldEnable }) => {
    state.microphone.isEnabled = shouldEnable;
  })
  .on(setCameraIsEnabled, (state, { payload: shouldEnable }) => {
    state.camera.isEnabled = shouldEnable;
  });

export const getAllDevices = createSelector((state) => state.devices.allDevices);
export const getDevicesById = createMemoizedSelector(getAllDevices, (allDevices) => keyBy(allDevices, 'id'));
export const getPreferredDeviceList = createSelector((state) => state.devices.devicePreferenceOrder);

export const getDevicesPartitionedByType = createMemoizedSelector(
  getAllDevices,
  (allDevices) =>
    ({
      microphone: [],
      camera: [],
      speakers: [],
      ...chain(allDevices).groupBy('type').value(),
    } as {
      [type in OutputDeviceType]: OutputDevice<type>[];
    } & { [type in InputDeviceType]: InputDevice<type>[] }),
);

export const getAllDevicesOfType = createSelector(
  <T extends DeviceType>(state: Pick<RootReduxState, 'devices'>, type: T) =>
    getDevicesPartitionedByType(state)[type],
);

export const getAllDevicesInPreferredOrder = createMemoizedSelector(
  getAllDevices,
  getPreferredDeviceList,
  (devices, prefs) =>
    sortBy(devices, ({ uniqueDeviceId }) => {
      const i = prefs.indexOf(uniqueDeviceId);
      return i === -1 ? Infinity : i;
    }),
);

export const getPreferredDevices = createMemoizedSelector(getAllDevicesInPreferredOrder, (devicesInOrder) => {
  const preferredDevices = {} as Partial<DeviceMap>;

  for (const device of devicesInOrder) {
    if (!(device.type in preferredDevices)) {
      preferredDevices[device.type] = device as any;
    }
  }

  return preferredDevices;
});

export const getPreferredMicrophone = createMemoizedSelector(
  getPreferredDevices,
  (devices) => devices.microphone,
);
export const getPreferredCamera = createMemoizedSelector(getPreferredDevices, (devices) => devices.camera);
export const getPreferredSpeakers = createMemoizedSelector(
  getPreferredDevices,
  (devices) => devices.speakers,
);

export const getPreferredDeviceByType = createSelector(
  makeKeyedMetaSelector({
    microphone: getPreferredMicrophone,
    camera: getPreferredCamera,
    speakers: getPreferredSpeakers,
  }),
);

export const [getIsPermissionGrantedForMicrophone, getIsPermissionGrantedForCamera] = allInputDeviceTypes.map(
  (deviceType) => createSelector((state) => !!state.devices[deviceType].isPermissionGranted),
);
export const getIsPermissionGrantedByType = createSelector(
  makeKeyedMetaSelector({
    microphone: getIsPermissionGrantedForMicrophone,
    camera: getIsPermissionGrantedForCamera,
    speakers: () => true,
  }),
);

/**
 * @deprecated use `getIsMicrophoneEnabled()`
 */
export const getIsMicEnabled = createSelector((state) => state.devices.microphone.isEnabled);

export const getIsDeviceEnabled = createSelector(
  (state, type: 'microphone' | 'camera') => state.devices[type].isEnabled,
);
export const getIsMicrophoneEnabled = createSelector((state) => getIsDeviceEnabled(state, 'microphone'));
export const getIsCameraEnabled = createSelector((state) => getIsDeviceEnabled(state, 'camera'));
