import { switchMap } from 'rxjs';

import { ignoreElements, ofAction } from 'common/utils/custom-rx-operators';

import { apiCall } from '../../api';
import { EpicWithDeps } from '../../redux/app-store';

import { revokeZoomAccess } from './zoom.slice';

export const revokeZoomAccessEpic: EpicWithDeps = (action$, _state$, { injector }) =>
  action$.pipe(
    ofAction(revokeZoomAccess),
    switchMap(() => apiCall('removeZoomLinkage')),
    ignoreElements(),
  );
