export const allJoinSoundIds = [
  'pop-d-12',
  'pop-d-8',
  'pop-d-6',
  'pop-d-4',
  'pop-d-2',
  'pop', // Original sound, the rest are detuned or uptuned by n half steps
  'pop-u-2',
  'pop-u-4',
] as const;
export type JoinSoundId = typeof allJoinSoundIds[number];

export const allLeaveSoundIds = ['whoosh-6', 'whoosh-4', 'whoosh-2', 'whoosh'] as const;
export type LeaveSoundId = typeof allLeaveSoundIds[number];

export const allSoundIds = [
  ...allJoinSoundIds,
  ...allLeaveSoundIds,
  'incoming-ringtone',
  'outgoing-ring',
  'outgoing-declined',
  'ptt',
  'wave',
] as const;
export type SoundId = typeof allSoundIds[number];

export const RINGTONE_URL =
  'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/client-large-files%2Fsounds%2Fringtone.ogg?alt=media&token=e3d5b87e-4d89-497d-a3b7-98b209a8b347';
export const OUTGOING_RING_URL =
  'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/client-large-files%2Fsounds%2Foutgoing-ring2.ogg?alt=media&token=338a5b9e-bba8-4ca7-8a9d-9b2bf4f3a728';
export const OUTGOING_DECLINED_URL =
  'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/client-large-files%2Fsounds%2Foutgoing-declined2.ogg?alt=media&token=d50d9dad-184f-4e5f-b686-39a63dd3f49b';
export const PTT_URL =
  'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/client-large-files%2Fsounds%2Fwalkie-talkie.ogg?alt=media&token=985efe6d-8b95-4fa7-864f-a4aca261cbc3';
export const WAVE_URL =
  'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/client-large-files%2Fsounds%2Fshake.ogg?alt=media&token=ed6bba34-7d12-4f47-8383-f8b28cfccb51';
