import { noop } from 'lodash';
import { useMemo } from 'react';
import { FieldErrors, FormProvider, UseFormReturn, useForm } from 'react-hook-form';

import { FlexBox, FlexBoxProps } from 'pages/vo/vo-react/components/ui-kit/flex-box';

export const Form = <T,>({
  onSubmit = noop,
  onError = noop,
  children,
  defaultValues,
  ...etc
}: {
  onSubmit?: (data: T, context: UseFormReturn<T>, event?: any) => any;
  onError?: (data: FieldErrors<T>) => any;
  defaultValues?: any;
  children: any;
} & FlexBoxProps) => {
  const formContext = useForm<T>({ defaultValues });
  const { handleSubmit } = formContext;
  const onSubmitWithContext = useMemo(
    () => (data: T, e?: any) => onSubmit(data, formContext, e),
    [onSubmit, formContext],
  );

  return (
    <FlexBox as="form" onSubmit={handleSubmit(onSubmitWithContext, onError as any)} {...etc}>
      <FormProvider {...formContext}>{children}</FormProvider>
    </FlexBox>
  );
};
