import autoBind from 'auto-bind';
import { map as lodashMap, keys, each } from 'lodash';
import { DistributiveOmit } from 'react-redux';

import { MediaType } from 'common/models/connection.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import { FloofMessages, P2pGenericSignalingMessage } from 'common/models/floof.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { allMediaTypes } from 'utils/client-utils';
import { FloofAnalyticsDelegate } from 'utils/floof-sdk/floof-sdk';
import {
  MediaConnectionManager,
  MediaConnectionManagerDelegate,
} from 'utils/floof-sdk/media-connection/media-connection-manager';
import { P2pMediaConnection } from 'utils/floof-sdk/media-connection/p2p/p2p-media-connection';

export interface P2pMediaConnectionManagerDelegate extends MediaConnectionManagerDelegate {
  onTrackSent: (info: {
    remotePeerId: RemotePeerId;
    localTrackId: string;
    trackTransportId: string | null;
  }) => void;
}

export class P2pMediaConnectionManager extends MediaConnectionManager {
  private p2pMediaConnections: { [peerId: string]: P2pMediaConnection } = {};
  private trackForMediaType: { [mediaType in MediaType]?: MediaStreamTrack } = {};
  constructor(
    protected selfPeerId: RemotePeerId,
    protected analyticsDelegate: FloofAnalyticsDelegate | undefined,
    protected delegate: P2pMediaConnectionManagerDelegate,
    private sendP2pSignalChannelMessage: <T extends FloofMessages['requests']['client-to-server']['p2p']>(
      message: DistributiveOmit<T, 'response'>,
    ) => T['response'],
  ) {
    super(selfPeerId, analyticsDelegate);
    autoBind(this);
  }

  public sendTrack(track: MediaStreamTrack | undefined, mediaType: MediaType) {
    this.trackForMediaType[mediaType] = track;
    each(this.p2pMediaConnections, (connection) => connection.sendTrack(track, mediaType));
  }

  public async sendPeerMessage(peerMessage: PeerMessage) {
    await Promise.all(
      lodashMap(this.p2pMediaConnections, (connection) =>
        connection.sendDataChannelMessage({ type: 'send-peer-message', peerMessage }),
      ),
    );
  }

  public disconnect() {
    keys(this.p2pMediaConnections).map((peerId) => this.deleteMediaConnection(peerId as RemotePeerId));
  }

  public async receiveGenericMessage(peerId: RemotePeerId, message: P2pGenericSignalingMessage) {
    await this.p2pMediaConnections[peerId].receiveGenericMessage(message);
  }

  public createMediaConnection(remotePeerId: RemotePeerId) {
    const p2pMediaConnection = (this.p2pMediaConnections[remotePeerId] = new P2pMediaConnection(
      this.selfPeerId,
      remotePeerId,
      {
        onTrackSent: (info) => this.delegate.onTrackSent({ ...info, remotePeerId }),
        onTrackReceived: (info) => this.delegate.onTrackReceived({ ...info, peerId: remotePeerId }),
        onMessageReceived: (message) => this.delegate.onMessageReceived(message, remotePeerId),
        onGenericMessageSendRequested: (message: P2pGenericSignalingMessage) =>
          this.sendP2pSignalChannelMessage({
            method: 'req-c2s-p2p-generic-signaling-message',
            data: {
              message,
              peerId: remotePeerId,
            },
          }),
      },
      this.analyticsDelegate,
    ));
    void allMediaTypes
      .filter((mediaType) => this.trackForMediaType[mediaType])
      .map((mediaType) => p2pMediaConnection.sendTrack(this.trackForMediaType[mediaType], mediaType));
  }

  public deleteMediaConnection(peerId: RemotePeerId) {
    void this.p2pMediaConnections[peerId]?.disconnect();
    delete this.p2pMediaConnections[peerId];
  }
}
