import { useCallback } from 'react';
import { useDispatch as useReactReduxDispatch } from 'react-redux';

import type { SessionId } from 'common/models/db/vo.interface';
import type { RootReduxDispatch } from 'pages/vo/vo-react/redux/app-store';

import { useCurrentSessionId } from './current-session-id-context';

export type UseDispatchActionMeta = {
  sessionId: SessionId | null;
};

// Re-export with better typings for thunks.
// Also, attach the current session id so we don't have to constantly pass it around.
export const useDispatch: () => RootReduxDispatch = () => {
  const dispatch = useReactReduxDispatch();
  const currentSessionId = useCurrentSessionId();

  return useCallback(
    (action: any) => {
      action.meta = {
        ...action.meta,
        sessionId: currentSessionId,
      };
      return dispatch(action);
    },
    [dispatch, currentSessionId],
  );
};
