import { values } from 'lodash';

import { OrgId, RingId } from 'common/models/db/vo.interface';

import { createSlice } from '../../redux/create-slice';

import { VoRing } from './rings.types';

export type RingSlice = {
  byId: { [id: string]: VoRing };
};

const initialState: RingSlice = {
  byId: {},
};
export const { createReducer, createSelector, createMemoizedSelector, createAction } = createSlice(
  'rings',
  initialState,
);

export const userRangUser = createAction<Omit<VoRing, 'id' | 'state'>>('userRangUser');
export const userAcceptedRing = createAction<RingId>('userAcceptedRing');
export const userDeclinedRing = createAction<RingId>('userDeclinedRing');
export const deleteRing = createAction<RingId>('deleteRing');
export const dbAddOrUpdateRing = createAction<VoRing>('dbAddOrUpdateRing');
export const dbDeleteRing = createAction<RingId>('dbDeleteRing');

export default createReducer()
  .on(dbAddOrUpdateRing, (state, { payload: ring }) => {
    state.byId[ring.id] = ring;
  })
  .on(dbDeleteRing, (state, { payload: ringId }) => {
    delete state.byId[ringId];
  });

export const getRingById = createSelector((state, ringId: RingId) => state.rings.byId[ringId]);
export const getAllRings = createSelector((state) => values(state.rings.byId));
export const getAllJoinableSessionInitiatorsFromRingsForOrgId = createMemoizedSelector(
  getAllRings,
  (_state: { rings: RingSlice }, { orgId }: { orgId: OrgId }) => orgId,
  (allRings, orgId) =>
    allRings
      .filter((ring) => ring.orgId === orgId)
      .map(({ joinableSessionInitiator }) => joinableSessionInitiator),
);
