import { castDraft } from 'immer';
import { find, findKey, flatten, groupBy, map, memoize, omit, pick, pull, sortBy } from 'lodash';
import RRule from 'rrule';
import { parseString } from 'rrule/dist/esm/src/parsestring';

import { CalendarId, CalendarSettings } from 'common/models/db/calendar.interface';
import { EventId, EventInstanceId } from 'common/models/db/event.interface';
import { OrgId, UserId } from 'common/models/db/vo.interface';
import {
  DateTimeBlock,
  getIanaTimeZoneName,
  getSliceForBoundaryInSortedArray,
  SerializableDateTimeBlock,
  humanizeEventInstanceId,
} from 'common/utils/date-time';
import { debugCheck } from 'utils/debug-check';

import { createShallowArrayEqualSelector, createSlice } from '../../redux/create-slice';

import { EventInstance, ScheduledBlock, ScheduledBlockType } from './calendar.types';
import {
  addToListInScheduledBlockRenderOrder,
  getDayIndexFromRruleWeekday,
  getUserOrgIdPairString,
  getUserOrgIdsFromIdPairString,
} from './calendar.utils';

export type CalendarSlice = {
  calendarIdByUserOrgIdPair: { [userOrgIdPair: string]: CalendarId };
  scheduledBlocks: { [id: string]: ScheduledBlock };
  scheduledBlockIdsByCalendarId: { [calendarId: string]: string[] };
  scheduledBlockIdsWaitingForSave: { [id: string]: true };
  allEventInstanceIdsByCalendarId: { [calendarId: string]: EventInstanceId[] };
  eventInstances: { [eventInstanceId: string]: EventInstance };
  settingsByCalendarId: {
    [calendarId: string]: CalendarSettings;
  };
  eventInstanceByEventId: { [eventId: string]: EventInstance };
  calendarIdByEventInstanceId: { [eventInstsanceId: string]: CalendarId };
};

const initialState: CalendarSlice = {
  scheduledBlocks: {},
  scheduledBlockIdsByCalendarId: {},
  calendarIdByUserOrgIdPair: {},
  scheduledBlockIdsWaitingForSave: {},
  allEventInstanceIdsByCalendarId: {},
  eventInstances: {},
  settingsByCalendarId: {},
  eventInstanceByEventId: {},
  calendarIdByEventInstanceId: {},
};
export const {
  createReducer,
  createSelector,
  createAction,
  createParametricMemoizedSelector,
  createMemoizedSelector,
  createAsyncAction,
} = createSlice('calendar', initialState);

export const addDefaultScheduledBlocksForDay = createAction<{ dayIndex: Day; calendarId: CalendarId }>(
  'addDefaultScheduledBlocksForDay',
);
export const removeAllScheduledBlocksForDay = createAction<{ dayIndex: Day; calendarId: CalendarId }>(
  'removeAllScheduledBlocksForDay',
);
export const addCalendarIdForUserIdOrgIdPair = createAction<{
  calendarId: CalendarId;
  userId: UserId;
  orgId: OrgId;
}>('addCalendarIdForUserIdOrgIdPair');
export const unsetCalendarIdForUserIdOrgIdPair = createAction<{
  userId: UserId;
  orgId: OrgId;
}>('unsetCalendarIdForUserIdOrgIdPair');
export const addScheduledBlockToCalendarWithoutId = createAction<{
  calendarId: CalendarId;
  block: Omit<ScheduledBlock, 'id'>;
}>('addScheduledBlockToCalendarWithoutId');
export const addScheduledBlockToCalendar = createAction<{ calendarId: CalendarId; block: ScheduledBlock }>(
  'addScheduledBlockToCalendar',
);
export const removeScheduledBlock = createAction<{ calendarId: CalendarId; blockId: string }>(
  'removeScheduledBlock',
);
export const updateTimeForScheduledBlock = createAction<
  { calendarId: CalendarId; id: string } & SerializableDateTimeBlock
>('updateTimeForScheduledBlock');
export const saveScheduleBlocks = createAction('saveScheduleBlocks');
export const dbClobberScheduledBlockForCalendarId = createAction<{
  calendarId: CalendarId;
  block: ScheduledBlock;
  isRemoval?: boolean;
}>('dbClobberScheduledBlockForCalendarId');
export const dbClobberScheduledBlocksForCalendarId = createAction<{
  blocks: ScheduledBlock[] | undefined;
  calendarId: CalendarId;
}>('dbClobberScheduledBlocksForCalendarId');
export const dbClobberSettingsForCalendarId = createAction<{
  settings: CalendarSettings;
  calendarId: CalendarId;
}>('dbClobberSettingsForCalendarId');
export const setIsUiShowingCalendarWithBoundary = createAction<{
  calendarId: CalendarId;
  orgId: OrgId;
  boundary: SerializableDateTimeBlock;
  isShowing: boolean;
}>('setIsUiShowingCalendarWithBoundary');
export const listenToCalendarDay = createAction<{
  payload: {
    calendarId: CalendarId;
    orgId: OrgId;
    dayTs: number;
  };
  type: 'create' | 'destroy';
}>('listenToCalendarDay');
export const listenToEvent = createAction<{
  payload: {
    calendarId: CalendarId;
    orgId: OrgId;
    endTs: number;
    eventInstanceId: EventInstanceId;
  };
  type: 'create' | 'destroy';
}>('listenToEvent');
export const listenToEventInstance = createAction<{
  payload: {
    calendarId: CalendarId;
    orgId: OrgId;
    eventInstanceId: EventInstanceId;
  };
  type: 'create' | 'destroy';
}>('listenToEventInstance');
export const dbEventCreatedOrUpdated = createAction<{
  event: EventInstance;
  calendarId: CalendarId;
}>('dbEventCreatedOrUpdated');
export const dbEventDeleted = createAction<{
  eventInstanceId: EventInstanceId;
  calendarId: CalendarId;
}>('dbEventDeleted');
export const setCalendarSettingsForCalendarId = createAction<
  { calendarId: CalendarId } & Partial<Omit<CalendarSettings, 'ianaTimeZone'>>
>('setTimeZoneOverride');

export default createReducer()
  .on(addCalendarIdForUserIdOrgIdPair, (state, { payload: { calendarId, userId, orgId } }) => {
    state.calendarIdByUserOrgIdPair[getUserOrgIdPairString({ userId, orgId })] = calendarId;
    state.allEventInstanceIdsByCalendarId[calendarId] = [];
  })
  .on(unsetCalendarIdForUserIdOrgIdPair, (state, { payload: { userId, orgId } }) => {
    const calendarId = state.calendarIdByUserOrgIdPair[getUserOrgIdPairString({ userId, orgId })];
    delete state.calendarIdByUserOrgIdPair[getUserOrgIdPairString({ userId, orgId })];
    delete state.allEventInstanceIdsByCalendarId[calendarId];
  })
  .on(addScheduledBlockToCalendar, (state, { payload: { calendarId, block } }) => {
    state.scheduledBlocks[block.id] = block;
    if (!state.scheduledBlockIdsByCalendarId[calendarId])
      state.scheduledBlockIdsByCalendarId[calendarId] = [];
    addToListInScheduledBlockRenderOrder({
      list: state.scheduledBlockIdsByCalendarId[calendarId],
      element: block.id,
      type: block.type,
    });
    state.scheduledBlockIdsWaitingForSave[block.id] = true;
  })
  .on(removeScheduledBlock, (state, { payload: { blockId, calendarId } }) => {
    pull(state.scheduledBlockIdsByCalendarId[calendarId], blockId);
    delete state.scheduledBlocks[blockId];
    state.scheduledBlockIdsWaitingForSave[blockId] = true;
  })
  .on(updateTimeForScheduledBlock, (state, { payload: { id, startTs, endTs } }) => {
    const scheduledBlock = castDraft(getScheduledBlockById({ calendar: state }, id));
    const start = new Date(startTs);
    scheduledBlock.rule = new RRule({
      ...parseString(scheduledBlock.rule),
      byhour: start.getUTCHours(),
      byminute: start.getUTCMinutes(),
      bysecond: start.getUTCSeconds(),
    }).toString();
    scheduledBlock.durationMs = endTs - startTs;
    state.scheduledBlockIdsWaitingForSave[id] = true;
  })
  .on(dbClobberScheduledBlockForCalendarId, (state, { payload: { isRemoval, block, calendarId } }) => {
    if (isRemoval) {
      delete state.scheduledBlocks[block.id];
      pull(state.scheduledBlockIdsByCalendarId[calendarId], block.id);
    } else {
      state.scheduledBlocks[block.id] = block;
      if (!state.scheduledBlockIdsByCalendarId[calendarId])
        state.scheduledBlockIdsByCalendarId[calendarId] = [];
      addToListInScheduledBlockRenderOrder({
        list: pull(state.scheduledBlockIdsByCalendarId[calendarId], block.id),
        element: block.id,
        type: block.type,
      });
    }
    delete state.scheduledBlockIdsWaitingForSave[block.id];
  })
  .on(dbClobberSettingsForCalendarId, (state, { payload: { calendarId, settings } }) => {
    state.settingsByCalendarId[calendarId] = settings;
  })
  .on(dbEventCreatedOrUpdated, (state, { payload: { calendarId, event } }) => {
    if (!state.allEventInstanceIdsByCalendarId[calendarId].includes(event.id))
      state.allEventInstanceIdsByCalendarId[calendarId].push(event.id);
    state.eventInstances[event.id] = event;
    state.eventInstanceByEventId[event.eventId] = event;
    state.calendarIdByEventInstanceId[event.id] = calendarId;
  })
  .on(dbEventDeleted, (state, { payload: { calendarId, eventInstanceId } }) => {
    debugCheck(
      state.allEventInstanceIdsByCalendarId[calendarId].includes(eventInstanceId),
      `dbEventDeleted: event ${eventInstanceId} (${humanizeEventInstanceId(eventInstanceId)}) doesn't exist!`,
    );
    pull(state.allEventInstanceIdsByCalendarId[calendarId], eventInstanceId);
    delete state.eventInstances[eventInstanceId];
  });

const makeRruleFromRuleString = memoize((rule) => RRule.fromString(rule));

export const getScheduledBlockById = createSelector(
  (state, id: string) => state.calendar.scheduledBlocks[id],
);
export const getAllScheduledBlocks = createSelector((state) => state.calendar.scheduledBlocks);
export const getScheduledBlockIdsForCalendarId = createSelector(
  (state, calendarId: CalendarId) => state.calendar.scheduledBlockIdsByCalendarId[calendarId],
);
export const getAllScheduledBlockIdsWaitingForSave = createSelector(
  (state) => state.calendar.scheduledBlockIdsWaitingForSave,
);
export const getEventInstances = createSelector((state) => state.calendar.eventInstances);

export const getEventInstanceIdsForCalendarId = createSelector(
  (state, calendarId: CalendarId) => state.calendar.allEventInstanceIdsByCalendarId[calendarId],
);

export const getAreAllScheduledBlocksSaved = createMemoizedSelector(
  getAllScheduledBlockIdsWaitingForSave,
  (blockIds) => !Object.keys(blockIds).length,
);

export const getCalendarId = createSelector(
  (state, { userId, orgId }: { userId: UserId; orgId: OrgId }) =>
    state.calendar.calendarIdByUserOrgIdPair[getUserOrgIdPairString({ userId, orgId })],
);

export const getScheduledBlocksForCalendarId = createParametricMemoizedSelector(
  getScheduledBlockIdsForCalendarId,
  getAllScheduledBlocks,
  (blockIdsForCalendarIds, allScheduledBlocks) =>
    Object.values(pick(allScheduledBlocks, blockIdsForCalendarIds)),
)((state, calendarId) => calendarId);

export const getScheduledBlocksForCalendarIdKeyedByDayIndex = createParametricMemoizedSelector(
  getScheduledBlocksForCalendarId,
  // `byweekday` is a flimsy way to check what day this recurs on, and only
  // works because we happen to create the recurrence using byweekday. Improve
  // this as recurrence gets more complex for scheduled blocks.
  (blocksForCalendarId) =>
    groupBy(blocksForCalendarId, (block) =>
      getDayIndexFromRruleWeekday(makeRruleFromRuleString(block.rule).options.byweekday[0]),
    ) as any as {
      [dayIndex: number]: ScheduledBlock[];
    },
)({
  keySelector: (_state, calendarId) => calendarId,
  selectorCreator: createShallowArrayEqualSelector,
});

export const getScheduledBlocksForDayIndexAndCalendarId = createParametricMemoizedSelector(
  (state: { calendar: CalendarSlice }, { calendarId }: { dayIndex: Day; calendarId: CalendarId }) =>
    getScheduledBlocksForCalendarIdKeyedByDayIndex(state, calendarId),
  (_state, { dayIndex }) => dayIndex,
  (blocksForCalendarIdKeyedByDayIndex, dayIndex) => blocksForCalendarIdKeyedByDayIndex[dayIndex],
)((_state, { calendarId, dayIndex }) => `${calendarId}-${dayIndex}`);

export const getScheduledBlockIdsForDayIndexAndCalendarId = createParametricMemoizedSelector(
  getScheduledBlocksForDayIndexAndCalendarId,
  (blocks) => map(blocks, 'id'),
)((_state, { calendarId, dayIndex }) => `${calendarId}-${dayIndex}`);

export const getScheduledBlocksKeyedByTypeForDayIndexAndCalendarId = createParametricMemoizedSelector(
  getScheduledBlocksForDayIndexAndCalendarId,
  (blocksForDay) => groupBy(blocksForDay, 'type'),
)({
  keySelector: (_state, { dayIndex, calendarId }) => `${calendarId}-${dayIndex}`,
  selectorCreator: createShallowArrayEqualSelector,
});

export const getScheduledBlocksForTypeAndDayIndexAndCalendarId = createParametricMemoizedSelector(
  getScheduledBlocksKeyedByTypeForDayIndexAndCalendarId,
  (_state, { type }: { type: ScheduledBlockType }) => type,
  (blocksKeyedByType, type) => blocksKeyedByType[type],
)((_state, { dayIndex, calendarId, type }) => `${calendarId}-${dayIndex}-${type}`);

export const getScheduledBlockIdsForTypeAndDayIndexAndCalendarId = createParametricMemoizedSelector(
  getScheduledBlocksForTypeAndDayIndexAndCalendarId,
  (blocks) => map(blocks, 'id') as ScheduledBlock['id'][],
)({
  keySelector: (_state, { dayIndex, calendarId, type }) => `${calendarId}-${dayIndex}-${type}`,
  selectorCreator: createShallowArrayEqualSelector,
});

export const getWorkDayIndices = createParametricMemoizedSelector(
  getScheduledBlocksForCalendarIdKeyedByDayIndex,
  (blocksKeyedByDayIndex) => Object.keys(blocksKeyedByDayIndex).map(Number) as Day[],
)((_state, calendarId) => calendarId);

export const getOrderedEventInstanceTimestampsForCalendarId = createParametricMemoizedSelector(
  getEventInstances,
  getEventInstanceIdsForCalendarId,
  (eventInstances, instanceIds) =>
    sortBy(instanceIds, (instanceId) => new Date(eventInstances[instanceId].startTs)),
)((_state, calendarId) => calendarId);

export const getOrderedEventInstanceIdsBetweenBoundaryForCalendarId = createParametricMemoizedSelector(
  getEventInstances,
  (state: { calendar: CalendarSlice }, { calendarId }: { calendarId: CalendarId; boundary: DateTimeBlock }) =>
    getOrderedEventInstanceTimestampsForCalendarId(state, calendarId),
  (_state, { boundary }) => boundary,
  (eventInstances, sortedInstanceIds, boundary) =>
    getSliceForBoundaryInSortedArray(
      sortedInstanceIds || [],
      boundary,
      (instanceId) => new Date(eventInstances[instanceId].startTs),
    ),
)((_state, { calendarId, boundary }) => `${calendarId}-${Number(boundary.start)}-${Number(boundary.end)}`);

export const getEventInstanceWithSerializedDateTimeBlock = createSelector(
  (state, eventInstanceId: EventInstanceId) => state.calendar.eventInstances[eventInstanceId],
);

export const getSessionGroupIdForEventInstance = createParametricMemoizedSelector(
  getEventInstanceWithSerializedDateTimeBlock,
  (eventInstance) => eventInstance?.sessionGroupId,
)((_state, eventInstanceId) => eventInstanceId);

const stablePickDateTimeBlockFromSerializedInstance = memoize(
  ({ id, startTs, endTs }: EventInstance) => ({
    id,
    start: new Date(startTs),
    end: endTs ? new Date(endTs) : new Date(),
  }),
  ({ id, startTs, endTs }) => `${id}-${startTs}-${endTs}`,
);

export const getNameForEventInstance = createParametricMemoizedSelector(
  getEventInstanceWithSerializedDateTimeBlock,
  (eventInstance) => eventInstance?.name,
)((_state, eventInstanceId) => eventInstanceId);

export const getEventInstanceBoundary = createParametricMemoizedSelector(
  getEventInstanceWithSerializedDateTimeBlock,
  (instance) => (!instance ? undefined : stablePickDateTimeBlockFromSerializedInstance(instance)),
)((_state, eventInstanceId) => eventInstanceId);

export const getEventInstance = createParametricMemoizedSelector(
  getEventInstanceWithSerializedDateTimeBlock,
  getEventInstanceBoundary,
  (instance, boundary) =>
    !instance
      ? undefined
      : {
          ...omit(instance, ['startTs', 'endTs']),
          ...boundary!,
        },
)((_state, eventInstanceId) => eventInstanceId);

export const getEventInstanceIdsBetweenBoundaryForCalendarIds = createParametricMemoizedSelector(
  (
    state: { calendar: CalendarSlice },
    { calendarIds, boundary }: { calendarIds: CalendarId[]; boundary: DateTimeBlock },
  ) =>
    calendarIds.map((calendarId) =>
      getOrderedEventInstanceIdsBetweenBoundaryForCalendarId(state, { calendarId, boundary }),
    ),
  (eventInstancesIdsPerCalendar) => flatten(eventInstancesIdsPerCalendar),
)({
  keySelector: (_state, { calendarIds, boundary }) =>
    `${calendarIds.join()}-${Number(boundary.start)}-${Number(boundary.end)}`,
  selectorCreator: createShallowArrayEqualSelector,
});

export const getFirstEventInstanceIdOfEventId = createSelector(
  (state, eventId: EventId) =>
    find(getEventInstances(state), (eventInstance) => eventInstance.eventId === eventId)?.id,
);

export const getUserAndOrgIdsFromCalendarIdSlow = createSelector((state, calendarId: CalendarId) => {
  const userOrgIdPair = findKey(state.calendar.calendarIdByUserOrgIdPair, (val) => val === calendarId);
  if (userOrgIdPair) return getUserOrgIdsFromIdPairString(userOrgIdPair);
  return undefined;
});

export const getTimeFormatForCalendarId = createSelector(
  (state, calendarId: CalendarId) => state.calendar.settingsByCalendarId[calendarId]?.timeFormat ?? '12-hour',
);
export const getWeekStartIndexForCalendarId = createSelector(
  (state, calendarId: CalendarId) =>
    state.calendar.settingsByCalendarId[calendarId]?.weekStartsOnDayIndex ?? 0,
);
export const getOverrideTimeZoneForCalendarId = createSelector(
  (state, calendarId: CalendarId) => state.calendar.settingsByCalendarId[calendarId]?.overrideIanaTimeZone,
);
export const getInferredTimeZoneForCalendarId = createSelector(
  (state, calendarId: CalendarId) => state.calendar.settingsByCalendarId[calendarId]?.ianaTimeZone,
);
export const getEffectiveTimeZoneForCalendarId = createSelector(
  (state, calendarId: CalendarId) =>
    getOverrideTimeZoneForCalendarId(state, calendarId) ??
    getInferredTimeZoneForCalendarId(state, calendarId) ??
    getIanaTimeZoneName(),
);
export const getEventById = createSelector(
  (state, eventId: EventId) => state.calendar.eventInstanceByEventId[eventId],
);
export const getSessionGroupIdForEvent = createSelector(
  (state, eventId: EventId) => getEventById(state, eventId)?.sessionGroupId,
);
export const getNameForEvent = createSelector(
  (state, eventId: EventId) => getEventById(state, eventId)?.name,
);
export const getCalendarIdForEventInstance = createSelector(
  (state, eventInstanceId: EventInstanceId) => state.calendar.calendarIdByEventInstanceId[eventInstanceId],
);
