import { hoursToMilliseconds, secondsToMilliseconds } from 'date-fns';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  merge,
  mergeMap,
  NEVER,
  of,
  startWith,
  Subject,
  switchMap,
  take,
  timer,
} from 'rxjs';

import { OrgId } from 'common/models/db/vo.interface';
import { filterIsTruthy, ofActionPayload, ignoreElements } from 'common/utils/custom-rx-operators';
import { sleep } from 'common/utils/ts-utils';
import { getIsOffline } from 'pages/vo/vo-react/features/activity/activity.slice';
import { getSelfUserId } from 'pages/vo/vo-react/features/auth/auth.slice';
import { getSessionTypeById } from 'pages/vo/vo-react/features/common/session-selectors';
import { getIsKnocking, leaveSession } from 'pages/vo/vo-react/features/sessions/sessions.slice';
import {
  getLocalSetting,
  getSyncedSetting,
  setLocalSetting,
} from 'pages/vo/vo-react/features/settings/settings.slice';
import { getUserAvatarUrlById, getUserDisplayNameById } from 'pages/vo/vo-react/features/users/users.slice';
import { debugCheck } from 'utils/debug-check';
import { deleteFloofConnection, getFloofConnection } from 'utils/floof-sdk/floof-sdk';
import { runRegionTest, setRegionForSdk } from 'utils/floof-sdk/utils/region';

import { EpicWithDeps } from '../../redux/app-store';
import { getCurrentUrlParams } from '../navigation/navigation.utils';

import {
  getRegion,
  setRegion,
  unexpectedlyDisconnected,
  joinFloofSession,
  connectToFloofSession,
  deleteFloofSession,
} from './floof.slice';
import { mergeMapDuringSession } from './floof.utils';

// TODO?
// export const setRegionEpic: EpicWithDeps = (action$, state$, { injector, ngxsActions$ }) =>
//   ngxsActions$.pipe(
//     ofActionDispatched(ReduxAdapterActions.Out.SetRegion),
//     tap(({ region }) => injector.get(StorageProvider).set(storageKeys.setRegion, region)),
//     ignoreElements(),
//   );

export const setRegionHourlyWhenOnlineOrWhenUserSelectsEpic: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    map((state) => getLocalSetting(state, 'userSelectedRegion')),
    distinctUntilChanged(),
    switchMap((userSelectedRegion) => {
      if (userSelectedRegion !== 'auto-detect') return of(setRegion(userSelectedRegion));

      const retry$ = new Subject();
      return combineLatest([
        state$.pipe(
          map((state) => getIsOffline(state)),
          distinctUntilChanged(),
        ),
        retry$.pipe(startWith(undefined)),
      ]).pipe(
        switchMap(([isOffline]) => (isOffline ? NEVER : timer(0, hoursToMilliseconds(1)))),
        switchMap(async () => {
          const region = await runRegionTest();
          if (region) return setRegion(region);
          await sleep(secondsToMilliseconds(5));
          retry$.next(undefined);
        }),
        filterIsTruthy(),
      );
    }),
  );

export const resetRegionWhenUserSelectsAutoDetectRegionEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(setLocalSetting),
    filter(({ name, value }) => name === 'userSelectedRegion' && value === 'auto-detect'),
    map(() => setRegion('unknown-unknown')),
  );

export const setRegionOnFloofSdkWhenChanged: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    map((state) => getRegion(state)),
    distinctUntilChanged(),
    map((region) => setRegionForSdk(region)),
    ignoreElements(),
  );

export const connectToFloofSessionEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(connectToFloofSession),
    mergeMap(({ sessionId }) => {
      const isJoiningWalkieTalkieSession = getSessionTypeById(state$.value, sessionId) === 'walkie-talkie';
      const [shouldEnableMic, shouldEnableCamera] = isJoiningWalkieTalkieSession
        ? [false, false]
        : [
            getSyncedSetting(state$.value, 'shouldEnableMicrophoneOnStart'),
            getSyncedSetting(state$.value, 'shouldEnableCameraOnStart'),
          ];

      const selfUserId = getSelfUserId(state$.value)!;
      debugCheck(!!selfUserId, 'connectToFloofSessionWithPeerIdEpic: no selfUserId set, bailing!');
      const displayName = getUserDisplayNameById(state$.value, selfUserId);
      const avatarUrl = getUserAvatarUrlById(state$.value, selfUserId);
      const isKnocking = getIsKnocking(state$.value);
      const orgId = getCurrentUrlParams()?.orgId as OrgId;
      const { connect, getSelfPeerId } = getFloofConnection(sessionId);
      connect({
        shouldEnableMic,
        shouldEnableCamera,
        displayName,
        userId: selfUserId,
        orgId,
        avatarUrl,
        isKnocking,
      });

      return merge(
        action$.pipe(
          ofActionPayload(leaveSession),
          filter(({ sessionId: leavingSessionId }) => leavingSessionId === sessionId),
          map(() => deleteFloofSession(sessionId)),
          take(1),
        ),
      );
    }),
  );

export const leaveSessionBeforeUnloadEpic = (action$) =>
  action$.pipe(
    mergeMapDuringSession((sessionId) =>
      fromEvent(window, 'beforeunload').pipe(map(() => leaveSession({ sessionId }))),
    ),
  );

// This epic is responsible for deleting the floof connection when the user leaves the session.
export const deleteFloofSessionEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(deleteFloofSession),
    map((sessionId) => deleteFloofConnection(sessionId)),
    ignoreElements(),
  );

// TODO: Thunks can't be dispatch()-ed from within thunks, so we have to use this workaround
export const rejoinWhenUnexpectedlyDisconnected: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(unexpectedlyDisconnected),
    map((sessionId) => joinFloofSession(sessionId)),
  );
