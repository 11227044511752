import { isFunction } from 'lodash';
import { shouldShowAdditionalErrors } from './client-utils';

/**
 * Use to sanity check assumptions and throw errors in development and testing.
 * Inspired by Chromium's concept of CHECKs and DEBUG_CHECKs.
 *
 * Usage:
 *
 * if (debugCheck(!!myAssumption, 'My assumption is not correct')) {
 *   //...
 * }
 *
 * or
 *
 * if (somethingCrazy && totallyUnexpected) {
 *   debugCheck('Something crazy and totally unexpected has happened');
 * }
 */
export function debugCheck(customMessage: string | (() => string)): boolean | never;
export function debugCheck(
  thingToCheck: boolean,
  customMessage: string | (() => string),
): thingToCheck is true;
export function debugCheck(
  thingOrMessage?: boolean | string | (() => string),
  customMessage?: string | (() => string),
) {
  let message;

  if (typeof thingOrMessage === 'boolean') {
    const thingToCheck = thingOrMessage;
    if (thingToCheck) return true;
    message = customMessage;
  } else {
    message = thingOrMessage;
  }

  if (!message) message = 'Generic error.';
  if (isFunction(message)) message = message();

  if (shouldShowAdditionalErrors) {
    throw Error(`Failed debugCheck in dev: ${message}`);
  } else {
    console.error(`Silently continuing after failed debugCheck in prod: ${message}`);
  }
  return false;
}

/**
 * A check is for when we think an error can't happen, but when it does, we want
 * to be told about it.
 *
 * A warn is for when we think an error can happen from time to time, and when
 * it does, we want to be told about it.
 */
export function debugWarn(customMessage: string) {
  if (!shouldShowAdditionalErrors) return;
  console.warn(customMessage);
}
