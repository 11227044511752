/* eslint-disable no-redeclare */
import { noop } from 'lodash';
import {
  MutableRefObject,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';

import { Rectangle } from 'common/models/geometry.interface';
import { domRectToRectangle } from 'common/utils/geometry-utils';
import { ReactChildren } from 'utils/react-utils';

import { useCurrentWindow } from './use-current-window';
import { useUpdateRef } from './use-update-ref';

const arbitraryUpdateReducer = (count: number) => (count + 1) % 1_000;

export const RecalcBoundingClientContext = createContext({ forceRecalc: noop, arbitraryCount: 0 });

export function useBoundingClientRect(params: {
  asRef: true;
  overrideWin?: Window;
}): [(element: HTMLElement) => any, MutableRefObject<Rectangle | undefined>, () => void];
export function useBoundingClientRect(params?: {
  asRef?: false;
  shouldUseRaf?: boolean;
  overrideWin?: Window;
}): [(element: HTMLElement) => any, Rectangle | undefined, () => void];
export function useBoundingClientRect({
  asRef = false,
  shouldUseRaf = true,
  overrideWin,
}: { asRef?: boolean; shouldUseRaf?: boolean; name?: string; overrideWin?: Window } = {}) {
  const [element, setElement] = useState<HTMLElement>();
  const [rect, setRect] = useState<Rectangle | undefined>();
  const rectRef = useRef<Rectangle | undefined>();
  const contextWin = useCurrentWindow();
  const win = overrideWin ?? contextWin;
  // Arbitrarily increase a count that we listen to in the useLayoutEffect deps
  // to force a recalculation of the bounds.
  const [arbitraryUpdateCount, forceRecalc] = useReducer(arbitraryUpdateReducer, 0);
  const contextUpdateCount = useContext(RecalcBoundingClientContext).arbitraryCount;

  const updateRect = useCallback(
    (nextRect: Rectangle | undefined) => {
      if (asRef) rectRef.current = nextRect;
      else {
        const doSetRect = () => setRect(nextRect);
        if (shouldUseRaf) requestAnimationFrame(doSetRect);
        else doSetRect();
      }
    },
    [asRef, setRect, rectRef, shouldUseRaf],
  );

  useLayoutEffect(() => {
    const nextRect = element ? domRectToRectangle(element.getBoundingClientRect()) : undefined;
    updateRect(nextRect);

    if (element && win) {
      const resizeObserver = new win['ResizeObserver'](() => {
        const nextRect = domRectToRectangle(element.getBoundingClientRect());
        updateRect(nextRect);
      });

      resizeObserver.observe(element);
      return () => resizeObserver.disconnect();
    }
  }, [updateRect, element, win, arbitraryUpdateCount, contextUpdateCount]);

  return [setElement, asRef ? rectRef : rect, forceRecalc] as const;
}

export const useBoundingClientForceRecalc = () => useContext(RecalcBoundingClientContext).forceRecalc;

export const useBoundingClientForceRecalcContextProvider = () => {
  const [arbitraryUpdateCount, incrementArbitraryUpdateCount] = useReducer(arbitraryUpdateReducer, 0);
  const ancestorArbitraryValue = useContext(RecalcBoundingClientContext).arbitraryCount;
  const totalArbitraryCount = arbitraryUpdateCount + ancestorArbitraryValue;
  const totalArbitraryCountRef = useUpdateRef(totalArbitraryCount);
  const forceRecalc = incrementArbitraryUpdateCount;

  const ForceRecalcContextProvider = useCallback(
    ({ children }: { children: ReactChildren }) => (
      <RecalcBoundingClientContext.Provider
        value={{ forceRecalc, arbitraryCount: totalArbitraryCountRef.current }}
      >
        {children}
      </RecalcBoundingClientContext.Provider>
    ),
    [forceRecalc],
  );

  return {
    ForceRecalcContextProvider,
    forceRecalc,
  };
};
