import { capitalize, reduce } from 'lodash';
import { useHarmonicIntervalFn, useUpdate } from 'react-use';
import {
  merge,
  distinctUntilChanged,
  NEVER,
  map,
  of,
  Observable,
  OperatorFunction,
  mergeMap,
  tap,
  switchMap,
  Subject,
  from,
} from 'rxjs';

import { filterIsTruthy, log, switchMapExists, switchMapIfTruthy } from 'common/utils/custom-rx-operators';
import { observeUnsubscribe } from 'common/utils/observe-unsubscribe';
import { FirstArgument } from 'common/utils/ts-utils';
import { getEnhancedTrackForTrack } from 'utils/react/audio-context';
import { getRefCountedTrack } from 'utils/react/get-user-media';
import { useObservable } from 'utils/react/use-observable';
import { useSelector } from 'utils/react/use-selector';

import { getAppStore } from '../../redux/app-store';
import { observeStoreWithSelector } from '../../redux/observe-state';
import { getMicrophoneTrack } from '../media/media.slice';

import { getIsCameraEnabled, getPreferredDeviceByType } from './devices.slice';
import { Device, DeviceType } from './devices.types';

export const makeUniqueDeviceId = <T extends DeviceType>(id: string, type: T) => `${id}-${type}` as const;

export const osDeviceKindToDeviceType: { [k in MediaDeviceKind]: DeviceType } = {
  audioinput: 'microphone',
  audiooutput: 'speakers',
  videoinput: 'camera',
};

export const convertOsMediaDeviceListToPopDevices = (osDevices: MediaDeviceInfo[]) =>
  reduce(
    osDevices,
    (memo, { deviceId, kind, label }) => {
      memo.push({
        type: osDeviceKindToDeviceType[kind],
        uniqueDeviceId: makeUniqueDeviceId(deviceId, osDeviceKindToDeviceType[kind]),
        originalDeviceId: deviceId,
        label:
          deviceId === 'default' && !label.toLowerCase().startsWith('default') ? `Default - ${label}` : label,
      } as Device);
      return memo;
    },
    [] as Device[],
  );

export function deviceTypeToHumanReadableString(deviceType: DeviceType) {
  return deviceType ? capitalize(deviceType) : 'Unknown';
}

export const deviceTypeToIconName = {
  microphone: IconMicOn,
  camera: IconCameraOn,
  speakers: IconSpeakersOn,
  screen: IconScreenShareOn,
} as const;

export const useMicrophoneTrack = () =>
  useObservable(() => observeRefCountedTrackForPreferredInputDevice('microphone'), undefined);

export const useCameraTrack = (
  { overrideIsCameraEnabled }: { overrideIsCameraEnabled: boolean } = { overrideIsCameraEnabled: false },
) =>
  useObservable(
    () =>
      (overrideIsCameraEnabled ? of(true) : observeStoreWithSelector(getAppStore(), getIsCameraEnabled)).pipe(
        switchMapIfTruthy(() => observeRefCountedTrackForPreferredInputDevice('camera')),
      ),
    undefined,
    [overrideIsCameraEnabled],
  );

export const useMicrophoneVuLevel = () => {
  const track = useSelector(getMicrophoneTrack, 'self' as const);
  const update = useUpdate();
  useHarmonicIntervalFn(update, 50);

  if (!track) return 0;
  return getEnhancedTrackForTrack(track)?.getAudioLevel() ?? 0;
};

export const observePreferredInputDeviceIdOfType = (
  type: 'microphone' | 'camera',
): Observable<string | undefined> =>
  observeStoreWithSelector(getAppStore(), getPreferredDeviceByType, type).pipe(
    map((device) => device?.originalDeviceId),
    distinctUntilChanged(),
  );

export const observeRefCountedTrackForPreferredInputDevice = (type: 'microphone' | 'camera') =>
  observePreferredInputDeviceIdOfType(type).pipe(
    log((deviceId) => `deviceId: ${deviceId}, type: ${type}`),
    map((deviceId) => (deviceId ? { type, deviceId } : undefined)),
    switchMapExists((info) => {
      const { track, release } = getRefCountedTrack(info);
      return merge(
        from(track),
        observeUnsubscribe(async () => {
          release();
        }),
      );
    }),
    log((track) => ['track', track]),
  );

/* eslint-disable rxjs/no-nested-subscribe */
export const refCountTrack =
  <A = MediaStreamTrack, R = never>({
    onAcquiredNewTrack = (track) => of(track) as unknown as Observable<A>,
    onReleasedOldTrack = () => NEVER,
  }: {
    onAcquiredNewTrack?: (refCountedTrack: MediaStreamTrack) => Observable<A>;
    onReleasedOldTrack?: (refCountedTrack: MediaStreamTrack) => Observable<R>;
  } = {}): OperatorFunction<FirstArgument<typeof getRefCountedTrack> | undefined, A | R> =>
  (outerObservable$: Observable<FirstArgument<typeof getRefCountedTrack> | undefined>) =>
    new Observable((subscriber) => {
      let currentTrack: ReturnType<typeof getRefCountedTrack> | undefined;
      let didComplete = false;

      const acquired$ = new Subject<ReturnType<typeof getRefCountedTrack> | undefined>();
      const released$ = new Subject<ReturnType<typeof getRefCountedTrack> | undefined>();
      const innerObservable$ = merge(
        acquired$.pipe(
          tap((...args) => {
            console.log('got emission on acquired$', ...args);
          }),
          filterIsTruthy(),
          mergeMap(({ track }) => track),
          switchMap(onAcquiredNewTrack),
        ),
        released$.pipe(
          filterIsTruthy(),
          tap(({ release }) => release()),
          mergeMap(({ track }) => track),
          switchMap(onReleasedOldTrack),
        ),
      );

      const innerSubscription = innerObservable$.subscribe(subscriber);

      const outerSubscription = outerObservable$.subscribe({
        next(info) {
          const nextTrack = info ? getRefCountedTrack(info) : undefined;
          acquired$.next(nextTrack);
          released$.next(currentTrack);
          currentTrack = nextTrack;
          console.log('innerObservable$', innerObservable$, innerSubscription);
        },
        complete() {
          didComplete = true;
          released$.next(currentTrack);
          subscriber.complete();
        },
      });

      return () => {
        if (!didComplete) released$.next(currentTrack);
        innerSubscription.unsubscribe();
        outerSubscription.unsubscribe();
      };
    });
