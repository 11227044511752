import { size, some } from 'lodash';

import { UniqueType } from '../../utils/ts-utils';

import { CalendarId, CalendarType, CalendarSettings } from './calendar.interface';
import { SlackUserTeam } from './linkage.interface';
import { MediaServer, MediaServerType } from './media-server.interface';
import { SessionHistoryId } from './session-history.interface';
import {
  JoinableSessionInitiator,
  SessionInitiator,
  SessionInitiatorType,
} from './session-initiatior.interface';
import { UrlId } from './url.interface';

export type OrgId = UniqueType<string, 'OrgId'>;
export type TeamId = UniqueType<string, 'TeamId'>;
export type SpaceId = UniqueType<string, 'SpaceId'>;
export type UserId = UniqueType<string, 'UserId'>;
export type InstantMeetingId = UniqueType<string, 'InstantMeetingId'>;
export type MessageId = UniqueType<string, 'MessageId'>;
export type SessionGroupId = UniqueType<string, 'SessionGroupId'>;
export type SessionId = UniqueType<string, 'SessionId'>;
export type RingId = MessageId;
export type RemotePeerId = UniqueType<string, 'RemotePeerId'>;

export type SelfPeerId = 'self';
export const selfPeerId = 'self' as SelfPeerId;
export type AnyPeerId = SelfPeerId | RemotePeerId;

export type QualifiedPeerIdObject = { peerId: RemotePeerId } | { peerId: 'self'; sessionId: SessionId };

export const serializeQualifiedPeerId = (payload: QualifiedPeerIdObject) =>
  payload.peerId === 'self' ? `self-${(payload as any).sessionId}` : payload.peerId;
export const makeQualifiedPeerId = ({ peerId, sessionId }: { peerId: AnyPeerId; sessionId: SessionId }) =>
  ({
    peerId,
    ...(peerId === 'self' && { sessionId }),
  } as QualifiedPeerIdObject);

export const allUserStatuses = ['available', 'dnd', 'away', 'in-session'] as const;
export type UserStatus = (typeof allUserStatuses)[number];

export type ExternalLinkageType = 'slack' | 'ms-teams' | 'discord';

/**
 *    /orgs/${orgId}
 *                  /teams/${teamId}/true
 *                  /fbUserIds/${fbUserId}/true
 *                  /meetings/${meetingId}/true
 *    /teams
 *          /${teamId}
 *    /meetings
 *             /${meetingId}
 */

export type DbCalendarScheduledBlockType = 'available' | 'blocked' | 'break' | 'range';

export type DbCalendarScheduledBlock = {
  id: string;
  type: DbCalendarScheduledBlockType;
  rule: string;
  durationMs: number;
  text?: string;
};

export const orgIconFormat = 'webp';
export const orgIconNameAndSizes = {
  icon: [512, 80, 40, 20] as const,
};

export type OrgIconName = keyof typeof orgIconNameAndSizes;
type OrgIconSize = (typeof orgIconNameAndSizes)[OrgIconName][number];
export type OrgIconNameAndSize<
  N extends OrgIconName = OrgIconName,
  S extends OrgIconSize = OrgIconSize,
> = `${N}${S}`;

export type DbOrgIcons = {
  [iconFilename in OrgIconNameAndSize]?: string;
};

export interface DbOrg {
  name: string;
  iconUrl: string;
  icons?: DbOrgIcons;
  externalLinkageType?: ExternalLinkageType;
  externalLinkageId?: string;
  teamIds?: { [teamId: string]: boolean };
  userIds?: { [userId: string]: DbOrgUser };
  spaceIds?: { [spaceId: string]: boolean };
  inviteUrlId?: UrlId;
  personalOrgUserId?: UserId;
}

export interface DbOrgUser {
  // Needed for proper firebase accounting.
  accountStatus: 'active' | 'deleted';
  calendarSettings: CalendarSettings;
  scheduledBlocks?: { [id: string]: DbCalendarScheduledBlock };
  primaryCalendarId: CalendarId | null;
  calendars?: {
    [calendarId: string]: {
      calendarType: CalendarType;
      name?: string;
    };
  };
  currentJoinableSessionInitiators?: {
    [sessionInitiatorType in SessionInitiatorType]?: {
      [sessionInitiatorId: string]: {
        [sessionId: string]: JoinableSessionInitiator;
      };
    };
  };
  stories?: {
    current?: {
      [storyId: string]: true;
    };
    history?: {
      [storyId: string]: true;
    };
  };
}

export interface DbTeam {
  name: string;
  emoji?: string;
  userIds: { [userId: string]: boolean };
  sessionGroupId: SessionGroupId;
}

export interface DbSpace {
  name: string;
  emoji?: string;
  sessionGroupId: SessionGroupId;
}

export interface DbInstantMeeting {
  didEnd?: boolean;
  name?: string;
  userIds?: { [userId: string]: boolean };
  sessionGroupId: SessionGroupId;
}

export interface DbSessionGroup {
  sessionInitiator: SessionInitiator;
  orgIds: { [orgId: string]: boolean };
  mediaServerType: MediaServerType;
  sessionIds: {
    [sessionId: string]: boolean;
  };
  temporarilyAllowedUserIds?: {
    [userId: string]: boolean;
  };
}

export interface DbSession {
  sessionId?: SessionId;
  sessionGroupId: SessionGroupId;
  sessionHistoryId?: SessionHistoryId;
  creationTs: number;
  mediaServer: MediaServer;
  isLocked?: boolean;
  peers?: {
    [peerId: string]: DbPeer;
  };
  slack?: {
    [slackTeamId: string]: {
      slackCallId: string;
    };
  };
}

export interface PeerData {
  avatarUrl?: string;
  displayName: string;
  userId: UserId;
}

export interface DbPeer extends PeerData {
  peerId?: RemotePeerId;
  joinTs?: number;
  isInactive?: boolean;
  tracks?: {
    [logicalTrackId: string]: PeerVideoTrackInfo | PeerAudioTrackInfo;
  };
  lastInactivityUpdateTs?: number;
  isConnected?: boolean;
  slack?: {
    [slackTeamId: string]: SlackUserTeam;
  };
  isKnockMicEnabled?: boolean;
  pauseMessage?: true | string;
  pauseImage?: string;
  knockStatus?: 'knocking' | 'allowed' | 'denied';
}

export type PeerTrackInfo = PeerVideoTrackInfo | PeerAudioTrackInfo;
export type PeerTrackInfoWithoutTransportIds = Omit<
  PeerVideoTrackInfo | PeerAudioTrackInfo,
  'trackTransportIds'
>;

export type PeerVideoTrackInfo = {
  logicalTrackId: string;
  mediaType: 'camera' | 'screen';
  width?: number;
  height?: number;
  deviceId?: string;
  ts: number;
  isEnabled: true;
  trackTransportIds?: TrackTransportIds;
};

export type PeerAudioTrackInfo = {
  logicalTrackId: string;
  mediaType: 'mic';
  ts: number;
  isEnabled: boolean;
  trackTransportIds?: TrackTransportIds;
};

type TrackTransportIds = {
  sfu?: string;
  p2p?: { [peerId: string]: string };
};

export const getConnectedPeersForSession = (dbSession?: DbSession) => dbSession?.peers;

export const getConnectedPeerCountForSession = (dbSession?: DbSession) => size(dbSession?.peers);

export const getIsPeerConnectedToSession = (peerId: RemotePeerId, dbSession?: DbSession) =>
  !!dbSession?.peers?.[peerId];

export const getIsUserConnectedToSession = (userId: UserId, dbSession?: DbSession) =>
  some(getConnectedPeersForSession(dbSession), (dbPeer) => dbPeer.userId === userId);
