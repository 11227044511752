import autoBind from 'auto-bind';

import { MediaType } from 'common/models/connection.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { FloofAnalyticsDelegate } from 'utils/floof-sdk/floof-sdk';

export interface MediaConnectionManagerDelegate {
  onTrackReceived: (info: {
    track: MediaStreamTrack;
    mediaType: MediaType;
    trackTransportId: string;
    peerId: RemotePeerId;
  }) => any;
  onTrackRemoved: (trackId: string, mediaType: MediaType, peerId: RemotePeerId) => any;
  onMessageReceived: (message: PeerMessage, peerId: RemotePeerId) => any;
}

export abstract class MediaConnectionManager {
  constructor(
    protected selfPeerId: RemotePeerId,
    protected analyticsDelegate: FloofAnalyticsDelegate | undefined,
  ) {
    autoBind(this);
  }

  public abstract sendTrack(track: MediaStreamTrack | undefined, mediaType: MediaType): void;

  public abstract disconnect(): void;
}
