import autoBind from 'auto-bind';
import { map } from 'rxjs';
import { SubSink } from 'subsink';

import { SessionId, RemotePeerId } from 'common/models/db/vo.interface';
import { observeConnectedRemotePeerCountForSession } from 'utils/floof-sdk/utils/db';

export interface SessionPeerCountObserverDelegate {
  onConnectedPeerCountChanged: (count: number) => any;
}

export class SessionPeerCountObserver {
  private subSink = new SubSink();
  constructor(
    private sessionId: SessionId,
    private peerId: RemotePeerId,
    private delegate: SessionPeerCountObserverDelegate,
  ) {
    autoBind(this);
  }

  public disconnect() {
    this.subSink.unsubscribe();
  }

  public start() {
    this.subSink.sink = observeConnectedRemotePeerCountForSession(this.sessionId, this.peerId)
      .pipe(
        map((count) => {
          this.delegate.onConnectedPeerCountChanged(count + 1);
        }),
      )
      .subscribe();
  }
}
