import { i18n } from '@lingui/core';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { initializeFirebase } from 'utils/firebase-app';
import { storage } from 'utils/storage';

import { router } from '../pages/vo/vo-react/router';

initializeFirebase({ shouldUseLocalhostAuth: !!storage.get('should-use-localhost-auth') });

const locale = navigator.language.split('-')[0];
void import(`assets/locales/${locale}.po`).then(({ messages }) => {
  i18n.load(locale, messages);
  i18n.activate(locale);
});

createRoot(document.getElementById('root') as HTMLElement).render(<RouterProvider router={router} />);
