import { capitalize, compact, pull } from 'lodash';

import { KeyboardEvent as IagoKeyboardEvent } from 'common/models/iago-event.interface';
import { KeyCombo, ShortcutModifier } from 'common/models/keyboard-shortcuts.interface';
import { isMac, isWindows } from 'utils/client-utils';

export const getModifierFromKey = (key: KeyboardEvent['key']): ShortcutModifier | undefined => {
  if (key === 'Control') return 'ctrl';
  if (key === 'Meta') return 'meta';
  if (key === 'Alt') return 'alt';
  if (key === 'Shift') return 'shift';
  return undefined;
};

export const getModifiersFromEvent = (event: KeyboardEvent): ShortcutModifier[] => [
  ...(event.ctrlKey ? ['ctrl' as const] : []),
  ...(event.metaKey ? ['meta' as const] : []),
  ...(event.altKey ? ['alt' as const] : []),
  ...(event.shiftKey ? ['shift' as const] : []),
];

export const getHumanReadableKeyCombo = (keyCombo?: KeyCombo) => {
  if (!keyCombo) return '';
  const modifierLabels = getHumanReadableModifiersForKeyCombo(keyCombo);
  return `${modifierLabels.join('')} ${(keyCombo.key ?? '').toLocaleUpperCase()}`;
};
export const getHumanReadableModifiersForKeyCombo = (keyCombo: KeyCombo) => {
  const modifiersInOrder = ['shift' as const, 'ctrl' as const, 'alt' as const, 'meta' as const];
  const modifierLabels = compact(
    modifiersInOrder.map((modifier) =>
      keyCombo.modifiers.includes(modifier) ? getLabelForModifier(modifier) : '',
    ),
  );
  return modifierLabels;
};

export function getLabelForModifier(modifier: ShortcutModifier) {
  if (isMac) {
    if (modifier === 'meta') return '⌘';
    if (modifier === 'ctrl') return '⌃';
    if (modifier === 'alt') return '⌥';
    if (modifier === 'shift') return '⇧';
  }
  if (isWindows) {
    if (modifier === 'meta') return 'Win';
    if (modifier === 'ctrl') return 'Ctrl';
    if (modifier === 'alt') return 'Alt';
    if (modifier === 'shift') return 'Shift';
  }
  if (modifier === 'meta') return 'Super';
  if (modifier === 'ctrl') return 'Ctrl';
  if (modifier === 'alt') return 'Alt';
  if (modifier === 'shift') return 'Shift';
  return '';
}

export function keyNameFromEventWithoutPrefix(event: KeyboardEvent) {
  const re = /key|digit|numpad|arrow/;
  const key = event.code.trim().toLowerCase();
  if (!key.match(re)) return event.key;
  return key.replace(re, '');
}

export class KeyComboAccumulator {
  protected modifiers: ShortcutModifier[] = [];
  protected key = '';
  public ingestKeyEvent(event: KeyboardEvent) {
    const eventModifiers = getModifiersFromEvent(event);
    const modifierKey = getModifierFromKey(event.key);
    if (event.type === 'keydown') {
      if (modifierKey) {
        if (eventModifiers.length === 1) {
          // When pressing the first modifier, reset everything.
          this.modifiers = [modifierKey];
          this.key = '';
        } else {
          // Otherwise, append the modifier.
          this.modifiers = [...this.modifiers, modifierKey];
        }
      } else {
        if (event.key === 'Backspace') {
          this.modifiers = [];
          this.key = '';
        } else {
          this.modifiers = eventModifiers;
          this.key = keyNameFromEventWithoutPrefix(event);
        }
      }
    } else if (event.type === 'keyup') {
      // Any key unpress should result in the key being
      // forgotten, since that's the final step of any shortcut.
      this.key = '';
      if (modifierKey) {
        // If a modifier was unpressed (e.g. Cmd-Ctrl, and then Ctrl was
        // unpressed), remove the modifier from the list.
        this.modifiers = pull(this.modifiers, modifierKey);
      }
    }
  }
  public getKeyCombo() {
    return { key: this.key, modifiers: this.modifiers };
  }
  public ingestIagoKeyboardEvent({ baseName, isDown }: IagoKeyboardEvent) {
    if (!baseName) throw new Error('ingestIagoKeyboardEvent: baseName cannot be undefined!');
    const browserKey = browserKeyFromIagoBaseName(baseName);
    const event = new KeyboardEvent(isDown ? 'keydown' : 'keyup', {
      metaKey: (browserKey === 'Meta' && isDown) || this.modifiers.includes('meta'),
      shiftKey: (browserKey === 'Shift' && isDown) || this.modifiers.includes('shift'),
      ctrlKey: (browserKey === 'Control' && isDown) || this.modifiers.includes('ctrl'),
      altKey: (browserKey === 'Alt' && isDown) || this.modifiers.includes('alt'),
      key: browserKey.length === 1 ? browserKey : capitalize(browserKey),
    });
    this.ingestKeyEvent(event);
  }
}

function browserKeyFromIagoBaseName(baseName: string) {
  const key = baseName.split('_')[0].toLowerCase();
  if (key === 'command' || key === 'meta') return 'Meta';
  if (key === 'option' || key === 'alt ') return 'Alt';
  if (key === 'shift') return 'Shift';
  if (key === 'control') return 'Control';
  return key;
}

/**
 * This is used when training a shortcut in settings. The only logic here is to
 * trap a shortcut once it's been trained. So if you press Ctrl-Cmd-Z, and then
 * you start lifting keys, since the "Z" has been detected, all future keyups
 * will result in an early return. Only when new modifiers (or backspace) are
 * pressed will it reset the combination.
 */
export class KeyComboTrainer extends KeyComboAccumulator {
  public ingestKeyEvent(event: KeyboardEvent): void {
    if (this.key && event.type === 'keyup') return;
    super.ingestKeyEvent(event);
  }
}
