import { memo } from 'react';
import styled, { css } from 'styled-components';

import { oppositeEdge, directionToEdge, Direction } from 'common/utils/geometry-utils';
import { clientAssertExhaustedType } from 'utils/client-utils';
import { blockAllForwardedPropsConfig } from 'utils/react-utils';

const getWhichEdgesHaveTransparentBordersForArrowDirection = (direction: Direction) => {
  if (direction === 'down' || direction === 'up') return ['left', 'right'] as const;
  if (direction === 'left' || direction === 'right') return ['top', 'bottom'] as const;
  clientAssertExhaustedType(direction);
};

export const Arrow = memo(styled('div').withConfig({
  ...blockAllForwardedPropsConfig(['background', 'direction', 'size']),
})<{
  direction: Direction;
  background?: string;
  size?: string;
}>`
  width: 0;
  height: 0;
  ${({ background, direction, size = '5px' }) => {
    const [a, b] = getWhichEdgesHaveTransparentBordersForArrowDirection(direction);
    const c = oppositeEdge(directionToEdge(direction));
    return css`
      border-${a}: ${size} solid transparent;
      border-${b}: ${size} solid transparent;
      border-${c}: ${size} solid ${background};
  `;
  }}
`);
