import { fromEvent, merge, of } from 'rxjs';

import { pluck } from './custom-rx-operators';

export const observeMatchesMedia = (query: string, win = window) => {
  const mediaQuery = win.matchMedia(query);
  return merge(
    of(mediaQuery.matches),
    fromEvent<MediaQueryListEvent>(mediaQuery, 'change').pipe(pluck('matches')),
  );
};
