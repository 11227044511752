/**
 * A "logical track" represents a single piece of media (an audio/camera/screen
 * input stream) that can consist of multiple "local tracks" that may be sent as
 * duplicates over multiple transports (P2P or SFU). This is useful because
 * ultimately the application doesn't care which transport media is being sent
 * on, and a logical track also helps tie a set of local received tracks to a
 * remote peer's sent tracks, such that they can both have a shared
 * understanding (in the form of an ID) as to which track is which. This is
 * especially useful for drawing, where we need to know which track is being
 * drawn on.
 */
import { each, findKey, forEach, isEqual } from 'lodash';

import { TrackInfo, Transport } from 'common/models/floof.interface';
import { debugCheck } from 'utils/debug-check';

type WithTrack<T> = T & {
  track?: MediaStreamTrack;
};

class LogicalTrack<TrackInfo> {
  private p2p: { track: MediaStreamTrack; trackTransportId: string } | undefined;
  private sfu: { track: MediaStreamTrack; trackTransportId: string } | undefined;
  private active: MediaStreamTrack | undefined;
  private preferredTransport: Transport;
  private onActiveTrackChanged: (track?: MediaStreamTrack) => void;

  constructor({
    preferredTransport = 'p2p',
    onActiveTrackChanged: onActiveTrackChanged,
  }: {
    preferredTransport: Transport;
    onActiveTrackChanged: LogicalTrack<TrackInfo>['onActiveTrackChanged'];
  }) {
    this.preferredTransport = preferredTransport;
    this.onActiveTrackChanged = onActiveTrackChanged;
  }

  public switchTransport(transport: Transport) {
    this.preferredTransport = transport;
    this.notify();
  }

  public storeTrack({
    transport,
    track,
    trackTransportId,
  }: {
    transport: Transport;
    track: MediaStreamTrack;
    trackTransportId: string;
  }) {
    this[transport] = { track, trackTransportId };
    this.notify();
  }

  public removeTrack(localTrackId: string) {
    const transport = this.getTransportForTrackId(localTrackId);
    if (!transport) return;
    this.clearTransport(transport);
  }

  public clearTransport(transport: 'p2p' | 'sfu') {
    this[transport] = undefined;
    this.notify();
  }

  public isHoldingLocalTrackId(localTrackId: string) {
    return !!this.getTransportForTrackId(localTrackId);
  }

  public isWaitingForTrackOnTransport(transport: Transport) {
    return !this[transport];
  }

  public isWaitingOnPreferredTransport() {
    this.isWaitingForTrackOnTransport(this.preferredTransport);
  }

  private getTransportForTrackId(localTrackId: string) {
    if (this.p2p?.track?.id === localTrackId) return 'p2p';
    if (this.sfu?.track?.id === localTrackId) return 'sfu';
    return undefined;
  }

  private notify() {
    const prevActive = this.active;

    this.active = this[this.preferredTransport]?.track;

    if (prevActive !== this.active) {
      this.onActiveTrackChanged(this.active);
    }
  }

  public getAllTrackAndTransportInfo() {
    return [
      ...(this.p2p ? [{ transport: 'p2p' as const, ...this.p2p }] : []),
      ...(this.sfu ? [{ transport: 'sfu' as const, ...this.sfu }] : []),
    ];
  }

  public destroy() {
    this.p2p = undefined;
    this.sfu = undefined;
    this.notify();
  }
}

interface LogicalTrackGroupDelegate {
  onTrackChanged: (logicalTrackId: string, trackInfo?: WithTrack<TrackInfo>) => void;
  getPreferredTransport: () => Transport;
}

/**
 * LogicalTrackGroup groups LogicalTracks arbitrarily, though in the case of our
 * application they are grouped along their mediaType likeness. With the advent
 * of trackTransportIds, which identify a single send/receive remote/local track
 * pair, this is less important and mostly kept for simple reason of being too
 * tired of reworking this code to change it.
 */
export class LogicalTrackGroup<TrackInfo extends { trackTransportIds: { sfu: string; p2p: string } }> {
  private logicalTracks: { [logicalTrackId: string]: LogicalTrack<TrackInfo> } = {};
  private trackInfoByLogicalTrackId: { [logicalTrackId: string]: TrackInfo | WithTrack<TrackInfo> } = {};
  private unassignedTracks: { track: MediaStreamTrack; transport: Transport; trackTransportId: string }[] =
    [];

  constructor(private delegate: LogicalTrackGroupDelegate) {}

  public createLogicalTrack(logicalTrackId: string, trackInfo: TrackInfo) {
    this.trackInfoByLogicalTrackId[logicalTrackId] = {} as any;

    const logicalTrack = new LogicalTrack({
      preferredTransport: this.delegate.getPreferredTransport(),
      onActiveTrackChanged: (localTrack?: MediaStreamTrack) =>
        this.updateTrackInfo(logicalTrackId, { track: localTrack } as WithTrack<Partial<TrackInfo>>),
    });

    this.logicalTracks[logicalTrackId] = logicalTrack;
    this.updateLogicalTrack(logicalTrackId, trackInfo);
  }

  public updateLogicalTrack(logicalTrackId: string, trackInfo: TrackInfo) {
    const logicalTrack = this.logicalTracks[logicalTrackId];
    if (!debugCheck(!!logicalTrack, `Expected logical track ${logicalTrackId}`)) return;

    this.updateTrackInfo(logicalTrackId, trackInfo);

    this.assignUnassignedTracks();
  }

  public removeLogicalTrack(logicalTrackId: string) {
    const logicalTrack = this.logicalTracks[logicalTrackId];
    if (!logicalTrack) return;

    this.unassignedTracks.push(...logicalTrack.getAllTrackAndTransportInfo());
    delete this.trackInfoByLogicalTrackId[logicalTrackId];
    delete this.logicalTracks[logicalTrackId];
    this.delegate.onTrackChanged(logicalTrackId, undefined);
  }

  public setPreferredTransport(transport: Transport) {
    each(this.logicalTracks, (logicalTrack) => logicalTrack.switchTransport(transport));
  }

  private updateTrackInfo(logicalTrackId: string, trackInfo: Partial<TrackInfo>) {
    const prevTrackInfo = this.trackInfoByLogicalTrackId[logicalTrackId];
    this.trackInfoByLogicalTrackId[logicalTrackId] = {
      ...prevTrackInfo,
      ...trackInfo,
    };

    if (!isEqual(this.trackInfoByLogicalTrackId[logicalTrackId], prevTrackInfo)) {
      this.delegate.onTrackChanged(logicalTrackId, this.trackInfoByLogicalTrackId[logicalTrackId]);
    }
  }

  public assignTrackOnTransport({
    track,
    transport,
    trackTransportId,
  }: {
    track: MediaStreamTrack;
    transport: Transport;
    trackTransportId: string;
  }) {
    const logicalTrackId = findKey(
      this.trackInfoByLogicalTrackId,
      (trackInfo) => trackInfo.trackTransportIds?.[transport] == trackTransportId,
    );

    if (!logicalTrackId) {
      this.unassignedTracks.push({ track, transport, trackTransportId });
      return;
    }

    this.logicalTracks[logicalTrackId].storeTrack({ transport, track, trackTransportId });
  }

  private assignUnassignedTracks() {
    const unassignedTracks = this.unassignedTracks;
    this.unassignedTracks = [];
    unassignedTracks.forEach((unassignedTrackInfo) => this.assignTrackOnTransport(unassignedTrackInfo));
  }

  public clearTransport(transport: 'sfu' | 'p2p') {
    forEach(this.logicalTracks, (logicalTrack) => logicalTrack.clearTransport(transport));
  }

  public removeTrack(localTrackId: string) {
    each(this.logicalTracks, (logicalTrack) => {
      if (logicalTrack.isHoldingLocalTrackId(localTrackId)) {
        logicalTrack.removeTrack(localTrackId);
        // Early return
        return false;
      }
    });
  }

  public destroy() {
    Object.keys(this.logicalTracks).forEach((logicalTrackId) => this.removeLogicalTrack(logicalTrackId));
  }
}
