import { ComponentProps } from 'react';
import styled from 'styled-components';

import { AutoImportedIconComponentType } from '../icon-component';

export const Spinner = styled(IconSpinner)`
  animation: 1s linear 0s infinite normal none running spinner-rotate;
  animation-duration: 750ms;

  @keyframes spinner-rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
