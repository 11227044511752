import { RefObject, useEffect } from 'react';

import { useCurrentWindow } from './use-current-window';

type HTMLRef = RefObject<HTMLElement | undefined>;

/**
 * Hook to simplify the process of listening for a click outside a certain
 * element. This is useful for modals, for example, when clicking on the curtain
 * should trigger something like closing the modal.
 */
export function useDocumentClickOutsideElementRef({
  handler,
  refs,
  isListenerActive = true,
}: {
  handler: (event: Event) => void;
  refs: HTMLRef | HTMLRef[];
  isListenerActive?: boolean;
}) {
  const win = useCurrentWindow();

  useEffect(() => {
    const onDocumentClick = (event: Event) => {
      if ([refs].flat().every((ref) => ref.current && !ref.current.contains(event.target! as Node))) {
        handler(event);
      }
    };

    if (isListenerActive) win.document.addEventListener('click', onDocumentClick, true);
    return () => win.document.removeEventListener('click', onDocumentClick, true);
  }, [refs, handler, win, isListenerActive]);
}
