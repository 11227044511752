import { ComponentProps, forwardRef, memo, Suspense } from 'react';

import { UserId } from 'common/models/db/vo.interface';
import { AnyPeerId } from 'common/models/db/vo.interface';
import { SpeechBubble } from 'pages/vo/vo-react/components/speech-bubble';
import { TranscriptContent } from 'pages/vo/vo-react/components/ui-kit/calendar/transcript';
import { BodyText } from 'pages/vo/vo-react/components/ui-kit/text';
import { getMostRecentTranscriptForPeerId } from 'pages/vo/vo-react/features/session-history/session-history.slice';
import {
  getPresenceAvatarUrlById,
  getUserAvatarUrlById,
  getUserDisplayNameById,
} from 'pages/vo/vo-react/features/users/users.slice';
import { displayNameToInitials } from 'utils/initials';
import { getUserColorForId, popColor } from 'utils/react/colors';
import { useCurrentSessionId } from 'utils/react/current-session-id-context';
import { useCurrentWindow } from 'utils/react/use-current-window';
import { useLatestTranscriptForPeer } from 'utils/react/use-latest-transcript-for-peer';
import { useListenToUsers } from 'utils/react/use-listen-to-users';
import { useSelector } from 'utils/react/use-selector';

import { SessionHistoryPeer } from '../../features/session-history/session-history.types';
import {
  getCurrentSessionHistoryIdForSession,
  getSessionIdForPeerId,
  getPeerAvatarUrlById,
  getPeerDisplayNameById,
} from '../../features/sessions/sessions.slice';

import { BasePopUiComponent, BasePopUiCssProps } from './base-pop-ui-component';
import { FlexBox, FlexBoxProps } from './flex-box';
import { Spinner } from './spinner';

export const BaseImageAvatar = ({ url, ...rest }: { url: string } & BasePopUiCssProps) => (
  <BasePopUiComponent as="img" draggable={false} src={url} {...rest} />
);

export const BaseInitialsAvatar = ({ initials, ...etc }: { initials: string } & FlexBoxProps) => (
  <FlexBox
    justifyContent="center"
    alignItems="center"
    color={popColor('background')}
    userSelect="none"
    {...etc}
  >
    <svg viewBox="0 0 24 24" width="100%">
      <text x="12" y="18" fill="currentColor" textAnchor="middle" fontWeight="bold">
        {initials}
      </text>
    </svg>
  </FlexBox>
);

export const PeerAvatarWithDecoration = memo(
  forwardRef(
    (
      {
        maskSize,
        maskWidth,
        children,
        shouldShowTranscript,
        ...etc
      }: {
        maskSize?: number;
        maskWidth?: number;
        shouldShowTranscript?: boolean;
      } & ComponentProps<typeof DangerousPeerAvatar>,
      forwardedRef,
    ) => (
      <BasePopUiComponent position="relative" ref={forwardedRef}>
        <DangerousPeerAvatar
          {...(maskSize &&
            maskWidth && {
              mask: `
          radial-gradient(
            circle ${maskSize - maskWidth}px at
            calc(100% - ${maskSize / 2}px)
            calc(100% - ${maskSize / 2}px),
            transparent 100%,
            #fff 100%)`,
            })}
          boxSizing="content-box"
          aspectRatio="1"
          overflow="hidden"
          border="0"
          {...etc}
        />
        {shouldShowTranscript && <SuspensefulPeerTranscript peerId={etc.peerId} />}

        {children && (
          <FlexBox inline position="absolute" right="0" bottom="0">
            {children}
          </FlexBox>
        )}
      </BasePopUiComponent>
    ),
  ),
);

const SuspensefulPeerTranscript = memo(({ peerId }: { peerId: AnyPeerId }) => (
  <Suspense fallback={<></>}>
    <PeerTranscript peerId={peerId} />
  </Suspense>
));

const PeerTranscript = ({ peerId }: { peerId: AnyPeerId }) => {
  return null;
  const sessionId = useSelector(getSessionIdForPeerId, peerId);
  const sessionHistoryId = useSelector(getCurrentSessionHistoryIdForSession, sessionId);
  const transcript = useSelector(getMostRecentTranscriptForPeerId, { peerId, sessionHistoryId });
  const isWindowFocused = useCurrentWindow().document.hasFocus();
  const { shouldShow } = useLatestTranscriptForPeer({
    peerId,
    isHovered: isWindowFocused,
  });

  if (!shouldShow || !transcript) return null;
  return (
    <SpeechBubble
      transition="opacity 80ms ease, filter 80ms ease, height 80ms ease"
      opacity={1}
      background={popColor('permanentWhite')}
      color={popColor('permanentBlack')}
      border={`1px solid ${popColor('permanentBlack', 0.4)}`}
      height="32px"
      padding="0 12px"
      maxWidth="200px"
      position="absolute"
      right="calc(100% - 15px)"
      bottom="calc(100% - 10px)"
      alignItems="center"
    >
      <TranscriptContent
        transcript={transcript}
        innerProps={{
          pointerEvents: 'none',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textDirection: 'rtl',
        }}
        isSmall
      />
    </SpeechBubble>
  );
};

export const UserAvatar = memo(
  ({
    userId,
    shouldUsePresenceAvatar = false,
    ...etc
  }: { userId: UserId; shouldUsePresenceAvatar?: boolean } & Omit<
    ComponentProps<typeof BaseImageAvatar>,
    'url'
  >) => {
    useListenToUsers([userId]);
    return (
      <Suspense fallback={<></>}>
        <SuspensefulUserAvatar userId={userId} shouldUsePresenceAvatar={shouldUsePresenceAvatar} {...etc} />
      </Suspense>
    );
  },
);
export const SuspensefulUserAvatar = memo(
  ({
    userId,
    shouldUsePresenceAvatar = false,
    ...etc
  }: { userId: UserId; shouldUsePresenceAvatar?: boolean } & Omit<
    ComponentProps<typeof BaseImageAvatar>,
    'url'
  >) => {
    const displayName = useSelector(getUserDisplayNameById, userId, { suspense: true });
    const avatarUrl = useSelector(getUserAvatarUrlById, userId);
    const presenceAvatarUrl = useSelector(getPresenceAvatarUrlById, userId);

    return (
      <Avatar
        avatarUrl={(shouldUsePresenceAvatar && presenceAvatarUrl) || avatarUrl}
        displayName={displayName}
        hashableId={userId}
        {...etc}
      />
    );
  },
);

export const PeerAvatar = memo(
  ({ peerId, ...etc }: { peerId: AnyPeerId } & Omit<ComponentProps<typeof BaseImageAvatar>, 'url'>) => (
    <Suspense fallback={<Spinner />}>
      <DangerousPeerAvatar peerId={peerId} {...etc} />
    </Suspense>
  ),
);

export const DangerousPeerAvatar = memo(
  ({
    peerId,
    displayName: passedDisplayName,
    avatarUrl: passedAvatarUrl,
    ...etc
  }: { peerId: AnyPeerId; displayName?: string; avatarUrl?: string } & Omit<
    ComponentProps<typeof BaseImageAvatar>,
    'url'
  >) => {
    const displayName = useSelector(getPeerDisplayNameById, { peerId, sessionId: useCurrentSessionId() });
    const avatarUrl = useSelector(
      getPeerAvatarUrlById,
      { peerId, sessionId: useCurrentSessionId() },
      { suspense: !displayName && !passedDisplayName && !passedAvatarUrl },
    );

    return (
      <Avatar
        avatarUrl={passedAvatarUrl ?? avatarUrl}
        displayName={passedDisplayName ?? displayName!}
        hashableId={peerId}
        {...etc}
      />
    );
  },
);

export const SessionHistoryPeerAvatar = ({
  peer,
  ...etc
}: {
  peer: SessionHistoryPeer;
} & FlexBoxProps) => (
  <DangerousPeerAvatar
    peerId={peer.peerId}
    displayName={peer.displayName}
    avatarUrl={peer.avatarUrl}
    {...etc}
  />
);

export const Avatar = ({
  hashableId,
  avatarUrl,
  displayName,
  ...etc
}: { hashableId: string; avatarUrl?: string; displayName: string } & Omit<
  ComponentProps<typeof BaseImageAvatar>,
  'url'
>) =>
  avatarUrl ? (
    <BaseImageAvatar url={avatarUrl} {...etc} />
  ) : (
    <BaseInitialsAvatar
      initials={displayNameToInitials(displayName)}
      background={getUserColorForId(hashableId)}
      {...etc}
    />
  );

export const CursorAvatar = ({
  peerId,
  shouldOnlyShowAvatar,
  ...etc
}: { peerId: AnyPeerId; shouldOnlyShowAvatar?: boolean } & FlexBoxProps) => {
  const displayName = useSelector(getPeerDisplayNameById, { peerId, sessionId: useCurrentSessionId() });
  const color = getUserColorForId(peerId);
  const peerAvatar = (
    <PeerAvatar peerId={peerId} width="18px" height="18px" borderRadius="10px" overflow="hidden" />
  );

  return shouldOnlyShowAvatar ? (
    <FlexBox {...etc}>{peerAvatar}</FlexBox>
  ) : (
    <FlexBox
      borderRadius="4px 12px 12px 12px"
      padding="4px 12px 4px 8px"
      gap="4px"
      background={color}
      alignItems="center"
      {...etc}
    >
      {peerAvatar}
      <BodyText bold color={popColor('background')} whiteSpace="nowrap">
        {displayName}
      </BodyText>
    </FlexBox>
  );
};
