export const STORE_RESET_ACTION = 'STORE_RESET';

export default (createStore: any) => (rootReducer: any, initialState: any, ...createStoreArgs: any[]) =>
  createStore(
    (state: any, action: any, ...reducerArgs: any[]) => {
      if (action.type === STORE_RESET_ACTION) {
        return rootReducer(action.payload || initialState, action);
      }
      return rootReducer(state, action, ...reducerArgs);
    },
    initialState,
    ...createStoreArgs,
  );
