import { DeviceType as NgxsDeviceType } from 'common/models/db/user.interface';

interface BaseDevice {
  uniqueDeviceId: UniqueDeviceId;
  originalDeviceId: string;
  label?: string;
}

export type OutputDeviceType = 'speakers';

export type InputDeviceType = 'microphone' | 'camera';

export const allInputDeviceTypes = ['microphone', 'camera'] as const;
export const deviceToNgxsDeviceTypeMap: { [deviceType in DeviceType]: NgxsDeviceType } = {
  microphone: 'mic',
  camera: 'camera',
  speakers: 'speakers',
};
export const allDeviceTypes = [...allInputDeviceTypes, 'speakers'] as const;
export const isDeviceType = (type: string): type is DeviceType => allDeviceTypes.includes(type as any);

export interface OutputDevice<T extends OutputDeviceType = OutputDeviceType> extends BaseDevice {
  type: T;
  uniqueDeviceId: UniqueDeviceId<T>;
}

export interface InputDevice<T extends InputDeviceType = InputDeviceType> extends BaseDevice {
  type: T;
  uniqueDeviceId: UniqueDeviceId<T>;
  track?: Promise<MediaStreamTrack | undefined>;
  isTrackWithoutMedia?: boolean;
  didTriggerGetUserMediaError?: boolean;
}

export type Device = OutputDevice | InputDevice;

export type DeviceMap = {
  [k in InputDeviceType]: InputDevice<k>;
} & {
  [k in OutputDeviceType]: OutputDevice<k>;
};

export type DeviceType = OutputDeviceType | InputDeviceType;
export const ALL_DEVICE_TYPES = ['microphone' as const, 'camera' as const, 'speakers' as const] as const;

export type UniqueDeviceId<T extends DeviceType = DeviceType> = `${string}-${T}`;

export const deviceErrorsToHumanFriendly = {
  permissionRequired: 'Permission required.',
  emptyCameraTrack: 'Empty camera stream detected.',
  emptyMicrophoneTrack: 'Inaudible input detected.',
  accessError: 'Access error.',
};

export type InputDeviceError = keyof typeof deviceErrorsToHumanFriendly;

export const deviceToNoneDeviceMap: { [deviceType in DeviceType]: Device } = {
  microphone: {
    type: 'microphone',
    uniqueDeviceId: 'none-microphone',
    originalDeviceId: '',
    label: 'No microphone selected',
  },
  camera: {
    type: 'camera',
    uniqueDeviceId: 'none-camera',
    originalDeviceId: '',
    label: 'No camera selected',
  },
  speakers: {
    type: 'speakers',
    uniqueDeviceId: 'none-speakers',
    originalDeviceId: '',
    label: 'No speakers selected',
  },
};
