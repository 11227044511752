import { flushSync } from 'react-dom';

let activeViewTransitionCallback: (() => void) | null = null;
let isMidTransition = false;

/**
 * There can only be one View Transitions transition happening at a time, so
 * this helper allows us to tack on during the same event loop multiple
 * transition callbacks to be run simultaneously when the browser is ready to
 * run the transition callback.
 */
export const startViewTransition = (fn: () => void, win = window as Window) => {
  if (isMidTransition) return fn();

  if (activeViewTransitionCallback) {
    const oldFn = activeViewTransitionCallback;
    activeViewTransitionCallback = () => {
      oldFn();
      fn();
    };
  } else {
    activeViewTransitionCallback = fn;
    win.document.startViewTransition(() => {
      flushSync(() => {
        isMidTransition = true;
        activeViewTransitionCallback?.();
        activeViewTransitionCallback = null;
        isMidTransition = false;
      });
    });
  }
};

export const getIsMidViewTransition = () => isMidTransition;
