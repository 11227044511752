import { isFunction, noop } from 'lodash';
import { useEffect } from 'react';
import { filter, fromEvent, merge } from 'rxjs';
import { distinctUntilKeyChanged, map } from 'rxjs/operators';

import { isDesktopApp, isMac } from 'utils/client-utils';
import { useAreKeyListenersSuspended } from 'utils/react/use-suspend-key-listeners';

import { useCurrentWindow } from './use-current-window';

/**
 * This hook is Electron window-aware.
 *
 * Also, doesRequireCmdOrCtrl resolves to Cmd on the Mac desktop app, and Ctrl everywhere else.
 */
export function useKeyPressEvent({
  filterKey,
  doesRequireCmdOrCtrl,
  doesRequireShift,
  doesRequireAlt,
  onKeyDown = noop,
  onKeyUp = noop,
  shouldOverrideSuspension = false,
}: {
  filterKey: string | ((key: string) => boolean);
  doesRequireCmdOrCtrl?: boolean;
  doesRequireAlt?: boolean;
  doesRequireShift?: boolean;
  onKeyDown?: (key: string) => void;
  onKeyUp?: (key: string) => void;
  shouldOverrideSuspension?: boolean;
}) {
  const win = useCurrentWindow();
  const areKeyListenersSuspended = useAreKeyListenersSuspended();
  useEffect(() => {
    if (areKeyListenersSuspended && !shouldOverrideSuspension) return;
    const subscription = merge(
      fromEvent(win.document.body, 'keydown').pipe(
        map(({ key, altKey, ctrlKey, shiftKey, metaKey }: KeyboardEvent) => ({
          key,
          altKey,
          ctrlKey,
          shiftKey,
          metaKey,
          isDown: true,
        })),
      ),
      fromEvent(win.document.body, 'keyup').pipe(
        map(({ key, altKey, ctrlKey, shiftKey, metaKey }: KeyboardEvent) => ({
          key,
          altKey,
          ctrlKey,
          shiftKey,
          metaKey,
          isDown: false,
        })),
      ),
    )
      .pipe(
        filter(
          ({ key, altKey, ctrlKey, shiftKey, metaKey }) =>
            (isFunction(filterKey) ? filterKey(key) : key === filterKey) &&
            (!doesRequireShift || shiftKey) &&
            (!doesRequireAlt || altKey) &&
            (!doesRequireCmdOrCtrl ||
              (isMac && isDesktopApp && metaKey) ||
              (!(isMac && isDesktopApp) && ctrlKey)),
        ),
        distinctUntilKeyChanged('isDown'),
      )
      .subscribe(({ key, isDown }) => (isDown ? onKeyDown(key) : onKeyUp(key)));
    return () => subscription.unsubscribe();
  }, [
    areKeyListenersSuspended,
    shouldOverrideSuspension,
    filterKey,
    onKeyDown,
    onKeyUp,
    win.document.body,
    win,
    doesRequireCmdOrCtrl,
    doesRequireShift,
    doesRequireAlt,
  ]);
}
