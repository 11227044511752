import { uniqueId } from 'lodash';
import { createContext, useMemo } from 'react';

import type { DesktopWindowInstance } from 'pages/vo/vo-react/desktop-window';

// Allow access to the current window where this React tree is rendering.
export const CurrentWindowContext = createContext({ win: window as Window, id: 'default' });

export const CurrentWindowContextProvider = ({ win, children }: { win: Window; children: any }) => (
  <CurrentWindowContext.Provider value={useCreateWindowContext(win)}>
    {children}
  </CurrentWindowContext.Provider>
);

export const useCreateWindowContext = (win: Window) => ({ win, id: useMemo(() => uniqueId(), [win]) });

export const DesktopWindowContext = createContext<DesktopWindowInstance | undefined>(undefined);
