import { IncomingCallId } from '../../../models/db/incoming-call.interface';
import { JoinableSessionInitiator } from '../../../models/db/session-initiatior.interface';

import { SessionId } from '../vo.interface';

import { DbMessage } from './message-bus.interface';

export type RingState = 'ringing' | 'declined' | 'accepted' | 'unauthorized';

export interface DbRingMessage extends DbMessage {
  type: 'ring';
  sessionId: SessionId;
  joinableSessionInitiator: JoinableSessionInitiator;
  state: RingState;
  v1RingIncomingCallId?: IncomingCallId;
  initiatorVersion?: 'vo' | 'v1';
}
export const isDbMessageRing = (dbMessage: DbMessage): dbMessage is DbRingMessage =>
  dbMessage?.type === 'ring';
