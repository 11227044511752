import { MediaType } from 'common/models/connection.interface';
import { OrgId, RemotePeerId, SessionId, UserId } from 'common/models/db/vo.interface';
import { AnyPeerId } from 'common/models/db/vo.interface';
import { Direction, P2pEventType, ProvisionalTrackInfo, TrackInfo } from 'common/models/floof.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { SessionConnectionManager } from 'utils/floof-sdk/session-connection-manager';

export interface FloofConnection {
  connect: (opts: {
    shouldEnableMic: boolean;
    shouldEnableCamera: boolean;
    displayName: string;
    avatarUrl?: string;
    userId: UserId;
    orgId: OrgId;
    isKnocking?: boolean;
  }) => void;
  disconnect: () => void;
  setSelfMediaTrack: (info: {
    mediaType: MediaType;
    track: MediaStreamTrack | Promise<MediaStreamTrack>;
    deviceId: string;
    isEnabled: boolean;
  }) => string;
  setSelfMediaTrackEnabled: (logicalTrackId: string, isEnabled: boolean) => void;
  removeSelfMediaTrack: (logicalTrackId: string) => void;
  setShouldShowRemoteHdVideo: (opts: { [peerId: string]: boolean; default: boolean }) => void;
  sendPeerMessage: (peerMessage: PeerMessage) => Promise<void>;
  getSelfPeerId: () => RemotePeerId;
}

export interface FloofConnectionDelegate {
  onPeerJoined?: (peerId: AnyPeerId) => void;
  onPeerLeft?: (peerId: AnyPeerId) => void;
  onTrack?: (peerId: AnyPeerId, logicalTrackId: string, track: TrackInfo) => void;
  onProvisionalTrack?: (peerId: AnyPeerId, logicalTrackId: string, track: ProvisionalTrackInfo) => void;
  onTrackRemoved?: (peerId: AnyPeerId, logicalTrackId: string) => void;
  onMessageReceived?: (peerId: RemotePeerId, peerMessage: PeerMessage) => void;
  onActiveSpeakerPeerIdChanged?: (peerId: AnyPeerId) => void;
  onReconnectionNeeded?: () => void;
}

export interface FloofAnalyticsDelegate {
  onLostDataChannel?: (signalingState: RTCSignalingState) => void;
  onP2pPeerConnectionEvent?: (
    peerId: RemotePeerId,
    eventType: P2pEventType,
    direction: Direction,
    connectionState: RTCPeerConnectionState,
    iceConnectionState: RTCIceConnectionState,
    signalingState: RTCSignalingState,
  ) => void;
  // TODO: add more analytics events
}

const connections: {
  [sessionId: string]: FloofConnection;
} = {};
(window as any).floofConnections = connections;

export const getFloofConnection = (sessionId: SessionId) => connections[sessionId];
export const deprecatedGetActiveFloofConnection = () => Object.values(connections)[0];

export const createFloofConnection = (
  sessionId: SessionId,
  delegate: FloofConnectionDelegate,
  analyticsDelegate?: FloofAnalyticsDelegate,
) => (connections[sessionId] = new SessionConnectionManager(sessionId, delegate, analyticsDelegate));
export const deleteFloofConnection = (sessionId: SessionId) => {
  connections[sessionId]?.disconnect();
  delete connections[sessionId];
};
