import { head } from 'lodash';
import { catchError, EMPTY, exhaustMap, ignoreElements, map, merge, mergeMap, of, take, tap } from 'rxjs';

import { InstantMeetingSessionInitiator } from 'common/models/db/session-initiatior.interface';
import { filterIsTruthy, ofAction, pluck } from 'common/utils/custom-rx-operators';
import { apiCall, apiResponseAsObservable } from 'pages/vo/vo-react/api';
import { getSelfUserId } from 'pages/vo/vo-react/features/auth/auth.slice';
import { generateUrlForPageIdWithParams } from 'pages/vo/vo-react/features/navigation/navigation.utils';
import { userRangUser } from 'pages/vo/vo-react/features/rings/rings.slice';
import { pushLocation } from 'pages/vo/vo-react/features/router/router.slice';

import { EpicWithDeps } from '../../redux/app-store';
import { getSessionIdsForSessionInitiator } from '../common/session-selectors';
import { joinKnownSession } from '../sessions/sessions.slice';

import { createAndJoinInstantMeeting, removeInstantMeeting } from './instant-meetings.slice';

export const createInstantMeetingEpic: EpicWithDeps = (action$, state$, { ngxsStore }) =>
  action$.pipe(
    ofAction(createAndJoinInstantMeeting),
    exhaustMap(
      ({
        payload: {
          instantMeeting: { orgId, name, userIds },
          mediaServerType,
        },
      }) =>
        apiResponseAsObservable(
          apiCall('createInstantMeeting', {
            name,
            orgIds: [orgId],
            userIds,
            mediaServerType: mediaServerType ?? 'floof',
          }),
        )
          .pipe(
            catchError((error: unknown) => {
              console.error(error);
              return EMPTY;
            }),
          )
          .pipe(
            tap(({ instantMeetingId }) => {
              if (!instantMeetingId) throw new Error('Unexpectedly falsey instantMeetingId');
            }),
            pluck('instantMeetingId'),
            map((instantMeetingId) => instantMeetingId!),
            mergeMap((instantMeetingId) =>
              merge(
                state$
                  .pipe(
                    map((state) =>
                      getSessionIdsForSessionInitiator(state, {
                        type: 'instant-meeting',
                        id: instantMeetingId!,
                      }),
                    ),
                    map((sessionIds) => head(sessionIds)!),
                    filterIsTruthy(),
                    map((sessionId) => ({
                      sessionId,
                      joinableSessionInitiator: {
                        type: 'instant-meeting',
                        id: instantMeetingId!,
                      } as InstantMeetingSessionInitiator,
                    })),
                    take(1),
                  )
                  .pipe(
                    tap(({ sessionId }) => {
                      if (!sessionId) throw new Error('Unexpectedly falsey sessionId');
                    }),
                    mergeMap(({ sessionId, joinableSessionInitiator }) =>
                      merge(
                        of(joinKnownSession({ sessionId, joinableSessionInitiator })),
                        of(
                          pushLocation(
                            generateUrlForPageIdWithParams('org-page', {
                              orgId,
                              instantMeetingId: joinableSessionInitiator.id,
                            }),
                          ),
                        ),
                        ...userIds.map((receiverUserId) =>
                          of(
                            userRangUser({
                              orgId,
                              sessionId,
                              joinableSessionInitiator,
                              receiverUserId,
                              senderUserId: getSelfUserId(state$.value)!,
                            }),
                          ),
                        ),
                      ),
                    ),
                  ),
              ),
            ),
          ),
    ),
  );

export const removeInstantMeetingEpic: EpicWithDeps = (action$, state$, { ngxsStore, injector }) =>
  action$.pipe(
    ofAction(removeInstantMeeting),
    exhaustMap(({ payload: { id, orgId, sessionId } }) =>
      apiResponseAsObservable(
        apiCall('removeInstantMeeting', {
          instantMeetingId: id,
          orgId,
          sessionId,
        }),
      ).pipe(
        catchError((error: unknown) => {
          console.error(error);
          return EMPTY;
        }),
        ignoreElements(),
      ),
    ),
  );
