import { fromEvent, merge, of } from 'rxjs';
import { map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators/tag';

import { distinctUntilChangedDeep, ofAction } from 'common/utils/custom-rx-operators';
import { leaveSession } from 'pages/vo/vo-react/features/sessions/sessions.slice';

import { EpicWithDeps } from '../../redux/app-store';
import { getAllBrowserPermissions } from '../permissions/permissions.slice';

import {
  clobberMediaDevices,
  getIsCameraEnabled,
  getIsMicEnabled,
  osUpdatedMediaDevices,
  setCameraIsEnabled,
  setMicrophoneIsEnabled,
} from './devices.slice';
import { convertOsMediaDeviceListToPopDevices } from './devices.utils';

export const osMediaDeviceUpdateEpic = () =>
  fromEvent(navigator.mediaDevices, 'devicechange').pipe(mapTo(osUpdatedMediaDevices()));

export const enumerateDevicesEpic: EpicWithDeps = (action$, state$) =>
  merge(
    action$.pipe(ofAction(osUpdatedMediaDevices)),
    state$.pipe(
      map((state) => getAllBrowserPermissions(state)),
      distinctUntilChangedDeep(),
    ),
  ).pipe(
    tag('devices/enumerateDevices'),
    switchMap(() => navigator.mediaDevices.enumerateDevices()),
    map((devices) => clobberMediaDevices(convertOsMediaDeviceListToPopDevices(devices))),
  );

export const disableAllMediaOnLeaveMeetingEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofAction(leaveSession),
    mergeMap(() =>
      merge(
        ...[
          ...(!getIsCameraEnabled(state$.value) ? [] : [of(setCameraIsEnabled(false))]),
          ...(!getIsMicEnabled(state$.value) ? [] : [of(setMicrophoneIsEnabled(false))]),
        ],
      ),
    ),
  );
