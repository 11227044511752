import { useFormState } from 'react-hook-form';

import { popColor } from 'utils/react/colors';

import { SmallText } from './text';

export const ErrorMessage = ({ register: registerName }: { register: string }) => {
  const { errors } = useFormState({ name: registerName });
  return errors[registerName]?.message ? (
    <SmallText bold color={popColor('tertiary1')} role="alert">
      {errors[registerName]?.message as string}
    </SmallText>
  ) : null;
};
