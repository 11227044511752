import { NEVER, merge, from, map, mergeMap, of, fromEvent, timer } from 'rxjs';

import { ofAction, simpleRefCount } from 'common/utils/custom-rx-operators';
import { isElectron } from 'utils/react/user-agent';

import { EpicWithDeps } from '../../redux/app-store';

import {
  listenToSystemPermissions,
  checkSystemPermission,
  setBrowserPermission,
  stopListeningToSystemPermissions,
} from './permissions.slice';

export const setBrowserPermissionEpic: EpicWithDeps = () => {
  if (isElectron) return NEVER;
  return merge(
    ...(['microphone', 'camera'] as const).map((name) =>
      from(navigator.permissions.query({ name: name as any })).pipe(
        map((permissionStatus) => ({ name, permissionStatus })),
      ),
    ),
  ).pipe(
    mergeMap(({ name, permissionStatus }) =>
      merge(
        of(
          setBrowserPermission({
            name,
            isPermitted: permissionStatus.state === 'granted',
          }),
        ),
        fromEvent(permissionStatus, 'change').pipe(
          map(() =>
            setBrowserPermission({
              name,
              isPermitted: permissionStatus.state === 'granted',
            }),
          ),
        ),
      ),
    ),
  );
};

export const listenToSystemPermissionsEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofAction(listenToSystemPermissions, stopListeningToSystemPermissions),
    // Transform
    //   { type: 'listenToSystemPermissions', payload: ['screen', 'microphone']}
    // into
    //   [
    //     { shouldIncrement: true, payload: 'screen' },
    //     { shouldIncrement: true, payload: 'microphone' }
    //   ]
    // so the permissions can be individually ref counted.
    mergeMap(({ type, payload: permissionKeys }) =>
      from(permissionKeys).pipe(
        map((permissionKey) => ({
          key: permissionKey,
          shouldIncrement: type === listenToSystemPermissions.type,
        })),
      ),
    ),
    simpleRefCount(
      {
        increment: ({ shouldIncrement }) => shouldIncrement,
        decrement: ({ shouldIncrement }) => !shouldIncrement,
        groupBy: ({ key }) => key,
      },
      ({ key }) => timer(0, 500).pipe(map(() => checkSystemPermission(key))),
    ),
  );

export const checkSystemPermissionsOnBootEpic: EpicWithDeps = () =>
  from(['microphone', 'camera', 'screen', 'accessibility'] as const).pipe(
    map((name) => checkSystemPermission(name)),
  );
