import { createSlice } from '../../redux/create-slice';

export type ToastsSlice = {
  toast?: string;
};

const initialState: ToastsSlice = {};
const { createReducer, createSelector, createAction } = createSlice('toasts', initialState);

export const addToast = createAction<{ message: string; durationMs?: number }>('addToast');
export const removeToast = createAction('removeToast');

export default createReducer()
  .on(addToast, (state, { payload: { message } }) => {
    state.toast = message;
  })
  .on(removeToast, (state) => {
    delete state.toast;
  });

export const getToast = createSelector((state) => state.toasts.toast);
