import { useContext } from 'react';

import { CurrentWindowContext, DesktopWindowContext } from './current-window-context';

export const useCurrentWindowId = () => useContext(CurrentWindowContext).id;
export const useDomWindow = () => useContext(CurrentWindowContext).win;
export const useDesktopWindow = () => useContext(DesktopWindowContext);
export const useCurrentWindow = useDomWindow;
export const useCurrentWindowSize = () => {
  const win = useCurrentWindow();
  return {
    width: win.innerWidth,
    height: win.innerHeight,
  };
};
