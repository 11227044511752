import { mapValues, pick } from 'lodash';
import { map, combineLatest, distinctUntilChanged, tap } from 'rxjs';

import { allFeatures, isFeatureEnabledForEmail } from 'common/models/db/feature-flags.interface';
import {
  distinctUntilChangedDeep,
  filterIsTruthy,
  ignoreElements,
  ofAction,
} from 'common/utils/custom-rx-operators';
import { db } from 'utils/firebase-db-wrapper-client';
import { storage } from 'utils/storage';

import { SharedEpic } from '../../redux/shared-store';
import { getSelfEmail } from '../auth/auth.slice';

import { getAllLocalOverrides, setAllFeatureFlags, setFeatureFlagOverride } from './feature-flags.slice';

export const setFeatureFlagsEpic: SharedEpic = (action$, state$) =>
  combineLatest([
    db
      .from('featureFlags')
      .whenChanged()
      .pipe(map(({ val }) => pick(val, allFeatures))),
    state$.pipe(
      map((state) => getSelfEmail(state)),
      distinctUntilChanged(),
      filterIsTruthy(),
    ),
  ]).pipe(
    map(([dbFeatureFlags, email]) =>
      mapValues(
        dbFeatureFlags,
        (dbFeatureFlag) => dbFeatureFlag && isFeatureEnabledForEmail(email, dbFeatureFlag),
      ),
    ),
    distinctUntilChangedDeep(),
    map(setAllFeatureFlags),
  );

export const storeLocalOverrideFeatureFlagsEpic: SharedEpic = (action$, state$) =>
  action$.pipe(
    ofAction(setFeatureFlagOverride),
    tap(() => {
      const overrides = getAllLocalOverrides(state$.value);
      storage.set('override-feature-flags', JSON.stringify(overrides));
    }),
    ignoreElements({ silenceDevEpicWarning: true }),
  );
