import { Emoji, init } from 'emoji-mart';
import { find, sample, trim } from 'lodash';
import { forwardRef, useEffect, useState } from 'react';

import { useCurrentWindow } from 'utils/react/use-current-window';

import { BasePopUiComponent, BaseProps } from './base-pop-ui-component';
import { mergeRefs } from 'utils/react-utils';

let randomEmojis: string[] = [];
export const getRandomEmoji = async () => {
  if (!randomEmojis.length) {
    // @ts-ignore
    const { default: data } = await import('@emoji-mart/data');
    randomEmojis = [
      ...find(data.categories, { id: 'people' }).emojis,
      ...find(data.categories, { id: 'nature' }).emojis,
      ...find(data.categories, { id: 'foods' }).emojis,
      ...find(data.categories, { id: 'activity' }).emojis,
      ...find(data.categories, { id: 'places' }).emojis,
      ...find(data.categories, { id: 'objects' }).emojis,
    ];
  }
  return sample(randomEmojis);
};

export const useIsEmojiDataLoaded = () => {
  const win = useCurrentWindow();
  if (typeof win.customElements !== 'undefined' && !win.customElements.get('em-emoji')) {
    win.customElements.define('em-emoji', Emoji);
  }

  const [isEmojiDataLoaded, setIsEmojiDataLoaded] = useState(false);

  useEffect(() => {
    let cancelled = false;
    // @ts-ignore
    void import('@emoji-mart/data').then(({ default: data }) => {
      if (cancelled) return;
      init({ data });
      setIsEmojiDataLoaded(true);
    });

    return () => {
      cancelled = true;
    };
  }, []);

  return isEmojiDataLoaded;
};

export const EmojiIcon = forwardRef(
  ({ emoji, size, ...etc }: { emoji: string; size: number } & BaseProps, forwardedRef) => {
    const win = useCurrentWindow();
    const isEmojiDataLoaded = useIsEmojiDataLoaded();
    const [emojiHolderRef, setEmojiHolderRef] = useState<HTMLElement | null>(null);

    // The emoji picker that we use right now recently switched to using web
    // components, which turn out to be a real pain in the butt with multiple
    // windows. You can't create a web component in one window and have it render
    // in the other, so our system doesn't really work. So, we handle DOM
    // manipulation manually until something better can be sorted.
    useEffect(() => {
      if (!isEmojiDataLoaded || !emojiHolderRef) return;
      const ele = new Emoji({ id: trim(emoji, ':'), size });
      (emojiHolderRef as any).replaceChildren(ele);
    }, [win, emojiHolderRef, isEmojiDataLoaded, emoji, size]);

    return (
      <BasePopUiComponent
        ref={mergeRefs(setEmojiHolderRef, forwardedRef)}
        display="inline-flex"
        fontSize={`${size}px`}
        // Avoid obvious relayout as emoji data is loaded.
        {...(!isEmojiDataLoaded && {
          width: `${size}px`,
        })}
        {...etc}
      ></BasePopUiComponent>
    );
  },
);
