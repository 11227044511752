import { find, head, last, some } from 'lodash';

import { OrgId, SessionGroupId, SessionId } from 'common/models/db/vo.interface';

import { createSlice } from '../../redux/create-slice';

import { SessionGroup } from './session-groups.types';

export type SessionGroupsSlice = {
  byId: {
    [sessionGroupId: string]: SessionGroup;
  };
};

const initialState: SessionGroupsSlice = {
  byId: {},
};
export const {
  createReducer,
  createSelector,
  createAction,
  createMemoizedSelector,
  createParametricMemoizedSelector,
  createThunk,
  createAsyncAction,
} = createSlice('sessionGroups', initialState);

export const sessionGroupCreatedOrUpdated = createAction<{
  sessionGroupId: SessionGroupId;
  sessionGroup: SessionGroup;
}>('sessionGroupCreatedOrUpdated');
export const sessionGroupDeleted = createAction<SessionGroupId>('sessionGroupDeleted');
export const listenToSessionGroup = createAction<{
  payload: {
    sessionGroupId: SessionGroupId;
    orgId: OrgId;
  };
  type: 'create' | 'destroy';
}>('listenToSessionGroup');

export default createReducer()
  .on(
    sessionGroupCreatedOrUpdated,
    (
      state,
      {
        payload: {
          sessionGroupId,
          sessionGroup: { sessionInitiator, items, mediaServerType, orgIds, temporarilyAllowedUserIds },
        },
      },
    ) => {
      const reorderedItemsWithLobbyAtHead = !some(items)
        ? []
        : [last(items)!, ...items.splice(0, items.length - 1)];
      state.byId[sessionGroupId] = {
        sessionInitiator,
        items: reorderedItemsWithLobbyAtHead,
        mediaServerType: mediaServerType ?? 'floof',
        orgIds,
        temporarilyAllowedUserIds,
      };
    },
  )
  .on(sessionGroupDeleted, (state, { payload: sessionGroupId }) => {
    delete state.byId[sessionGroupId];
  });

export const getSessionGroupById = createSelector(
  (state, sessionGroupId: SessionGroupId) => state.sessionGroups.byId[sessionGroupId],
);
export const getItemsForSessionGroupId = createSelector(
  (state, sessionGroupId: SessionGroupId) => state.sessionGroups.byId[sessionGroupId]?.items,
);
export const getHeadItemForSessionGroupId = createParametricMemoizedSelector(
  getItemsForSessionGroupId,
  (items) => head(items),
)((state, sessionGroupId: SessionGroupId) => sessionGroupId);
export const getItemForSessionInSessionGroupId = createSelector(
  (state, sessionGroupId: SessionGroupId, sessionId: SessionId) =>
    find(state.sessionGroups.byId[sessionGroupId]?.items, (item) => item.sessionId === sessionId),
);
export const getMediaServerTypeForSessionGroupId = createSelector((state, sessionGroupId?: SessionGroupId) =>
  !sessionGroupId ? undefined : state.sessionGroups.byId[sessionGroupId]?.mediaServerType,
);
export const getOrgIdsForSessionGroupId = createSelector(
  (state, sessionGroupId: SessionGroupId) => state.sessionGroups.byId[sessionGroupId]?.orgIds,
);
export const getSessionInitiatorForSessionGroupId = createSelector(
  (state, sessionGroupId: SessionGroupId) => state.sessionGroups.byId[sessionGroupId]?.sessionInitiator,
);
export const getTemporarilyAllowedUserIdsForSessionGroupId = createSelector(
  (state, sessionGroupId: SessionGroupId) =>
    state.sessionGroups.byId[sessionGroupId]?.temporarilyAllowedUserIds,
);

export const getSessionIdsForSessionGroupId = createParametricMemoizedSelector(
  getItemsForSessionGroupId,
  (sessions) => sessions?.map((session) => session.sessionId),
)((_state, sessionGroupId) => sessionGroupId);

export const getSessionTypesForSessionGroupId = createParametricMemoizedSelector(
  getItemsForSessionGroupId,
  (sessions) => sessions?.map((session) => session.sessionType),
)((_state, sessionGroupId) => sessionGroupId);

export const getSessionTypeForSessionGroupIdAndSessionId = createParametricMemoizedSelector(
  getItemsForSessionGroupId,
  (_state, sessionGroupId, sessionId: SessionId) => sessionId,
  (sessions, sessionId) => find(sessions, (session) => session.sessionId === sessionId)?.sessionType,
)((_state, sessionGroupId, sessionId) => `${sessionGroupId}-${sessionId}`);

export const getNameForSessionGroupIdAndSessionId = createParametricMemoizedSelector(
  getItemsForSessionGroupId,
  (_state, sessionGroupId, sessionId: SessionId) => sessionId,
  (sessions, sessionId) => find(sessions, (session) => session.sessionId === sessionId)?.name,
)((_state, sessionGroupId, sessionId) => `${sessionGroupId}-${sessionId}`);
