import {
  getAuth,
  User,
  onAuthStateChanged,
  updateProfile,
  updateEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  beforeAuthStateChanged,
} from 'firebase/auth';
import { NEVER, Observable, map, mergeMap, ignoreElements, switchMap, of, EMPTY, concatMap } from 'rxjs';

import { UserId } from 'common/models/db/vo.interface';
import { ofAction, ofActionPayload } from 'common/utils/custom-rx-operators';
import { isStorybook } from 'utils/client-utils';
import { getFirebaseApp } from 'utils/firebase-app';

import { apiCall } from '../../api';
import { SharedEpic } from '../../redux/shared-store';
import { generateUrlForPageIdWithParams } from '../navigation/navigation.utils';
import { pushLocation } from '../router/router.slice';

import {
  setSelfEmail,
  setSelfPassword,
  setSelfDisplayName,
  signedIn,
  signedOut,
  deleteAccount,
  loginBlocked,
} from './auth.slice';

export const blockUnverifiedEmailEpic: SharedEpic = () => {
  if (isStorybook) return NEVER;

  const auth = getAuth(getFirebaseApp());
  return new Observable<User | null>((obs) =>
    beforeAuthStateChanged(
      auth,
      (user) => {
        if (user && !user.emailVerified) {
          obs.next(user);
          // Throwing this error initiates Firebase's built-in mechanism to
          // halt the login process.
          throw new Error('email-verification-required');
        } else {
          obs.next(user);
        }
      },
      () => {
        // onAbort Callback:
        // The `beforeAuthStateChanged` listener can be added multiple times, allowing
        // the caller to revert possible state changes from a prior listener callback
        // that may have thrown an error.
        //
        // A Firebase developer provided an explanation here:
        // https://github.com/firebase/firebase-js-sdk/issues/6311
      },
    ),
  ).pipe(
    mergeMap(async (user) => {
      if (user && !user.emailVerified) {
        await apiCall('sendVerificationCode', { email: user.email! });
        const redirectTo = `/verify-email/${encodeURIComponent(user.email!)}`;
        return pushLocation(redirectTo);
      }
      return loginBlocked();
    }),
  );
};

export const selfUserInfoEpic: SharedEpic = () => {
  if (isStorybook) return NEVER;
  const auth = getAuth(getFirebaseApp());
  return new Observable<User | null>((obs) => onAuthStateChanged(auth, obs)).pipe(
    map((authUser) =>
      authUser
        ? signedIn({
            id: authUser?.uid as UserId,
            email: authUser?.email as string,
            displayName: authUser?.displayName,
            avatarUrl: authUser?.photoURL,
          })
        : signedOut(),
    ),
  );
};

export const redirectFromSignOutEpic: SharedEpic = (action$) =>
  action$.pipe(
    ofAction(signedIn),
    switchMap(() =>
      action$.pipe(
        ofAction(signedOut),
        map(() => pushLocation(generateUrlForPageIdWithParams('signin'))),
      ),
    ),
  );

export const setDisplayNameEpic: SharedEpic = (action$) => {
  const auth = getAuth(getFirebaseApp());

  return action$.pipe(
    ofActionPayload(setSelfDisplayName),
    mergeMap((displayName) =>
      updateProfile(auth.currentUser!, {
        displayName,
      }),
    ),
    ignoreElements(),
  );
};

export const setEmailEpic: SharedEpic = (action$) => {
  const auth = getAuth(getFirebaseApp());

  return action$.pipe(
    ofActionPayload(setSelfEmail),
    mergeMap(async ({ email, password }) => {
      const user = auth.currentUser;
      const credentials = EmailAuthProvider.credential(user!.email!, password);
      await reauthenticateWithCredential(user!, credentials);
      await updateEmail(user!, email);
    }),
    ignoreElements(),
  );
};

export const setPasswordEpic: SharedEpic = (action$) => {
  const auth = getAuth(getFirebaseApp());

  return action$.pipe(
    ofActionPayload(setSelfPassword),
    mergeMap(async (payload) => {
      const user = auth.currentUser;
      const credentials = EmailAuthProvider.credential(user!.email!, payload.old);

      await reauthenticateWithCredential(user!, credentials);
      await updatePassword(user!, payload.new);
    }),
    ignoreElements(),
  );
};

export const deleteAccountEpic: SharedEpic = (action$) => {
  const auth = getAuth(getFirebaseApp());

  return action$.pipe(
    ofActionPayload(deleteAccount),
    mergeMap(async () => {
      await apiCall('deleteUser', {});
      await auth.signOut();
    }),
    ignoreElements(),
  );
};
