import { debugCheck } from 'utils/debug-check';

/**
 * Track multiple resources and offer a destroy callback for when they're no
 * longer needed.
 *
 * Usage:
 *
 * ```ts
 * // Imagine we're a city with parks that open on demand but as soon as
 * // everyone is gone, the park closes for cleanup.
 * const refCountedPark = new RefCountable({
 *     create: (name: string) => openPark(name),
 *     destroy: (name: string) => closeParkAndClean(name),
 * });
 *
 * // Golden Gate Park opens as a guest arrives
 * refCountedPark.increment('golden gate park');
 * refCountedPark.increment('golden gate park');
 *
 * // The Presidio opens as a guest arrives
 * refCountedPark.increment('presidio');
 *
 * // The Presidio closes for cleaning
 * refCountedPark.decrement('presidio');
 * ```
 */
export class RefCountable<T, Id extends number | string, Args extends any[]> {
  private store: Record<Id, { value: T; count: number }> = {} as any;

  constructor(
    private callbacks: { create: (id: Id, ...args: Args) => T; destroy: (val: T, id: Id) => void },
  ) {}

  public increment(id: Id, ...args: Args) {
    if (!this.store[id]) {
      this.store[id] = {
        count: 0,
        value: this.callbacks.create(id, ...args),
      };
    }
    this.store[id].count++;
    return this.store[id].value;
  }

  public decrement(id: Id) {
    debugCheck(!!this.store[id], `Value for id ${id} expected to exist`);

    this.store[id].count--;
    if ((this.store[id]?.count ?? 1) <= 0) {
      this.callbacks.destroy(this.store[id].value, id);
      delete this.store[id];
    }
  }
}
