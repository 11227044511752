import { isEqual } from 'lodash';
import { NEVER, filter, fromEvent, map, merge, mergeMap, scan, takeUntil } from 'rxjs';

import { isKeyboardEvent } from 'common/models/iago-event.interface';
import { KeyCombo } from 'common/models/keyboard-shortcuts.interface';
import { filterIsTruthy, ofActionPayload, pluck } from 'common/utils/custom-rx-operators';
import {
  toggleCamera,
  toggleMicMute,
  toggleScreenIsEnabled,
} from 'pages/vo/vo-react/features/devices/devices.slice';
import { onIagoLocalEvent } from 'pages/vo/vo-react/features/iago/iago-state';
import { toggleChat } from 'pages/vo/vo-react/features/sessions/sessions.slice';
import { toggleSyncedSetting } from 'pages/vo/vo-react/features/settings/settings.slice';
import {
  getNamesForShortcutKeyCombo,
  getShortcutKeyCombo,
  onKeyboardShortcut,
  startShortcutListener,
  stopShortcutListener,
} from 'pages/vo/vo-react/features/shortcuts/shortcuts.slice';
import { KeyComboAccumulator } from 'pages/vo/vo-react/features/shortcuts/shortcuts.utils';
import { EpicWithDeps } from 'pages/vo/vo-react/redux/app-store';
import { isDesktopApp } from 'utils/client-utils';

import { getWindowById } from '../../desktop-window';

export const handleDesktopShortcutsEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(onKeyboardShortcut),
    map((id) => {
      switch (id) {
        case 'microphone':
          return toggleMicMute();
        case 'camera':
          return toggleCamera();
        case 'screen':
          return toggleScreenIsEnabled();
        case 'chat':
          return toggleChat();
        case 'control':
          return toggleSyncedSetting('isDrawing');
        default:
          return;
      }
    }),
    filterIsTruthy(),
  );

/**
 * If the local keyboard is blocked (for any reason), shortcuts won't be
 * detected by either Electron's BrowserWindow or GlobalAccelerator. Since we do
 * have Iago whispering blocked events to us, we can use them to detect
 * shortcuts and trigger them! In the future, we could even use this to have a
 * shortcut to forcibly end a screen share in case a malicious user has taken
 * control over a user's computer and is refusing to let go of control.
 *
 * In fact, we may even want to allow for more complex shortcuts (e.g. press Esc
 * 3x in 5 seconds), for which we'd need to have our own detection code sitting
 * atop any other mechanism to detect keys, in which case this sort of epic
 * would be an epic way to handle that.
 *
 * Finally, this epic has to be running for all shortcut names and accumulating
 * modifiers at all times, but it only emits them if the event was blocked and
 * if the shortcut was global.
 */
export const detectBlockedDesktopShortcutsEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(onIagoLocalEvent),
    map((event) => (isKeyboardEvent(event) ? event : undefined)),
    filterIsTruthy(),
    scan(
      ({ tracker }, event) => {
        if (event.baseName) tracker.ingestIagoKeyboardEvent(event);
        const keyCombo = event.blockState ? tracker.getKeyCombo() : undefined;
        return { tracker, keyCombo };
      },
      { tracker: new KeyComboAccumulator(), keyCombo: undefined as KeyCombo | undefined },
    ),
    pluck('keyCombo'),
    filterIsTruthy(),
    map((keyCombo) => getNamesForShortcutKeyCombo(state$.value, keyCombo)),
    filter((names) => !!names.length),
    mergeMap((names) => names.map((name) => onKeyboardShortcut(name))),
  );

export const detectShortcutsEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(startShortcutListener),
    mergeMap(({ name, desktopWindowId }) => {
      const keyComboAccumulator = new KeyComboAccumulator();
      const document =
        isDesktopApp && desktopWindowId
          ? getWindowById(desktopWindowId).getWindow().document
          : window.document;
      return !document
        ? NEVER
        : merge(fromEvent(document, 'keyup'), fromEvent(document, 'keydown')).pipe(
            map((event) => {
              keyComboAccumulator.ingestKeyEvent(event as KeyboardEvent);
              const keyCombo = keyComboAccumulator.getKeyCombo();
              if (isEqual(keyCombo, getShortcutKeyCombo(state$.value, name))) return onKeyboardShortcut(name);
            }),
            filterIsTruthy(),
            takeUntil(
              action$.pipe(
                ofActionPayload(stopShortcutListener),
                filter((payload) => isEqual(payload, { name, desktopWindowId })),
              ),
            ),
          );
    }),
  );
