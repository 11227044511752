import { trim } from 'lodash';

import { protocolName } from 'common/config/dev-config';

import { signInWithFirebaseAuthToken } from './features/auth/auth.slice';
import { pushLocation } from './features/router/router.slice';
import { createGlobalThunk } from './redux/create-slice';

export const handleProtocolUrl = createGlobalThunk(
  'handleProtocolUrl',
  async (dispatch, _getState, { url }: { url: string }) => {
    const { protocol, pathname } = new URL(url);

    // URL.protocol includes the trailing `:`.
    if (protocol !== `${protocolName}:`) {
      return console.error('Dropping unknown protocol', protocol);
    }

    // Trim `/` and whitespace. from the start because it will look like `//login/1234`.
    const [action, ...params] = trim(pathname, '/ ').split('/');

    switch (action) {
      case 'login': {
        const [customToken] = params;
        await dispatch(signInWithFirebaseAuthToken({ customToken }));
        dispatch(pushLocation('/vo'));
        break;
      }
    }
  },
);

export const makeLoginProtocolUrl = (token: string) => `${protocolName}://login/${token}`;
