import { clamp, find, includes, isUndefined, map, mapValues, minBy, partialRight, round } from 'lodash';

import { FlatRectangle, InsetRect, Point, Rectangle, Size } from '../models/geometry.interface';
import { assertExhaustedType } from '../utils/ts-utils';

export const size480p: Size = { width: 720, height: 480 };
export const storybookVideoSize: Size = { width: 3360, height: 2100 };

export const getZeroPoint = () => ({ x: 0, y: 0 } as Point);
export const getUnitPoint = () => ({ x: 1, y: 1 } as Point);
export const getZeroSize = () => ({ width: 0, height: 0 } as Size);
export const getUnitSize = () => ({ width: 1, height: 1 } as Size);
export const getZeroRectangle = () => ({ origin: getZeroPoint(), size: getZeroSize() } as Rectangle);
export const getUnitRectangle = () => ({ origin: getZeroPoint(), size: getUnitSize() } as Rectangle);

export const areSizesEqual = (a: Size, b: Size) => a.width === b.width && a.height === b.height;

export const pointClampedToRectangle = (point: Point, rect: Rectangle) => ({
  x: clamp(point.x, rect.origin.x, rect.origin.x + rect.size.width),
  y: clamp(point.y, rect.origin.y, rect.origin.y + rect.size.height),
});

export const pointAddPoint = (point: Point, addPoint: Point) => ({
  x: point.x + addPoint.x,
  y: point.y + addPoint.y,
});

export const pointSubtractPoint = (point: Point, minusPoint: Point) => ({
  x: point.x - minusPoint.x,
  y: point.y - minusPoint.y,
});

export const pointMultiplySize = (point: Point, multiplySize: Size) => ({
  x: point.x * multiplySize.width,
  y: point.y * multiplySize.height,
});

export const pointDivideSize = (point: Point, divideSize: Size) => ({
  x: point.x / divideSize.width,
  y: point.y / divideSize.height,
});

export const pointFlipWithContainerSize = (point: Point, containerSize: Size) => ({
  x: containerSize.width - point.x,
  y: containerSize.height - point.y,
});

export const sizeToPoint = ({ width, height }: Size) => ({ x: width, y: height } as Point);
export const pointToSize = ({ x, y }: Point) => ({ width: x, height: y } as Size);
export const scalePoint = ({ x, y }: Point, scale: number) => ({ x: x * scale, y: y * scale } as Point);

export const growRect = (bounds: Rectangle, offset: number) =>
  ({
    origin: {
      x: bounds.origin.x - offset,
      y: bounds.origin.y - offset,
    },
    size: {
      width: bounds.size.width + offset * 2,
      height: bounds.size.height + offset * 2,
    },
  } as Rectangle);
export const shrinkRect = (bounds: Rectangle, offset: number) => growRect(bounds, -offset);
export const shrinkRectByPercentageOfHeight = (bounds: Rectangle, offsetPercent: number) =>
  growRect(bounds, -(bounds.size.height * offsetPercent));

export const boundsAddPointToOrigin = (bounds: Rectangle, offsetOrigin: Point) =>
  ({ origin: pointAddPoint(bounds.origin, offsetOrigin), size: bounds.size } as Rectangle);
export function isPointInBounds(point: Point, bounds: Rectangle) {
  return (
    point.x >= bounds.origin.x &&
    point.x <= bounds.origin.x + bounds.size.width &&
    point.y >= bounds.origin.y &&
    point.y <= bounds.origin.y + bounds.size.height
  );
}
export function doBoundsIntersect(inner: Rectangle, outer: Rectangle) {
  return (
    isPointInBounds(getNamedPointFromRect('top-left', inner), outer) ||
    isPointInBounds(getNamedPointFromRect('top-right', inner), outer) ||
    isPointInBounds(getNamedPointFromRect('bottom-left', inner), outer) ||
    isPointInBounds(getNamedPointFromRect('bottom-right', inner), outer)
  );
}

export function getShortestPathToRect(point: Point, rect: Rectangle) {
  return {
    x: Math.max(rect.origin.x - point.x, 0) || Math.min(rect.origin.x + rect.size.width - point.x, 0),
    y: Math.max(rect.origin.y - point.y, 0) || Math.min(rect.origin.y + rect.size.height - point.y, 0),
  };
}

// Fits a rect within a larger rect via the shortest path, without resizing
// (unless the inner rect is bigger than the outer rect).
export function fitRectWithinRect(inner: Rectangle, outer: Rectangle) {
  const clampedSize = {
    width: Math.min(inner.size.width, outer.size.width),
    height: Math.min(inner.size.height, outer.size.height),
  };
  const clampedOrigin = pointAddPoint(inner.origin, getShortestPathToRect(inner.origin, outer));
  const bottomRightPointAfterOriginClamped = pointAddPoint(clampedOrigin, sizeToPoint(clampedSize));
  const clampedBottomRightPoint = pointAddPoint(
    bottomRightPointAfterOriginClamped,
    getShortestPathToRect(bottomRightPointAfterOriginClamped, outer),
  );

  return {
    ...inner,
    origin: pointSubtractPoint(clampedBottomRightPoint, sizeToPoint(clampedSize)),
    size: clampedSize,
  };
}

export const scaleSize = ({ width, height }: Size, scale: number) =>
  ({ width: width * scale, height: height * scale } as Size);
export const boundsScaleSize = (bounds: Rectangle, scale: number) =>
  ({ ...bounds, size: scaleSize(bounds.size, scale) } as Rectangle);
export const scaleBounds = (bounds: Rectangle, scale: number) =>
  ({ origin: scalePoint(bounds.origin, scale), size: scaleSize(bounds.size, scale) } as Rectangle);

export function getPageMousePositionFromEvent(event: any): Point {
  if (event.pageX !== undefined) return { x: event.pageX, y: event.pageY };
  if (event.changedTouches && event.changedTouches[0])
    return { x: event.changedTouches[0].pageX, y: event.changedTouches[0].pageY };
  if (event.touches && event.touches[0]) return { x: event.touches[0].pageX, y: event.touches[0].pageY };
  console.error('getPageMousePositionFromEvent: no event touches:', event);
  return getZeroPoint();
}

export function getOffsetMousePositionFromEvent(event: any): Point {
  if (event.offsetX !== undefined) return { x: event.offsetX, y: event.offsetY };
  const point = {
    x: event.changedTouches?.[0]?.clientX ?? event.touches?.[0]?.clientX,
    y: event.changedTouches?.[0]?.clientY ?? event.touches?.[0]?.clientY,
  };
  const parentOffset = event.target.getBoundingClientRect();
  return pointSubtractPoint(point, { x: parentOffset.left, y: parentOffset.top });
}

export function getOffsetSizeFromElement(el: HTMLElement) {
  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  } as Size;
}

export function getFractionMousePositionFromEvent(event: any): Point {
  if (event.fractionMousePosition) return event.fractionMousePosition;
  const offsetMousePosition = getOffsetMousePositionFromEvent(event);
  const offsetSize = getOffsetSizeFromElement(event.target);
  return pointDivideSize(offsetMousePosition, offsetSize);
}

// Note: untested
export function offsetElement(htmlEl: HTMLElement): Point {
  if (!htmlEl) return getZeroPoint();
  const offsetParent = offsetElement(htmlEl.offsetParent as HTMLElement);
  const offset = { x: htmlEl.offsetLeft, y: htmlEl.offsetTop };
  return pointAddPoint(offset, offsetParent);
}

export function getElementRect(element: HTMLElement, type: 'absolute' | 'relative'): Rectangle {
  const window = element.ownerDocument.defaultView;
  if (type === 'absolute') return boundsAddPointToOrigin(domRectToRectangle(element.getBoundingClientRect()), { x: window?.screenLeft ?? 0, y: window?.screenTop ?? 0 });
  return  domRectToRectangle({
    x: element.offsetLeft,
    y: element.offsetTop,
    width: element.offsetWidth,
    height: element.offsetHeight,
  });
}

// comparison
const areObjectsClose = (o1: any, o2: any, threshold: number) =>
  isUndefined(find(o1, (val, dim) => Math.abs(val - o2[dim]) > threshold));
export const arePointsClose = (p1: Point, p2: Point, threshold = 5) => areObjectsClose(p1, p2, threshold);
export const areSizesClose = (s1: Size, s2: Size, threshold = 5) => areObjectsClose(s1, s2, threshold);
export const areBoundsClose = (b1: Readonly<Rectangle>, b2: Readonly<Rectangle>, threshold = 5) =>
  arePointsClose(b1.origin, b2.origin, threshold) && areSizesClose(b1.size, b2.size, threshold);

export function tupleToPoint([x, y]: [number, number]) {
  return { x, y };
}

export type Direction = 'up' | 'down' | 'left' | 'right';
export type VerticalEdge = 'top' | 'bottom';
export type HorizontalEdge = 'left' | 'right';
export type Edge = VerticalEdge | HorizontalEdge;
export type Corner = `${VerticalEdge}-${HorizontalEdge}`;
export type EdgeMidpoint = `${VerticalEdge | HorizontalEdge}-center`;
export type NamedPoint = Corner | 'center' | EdgeMidpoint;
export const allNamedPoints: NamedPoint[] = [
  'top-left',
  'top-center',
  'top-right',
  'left-center',
  'center',
  'right-center',
  'bottom-left',
  'bottom-center',
  'bottom-right',
];
const namedPointRatios: { [k in NamedPoint]: [0 | 0.5 | 1, 0 | 0.5 | 1] } = {
  'top-left': [0, 0],
  'top-center': [0.5, 0],
  'top-right': [1, 0],
  'left-center': [0, 0.5],
  center: [0.5, 0.5],
  'right-center': [1, 0.5],
  'bottom-left': [0, 1],
  'bottom-center': [0.5, 1],
  'bottom-right': [1, 1],
};
export function getNamedPointFromRect(anchorPoint: NamedPoint, rect: Rectangle): Point {
  if (!anchorPoint) {
    console.trace('getNamedPointFromRect: no anchorPoint');
    return getZeroPoint();
  }
  const anchorPointSizeRatio = tupleToPoint(namedPointRatios[anchorPoint]);
  return pointAddPoint(rect.origin, pointMultiplySize(anchorPointSizeRatio, rect.size));
}

export function oppositeNamedPoint(namedPoint: NamedPoint) {
  if (namedPoint === 'top-left') return 'bottom-right';
  if (namedPoint === 'top-center') return 'bottom-center';
  if (namedPoint === 'top-right') return 'bottom-left';
  if (namedPoint === 'left-center') return 'right-center';
  if (namedPoint === 'center') return 'center';
  if (namedPoint === 'right-center') return 'left-center';
  if (namedPoint === 'bottom-left') return 'top-right';
  if (namedPoint === 'bottom-center') return 'top-center';
  if (namedPoint === 'bottom-right') return 'top-left';
  assertExhaustedType(namedPoint);
}

export function oppositeEdge(edge: Edge) {
  if (edge === 'bottom') return 'top';
  if (edge === 'top') return 'bottom';
  if (edge === 'left') return 'right';
  if (edge === 'right') return 'left';
  assertExhaustedType(edge);
}

export function getEdgeFromEdgeMidpoint<U extends Edge, T extends `${U}-center`>(edgeMidpoint: T) {
  return edgeMidpoint.split('-')[0] as U;
}

export function getEdgeMidpointFromEdge<U extends Edge, T extends `${U}-center`>(edge: U) {
  return `${edge}-center` as T;
}

export function directionToEdge(direction: Direction): Edge {
  if (direction === 'up') return 'top';
  if (direction === 'down') return 'bottom';
  return direction;
}

export function edgeToDirection(edge: Edge): Direction {
  if (edge === 'top') return 'up';
  if (edge === 'bottom') return 'down';
  return edge;
}

/**
 * Match an inner rect anchor point to an outer rect anchor point. For example,
 * place a rect on the midpoint of the screen bounds:
 *
 *     ```
 *     anchorToOuterRectPoint({
 *       inner: rectToPlace,
 *       outer: screenBounds,
 *       anchor: 'left-center',
 *     });
 *     ```
 *
 *     *----------*----------*
 *     |                     |
 *     |........             |
 *     |       .             |
 *     *       .  *          *
 *     |       .             |
 *     |........             |
 *     |                     |
 *     *----------*----------*
 *
 */
export function anchorToOuterRectPoint<AnyRectWithSize extends { size: Size }>({
  inner,
  outer,
  anchor,
  offset = { x: 0, y: 0 },
}: {
  inner: AnyRectWithSize;
  outer: Rectangle;
  anchor: NamedPoint;
  offset?: Point;
}) {
  const anchoredDeltaOffset: Point = pointMultiplySize(
    offset,
    pointToSize({
      x: includes(anchor, 'right') ? -1 : 1,
      y: includes(anchor, 'bottom') ? -1 : 1,
    }),
  );
  return {
    ...inner,
    origin: pointSubtractPoint(
      getNamedPointFromRect(anchor, outer),
      pointSubtractPoint(
        pointMultiplySize(tupleToPoint(namedPointRatios[anchor]), inner.size),
        anchoredDeltaOffset,
      ),
    ),
  };
}

export function distanceBetweenPoints(p1: Point, p2: Point) {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

export function getClosestNamedPoint(point: Point, bounds: Rectangle, namedPoints: readonly NamedPoint[]) {
  const distancesToNamedPoints = map(namedPoints, (namedPoint) => ({
    namedPoint,
    distance: distanceBetweenPoints(point, getNamedPointFromRect(namedPoint, bounds)),
  }));
  const minDistanceAndNamedPoint = minBy(distancesToNamedPoints, ({ distance }) => distance);
  return minDistanceAndNamedPoint!.namedPoint;
}

export function getClosestCorner(point: Point, bounds: Rectangle) {
  return getClosestNamedPoint(point, bounds, [
    'bottom-left',
    'top-left',
    'top-right',
    'bottom-right',
  ] as const) as Corner;
}

export function getClosestEdge(point: Point, bounds: Rectangle) {
  return getClosestNamedPoint(point, bounds, [
    'bottom-center',
    'top-center',
    'right-center',
    'left-center',
  ] as const) as EdgeMidpoint;
}

export const deltaFromNamedPointAndTopLeftOfRect = (namedPoint: NamedPoint, rect: Rectangle) =>
  pointSubtractPoint(getNamedPointFromRect(namedPoint, rect), getNamedPointFromRect('top-left', rect));

export function originAndBottomRightPointToRect(origin: Point, bottomRightPoint: Point) {
  const rect: Rectangle = {
    origin,
    size: pointToSize(pointSubtractPoint(bottomRightPoint, origin)),
  };
  return rect;
}

export function getBottomRightOffset(outer: Rectangle, inner: Rectangle) {
  return pointSubtractPoint(
    getNamedPointFromRect('bottom-right', outer),
    getNamedPointFromRect('bottom-right', inner),
  );
}

export function domRectToRectangle(rect: Pick<DOMRect, 'width' | 'height' | 'x' | 'y'>) {
  return {
    size: {
      width: rect.width,
      height: rect.height,
    },
    origin: {
      x: rect.x,
      y: rect.y,
    },
  };
}

export function rectangleToCssRectangle(rect: Rectangle) {
  return {
    width: rect.size.width,
    height: rect.size.height,
    left: rect.origin.x,
    top: rect.origin.y,
  };
}

export function cssRectangleToRectangle(cssRect: {
  width: number;
  height: number;
  left: number;
  top: number;
}) {
  return {
    size: {
      width: cssRect.width,
      height: cssRect.height,
    },
    origin: {
      x: cssRect.left,
      y: cssRect.top,
    },
  };
}

// This looks like absolute nonsense, but the electron setPosition/setBounds api
// doesn't like -0 (which is valid JS, ugh)...
function safeRound(val: number) {
  const rounded = round(val);
  if (rounded === 0) return 0;
  return rounded;
}

export function roundedPoint(point: Point) {
  return { x: safeRound(point.x), y: safeRound(point.y) };
}

export function roundedSize(size: Size) {
  const roundedSize = mapValues(size, (val) => safeRound(val)) as Size;
  return roundedSize;
}

export function roundedRect(rect: Rectangle) {
  const roundedRect = mapValues(rect, (val) => mapValues(val, (innerVal) => safeRound(innerVal)));
  return roundedRect as Rectangle;
}

export function evenRoundedRect(rect: Rectangle) {
  const roundedRect = mapValues(rect, (val) => mapValues(val, (innerVal) => safeRound(innerVal / 2) * 2));
  return roundedRect as Rectangle;
}

export function aspectRatioForSize({ width, height }: Size) {
  return width / height;
}

export function sizeForAspectRatioWithMaxSize(aspectRatio: number, { width, height }: Size) {
  return {
    width: Math.min(width, height * aspectRatio),
    height: Math.min(height, width / aspectRatio),
  };
}

export function constrainSize(size: Size, maxSize: Size) {
  const maxSizeWithAspectRatio = sizeForAspectRatioWithMaxSize(aspectRatioForSize(size), maxSize);
  return maxSizeWithAspectRatio.width > size.width ? size : maxSizeWithAspectRatio;
}

export function rectRelativeToOuterRect(innerRect: Rectangle, outerRect: { origin: Point }) {
  return {
    origin: pointSubtractPoint(innerRect.origin, outerRect.origin),
    size: innerRect.size,
  };
}

export function appendUnit<T>(val: T, unit: string) {
  return `${val}${unit}`;
}

export const appendPx = partialRight(appendUnit, 'px');

export function appendUnitToValues<T>(values: Record<any, T>, unit: string) {
  return mapValues(values, (val) => `${val}${unit}`);
}

export const insetRectToRect = (insetRect: InsetRect, outerRect: Rectangle) => {
  return {
    origin: {
      x: outerRect.origin.x + insetRect.left,
      y: outerRect.origin.y + insetRect.top,
    },
    size: {
      width: outerRect.size.width - insetRect.left - insetRect.right,
      height: outerRect.size.height - insetRect.top - insetRect.bottom,
    },
  };
}

export const appendPxToValues = partialRight(appendUnitToValues, 'px') as (
  values: Record<any, any>,
) => Record<any, string>;

export function polygonStringFromNormalizedRect({ origin, size }: Rectangle) {
  const BottomRightPointNormalized = pointAddPoint(origin, sizeToPoint(size));
  const originPct = mapValues(origin, (val) => round(val * 100, 3));
  const BottomRightPointPct = mapValues(BottomRightPointNormalized, (val) => round(val * 100, 3));
  return `polygon(
    0% 0%,
    0% 100%,
    ${originPct.x}% 100%,
    ${originPct.x}% ${originPct.y}%,
    ${BottomRightPointPct.x}% ${originPct.y}%,
    ${BottomRightPointPct.x}% ${BottomRightPointPct.y}%,
    ${originPct.x}% ${BottomRightPointPct.y}%,
    ${originPct.x}% 100%,
    100% 100%,
    100% 0%
  )`;
}

export function polygonStringFromRect({ origin, size }: Rectangle) {
  const BottomRightPoint = pointAddPoint(origin, sizeToPoint(size));
  return `polygon(
    0% 0%,
    0% 100%,
    ${origin.x}px 100%,
    ${origin.x}px ${origin.y}px,
    ${BottomRightPoint.x}px ${origin.y}px,
    ${BottomRightPoint.x}px ${BottomRightPoint.y}px,
    ${origin.x}px ${BottomRightPoint.y}px,
    ${origin.x}px 100%,
    100% 100%,
    100% 0%
  )`;
}

export function flatRectToRect({ x, y, width, height }: FlatRectangle): Rectangle {
  return {
    origin: { x, y },
    size: { width, height },
  };
}

export function rectToFlatRect({ origin: { x, y }, size: { width, height } }: Rectangle): FlatRectangle {
  return {
    x,
    y,
    width,
    height,
  };
}
