import { isTeamSessionInitiator } from 'common/models/db/session-initiatior.interface';

import { createGlobalParametricMemoizedSelector } from '../../redux/create-slice';
import { getSessionInitiatorForSession } from '../sessions/sessions.slice';

export const getTeamIdForSession = createGlobalParametricMemoizedSelector(
  getSessionInitiatorForSession,
  (sessionInitiator) => {
    if (isTeamSessionInitiator(sessionInitiator)) return sessionInitiator.id;
    return undefined;
  },
)((_state, sessionId) => sessionId);
