import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { UserId } from 'common/models/db/vo.interface';
import { listenToUser } from 'pages/vo/vo-react/features/users/users.slice';

export const useListenToUsers = (userIds: UserId[]) => {
  const dispatch = useDispatch();
  useEffect(() => {
    userIds.map(
      (userId) =>
        userId &&
        dispatch(
          listenToUser({
            payload: { userId },
            type: 'create',
          }),
        ),
    );
    return () => {
      userIds.map(
        (userId) =>
          userId &&
          dispatch(
            listenToUser({
              payload: { userId },
              type: 'destroy',
            }),
          ),
      );
    };
  }, [userIds, dispatch]);
};
