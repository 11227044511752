import {
  differenceInMilliseconds,
  differenceInSeconds,
  endOfDay,
  hoursToMilliseconds,
  minutesToMilliseconds,
} from 'date-fns';
import { FC } from 'react';

import { UserStatus } from 'common/models/db/vo.interface';
import { popColor } from 'utils/react/colors';

import { BaseProps } from '../../components/ui-kit/base-pop-ui-component';

import { StatusClearOption } from './users.types';

export function wasRecentlyPresent(presenceTs: number) {
  return differenceInSeconds(new Date(), presenceTs) < 5;
}

export const userStatusToLabel: { [userStatus in UserStatus]: string } = {
  available: 'Open to talk',
  dnd: 'Not open to talk',
  away: 'Away',
  'in-session': 'In a call',
};

export const userStatusToSubLabel: { [userStatus in UserStatus]?: string } = {
  available: 'Accept calls and listen to teams',
  dnd: 'Block calls and teams',
};

export const userStatusToIconColor: { [userStatus in UserStatus]: string } = {
  available: popColor('secondary1'),
  away: popColor('grey2'),
  dnd: popColor('tertiary1'),
  'in-session': popColor('tertiary1'),
};

export const userStatusToIcon: { [userStatus in UserStatus]: FC<BaseProps> } = {
  available: IconStatusAvailable,
  away: IconStatusAway,
  dnd: IconStatusDnd,
  'in-session': IconCall,
};

export const statusClearOptionToMs = (statusClearOption: StatusClearOption) => {
  switch (statusClearOption) {
    case 'none':
      return 0;
    case '30-mins':
      return minutesToMilliseconds(30);
    case '1-hour':
      return hoursToMilliseconds(1);
    case '4-hours':
      return hoursToMilliseconds(4);
    case 'today':
      return differenceInMilliseconds(endOfDay(new Date()), new Date());
  }
};
