import { IpcToMain, UNSUPPORTED_IPC_MESSAGE } from 'common/models/ipc-messages';
import { isDev } from 'utils/client-utils';

import { createGlobalAction } from '../redux/create-slice';

(window as any).shouldLogIpc = false;

const defaults: { [k in keyof IpcToMain]?: Awaited<ReturnType<IpcToMain[k]>> } = {
  getAppLaunchInfo: { didAppLaunchAfterNinjaUpdate: false, didAppLaunchAfterReboot: false },
  checkPermission: true,
};

export const ipcToClient = createGlobalAction<{ type: string; handlerArgs: any }>('ipcToClient');

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export const sendIpc = async <K extends keyof IpcToMain>(
  type: K,
  ...args: Parameters<IpcToMain[K]>
): Promise<Awaited<ReturnType<IpcToMain[K]>>> => {
  if ((window as any).shouldLogIpc) console.log('[IPC:send]', type, args);
  let response: any;
  try {
    response = await window['electron']?.invokeIpcToMain(type, ...args);
  } catch (error) {
    console.error(error);
    response = UNSUPPORTED_IPC_MESSAGE;
  }
  if ((window as any).shouldLogIpc) console.log('[IPC:receive]', type, args, response);
  if (response === UNSUPPORTED_IPC_MESSAGE) {
    const defaultVal = defaults[type];
    if (defaultVal) return defaultVal as Awaited<ReturnType<IpcToMain[K]>>;
    throw new Error(`Unsupported ipc-to-main message: ${type}`);
  }
  return response;
};

if (isDev) (window as any).sendIpc = sendIpc;
