import { assign, isObject, keyBy, keys, mapKeys, mapValues, reduce } from 'lodash';

import { escapeFirebaseKey, unescapeFirebaseKey } from './firebase-escape';

export function firebaseTruthyObjectToArray(truthyObject: undefined): void;
export function firebaseTruthyObjectToArray(truthyObject: { [key: string]: boolean }): string[];
export function firebaseTruthyObjectToArray(
  truthyObject: { [key: string]: boolean } | undefined,
): string[] | void;
export function firebaseTruthyObjectToArray(truthyObject: { [key: string]: boolean } | undefined) {
  return !truthyObject ? undefined : keys(truthyObject ?? {});
}

export function arrayToFirebaseTruthyObject(strings: undefined): void;
export function arrayToFirebaseTruthyObject(strings: string[]): Record<string, any>;
export function arrayToFirebaseTruthyObject(strings: string[] | undefined): Record<string, any> | void;
export function arrayToFirebaseTruthyObject(strings: string[] | undefined) {
  return !strings ? undefined : mapValues(keyBy(strings), () => true);
}

export function escapeFirebaseKeysInObject(object: undefined): void;
export function escapeFirebaseKeysInObject(object: Record<string, any>): Record<string, any>;
export function escapeFirebaseKeysInObject(
  object: Record<string, any> | undefined,
): Record<string, any> | void;
export function escapeFirebaseKeysInObject(object: Record<string, any> | undefined) {
  return !object ? undefined : mapKeys(object, (_value, key) => escapeFirebaseKey(key));
}

export function unescapeFirebaseKeysInObject(object: undefined): void;
export function unescapeFirebaseKeysInObject(object: Record<string, any>): Record<string, any>;
export function unescapeFirebaseKeysInObject(
  object: Record<string, any> | undefined,
): Record<string, any> | void;
export function unescapeFirebaseKeysInObject(object: Record<string, any> | undefined) {
  return !object ? undefined : mapKeys(object, (_value, key) => unescapeFirebaseKey(key));
}

/**
 * This function converts a JS object into one that can be sent to FirebaseDB's update() method.
 *
 * Example:
 *
 * {
 *   foo: {
 *     bar: {
 *       char: 'xyz'
 *     }
 *   },
 *   car: {
 *     bar: 123
 *   }
 * } --> {
 * 'foo/bar/char': 'xyz',
 * 'car/bar': 123,
 * }
 */
export function objectToFirebaseUpdateObject(object: any, inheritedPath?: string): any {
  if (!object || !isObject(object)) return;
  return reduce(
    keys(object),
    (newObj, parentKey) => {
      const parentVal = object[parentKey];
      const childKey = `${inheritedPath ? `${inheritedPath}/` : ''}${parentKey}`;
      assign(
        newObj,
        !isObject(parentVal) ? { [childKey]: parentVal } : objectToFirebaseUpdateObject(parentVal, childKey),
      );
      return newObj;
    },
    {},
  );
}
