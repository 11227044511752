import { Point } from './geometry.interface';

import { AnyPeerId } from '../models/peer.interface';

export interface BaseLocalIagoEvent {
  blockState?: 'exclusive-control' | 'cosumer-specified-block';
  peerId: AnyPeerId;
  role: 'local' | 'remote';
}

// Include KeyboardEvent even though we might not actually send it to guests —
// decide in JS whether we want to send privacy-conscious keyboard updates.
export type LocalIagoEvent =
  | LocalizeIagoEvent<KeyboardEvent | PointerEvent | ControlChangeEvent>
  | CursorChangeEvent;
export type RemoteIagoEvent = KeyboardEvent | PointerEvent | CursorChangeEvent | ControlChangeEvent;

type IncompleteKeyboardEvent = Omit<KeyboardEvent, 'location'>;
export type IncompleteRemoteIagoEvent = IncompleteKeyboardEvent | PointerEvent;

export type LocalizeIagoEvent<T> = Omit<T, 'trackId'> & BaseLocalIagoEvent;

export interface CursorChangeEvent {
  type: 'cursor-change';
  representations: {
    data: number[];
    hotspot: Point;
  }[];
}

export interface ControlChangeEvent extends BaseLocalIagoEvent {
  type: 'control-change';
}

export function isKeyboardEvent(event: { type: string }): event is KeyboardEvent {
  return event.type === 'keyboard';
}

export function isPointerEvent(event: { type: string }): event is PointerEvent {
  return event.type === 'click' || event.type === 'move' || event.type === 'drag' || event.type === 'scroll';
}

export function isControlChangeEvent(event: { type: string }): event is ControlChangeEvent {
  return event.type === 'control-change';
}

export function isCursorChangeEvent(event: { type: string }): event is CursorChangeEvent {
  return event.type === 'cursor-change';
}

interface TrackEvent {
  trackId: string;
}

export interface KeyboardEvent extends TrackEvent {
  type: 'keyboard';
  baseName?: string;
  outputCharacter?: string;
  browserCodeKey?: {
    code: string;
    key: string;
  };
  isDown: boolean;
  location: Point;
}

export type PointerEvent = PointerClick | PointerMove | PointerDrag | PointerScroll;

export interface PointerClick extends TrackEvent {
  type: 'click';
  isDown: boolean;
  location: Point;
  button: PointerButton;
}

export interface PointerDrag extends TrackEvent {
  type: 'drag';
  location: Point;
  button: PointerButton;
}

export interface PointerScroll extends TrackEvent {
  type: 'scroll';
  location: Point;
  magnitude: Point;
}

export type PointerButton = 'left' | 'right' | 'back' | 'forward' | 'other';

export interface PointerMove extends TrackEvent {
  type: 'move';
  location: Point;
}
