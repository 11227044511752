import { Observable, share } from 'rxjs';

import { observeMatchesMedia } from 'common/utils/observe-matches-media';

import { useObservable } from './use-observable';

const observables: Record<string, Observable<boolean>> = {};

export const useMatchesMedia = (query: string) => {
  const mediaQuery$ = observables[query] ?? (observables[query] = observeMatchesMedia(query).pipe(share()));
  return useObservable(() => mediaQuery$, undefined, [query]);
};
