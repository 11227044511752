import { getTimezoneOffset } from 'date-fns-tz';

import { OrgId } from 'common/models/db/vo.interface';

import { createGlobalSelector } from '../../redux/create-slice';
import { getSelfUserId } from '../auth/auth.slice';
import { getCalendarId, getEffectiveTimeZoneForCalendarId } from '../calendar/calendar.slice';
import { getCurrentUrlParams } from '../navigation/navigation.utils';

export const getTimeZoneOffsetForSelf = createGlobalSelector((state) => {
  const orgId = getCurrentUrlParams()?.orgId as OrgId;
  if (!orgId) return 0;
  const userId = getSelfUserId(state);
  if (!userId) return 0;
  const calendarId = getCalendarId(state, { userId, orgId: orgId });
  if (!calendarId) return 0;
  const timezone = getEffectiveTimeZoneForCalendarId(state, calendarId);
  const offset = getTimezoneOffset(timezone);
  return offset;
});
