import { createSlice } from '../../redux/create-slice';

export type ActivitySlice = {
  isSuspended: boolean;
  isLocked: boolean;
  isOffline: boolean;
  // Idle here refers to the application-level definition of being unconnected
  // to a room and not having moved mouse or keyboard within Pop in some n seconds.
  isIdleFromPop: boolean;
};

const initialState: ActivitySlice = {
  isSuspended: false,
  isLocked: false,
  isOffline: false,
  isIdleFromPop: false,
};
export const { createReducer, createSelector, createMemoizedSelector, createAction } = createSlice(
  'activity',
  initialState,
);

export const setIsSuspended = createAction<boolean>('setIsSuspended');
export const setIsLocked = createAction<boolean>('setIsLocked');
export const setIsOffline = createAction<boolean>('setIsOffline');
export const setIsSelfInactive = createAction<boolean>('setIsSelfInactive');
export const setIsIdleFromPop = createAction<boolean>('setIsIdleFromPop');
export const onActivityDetected = createAction('onActivityDetected');

export default createReducer()
  .on(setIsSuspended, (state, { payload: isSuspended }) => {
    state.isSuspended = isSuspended;
  })
  .on(setIsLocked, (state, { payload: isLocked }) => {
    state.isLocked = isLocked;
  })
  .on(setIsOffline, (state, { payload: isOffline }) => {
    state.isOffline = isOffline;
  })
  .on(setIsIdleFromPop, (state, { payload: isIdleFromPop }) => {
    state.isIdleFromPop = isIdleFromPop;
  });

export const getIsActive = createSelector(
  (state) => !state.activity.isSuspended && !state.activity.isLocked && !state.activity.isOffline,
);
export const getIsOffline = createSelector((state) => state.activity.isOffline);
export const getIsIdleFromPop = createSelector((state) => state.activity.isIdleFromPop);
