import {
  addDays,
  addMilliseconds,
  addMinutes,
  addWeeks,
  differenceInMilliseconds,
  eachMinuteOfInterval,
  endOfDay,
  format,
  formatDuration,
  getDay,
  getHours,
  getMilliseconds,
  getMinutes,
  getSeconds,
  intervalToDuration,
  isToday,
  minutesToMilliseconds,
  previousDay,
  roundToNearestMinutes,
  set,
  startOfDay,
  startOfHour,
  isEqual,
  subMilliseconds,
  differenceInSeconds,
  differenceInMinutes,
  // eslint-disable-next-line no-restricted-imports
  formatDistance as formatDistanceDateFns,
  lightFormat,
  hoursToMilliseconds,
  formatRelative,
  addSeconds,
} from 'date-fns';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import { capitalize, head, isDate, last, memoize, sortBy, sortedIndexBy, sortedLastIndexBy } from 'lodash';

import { EventInstanceId } from '../models/db/event.interface';

import { getEventIdAndInstanceStartTsFromEventInstanceId } from './event-utils';

export type DateTimeBlock = { start: Date; end: Date };
export type DateTimeBlockWithOptionalEnd = { start: Date; end?: Date };

export type SerializableDateTimeBlock = { startTs: number; endTs: number };

export type Recurrence = {
  rule: string;
  durationMs: number;
};

export const serializeDateTimeBlock = (block: DateTimeBlock) => ({
  startTs: Number(block.start),
  endTs: Number(block.end),
});

export const deserializeDateTimeBlock = (block: SerializableDateTimeBlock) => ({
  start: new Date(block.startTs),
  end: new Date(block.endTs),
});

/**
 * Rationale for `Time`
 *
 * In Javascript, the `Date` built-in is a misnomer, as it actually should be
 * called DateTime. Often this is what we want, to represent a single moment in
 * time. Other times though, we want to represent a time like 3:00pm, regardless
 * of the date. Because of the language limitations, it's still easiest to
 * represent it as a `Date` underneath, but we want to make sure we're not
 * blindly using the non-Time components that we don't actually care about. So,
 * `Time` uses the type system to force us to make a decision. There are
 * sanctioned ways to get back to a `Date` from a `Time`; 1. combineTimeAndDay()
 * which does exactly what it says and 2. dangerouslyCastTimeToDate, which tells
 * the compiler (and your fellow programmer) "I know what I'm doing in casting
 * this".
 */
export type Time = { __date: Date };
export type TimeBlock = { start: Time; end: Time };
export type ComponentTime = {
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
};
export const makeTime = (date: Date) => ({ __date: date } as Time);
export const makeTimeFromComponents = (components: ComponentTime) =>
  makeTime(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0, ...components }));
export const makeNowTime = () => makeTime(new Date());
export const isTime = (date: Time | Date): date is Time => !!(date as any).__date;
export const isTimeBlock = (block: TimeBlock | DateTimeBlock): block is TimeBlock => !!isTime(block.start);
export const dangerouslyCastTimeToDate = (time: Time | Date) => (isTime(time) ? time.__date : time);
export const dangerouslyCastTimeBlockToDate = (block: TimeBlock | DateTimeBlock) =>
  isTimeBlock(block) ? combineTimeBlockAndDay({ timeBlock: block, day: new Date() }) : block;
export const combineTimeAndDay = ({ time, day }: { time: Date | Time; day: Date }) =>
  set(day, getComponentTimeFromDate(dangerouslyCastTimeToDate(time)));

export const makeTimeBlockFromDateTimeBlock = (block: DateTimeBlock) => ({
  start: makeTime(block.start),
  end: makeTime(block.end),
});
export const combineTimeBlockAndDay = ({
  timeBlock: { start, end },
  day,
}: {
  timeBlock: TimeBlock;
  day: Date;
}): DateTimeBlock => ({
  start: combineTimeAndDay({ time: start, day }),
  end: combineTimeAndDay({ time: end, day }),
});

export const makeDateTimeBlockForClosestDayIndex = ({
  start,
  end,
  dayIndex,
}: TimeBlock & { dayIndex: Day }) =>
  combineTimeBlockAndDay({
    timeBlock: { start, end },
    day: getClosestDateForDayOfWeekPresentOrPast({ date: new Date(), dayOfWeek: dayIndex }),
  });

export const isDateTimeBlockEqual = (a: DateTimeBlock, b: DateTimeBlock) =>
  isEqual(a.start, b.start) && isEqual(a.end, b.end);

export const isStartOfHour = (date: Date) => isEqual(date, startOfHour(date));

export const friendlyDayFormat = (date: Date | number) => format(date, 'EEEE, LLLL d');
export const friendlyRelativeDayFormat = (date: Date | number) =>
  capitalize(head(formatRelative(date, new Date()).split(' at')));

export const friendlyHourFormat = (
  date: Date | number,
  { is24Hour = false }: { is24Hour?: boolean } = {},
) => {
  const hours = getHours(date);
  if (getMinutes(date) === 0 && (hours === 12 || hours === 24 || hours === 0))
    return capitalize(format(date, 'bbbb'));
  return format(date, is24Hour ? 'HH:mm' : 'h a');
};

export const friendlyTimeFormat = (
  date: Date | number,
  {
    is24Hour,
    shouldShowSeconds,
    timeZoneName = getIanaTimeZoneName(),
    shouldShowTz = false,
  }: { is24Hour?: boolean; shouldShowSeconds?: boolean; timeZoneName?: string; shouldShowTz?: boolean } = {},
) =>
  formatInTimeZone(
    date,
    timeZoneName,
    `${shouldShowSeconds ? (is24Hour ? 'HH:mm:ss' : 'h:mm:ss a') : is24Hour ? 'HH:mm' : 'h:mm a'} ${
      shouldShowTz ? 'z' : ''
    }`,
  );

export const friendlyDurationFormat = (
  durationMs: number,
  { shouldAlwaysShowHours = false }: { shouldAlwaysShowHours?: boolean } = {},
) =>
  lightFormat(
    addMilliseconds(new Date(0, 0, 0, 0), durationMs),
    shouldAlwaysShowHours || durationMs >= hoursToMilliseconds(1) ? 'HH:mm:ss' : 'mm:ss',
  );

export const isSameTimeOfDay = (a: Date, b: Date) =>
  getHours(a) === getHours(b) && getMinutes(a) === getMinutes(b);

export const getDurationMs = (block: DateTimeBlock | TimeBlock) =>
  getDurationMsForDateTimeBlock(dangerouslyCastTimeBlockToDate(block));
const getDurationMsForDateTimeBlock = ({ start, end }: DateTimeBlock) => differenceInMilliseconds(end, start);

export const roundToMinutes = (step: number) => (date: Date) =>
  roundToNearestMinutes(date, { nearestTo: step });

export const clampToBoundaryWithoutChangingDuration = ({
  block,
  boundary,
}: {
  block: DateTimeBlock;
  boundary: DateTimeBlock;
}) => {
  const durationMs = getDurationMs(block);
  if (block.start < boundary.start)
    return { start: boundary.start, end: addMilliseconds(boundary.start, durationMs) };
  if (block.end > boundary.end)
    return { start: subMilliseconds(boundary.end, durationMs), end: boundary.end };
  return block;
};

export const getBoundariesForMultiDayBoundary = memoize(
  ({ dates, boundary }: { dates: Date[]; boundary: TimeBlock }) =>
    dates.map((date) => combineTimeBlockAndDay({ timeBlock: boundary, day: date })),
);

export const makeMultiDayBoundary = ({
  dates,
  boundary: { start, end },
}: {
  dates: Date[];
  boundary: TimeBlock;
}) => ({
  start: combineTimeAndDay({ day: dates[0], time: start }),
  end: combineTimeAndDay({ day: last(dates)!, time: end }),
});

export const findAvailableTimeForDurationWithinBoundary = ({
  boundary,
  blocks,
  durationMs,
}: {
  boundary: DateTimeBlock;
  blocks: DateTimeBlock[];
  durationMs: number;
}) => {
  const sortedBlocks = sortBy(blocks, ({ start }) => Number(start));

  let cursorTime = boundary.start;
  for (const block of sortedBlocks) {
    if (differenceInMilliseconds(block.start, cursorTime) > durationMs) {
      return { start: cursorTime, end: addMilliseconds(cursorTime, durationMs) };
    }
    if (block.end > cursorTime) cursorTime = block.end;
  }

  return null;
};

export const getComponentTimeFromDate = (date: Date) => ({
  hours: getHours(date),
  minutes: getMinutes(date),
  seconds: getSeconds(date),
  milliseconds: getMilliseconds(date),
});

const formatDistanceLocale = {
  xSeconds: '{{count}} sec',
  xMinutes: '{{count}} min',
  xHours: '{{count}} hr',
  xDays: '{{count}} d',
  xWeeks: '{{count}} wk',
  xMonths: '{{count}} mo',
  xYears: '{{count}} yr',
};
const shortEnLocale = {
  formatDistance: (token: string, count: number) => formatDistanceLocale[token].replace('{{count}}', count),
};

export const friendlyDurationMsFormat = (durationMs: number) => {
  const now = new Date();
  return formatDuration(intervalToDuration({ start: now, end: addMilliseconds(now, durationMs) }), {
    locale: shortEnLocale,
  });
};

const shortDayFormatter = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  day: 'numeric',
  weekday: 'short',
});

export const friendlyShortDate = (date: Date) => {
  if (!isToday(date)) return shortDayFormatter.format(date);

  // Instead of `Wed, Jan 5`, this will return `Today, Jan 5`.
  return shortDayFormatter.formatToParts(date).map(({ type, value }) => {
    if (type === 'weekday') return 'Today';
    return value;
  });
};

export const getNextMinute = (date: Date = new Date()) =>
  addMinutes(set(date, { seconds: 0, milliseconds: 0 }), 1);

export const getNextSecond = (date: Date = new Date()) =>
  addSeconds(set(date, { milliseconds: 0 }), 1);

export const getMinutesOfDay = ({ step = 1, date = new Date() }: { step?: number; date?: Date } = {}) =>
  eachMinuteOfInterval({ start: startOfDay(date), end: endOfDay(date) }, { step });

export const getAllQuarterHours = ({ date = new Date() }: { date?: Date } = {}) =>
  getMinutesOfDay({ date, step: 15 });

export const DEFAULT_MEETING_DURATIONS_MS = [15, 20, 30, 40, 45, 55, 60, 75, 90, 120, 180, 240, 480].map(
  minutesToMilliseconds,
);

export const getClosestDateForDayOfWeekPresentOrPast = ({
  date,
  dayOfWeek,
}: {
  date: Date;
  dayOfWeek: number;
}) => {
  if (getDay(date) === dayOfWeek) {
    return startOfDay(date);
  }
  return startOfDay(previousDay(date, dayOfWeek));
};

export const getIanaTimeZoneName = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimeZoneOffsetMsRelativeToLocalTimeZone = ({
  localTimeZone = getIanaTimeZoneName(),
  remoteTimeZone,
}: {
  localTimeZone: string;
  remoteTimeZone: string;
}) => getTimezoneOffset(remoteTimeZone) - getTimezoneOffset(localTimeZone);

export const getShortTimeZoneName = (timeZone: string) => formatInTimeZone(new Date(), timeZone, 'zzz');
export const getLongTimeZoneName = (timeZone: string) => formatInTimeZone(new Date(), timeZone, 'zzzz');

/**
 *
 * Powers calendars that span multiple days, allowing the user to select the
 * days that are relevant to their week.
 *
 * Usage:
 *
 * ```
 * getCustomizableWeekForDate({
 *   date: new Date(),
 *   weekDays: [6, 0, 1, 3, 4],
 *   startDayOfWeek: 6,
 * });
 * // > [Fri Feb 4, Sun Feb 6, Mon Feb 7, Wed Feb 9, Thu Feb 10]
 * ```
 */
export const getCustomizableWeekForDate = ({
  date = new Date(),
  weekDays = [1, 2, 3, 4, 5],
  startDayOfWeek = 1,
}: {
  date: Date;
  weekDays: Day[];
  startDayOfWeek: number;
}) => {
  const startWeekBoundary = getClosestDateForDayOfWeekPresentOrPast({ date, dayOfWeek: startDayOfWeek });
  const endWeekBoundary = addWeeks(startWeekBoundary, 1);
  let dateCursor = startWeekBoundary;
  const week = [];

  while (dateCursor < endWeekBoundary) {
    if (weekDays.includes(getDay(dateCursor))) {
      week.push(dateCursor);
    }

    dateCursor = addDays(dateCursor, 1);
  }

  return week;
};

/**
 * Get a subset of a sorted array based on date.
 */
export const getSliceForBoundaryInSortedArray = <T>(
  arr: T[],
  boundary: DateTimeBlock,
  iteratee: (element: T) => any,
) =>
  arr.slice(
    getIndexForDateTime(arr, boundary.start, iteratee),
    getIndexForDateTime(arr, boundary.end, iteratee, sortedLastIndexBy),
  );

const getIndexForDateTime = <T>(
  arr: T[],
  time: Date,
  iteratee: (element: T) => any,
  sortIndexByFn = sortedIndexBy,
) =>
  sortIndexByFn<T | Date>(arr, time, (elementOrTime) =>
    isDate(elementOrTime) ? time : iteratee(elementOrTime),
  );

export function getYearMonthDateAsStringsFromTimestamp(ts: number) {
  const date = new Date(ts);
  return {
    year: date.getUTCFullYear().toString(),
    month: date.getUTCMonth().toString(),
    date: date.getUTCDate().toString(),
  };
}

export const dayToBoundary: (day: Date) => DateTimeBlock = (day) => ({
  start: startOfDay(day),
  end: endOfDay(day),
});

export const formatDistance = (ts: number | Date, nowTs = new Date(), { justNowThresholdSecs = 10 } = {}) =>
  Math.abs(differenceInSeconds(ts, nowTs)) < justNowThresholdSecs
    ? 'just now'
    : Math.abs(differenceInMinutes(ts, nowTs)) < 1
    ? ts < nowTs
      ? 'less than a minute ago'
      : 'in less than a minute'
    : formatDistanceDateFns(ts, nowTs, { addSuffix: true, includeSeconds: true });

export function humanizeEventInstanceId(eventInstanceId: EventInstanceId) {
  const { eventId, eventInstanceStartTs } = getEventIdAndInstanceStartTsFromEventInstanceId(eventInstanceId);
  if (!eventId) return 'no eventId';
  if (!eventInstanceStartTs) return 'no eventInstanceStartTs';
  return `${eventId.substring(eventId.length - 5)}: ${friendlyRelativeDayFormat(
    eventInstanceStartTs,
  )} ${friendlyHourFormat(eventInstanceStartTs)}`;
}
