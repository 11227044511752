import {
  createBrowserRouter,
  createMemoryRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from 'react-router-dom';

import SignIn from 'pages/vo/vo-react/web/routes/sign-in';
import { isDesktopApp } from 'utils/client-utils';
import { setRouter } from 'utils/get-router';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';

import { lazyRoutes as ro } from './route-definitions';

const voRoutes = [
  <Route path="vo" key="vo">
    <Route {...ro.RequireAuth}>
      <Route index {...ro.NavigateToPersonalOrgId} />
      <Route path=":orgId" {...ro.App}>
        <Route index {...ro.OrgPage} />
        <Route path="settings/*" {...ro.OrgSettings} />
        <Route path="teams/*">
          <Route index {...ro.OrgPage} />
          <Route path=":teamId">
            <Route path="transcript" {...ro.TeamTranscript} />
            <Route path="settings" {...ro.TeamSettings} />
          </Route>
          <Route path="new" {...ro.TeamSettings} />
          <Route path="all" {...ro.AllTeams} />
        </Route>
        <Route path="rooms">
          <Route index {...ro.OrgPage} />
          <Route path="new" {...ro.SpaceSettings} />
          <Route path="all" {...ro.AllSpaces} />
          <Route path=":spaceId">
            <Route path="settings" {...ro.SpaceSettings} />
          </Route>
        </Route>
        <Route path="instant-meetings">
          <Route index element={<Navigate to="new" />} />
          <Route path="new" {...ro.NewMeeting} />
          <Route path="join" {...ro.JoinMeeting} />
          <Route path=":instantMeetingId">
            <Route index {...ro.OrgPage} />
          </Route>
        </Route>
        <Route path="events">
          <Route index element={<Navigate to="../calendar" />} />
        </Route>
        <Route path="calendar">
          <Route path="edit" {...ro.EditAvailability} />
          <Route path="settings" {...ro.CalendarSettings} />
          <Route index {...ro.YourCalendar} />
        </Route>
        <Route path="session-history/:sessionHistoryId">
          <Route index {...ro.SessionHistoryOverview} />
          <Route path="transcript" {...ro.SessionHistoryTranscript} />
        </Route>
        <Route path="people/*">
          <Route path=":userId" {...ro.Person} />
          <Route path="invite" {...ro.InvitePerson} />
          <Route path="settings" {...ro.PeopleSettings} />
          <Route index {...ro.AllPeople} />
        </Route>
      </Route>
      <Route path=":orgId/schedule-meeting" {...ro.App}>
        <Route index {...ro.ScheduleMeeting} />
      </Route>
      <Route path="settings" {...ro.App}>
        <Route index {...ro.SettingsPage} />
        <Route path="audio-video" {...ro.AudioVideoSettings} />
        <Route path="screen-sharing" {...ro.ScreenSharingSettings} />
        <Route path="appearance" {...ro.AppearanceSettings} />
        <Route path="sound-effects" {...ro.SoundEffectsSettings} />
        <Route path="profile" {...ro.ProfileSettings} />
        <Route path="keyboard-shortcuts" {...ro.KeyboardShortcutsSettings} />
        <Route path="updates" {...ro.UpdatesSettings} />
        <Route path="misc" {...ro.MiscellaneousSettings} />
        <Route path="sign-out" {...ro.SignOutSettings} />
      </Route>

      <Route path="new-org" {...ro.App}>
        <Route index {...ro.NewOrg} />
      </Route>

      <Route path="app-onboarding" {...ro.AppOnboarding} />
    </Route>
  </Route>,
  <Route path="u" key="urls">
    <Route {...ro.RequireAuth}>
      <Route path="org-invite/:urlId" {...ro.AcceptOrgInvite}></Route>
    </Route>
  </Route>,
];

const createDesktopRouter = () => {
  const desktopRouter = createMemoryRouter(
    createRoutesFromElements(
      <>
        <Route path="/" {...ro.DesktopShell}>
          {/* TODO: replace with better sign in/out story for desktop app */}
          <Route path="signin" {...ro.SignIn} />
          <Route path="signout" {...ro.SignOut} />

          {voRoutes}
        </Route>
        <Route path="signin" element={<SignIn />}></Route>
      </>,
    ),
  );
  exposeToGlobalConsole({ desktopRouter });
  void desktopRouter.navigate('/vo');
  return desktopRouter;
};

const createWebAppRouter = () => {
  const webAppRouter = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route {...ro.WebShell}>
          <Route path="/" {...ro.Home} />
          <Route path="signup" {...ro.SignUp} />
          <Route path="signin" {...ro.SignIn} />
          <Route path="signout" {...ro.SignOut} />
          <Route path="open-desktop-app/:loginToken" {...ro.OpenDesktopApp} />
          <Route path="login" element={<Navigate to="/signin" />} />
          <Route path="logout" element={<Navigate to="/signout" />} />
          <Route path="verify-email" {...ro.VerifyEmail} />
          <Route path="verify-email/:email" {...ro.VerifyEmail} />
          <Route path="link-account/:email/code/:code" {...ro.LinkAccount} />
          <Route
            path="google-login-redirect/:customToken/redirectToApp/:redirectToApp"
            {...ro.GoogleLoginRedirect}
          />
          <Route path="google-login-redirect/:error" {...ro.GoogleLoginRedirect} />
          <Route path="reset-password" {...ro.ResetPassword} />
          <Route path="reset-password/:encodedEmail" {...ro.ResetPassword} />
          <Route path="reset-password/:encodedEmail/code/:code" {...ro.ResetPassword} />
          <Route path="download" {...ro.DownloadApp} />
          <Route path="download/:platform" {...ro.DownloadAppPlatform} />
          <Route {...ro.RequireAuth}>
            <Route path="/onboarding/*" element={<Outlet />}>
              <Route path="create-new-org" {...ro.OnboardingCreateNewOrg} />
              <Route path="invite" {...ro.OnboardingInvite} />
              <Route path="linked-invite" {...ro.OnboardingLinkedInvite} />
            </Route>
            <Route path="/pricing" {...ro.Pricing} />
          </Route>
        </Route>

        <Route {...ro.WebAppShell}>{voRoutes}</Route>
      </>,
    ),
  );
  return webAppRouter;
};

export const router = isDesktopApp ? createDesktopRouter() : createWebAppRouter();
setRouter(router);
