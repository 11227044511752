import { includes, reduce } from 'lodash';

import { UserId, UserStatus } from 'common/models/db/vo.interface';
import { VoUser } from 'pages/vo/vo-react/features/users/users.types';
import { debugCheck } from 'utils/debug-check';

import { createSlice } from '../../redux/create-slice';

export type UsersSlice = {
  selfId?: UserId;
  selfEmail?: string;
  byId: { [id: string]: VoUser };
  allIds: UserId[];
  selfUserStatusClearTs?: number;
  // Overrides selfUserStatus, useful for when you're in a session and then
  // leave, since we want to reset your status to whatever it was before
  // 'in-session'.
  selfUserStatusOverride?: UserStatus;
  selfPersonalContactLink?: string;
};

const initialState: UsersSlice = {
  byId: {},
  allIds: [],
};
const { createReducer, createSelector, createAction, createMemoizedSelector } = createSlice(
  'users',
  initialState,
);

export const addOrUpdateUser = createAction<Omit<VoUser, 'status' | 'timeZone'>>('addUser');
export const setSelfUserId = createAction<UserId | undefined>('setSelfUserId');
export const setSelfEmail = createAction<string | undefined>('setSelfEmail');
export const setSelfPersonalContactLink = createAction<string | undefined>('setSelfPersonalContactLink');
export const setUserStatus =
  createAction<{ userId: UserId; status: UserStatus; statusReason?: string }>('setUserStatus');
export const setSelfUserStatus = createAction<{
  status: UserStatus;
  statusReason?: string;
  selfUserStatusClearMs?: number;
}>('setSelfUserStatus');
export const setSelfUserStatusOverride = createAction<UserStatus | undefined>('setSelfUserStatusOverride');
export const listenToUser = createAction<{
  payload: {
    userId: UserId;
  };
  type: 'create' | 'destroy';
}>('listenToUser');

export default createReducer()
  .on(setSelfUserId, (state, { payload: userId }) => {
    state.selfId = userId;
  })
  .on(setSelfEmail, (state, { payload: email }) => {
    state.selfEmail = email;
  })
  .on(setSelfPersonalContactLink, (state, { payload: personalContactLink }) => {
    state.selfPersonalContactLink = personalContactLink;
  })
  .on(addOrUpdateUser, (state, { payload: user }) => {
    if (!includes(state.allIds, user.id)) state.allIds.push(user.id);
    state.byId[user.id] = {
      status: user.id === state.selfId ? 'available' : 'away',
      ...(state.byId[user.id] ? state.byId[user.id] : {}),
      avatarUrl: user.avatarUrl,
      presentUrl: user.presentUrl,
      awayUrl: user.awayUrl,
      displayName: user.displayName,
      id: user.id,
    };
  })
  .on(setUserStatus, (state, { payload: { userId, status, statusReason } }) => {
    debugCheck(!!state.byId[userId], `setUserStatus: User id doesn't exist in store: ${userId}`);
    state.byId[userId].status = status;
    state.byId[userId].statusReason = statusReason;
  })
  .on(setSelfUserStatus, (state, { payload: { status, statusReason, selfUserStatusClearMs } }) => {
    debugCheck(!!state.byId[state.selfId!], `setSelfUserStatus: selfId not found! ${state.selfId}`);
    state.byId[state.selfId!].status = status;
    state.byId[state.selfId!].statusReason = statusReason;
    state.selfUserStatusClearTs = selfUserStatusClearMs ? Date.now() + selfUserStatusClearMs : undefined;
  })
  .on(setSelfUserStatusOverride, (state, { payload: selfUserStatusOverride }) => {
    state.selfUserStatusOverride = selfUserStatusOverride;
  });
export const getUserById = createSelector((state, userId: UserId) => state.users.byId[userId]);
export const getSelfUserStatusReason = createSelector((state) =>
  getUserStatusReasonById(state, state.users.selfId!),
);
export const getSelfUserStatusClearTs = createSelector((state) => state.users.selfUserStatusClearTs);
export const getSelfUserStatusClearMs = createSelector((state) =>
  !state.users.selfUserStatusClearTs ? undefined : state.users.selfUserStatusClearTs - Date.now(),
);
export const getSelfUserStatus = createSelector(
  (state) => state.users.selfUserStatusOverride ?? getUserStatusById(state, getSelfUserId(state)!),
);
export const getUserStatusById = createSelector((state, userId: UserId) => state.users.byId[userId]?.status);
export const getUserStatusReasonById = createSelector(
  (state, userId: UserId) => state.users.byId[userId]?.statusReason,
);
export const getUserDisplayNameById = createSelector(
  (state, userId: UserId) => getUserById(state, userId)?.displayName,
);
export const getUserAvatarUrlById = createSelector(
  (state, userId: UserId) => getUserById(state, userId)?.avatarUrl,
);

export const getPresenceAvatarUrlById = createMemoizedSelector(
  getUserStatusById,
  getUserById,
  (status, user) => (status === 'away' ? user.awayUrl : user.presentUrl) ?? user.avatarUrl,
);

export const getSelfUserId = createSelector((state) => state.users.selfId);
export const getSelfUser = createSelector((state) => getUserById(state, getSelfUserId(state)!));
export const getSelfEmail = createSelector((state) => state.users.selfEmail);
export const getSelfPersonalContactLink = createSelector((state) => state.users.selfPersonalContactLink);

export const getUsersById = createSelector((state) => state.users.byId);

export const getAllUserDisplayNamesByIds = createMemoizedSelector(getUsersById, (usersById) =>
  reduce(
    usersById,
    (memo, { displayName }, userId) => {
      memo[userId] = displayName;
      return memo;
    },
    {} as { [userId: string]: string },
  ),
);
