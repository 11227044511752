import { each, filter, find, get, isNumber, last, map, mapValues, size, sum } from 'lodash';

import { MediaType } from 'common/models/connection.interface';
import { assertExhaustedType, prettifyRoomId } from 'common/utils/ts-utils';

import icons from 'assets/svg/icons';

export const meetingUrlPath = '/j/';

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('contenteditable', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  el.style.opacity = '0';
  document.body.appendChild(el);
  if (isMobile) {
    const range = document.createRange();
    range.selectNodeContents(el);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    el.setSelectionRange(0, str.length);
  } else {
    el.select();
  }
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const isNewIpad = (() =>
  !!navigator.userAgent.match(/(Intel Mac OS X)/) && matchMedia('(pointer:coarse)').matches)();

export const isAndroid = !!navigator.userAgent.match(/(Android)/);
export const isIos = !!navigator.userAgent.match(/(iPod|iPhone|iPad)/) || isNewIpad;
export const isMobileApp = !!get(window, 'cordova');
export const isMobilePwa = !!window.navigator['standalone'];
export const isMobile = isAndroid || isIos;
export const isMobileBrowser = isMobile && !isMobileApp;

export const isThirdPartyApp = window.parent !== window;

const isElectron = !!navigator.userAgent.match(/(Electron)/);
export const isObsoleteIteleport = !!navigator.userAgent.match(/Electron\/(3|4)/);
export const isDesktopApp = isElectron && !isThirdPartyApp;
export const isDesktopBrowser = !isDesktopApp && !isMobile;
export const isDesktop = isDesktopApp || isDesktopBrowser;
export const isApp = isMobileApp || isMobilePwa || isDesktopApp;
export const isBrowser = isMobileBrowser || isDesktopBrowser;

export const isChrome = isBrowser && !!window.navigator.userAgent.match(/Chrome/);
export const isSafari = isBrowser && !isChrome && !!window.navigator.userAgent.match(/Safari/);
export const isFirefox = isBrowser && !!window.navigator.userAgent.match(/Firefox/);

export const isTestMode = window.location.search.indexOf('isTestMode=1') !== -1;
export const isLocalhost = window.location.hostname === 'localhost';
export const isNgrok = window.location.host.indexOf('ngrok.io') !== -1;
export const isLocalhostNgServe = (isLocalhost && window.location.port === '8101') || isNgrok;
export const isDev = isLocalhost || window.location.host.startsWith('dev.') || isNgrok;
export const is3dDomain =
  true || window.location.host.startsWith('3d.') || window.location.search.indexOf('cube') !== -1;
export const isStorybook = window.location.pathname.includes('iframe');
export const shouldShowAdditionalErrors = (isDev || isLocalhost) && !isStorybook;
export const isUnsupportedBrowser =
  window.location.protocol === 'https:' &&
  (!get(navigator, 'mediaDevices.getUserMedia') ||
    !window['RTCPeerConnection'] ||
    !!window.navigator.userAgent.match(/Edge/));
export const isScreenDotSoDomain = window.location.hostname.indexOf('screen.so') !== -1;

export const isWindows = !!navigator.userAgent.match(/(Windows)/);
export const isMac = !!navigator.userAgent.match(/(Macintosh)/) && !isNewIpad;
export const isLinux = !isAndroid && !!navigator.userAgent.match(/(Linux)/);
export const isLinuxRedhat = isLinux && !!navigator.userAgent.match(/(Red Hat)/);
export const isLinuxUbuntu = isLinux && !!navigator.userAgent.match(/(Ubuntu|Debian)/);
export const isChromeOs = !!navigator.userAgent.match(/CrOS/);

export const isScreenView = !!window.location.href.match(/\/j\//);
export const isDesktopBrowserWindow = isScreenView || !!window.location.href.match(/\/desktop/);
export const isDownload = !!window.location.href.match(/\/download/);

export const isM1 = (() => {
  try {
    // from: https://stackoverflow.com/questions/65146751/detecting-apple-silicon-mac-in-javascript
    const w = document.createElement('canvas').getContext('webgl');
    const d = w.getExtension('WEBGL_debug_renderer_info');
    const g = (d && w.getParameter(d.UNMASKED_RENDERER_WEBGL)) || '';
    return /Apple/.test(g);
  } catch (error) {
    return false;
  }
})();

export const isScreenShareSupported = isDesktopApp || !!get(navigator, 'mediaDevices.getDisplayMedia');
// safari can't do the fancy 64xxx codec that mediasoup uses (?)
// and chrome M1 can't either (https://bugs.chromium.org/p/chromium/issues/detail?id=1238290&q=m1%20h264&can=2)
export const canOnlyUseBaselineH264Codec = isSafari || isM1;

// need to update. -mh.
export const urls = {
  // ios: 'https://itunes.apple.com/us/app/scrn-remote-desktop/id1462027265?mt=8',
  // android: 'https://play.google.com/store/apps/details?id=app.screen.so',
  linux: `download/linux`,
  darwin: `download/mac`,
  win32: `download/windows`,
};

export const isOrientationPortrait = () => !window.orientation || window.orientation === 180;
const getCoreNotchSizes = () => ({
  top: parseInt(getComputedStyle(document.body).getPropertyValue('padding-top').replace('px', ''), 10),
  bottom: parseInt(getComputedStyle(document.body).getPropertyValue('padding-bottom').replace('px', ''), 10),
  left: parseInt(getComputedStyle(document.body).getPropertyValue('padding-left').replace('px', ''), 10),
  right: parseInt(getComputedStyle(document.body).getPropertyValue('padding-right').replace('px', ''), 10),
});
export const getNotchSizes = () => {
  const notchSizes = getCoreNotchSizes();
  return {
    top: (notchSizes.top * 30) / 44,
    left: (notchSizes.left * 30) / 44,
    right: (notchSizes.right * 30) / 44,
    bottom: notchSizes.bottom,
  };
};
export const cityBlockDistance = (pos1, pos2) =>
  sum(map(mapValues(pos1, (val, dim) => Math.abs(pos2[dim] - val))));
export const getIosVersion = () => {
  if (!isIos) return;
  if (isNewIpad) return '13.3.999';
  const version = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return `${parseInt(version[1], 10)}.${parseInt(version[2], 10)}.${parseInt(version[3] || '0', 10)}`;
};

export const fakeVideoUrl = 'https://rawgit.com/bower-media-samples/big-buck-bunny-480p-30s/master/video.mp4';
export const fakeVerticalVideoUrl =
  'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/dev%2Ffake-vertical.mp4?alt=media&token=2426c0a7-2bb8-41ac-b935-4924d4b0edc8';
export function getFakeVideoUrl() {
  const fakeCloudUrl =
    'https://firebasestorage.googleapis.com/v0/b/scrn-prod.appspot.com/o/dev%2Ffake.mov?alt=media&token=cde9c990-978d-4ab5-9544-8603af5481cc';
  const fakeLocalUrl = '/assets/fake.mov';
  const isLocalUrl =
    window.location.hostname === 'localhost' ||
    !find(window.location.hostname.split('.'), (chunk) => !isNumber(chunk));
  return isLocalUrl ? fakeLocalUrl : fakeCloudUrl;
}

export function escapeText(text, isBackwards = false) {
  if (isBackwards)
    return text
      .replace(/&amp;/g, '&')
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<')
      .replace(/&quot;/g, '"');
  return text.replace(/&/g, '&amp;').replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/"/g, '&quot;');
}

export function markdownToMrkdwn(markdown) {
  const initialSlackifiedMrkdwn = map(markdown.split('\n'), (ogLine) => {
    let line = ogLine;
    const checkboxMatch = line.match(/^(\s*)[*-]\s+\[(\s|x)\](.*)/);
    if (size(checkboxMatch) === 4) {
      line = `${checkboxMatch[1]}${checkboxMatch[2] === 'x' ? '✅' : '⬜️'}${checkboxMatch[3]}`;
    }
    const bulletMatch = line.match(/^(\s*)[*-](\s+)(.*)/);
    if (size(bulletMatch) === 4) line = `${bulletMatch[1]}•${bulletMatch[2]}${bulletMatch[3]}`;
    return line;
  }).join('\n');
  // const libSlackifiedMrkdwn = slackifyMarkdown(initialSlackifiedMrkdwn).trim();
  const libSlackifiedMrkdwn = initialSlackifiedMrkdwn.trim();
  const blankCharRemovedMrkdown = filter(libSlackifiedMrkdwn, (c) => c.charCodeAt(0) !== 0x200b).join('');
  const emojiConvertedMrkdwn = blankCharRemovedMrkdown
    .replace('✅', ':white_check_mark:')
    .replace('⬜️', ':white_large_square:');
  const mrkdwn = emojiConvertedMrkdwn;
  return mrkdwn;
}

export function mrkdwnToMarkdown(mrkdwn) {
  const mrkdwnToMarkdownFormatters = [
    { from: '\\*', to: '**' },
    { from: '\\~', to: '~~' },
    { from: '`', to: '`', shouldUnescape: true },
  ] as { from: string; to: string; shouldUnescape?: boolean }[];
  return map(mrkdwn.split('\n'), (originalLine) => {
    let line = originalLine;
    line = line.replace(/```/g, '\n```\n');
    each(mrkdwnToMarkdownFormatters, (m) => {
      let transform = (t) => t;
      if (m.shouldUnescape) transform = (t) => escapeText(t, true);
      line = line.replace(
        new RegExp(`(?:${m.from})(.+)(?:${m.from})`, 'g'),
        (wm, t) => `${m.to}${transform(t)}${m.to}`,
      );
    });

    return line;
  }).join('\n');
}

export function sanitizeMarkdown(markdown: string) {
  const DISALLOWED_PROTOCOL_PATTERN = /(file|tel|sms|ftp|mailto):\/\/?/gi;
  const matches = [...markdown.matchAll(DISALLOWED_PROTOCOL_PATTERN)];
  each(matches, () => (markdown = markdown.replace(DISALLOWED_PROTOCOL_PATTERN, `disallowed-protocol:`)));
  return markdown;
}

export const colors = [
  '#4FBB3D',
  '#E6A600',
  '#F17575',
  '#5F82DD',
  '#CF5FCB',
  '#777777',
  '#CED200',
  '#00CCCC',
  '#9B97C2',
];

export const cameraAndMicTypes = ['camera', 'mic'] as const;
export const micAndCameraTypes = ['mic', 'camera'] as const;
export const allMediaTypes = [...cameraAndMicTypes, 'screen'] as const;
export const allDeviceTypes = [...allMediaTypes, 'speakers'] as const;
export const cameraMicAndSpeakerTypes = [...cameraAndMicTypes, 'speakers'] as const;
export function historyPushStateWithoutQueryParams() {
  const locationWithRemovedQueryParams = [
    window.location.origin,
    window.location.pathname,
    window.location.hash,
  ].join('');
  history.pushState(null, '', locationWithRemovedQueryParams);
}

export function getVideoTagNameForPeerIdAndMediaType(peerId: string, mediaType: MediaType) {
  return `${peerId}-${mediaType}`;
}

export function getJoinUrlForRoomId(roomId: string, shouldSkipProtocol = false) {
  return `${shouldSkipProtocol ? location.hostname : location.origin}${meetingUrlPath}${prettifyRoomId(
    roomId,
  )}`;
}

export const soundsRootFolder = '/assets/sounds';

export type Icon = keyof typeof icons;

export const referenceEqualityFn = (a: any, b: any) => a === b;
export type EqualityFn = typeof referenceEqualityFn;

export const zoomJoinUriToDesktopUri = (joinUri: string) => {
  const url = new URL(joinUri);
  const meetingId = last(url.pathname.split('/'));
  const password = url.searchParams.get('pwd');
  const desktopUri = `zoommtg://${url.hostname}/join?action=join&confno=${meetingId}&pwd=${password}`;
  return desktopUri;
};
export const clientAssertExhaustedType = (x: never) => assertExhaustedType(x, shouldShowAdditionalErrors);
// Lifted from https://stackoverflow.com/questions/6877403/how-to-tell-if-a-video-element-is-currently-playing
export const isVideoPlaying = (video: HTMLVideoElement) =>
  video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;

export const getDebugStackTrace = () => {
  let trace: any;
  try {
    throw new Error();
  } catch (e: unknown) {
    trace = (e as any).stack;
  }
  return trace;
};
