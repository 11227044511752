import { forwardRef, HTMLAttributes, RefAttributes } from 'react';

import { fontLargeProps, fontBodyProps, fontSmallProps } from 'utils/react/fonts';

import { BasePopUiComponent, BaseProps } from './base-pop-ui-component';

export const LargeText = forwardRef(
  (
    { children, ...etc }: { children?: any } & BaseProps & HTMLAttributes<any> & RefAttributes<any>,
    forwardedRef,
  ) => (
    <BasePopUiComponent ref={forwardedRef} as="span" {...fontLargeProps} {...(etc as any)}>
      {children}
    </BasePopUiComponent>
  ),
);
export const BodyText = forwardRef(
  (
    { children, ...etc }: { children?: any } & BaseProps & HTMLAttributes<any> & RefAttributes<any>,
    forwardedRef,
  ) => (
    <BasePopUiComponent ref={forwardedRef} as="span" {...fontBodyProps} {...(etc as any)}>
      {children}
    </BasePopUiComponent>
  ),
);
export const SmallText = forwardRef(
  (
    { children, ...etc }: { children?: any } & BaseProps & HTMLAttributes<any> & RefAttributes<any>,
    forwardedRef,
  ) => (
    <BasePopUiComponent ref={forwardedRef} as="span" {...fontSmallProps} {...(etc as any)}>
      {children}
    </BasePopUiComponent>
  ),
);
