import { from, NEVER, skip, tap } from 'rxjs';

import { filterIsFalsey, mapSelector, ignoreElements, pluck } from 'common/utils/custom-rx-operators';
import { flatRectToRect } from 'common/utils/geometry-utils';
import { getCurrentWindow } from 'pages/vo/vo-react/desktop-window';
import { getIsSelfScreenShareGuest } from 'pages/vo/vo-react/features/media/media.slice';
import { getLocalSetting } from 'pages/vo/vo-react/features/settings/settings.slice';
import { sendIpc } from 'pages/vo/vo-react/ipc/send-ipc';
import { EpicWithDeps } from 'pages/vo/vo-react/redux/app-store';
import { isDesktopApp } from 'utils/client-utils';

export const listenToShowOrHideVoWindowWhenVoFeatureFlagChangedEpic: EpicWithDeps = (action$, state$) =>
  !isDesktopApp
    ? NEVER
    : from(sendIpc('getAppLaunchInfo')).pipe(
        pluck('didAppLaunchAfterNinjaUpdate'),
        filterIsFalsey(),
        tap(() =>
          getCurrentWindow('vo')?.setBoundsElectron(
            flatRectToRect(getLocalSetting(state$.value, 'voWindowBounds')),
          ),
        ),
        ignoreElements(),
      );

export const listenToShowVoWindowWhenGuestScreenShareEndsEpic: EpicWithDeps = (action$, state$) =>
  !isDesktopApp
    ? NEVER
    : state$.pipe(
        mapSelector(getIsSelfScreenShareGuest),
        filterIsFalsey(),
        skip(1),
        tap(() => getCurrentWindow('vo')?.show()),
        ignoreElements(),
      );
