import { Point, Rectangle, Size } from './geometry.interface';

export type TrafficLightButton = 'close' | 'minimize' | 'focus' | 'maximize' | 'fullscreen' | 'restore';

export const allWindowTypes = ['core', 'main', 'primary', 'secondary', 'loading', 'overlay', 'vo'] as const;
export type WindowType = (typeof allWindowTypes)[number];

export interface SetNativeWindowOptions {
  windowType: WindowType;
  size?: Size;
  origin?: Point;
  maxSize?: Partial<Size>;
  minSize?: Partial<Size>;
  shouldShow?: boolean;
  shouldFocus?: boolean;
  isAlwaysOnTop?: boolean;
  shouldToggleDevTools?: boolean;
  trafficLightButtonClicked?: TrafficLightButton;
  shouldAnimate?: boolean;
  reason?: string;
  shouldClose?: boolean;
  moveToTop?: boolean;
}

export interface SetWindowInfoOptions {
  windowType: WindowType;
  isVisible?: boolean;
  isFullScreen?: boolean;
  isMinimized?: boolean;
  isMaximized?: boolean;
  isFocused?: boolean;
  bounds?: Rectangle;
  screenBounds?: Rectangle;
  screenBoundsWorkArea?: Rectangle;
}

export type InfoPanel =
  | 'menu'
  | 'av-settings'
  | 'invite'
  | 'statistics'
  | 'advanced-settings'
  | 'chat'
  | 'bug-report';

export function widthForInfoPanel(infoPanel?: InfoPanel) {
  return infoPanel ? 250 : 0;
}

export const defaultMainWindowSize: Size = { width: 350, height: 413 };
export const defaultVoWindowSize: Size = { width: 500, height: 564 };
