import { compact, differenceBy, flatMap, uniq, uniqBy, values } from 'lodash';

import { CalendarId } from 'common/models/db/calendar.interface';
import { EventInstanceId } from 'common/models/db/event.interface';
import {
  InstantMeetingSessionInitiator,
  JoinableSessionInitiator,
} from 'common/models/db/session-initiatior.interface';
import { InstantMeetingId, OrgId, UserId } from 'common/models/db/vo.interface';
import {
  getCalendarId,
  getEventInstanceIdsBetweenBoundaryForCalendarIds,
  getEventInstances,
} from 'pages/vo/vo-react/features/calendar/calendar.slice';
import { getTeamIdsForSelfByOrgId } from 'pages/vo/vo-react/features/common/get-team-ids-for-self-by-org-id';
import {
  getAllInstantMeetingIds,
  getInstantMeetingById,
} from 'pages/vo/vo-react/features/instant-meetings/instant-meetings.slice';
import {
  getOrgIds,
  getOrgUserCurrentJoinableSessionInitiatorsById,
  getPersonalOrgId,
} from 'pages/vo/vo-react/features/orgs/orgs.slice';
import { getAllJoinableSessionInitiatorsFromRingsForOrgId } from 'pages/vo/vo-react/features/rings/rings.slice';
import { getAllSpaceIds, getSpaceById } from 'pages/vo/vo-react/features/spaces/spaces.slice';
import {
  getAllTeamIds,
  getTeamById,
  getTeamMemberIdsByTeamId,
} from 'pages/vo/vo-react/features/teams/teams.slice';
import { RootReduxState } from 'pages/vo/vo-react/redux/app-store';
import { WithoutEmptyObject } from 'utils/react-utils';

import {
  createGlobalMemoizedIsEqualSelector,
  createGlobalMemoizedSelector,
  createGlobalSelector,
} from '../../redux/create-slice';
import { getSelfUserId } from '../auth/auth.slice';
import { getCurrentUrlParams } from '../navigation/navigation.utils';

export const getJoinableSessionInitiatorAndOrgIdForNavigatedSession = createGlobalMemoizedSelector(
  () => getCurrentUrlParams(),
  (params) => {
    const isNavigatedToSession =
      params?.orgId && (params?.instantMeetingId || (params?.eventInstanceId && params?.calendarId));
    if (!isNavigatedToSession) return;
    const orgId = params?.orgId as OrgId;
    if (params?.instantMeetingId)
      return {
        orgId,
        joinableSessionInitiator: {
          type: 'instant-meeting',
          id: params.instantMeetingId as InstantMeetingId,
        } as InstantMeetingSessionInitiator,
      };
    const eventInstanceId = params!.eventInstanceId as EventInstanceId;
    const calendarId = params!.calendarId as CalendarId;
    const joinableSessionInitiator: JoinableSessionInitiator = {
      type: 'event-instance',
      id: eventInstanceId,
      calendarId,
    };
    return { orgId: params!.orgId as OrgId, joinableSessionInitiator: joinableSessionInitiator };
  },
);

const getCurrentEventInstanceSessionInitiators = createGlobalSelector((state, { orgId, boundary }) => {
  const selfUserId = getSelfUserId(state);
  if (!selfUserId) return [];
  const calendarId = getCalendarId(state, { userId: selfUserId, orgId });
  const currentEventSessionInitiators = !boundary
    ? []
    : (
        getEventInstanceIdsBetweenBoundaryForCalendarIds(state, {
          calendarIds: [calendarId],
          boundary,
        }) ?? []
      ).map((eventInstanceId) => ({
        type: 'event-instance',
        id: eventInstanceId,
        calendarId,
      }));
  return currentEventSessionInitiators;
});

// Used by OrgPage to determine which events / instant meetings to show
export const getJoinableSessionInitiatorsForSelfAndSelfTeamsByOrgId = createGlobalMemoizedIsEqualSelector(
  (state: WithoutEmptyObject<RootReduxState>) => state,
  getSelfUserId,
  getCurrentEventInstanceSessionInitiators,
  getAllJoinableSessionInitiatorsFromRingsForOrgId,
  getJoinableSessionInitiatorAndOrgIdForNavigatedSession,
  (state: WithoutEmptyObject<RootReduxState>, { orgId }: { orgId: OrgId }) =>
    getTeamIdsForSelfByOrgId(state, orgId),
  (_state: WithoutEmptyObject<RootReduxState>, { orgId }: { orgId: OrgId }) => orgId,
  (
    state,
    selfUserId,
    currentEventSessionInitiators,
    ringJoinableSessionInitiators,
    sessionInitiatorAndOrgIdForNavigatedSession,
    teamIds,
    orgId,
  ) => {
    if (!selfUserId) return [];
    const allSelfAndSelfTeamUserIds = getUserIdsForSelfAndSelfTeamsByOrgId(state, orgId);
    const sessionInitiators = flatMap(allSelfAndSelfTeamUserIds, (userId) =>
      getOrgUserCurrentJoinableSessionInitiatorsById(state, orgId, userId),
    );

    const sessionInitiatorsWithNavigatedSession = compact([
      ...sessionInitiators,
      ...currentEventSessionInitiators,
      ...ringJoinableSessionInitiators,
      ...(!sessionInitiatorAndOrgIdForNavigatedSession ||
      sessionInitiatorAndOrgIdForNavigatedSession.orgId !== orgId
        ? []
        : [sessionInitiatorAndOrgIdForNavigatedSession.joinableSessionInitiator]),
    ]);
    const sessionInitiatorsWithoutJoinedTeams = !sessionInitiatorsWithNavigatedSession
      ? []
      : !teamIds
      ? sessionInitiatorsWithNavigatedSession
      : differenceBy(
          sessionInitiatorsWithNavigatedSession,
          teamIds.map((id) => ({ type: 'team', id })),
          ({ id }) => id,
        );
    const uniqueSessionInitiators = uniqBy(sessionInitiatorsWithoutJoinedTeams, 'id');
    return uniqueSessionInitiators as JoinableSessionInitiator[];
  },
);

// TODO: this needs to be optimized since it's O(n^2) for all joinable session
// initiators in an org, for all orgs
export const getAllSelfAndSelfTeamsUserCurrentSessionInitiatorAndOrgIdPairs = createGlobalSelector(
  (state) => {
    const orgIds = getOrgIds(state);
    const currentSessionInitiatorAndOrgIdPairs = orgIds.flatMap((orgId) =>
      getJoinableSessionInitiatorsForSelfAndSelfTeamsByOrgId(state, { orgId }).map((sessionInitiator) => ({
        orgId,
        sessionInitiator,
      })),
    );
    return currentSessionInitiatorAndOrgIdPairs;
  },
);

// TODO: this needs to be optimized since it's O(n^2) for all users in an org,
// for all orgs
export const getAllSelfAndSelfTeamsUserIdAndOrgIdPairs = createGlobalSelector((state) => {
  const selfUserId = getSelfUserId(state);
  if (!selfUserId) return [];
  const orgIds = getOrgIds(state);
  const personalOrgId = getPersonalOrgId(state);
  const userIdAndOrgIdPairs = flatMap(orgIds, (orgId) =>
    orgId === personalOrgId
      ? [{ orgId, userId: selfUserId }]
      : getUserIdsForSelfAndSelfTeamsByOrgId(state, orgId).map((userId) => ({ orgId, userId })),
  );
  return userIdAndOrgIdPairs;
});

// TODO: this needs to be optimized since it's O(n^2) for all users in a team,
// for all teams in an org
const getUserIdsForSelfAndSelfTeamsByOrgId = createGlobalSelector((state, orgId: OrgId) => {
  const selfUserId = getSelfUserId(state);
  if (!selfUserId) return [];
  const teamIds = getTeamIdsForSelfByOrgId(state, orgId);
  const userIds = teamIds.reduce((memo, teamId) => {
    const userIds = getTeamMemberIdsByTeamId(state, teamId);
    Array.prototype.push.apply(memo, userIds);
    return memo;
  }, [] as UserId[]);

  return uniq([selfUserId, ...userIds]);
});

// TODO: this needs to be optimized since it's O(n^2) for all session initiators in an org,
// for all orgs
export const getAllSessionGroupIdAndOrgIdPairs = createGlobalSelector((state) => {
  const teams = getAllTeamIds(state).map((teamId) => getTeamById(state, teamId));
  const spaces = getAllSpaceIds(state).map((spaceId) => getSpaceById(state, spaceId));
  const instantMeetings = getAllInstantMeetingIds(state).map((instantMeetingId) =>
    getInstantMeetingById(state, instantMeetingId),
  );
  const events = values(getEventInstances(state));
  return flatMap([teams, spaces, instantMeetings, events], (sessionGroupContainer) =>
    sessionGroupContainer.map(({ sessionGroupId, orgId }) => ({ sessionGroupId, orgId })),
  );
});
