import autoBind from 'auto-bind';
import { each } from 'lodash';
import { filter, firstValueFrom, BehaviorSubject } from 'rxjs';

import { MediaType } from 'common/models/connection.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import { P2pGenericSignalingMessage } from 'common/models/floof.interface';
import { filterIsTruthy } from 'common/utils/custom-rx-operators';
import { allMediaTypes, isFirefox, isMobile } from 'utils/client-utils';
import { FloofAnalyticsDelegate } from 'utils/floof-sdk/floof-sdk';
import { DataChannelMessage } from 'utils/floof-sdk/media-connection/p2p/p2p-media-connection';
import {
  P2pUnidirectionalConnection,
  P2pUnidirectionalConnectionDelegate,
} from 'utils/floof-sdk/media-connection/p2p/p2p-unidirectional-connection';

interface P2pSendConnectionDelegate extends P2pUnidirectionalConnectionDelegate {
  onTrackSent: (info: { localTrackId: string; trackTransportId: string | null }) => void;
}

export class P2pSendConnection extends P2pUnidirectionalConnection {
  private isScreenShareHostOrGuest = false;
  private didSendOffer$ = new BehaviorSubject(false);
  private transceivers?: { [mediaType in MediaType]: RTCRtpTransceiver };
  private lastEmittedTransportIdForTrackId: { [localTrackId: string]: string } = {};
  constructor(
    protected selfPeerId: RemotePeerId,
    protected remotePeerId: RemotePeerId,
    protected delegate: P2pSendConnectionDelegate,
    protected analyticsDelegate: FloofAnalyticsDelegate | undefined,
  ) {
    super('send', selfPeerId, remotePeerId, delegate, analyticsDelegate);
    autoBind(this);
  }

  public async sendDataChannelMessage(message: DataChannelMessage) {
    if (!this.didOpenDataChannel$.value)
      await firstValueFrom(this.didOpenDataChannel$.pipe(filterIsTruthy()));
    try {
      this.dataChannel!.send(JSON.stringify(message));
    } catch (error: any) {
      console.error('Error sending message:', error?.message || error);
    }
  }

  public async connect() {
    await super.connect();

    if (!this.connection) throw new Error('connect: Expected connection to exist');
    this.connection.onnegotiationneeded = async () => this.sendOffer();

    const transceiverDirection: { direction: RTCRtpTransceiverDirection } = {
      direction: isFirefox ? 'inactive' : 'sendonly',
    };

    this.transceivers = {
      mic: this.connection.addTransceiver('audio', { ...transceiverDirection }),
      camera: this.connection.addTransceiver('video', { ...transceiverDirection }),
      screen: this.connection.addTransceiver('video', { ...transceiverDirection }),
    };
  }

  private maybeEmitTrackSent(localTrackId: string | undefined, trackTransportId: string | null) {
    if (!localTrackId) return;
    let shouldEmit = false;
    if (!trackTransportId && this.lastEmittedTransportIdForTrackId[localTrackId]) {
      shouldEmit = true;
      delete this.lastEmittedTransportIdForTrackId[localTrackId];
    } else if (trackTransportId && !this.lastEmittedTransportIdForTrackId[localTrackId]) {
      shouldEmit = true;
      this.lastEmittedTransportIdForTrackId[localTrackId] = trackTransportId;
    }
    if (!shouldEmit) return;
    this.delegate.onTrackSent({
      localTrackId,
      trackTransportId,
    });
  }

  public async replaceOrRemoveTrack(mediaType: MediaType, track: MediaStreamTrack | undefined) {
    await firstValueFrom(this.didSendOffer$.pipe(filterIsTruthy()));
    if (!this.transceivers) throw new Error('replaceOrRemoveTrack: transceivers is undefined!');
    const oldTrack = this.transceivers[mediaType].sender.track;
    if (oldTrack === track) return;

    // on leave room, p2pPeers is empty, so be extra defensive
    const sender = this.transceivers[mediaType].sender;
    await sender.replaceTrack(track || null);
    this.maybeEmitTrackSent(track?.id || oldTrack?.id, this.transceivers[mediaType].mid);

    // ensure transceiver direction is sendonly on Firefox if track not null
    if (track && isFirefox && this.transceivers[mediaType].direction === 'inactive')
      this.transceivers[mediaType].direction = 'sendonly';

    if (track && mediaType === 'camera') {
      await this.setCameraMaxBitrate();
    }
    return this.transceivers[mediaType].mid;
  }

  private async setCameraMaxBitrate() {
    await firstValueFrom(
      this.connectionState$.pipe(filter((connectionState) => connectionState === 'connected')),
    );
    if (!this.transceivers) throw new Error('setCameraMaxBitrate: transceivers is undefined!');

    const sender = this.transceivers.camera.sender;
    const params = sender.getParameters() as RTCRtpSendParameters;
    const maxMbps = this.isScreenShareHostOrGuest ? 0.2 : isMobile ? 0.5 : 3;
    each(params.encodings, (encoding) => (encoding.maxBitrate = maxMbps * 1000 * 1000));
    await sender.setParameters(params);
  }

  private async sendOffer({ shouldRestartIce = false } = {}) {
    if (!this.connection) throw new Error('connect: Expected connection to exist');
    const id = Math.floor(Math.random() * 100000); // we set the ID here, always on the send side
    this.id$.next(id);
    console.log(`sendOffer(): creating offer, iceRestart: ${shouldRestartIce}, id: ${id}`);
    const ogOffer = await this.connection.createOffer({ iceRestart: shouldRestartIce });
    const offer = this.mungeSdpDesc(ogOffer, { isLocal: true, isOffer: true });
    await this.connection.setLocalDescription(offer);

    each(
      allMediaTypes,
      (mediaType) =>
        this.transceivers &&
        this.maybeEmitTrackSent(
          this.transceivers[mediaType].sender.track?.id,
          this.transceivers[mediaType].mid,
        ),
    );
    this.didSendOffer$.next(true);

    this.remoteDescription$.next(undefined);
    await this.delegate.onGenericMessageSendRequested({
      type: 'offer',
      id,
      offer,
      recipientDirection: 'receive',
    });

    const originalRemoteAnswerDesc = await firstValueFrom(this.remoteDescription$.pipe(filterIsTruthy()));
    const mungedRemoteAnswerDesc = this.mungeSdpDesc(originalRemoteAnswerDesc);
    if (!this.connection) throw new Error('connect: Expected connection to exist');
    await this.connection.setRemoteDescription(mungedRemoteAnswerDesc);
  }

  public async receiveGenericMessage(message: P2pGenericSignalingMessage) {
    if (message.type === 'answer') {
      if (message.id !== this.id$.value)
        return console.warn('received answer for stale p2p id, discarding…', message.id, this.id$.value);
      return this.remoteDescription$.next(message.answer);
    }
    await super.receiveGenericMessage(message);
  }

  public async disconnect() {
    each(
      allMediaTypes,
      (mediaType) =>
        this.transceivers && this.maybeEmitTrackSent(this.transceivers[mediaType].sender.track?.id, null),
    );

    await super.disconnect();
  }
}
