import { Point } from 'common/models/geometry.interface';
import { isPointInBounds } from 'common/utils/geometry-utils';

import { RootReduxState } from '../../redux/app-store';
import { createGlobalMemoizedSelector } from '../../redux/create-slice';
import { getAllScreensKeyedById } from '../screens/screens.slice';

import { getScreenTracksForPeerIds } from './media.slice';

export const getSelfScreenTrackIdsForMousePosition = createGlobalMemoizedSelector(
  getAllScreensKeyedById,
  (state: RootReduxState) => getScreenTracksForPeerIds(state, { peerIds: ['self'] }),
  (_state: RootReduxState, position: Point) => position,
  (allScreens, tracks, position) => {
    if (tracks.length === 0) return [];
    if (tracks.length === 1) return [tracks[0].logicalTrackId];
    if (!position) return tracks.map(({ logicalTrackId }) => logicalTrackId);
    const matchedTracks = tracks.filter(({ deviceId }) =>
      isPointInBounds(position, allScreens[deviceId].bounds),
    );
    return matchedTracks.map((track) => track.logicalTrackId);
  },
);
