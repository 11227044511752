import { map, filter, merge } from 'rxjs';

import { ofAction } from 'common/utils/custom-rx-operators';

import { EpicWithDeps } from '../../redux/app-store';
import { mirrorActionToAllPeers } from '../remote-actions/remote-actions.actions';

import { addMessage } from './chat.slice';

export const mirrorChatActionsToPeersEpic: EpicWithDeps = (action$, state$) =>
  merge(
    action$.pipe(ofAction(addMessage)).pipe(filter((action) => action.payload.message.peerId === 'self')),
  ).pipe(map((action) => mirrorActionToAllPeers(action)));
