import { ComponentProps, forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link as ReactRouterDomLink } from 'react-router-dom';

import { FlexBoxProps, FlexBox } from './flex-box';

export const RelativeLink = forwardRef(
  ({ children, ...etc }: ComponentProps<typeof ReactRouterDomLink> & FlexBoxProps, forwardedRef) => (
    <FlexBox ref={forwardedRef} inline as={ReactRouterDomLink} {...etc}>
      {children}
    </FlexBox>
  ),
);
