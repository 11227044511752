import { map } from 'rxjs';

import { observeMatchesMedia } from 'common/utils/observe-matches-media';

import { SharedEpic } from '../../redux/shared-store';

import { setSystemDarkMode } from './environment.slice';

export const systemDarkModeEpic: SharedEpic = () =>
  observeMatchesMedia('(prefers-color-scheme: dark)').pipe(
    map((isDarkMode) => setSystemDarkMode(isDarkMode)),
  );
