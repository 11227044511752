import autoBind from 'auto-bind';
import { each, mapValues } from 'lodash';

import { Transport, TransportContents } from 'common/models/floof.interface';
import { CountDiscriminator } from 'utils/count-discriminator';
import { TransportConfig } from 'utils/floof-sdk/floof-sdk-internal.interface';

interface PreferredTransportDelefgate {
  onPreferredTransportChanged: (transportContents: TransportContents, preferredTransport: Transport) => void;
}

/**
 * This class is responsible for deciding which transport should be used for
 * each transport content. These can be different for product and/or technical
 * reasons: for example, we want to ensure audio is delivered with low-latency,
 * but video is less important, so we transmit peer-to-peer audio while sending
 * SFU video (up to and depending on a certain `n` number of peers).
 */
export class PreferredTransport {
  private transportPreferences = mapValues(
    this.config.maxPeersForP2pForTransportContent,
    (maxPeersForP2p, transportContents: TransportContents) =>
      new CountDiscriminator({
        discriminator: maxPeersForP2p,
        valueWhenLessThanOrEqualTo: 'p2p' as const,
        valueWhenGreaterThan: 'sfu' as const,
        onChanged: (preferredTransport) =>
          this.delegate.onPreferredTransportChanged(transportContents, preferredTransport),
      }),
  );

  constructor(private config: TransportConfig, private delegate: PreferredTransportDelefgate) {
    autoBind(this);
    (window as any).preferredTransport = this;
  }

  public updatePeerCount(peerCount: number) {
    each(this.transportPreferences, (transportPreference) => {
      transportPreference.updateCount(peerCount);
    });
  }
}
