import { secondsToMilliseconds } from 'date-fns';
import { addedDiff, deletedDiff, updatedDiff } from 'deep-object-diff';
import {
  DataSnapshot,
  endAt,
  startAfter,
  startAt,
  endBefore,
  orderByChild,
  orderByKey,
  EventType,
  onChildAdded,
  onChildChanged,
  onChildMoved,
  onChildRemoved,
  query as fbQuery,
  Query,
} from 'firebase/database';
import { getDatabase, ref, get, set, remove, push, update, onValue } from 'firebase/database';
import {
  assign,
  cloneDeep,
  fromPairs,
  identity,
  isEmpty,
  map as lodashMap,
  mapKeys,
  pickBy,
  size,
} from 'lodash';
import { BehaviorSubject, EMPTY, merge, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  finalize,
  map,
  pairwise,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { Object } from 'ts-toolbelt';
import { AutoPath } from 'ts-toolbelt/out/Function/AutoPath';
import { Split } from 'ts-toolbelt/out/String/Split';

import {
  DbRecordToIdPushIdPrefix,
  PushIdPrefixToId,
  TypedFirebaseDatabase,
} from 'common/models/db/db.interface';
import { log } from 'common/utils/custom-rx-operators';
import { isStorybook } from 'utils/client-utils';
import { getFirebaseApp } from 'utils/firebase-app';

import { debugCheck } from './debug-check';
import { MockRxFirebaseDbWrapper } from './mock-firebase-db-wrapper-client';

type NoRepetition<U extends string, ResultT extends any[] = []> =
  | ResultT
  | {
      [k in U]: NoRepetition<Exclude<U, k>, [k, ...ResultT]>;
    }[U];

export class RxFirebaseDbWrapper<S = TypedFirebaseDatabase> {
  constructor(private path?: string, private queryArgs: any[] = []) {}

  /** {@link TypedFirebaseDatabase} */
  from<T extends string, R extends Object.Path<S, Split<T, '/'>>>(
    path: T extends AutoPath<S, infer _U, '/'>
      ? R extends undefined
        ? undefined extends R
          ? never
          : T
        : T
      : never,
  ) {
    return new RxFirebaseDbWrapper<R>([this.path, path].join('/'), this.queryArgs);
  }

  ref() {
    const dbRef = ref(getDatabase(getFirebaseApp()), this.path);

    // useful for debugging permission errors:
    // (window as any).refs = (window as any).refs ?? {};
    // (window as any).refs[this.path] = dbRef;

    return dbRef;
  }

  queryRef() {
    return fbQuery(this.ref(), ...this.queryArgs);
  }

  query(...args) {
    this.queryArgs = [...this.queryArgs, ...args];
    return this;
  }

  whenChanged() {
    // Firebase may over time lose permissions for certain refs, which is fine,
    // and shouldn't be counted as an error. However, if the user doesn't have
    // permission on start, that's an error we do want to report. Hence, we only
    // show errors if we've never successfully made our way through the pipe at
    // least once.
    let didSucceed = false;
    return loggedFirebaseFromEvent(this.queryRef(), 'value').pipe(
      map((snapshot: DataSnapshot) => ({
        key: snapshot.key!,
        val: snapshot.val() as S,
        eventType: 'value_changed' as const,
      })),
      tap(() => (didSucceed = true)),
      catchError((e: unknown) => {
        if (!didSucceed) debugCheck((e as any)?.message ?? e);
        return EMPTY;
      }),
    );
  }

  whenChild<T extends NoRepetition<'added' | 'changed' | 'removed'>>(...eventNames: T) {
    // Firebase may over time lose permissions for certain refs, which is fine,
    // and shouldn't be counted as an error. However, if the user doesn't have
    // permission on start, that's an error we do want to report. Hence, we only
    // show errors if they occur within the first 5 seconds of the listener
    // (since whenChild doesn't fire until data changes).
    const startTs = Date.now();
    return merge(
      ...lodashMap(eventNames, (eventName) =>
        loggedFirebaseFromEvent(this.queryRef(), `child_${eventName}` as const).pipe(
          map((snapshot: DataSnapshot) => ({
            key: snapshot.key!,
            val: snapshot.val() as S extends any[] ? S[number] : S[keyof S],
            eventType: eventName as T[number],
          })),
          catchError((e: unknown) => {
            if (Date.now() - startTs < secondsToMilliseconds(5)) debugCheck((e as any)?.message ?? e);
            return EMPTY;
          }),
        ),
      ),
    );
  }

  // copied from cloud version of this file

  async value() {
    const snapshot = await get(this.queryRef());
    return snapshot.val() as S;
  }

  async exists() {
    const snapshot = await get(this.ref());
    return snapshot.exists();
  }

  async set(val: S | null) {
    await set(this.ref(), val);
  }

  async remove() {
    await remove(this.ref());
  }

  getPushKeyWithPrefix<T extends DbRecordToIdPushIdPrefix<S>>(prefix: T) {
    const key = push(this.ref()).key;
    return `${prefix}${key}` as PushIdPrefixToId[T];
  }

  async update<T extends string>(
    ...args: ('type-expanded-to-any-string' extends T
      ? never
      : T extends AutoPath<S, infer _U, '/'>
      ? [T, Object.Path<S, Split<T, '/'>> | null]
      : never)[]
  ) {
    await update(this.ref(), fromPairs(args));
  }
}

export const db = isStorybook
  ? (new MockRxFirebaseDbWrapper() as unknown as RxFirebaseDbWrapper)
  : new RxFirebaseDbWrapper();

const mapFromEventTypeToFunctionName: { [eventName in EventType]: typeof onValue } = {
  value: onValue,
  child_added: onChildAdded,
  child_changed: onChildChanged,
  child_moved: onChildMoved,
  child_removed: onChildRemoved,
};

/**
 * The built-in `fromEvent` doesn't quite fit with the error handling API that
 * firebase exposes, so do it ourselves.
 */
const firebaseFromEvent = (ref: Query, eventName: EventType) =>
  new Observable((obs) =>
    mapFromEventTypeToFunctionName[eventName](
      ref,
      (val) => obs.next(val),
      (err) => obs.error(err),
    ),
  );

/**
 * Wrappping an observable: https://stackoverflow.com/questions/64035614/wrapping-rxjs-observable-to-do-something-before-and-after-for-example-show-hide
 */
export const loggedFirebaseFromEvent = (ref: Query, eventName: EventType) => {
  const refString = ref.toString();
  return of(undefined).pipe(
    tap(() => {
      if (!dbListeners[refString]) dbListeners[refString] = {};
      dbListeners[refString][eventName] = (dbListeners[refString][eventName] ?? 0) + 1;
      dbListenersUpdated$.next();
    }),
    switchMap(() => firebaseFromEvent(ref, eventName)),
    finalize(() => {
      dbListeners[refString][eventName]--;
      if (!dbListeners[refString][eventName]) delete dbListeners[refString][eventName];
      if (!size(dbListeners[refString])) delete dbListeners[refString];
      dbListenersUpdated$.next();
    }),
  );
};

const dbListeners = {};
const dbListenersUpdated$ = new BehaviorSubject<void>(undefined);
(window as any).db = db;
// TODO: Remove this when we're closer to public beta, keeping it for now since it helps with debugging
assign(window as any, { endAt, startAfter, startAt, endBefore, orderByChild, orderByKey });
(window as any).dbListeners = dbListeners;
(window as any).logDbListeners = () =>
  void ((window as any).stopLogDbListeners = dbListenersUpdated$
    .pipe(
      map(() => cloneDeep(dbListeners)),
      debounceTime(500),
      startWith({}),
      pairwise(),
      map(([prev, curr]) =>
        mapKeys(
          pickBy(
            {
              added: addedDiff(prev, curr),
              removed: deletedDiff(prev, curr),
              updated: updatedDiff(prev, curr),
            },
            (diff) => !isEmpty(diff),
          ),
          (val, key) => `${key} (${size(val)})`,
        ),
      ),
      filter((obj) => !isEmpty(obj)),
      log(() => `db (${size(dbListeners)})`, identity),
    )
    .subscribe().unsubscribe);
