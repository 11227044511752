import { Subject } from 'rxjs';

import { LocalIagoEvent } from 'common/models/iago-event.interface';

const firehose$ = new Subject<LocalIagoEvent>();

export const iago$ = firehose$.asObservable();

export const sendEventToIagoFirehose = (event: LocalIagoEvent) => {
  firehose$.next(event);
};
