import * as CSS from 'csstype';
import { isString, merge, omit } from 'lodash';
import { memo, forwardRef, ComponentProps, Component } from 'react';

import { popColor } from 'utils/react/colors';
import { fontBodyProps } from 'utils/react/fonts';
import { useMatchesMedia } from 'utils/react/use-matches-media';
import { ReactChildren } from 'utils/react-utils';

import { interpretPassedIconAsElement } from '../icon';

import { BaseProps } from './base-pop-ui-component';
import { FlexBoxProps, FlexBoxWithFocusVisibleStyles } from './flex-box';

export type ButtonType = 'primary' | 'secondary' | 'secondary-strokeless' | 'transparent' | 'link';

const maybeWrapStringNode = (componentOrTextNode: string | ReactChildren) =>
  isString(componentOrTextNode) ? <span>{componentOrTextNode}</span> : componentOrTextNode;

const defaultIconColorForButtonType: { [buttonType in ButtonType]: CSS.Properties['color'] } = {
  primary: popColor('background'),
  secondary: popColor('grey2'),
  'secondary-strokeless': popColor('grey2'),
  transparent: popColor('grey2'),
  link: popColor('grey2'),
};

export const Button = memo(
  forwardRef(
    (
      {
        icon,
        children,
        trailingIcon,
        fullWidth,
        small,
        shadow,
        type = 'primary',
        iconSize = small ? '20px' : '24px',
        trailingIconSize,
        iconColor,
        trailingIconColor,
        shouldSkipHoverStyles = false,
        as = 'button',
        inputType,
        ...etc
      }: {
        icon?: JSX.Element | Component;
        iconSize?: CSS.Properties['width'];
        iconColor?: CSS.Properties['color'];
        children?: ReactChildren;
        trailingIcon?: JSX.Element | Component;
        trailingIconSize?: CSS.Properties['width'];
        trailingIconColor?: CSS.Properties['color'];
        fullWidth?: boolean;
        small?: boolean;
        shadow?: boolean;
        shouldSkipHoverStyles?: boolean;
        type?: ButtonType;
        inputType?: HTMLButtonElement['type'];
      } & FlexBoxProps,
      ref: any,
    ) => (
      <FlexBoxWithFocusVisibleStyles
        alternateFocusBorderColor={type === 'primary' ? popColor('background') : undefined}
        as={as}
        gap="4px"
        justifyContent={fullWidth ? 'center' : 'space-around'}
        direction="row"
        alignItems="center"
        inline={!fullWidth}
        padding={type === 'transparent' || type === 'link' ? '0' : small ? '8px' : '4px 12px'}
        height={small ? '32px' : '40px'}
        {...(fullWidth && { width: '100%' })}
        {...(type !== 'link' && { fontWeight: 'bold' })}
        borderRadius="8px"
        {...fontBodyProps}
        border="1px solid transparent"
        boxSizing="border-box"
        webkitAppRegion="no-drag"
        whiteSpace="nowrap"
        ref={ref}
        type={inputType ?? ''}
        {...(shadow && { boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)' })}
        {...merge(
          {},
          shouldSkipHoverStyles ? omit(buttonStyles[type], 'hover') : buttonStyles[type],
          type === 'primary' && {
            hover: {
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${
                etc.background ?? popColor('primary1')
              }`,
            },
          },
          etc.disabled && disabledButtonStyle,
          etc,
        )}
      >
        {interpretPassedIconAsElement({
          icon,
          color: iconColor ?? (etc.disabled ? popColor('grey4') : defaultIconColorForButtonType[type]),
          size: iconSize,
        })}
        {/* Make sure flexbox can do its thing if the child is a string. */}
        {maybeWrapStringNode(children)}
        {interpretPassedIconAsElement({
          icon: trailingIcon,
          size: trailingIconSize ?? iconSize,
          color:
            trailingIconColor ??
            iconColor ??
            (etc.disabled ? popColor('grey4') : defaultIconColorForButtonType[type]),
        })}
      </FlexBoxWithFocusVisibleStyles>
    ),
  ),
);

export type ButtonProps = ComponentProps<typeof Button>;

const buttonStyles: { [buttonType in ButtonType]: Partial<BaseProps> } = {
  primary: {
    color: popColor('background'),
    background: popColor('primary1'),
  },
  secondary: {
    color: popColor('grey1'),
    background: popColor('grey5'),
    border: `1px solid ${popColor('grey3')}`,
    hover: {
      background: popColor('background'),
    },
  },
  'secondary-strokeless': {
    color: popColor('grey1'),
    background: popColor('background'),
    hover: {
      border: `1px solid ${popColor('grey3')}`,
    },
  },
  transparent: {
    background: 'transparent',
  },
  link: {
    background: 'transparent',
    textDecoration: 'underline',
  },
};

const baseDisabledButtonStyle: Partial<BaseProps> = {
  color: popColor('grey3'),
  background: popColor('grey5'),
  borderColor: 'transparent',
  pointerEvents: 'none',
};
const disabledButtonStyle = {
  ...baseDisabledButtonStyle,
  hover: baseDisabledButtonStyle,
};

export const useIsWindowSizedForCompactButtons = () => useMatchesMedia('(max-width: 600px)');
