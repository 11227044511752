const productionReleases = ['ci', 'dev', 'prod'];
export const isTrulyProd = 'prod' === process.env.FIREBASE_ENV;
export const isProd = productionReleases.includes(process.env.FIREBASE_ENV!);
export const productName = process.env.FIREBASE_ENV === 'prod' ? 'Pop' : 'Pop-' + process.env.FIREBASE_ENV;
export const protocolName = productName.toLowerCase();
export const debOrRpmProductName = productName.toLowerCase();
export const sentryEnabledProjects = ['pop', 'pop-dev'];

const sentryConfig = {
  ci: {
    dsn: 'https://66923787017e48298671ee7555f810be@sentry.io/1468774',
  },
  prod: {
    dsn: 'https://14086186447e413aa45aab3ec61c5456@sentry.io/1466383',
  },
  j: {
    dsn: 'https://d34ce388dd34426fb25b6272a55e42a0@sentry.io/1468776',
  },
  dev: {
    dsn: 'https://08d8ffc13ce84d4fada504069ee6d736@sentry.io/1763817',
  },
  mh: {
    dsn: 'https://96e831ba9ff84e54b94f22d5f84786e9@sentry.io/1763258',
  },
};

export const crashReporterConfig = sentryConfig[process.env.FIREBASE_ENV as 'dev' | 'prod'];
