import autoBind from 'auto-bind';
import { firstValueFrom } from 'rxjs';

import { MediaType } from 'common/models/connection.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import { P2pGenericSignalingMessage } from 'common/models/floof.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { filterIsTruthy } from 'common/utils/custom-rx-operators';
import { FloofAnalyticsDelegate } from 'utils/floof-sdk/floof-sdk';
import { DataChannelMessage } from 'utils/floof-sdk/media-connection/p2p/p2p-media-connection';
import { P2pUnidirectionalConnection } from 'utils/floof-sdk/media-connection/p2p/p2p-unidirectional-connection';

export interface P2pReceiveConnectionDelegate {
  onTrackReceived: (info: { track: MediaStreamTrack; mediaType: MediaType; trackTransportId: string }) => any;
  onMessageReceived: (message: PeerMessage) => any;
  onGenericMessageSendRequested: (message: P2pGenericSignalingMessage) => void;
}

export class P2pReceiveConnection extends P2pUnidirectionalConnection {
  constructor(
    protected selfPeerId: RemotePeerId,
    protected remotePeerId: RemotePeerId,
    protected delegate: P2pReceiveConnectionDelegate,
    protected analyticsDelegate: FloofAnalyticsDelegate | undefined,
  ) {
    super(
      'receive',
      selfPeerId,
      remotePeerId,
      { onGenericMessageSendRequested: delegate.onGenericMessageSendRequested },
      analyticsDelegate,
    );
    autoBind(this);
  }

  public async connect() {
    await super.connect();
    if (!this.connection) throw new Error('connect: Expected connection to exist');
    if (!this.dataChannel) throw new Error('connect: Expected dataChannel to exist');
    this.connection.ontrack = async ({ track, transceiver, receiver }) => {
      const mediaType = transceiver.mid === '0' ? 'mic' : transceiver.mid === '1' ? 'camera' : 'screen';
      const stats = await receiver.getStats();
      let ssrc: any;
      stats.forEach((stat) => {
        if (!stat.ssrc || ssrc) return;
        ssrc = stat.ssrc;
      });
      if (transceiver.mid === null) throw new Error('received transceiver with null mid');
      this.delegate.onTrackReceived({ track, mediaType, trackTransportId: transceiver.mid });
    };

    this.dataChannel.onmessage = ({ data }) => {
      try {
        const dataChannelMessage = JSON.parse(data) as DataChannelMessage;
        if (dataChannelMessage.type !== 'send-peer-message')
          return console.error(`unknown data channel message: ${dataChannelMessage.type}`);
        const { peerMessage } = dataChannelMessage;
        this.delegate.onMessageReceived(peerMessage);
      } catch (error) {
        console.log('datachannel: error on message:', error);
      }
    };
  }

  public async receiveGenericMessage(message: P2pGenericSignalingMessage) {
    if (message.type === 'offer') return this.receiveOffer(message.id, message.offer);
    await super.receiveGenericMessage(message);
  }

  private async receiveOffer(id: number, desc: RTCSessionDescription) {
    this.id$.next(id);
    await firstValueFrom(this.connectionState$.pipe(filterIsTruthy()));
    if (!this.connection) throw new Error('receiveOffer: Expected connection to exist');
    console.log(`receiveOffer(): ${this.remotePeerId} with id: ${id}`);
    await this.connection.setRemoteDescription(desc);
    this.remoteDescription$.next(desc);

    const ogAnswer = await this.connection.createAnswer();
    const answer = this.mungeSdpDesc(ogAnswer, { isLocal: true });
    await this.connection.setLocalDescription(answer);
    await this.delegate.onGenericMessageSendRequested({
      id,
      answer,
      type: 'answer',
      recipientDirection: 'send',
    });
  }
}
