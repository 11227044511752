import { EMPTY } from 'rxjs';

export class MockRxFirebaseDbWrapper {
  constructor() {}

  from() {
    return this;
  }

  ref() {
    return null;
  }

  queryRef() {
    return null;
  }

  query() {
    return this;
  }

  whenChanged() {
    return EMPTY;
  }

  whenChild() {
    return EMPTY;
  }

  async value() {
    return;
  }

  async exists() {
    return false;
  }

  async set() {
    return;
  }

  async remove() {
    return;
  }

  getPushKeyWithPrefix() {
    return 'test';
  }

  async update() {
    return;
  }
}
