import loadable from '@loadable/component';
import { Suspense } from 'react';

import { isLocalhostNgServe } from 'utils/client-utils';

const LocalhostDevHelperInternal = loadable(() => import('./localhost-dev-helper-internal'));

export const LocalhostDevHelper = () =>
  !isLocalhostNgServe ? null : (
    <Suspense fallback={<></>}>
      <LocalhostDevHelperInternal />
    </Suspense>
  );
