import autoBind from 'auto-bind';

import { MediaType } from 'common/models/connection.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import { P2pGenericSignalingMessage } from 'common/models/floof.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { FloofAnalyticsDelegate } from 'utils/floof-sdk/floof-sdk';
import { P2pReceiveConnection } from 'utils/floof-sdk/media-connection/p2p/p2p-receive-connection';
import { P2pSendConnection } from 'utils/floof-sdk/media-connection/p2p/p2p-send-connection';

export type DataChannelMessage = DataChannelMessageSendPeerMessage;

export interface DataChannelMessageSendPeerMessage {
  type: 'send-peer-message';
  peerMessage: PeerMessage;
}

export interface P2pMediaConnectionDelegate {
  onTrackSent: (info: { localTrackId: string; trackTransportId: string | null }) => void;
  onTrackReceived: (info: { track: MediaStreamTrack; mediaType: MediaType; trackTransportId: string }) => any;
  onMessageReceived: (message: PeerMessage) => any;
  onGenericMessageSendRequested: (message: P2pGenericSignalingMessage) => void;
}

export class P2pMediaConnection {
  protected sendConnection = new P2pSendConnection(
    this.selfPeerId,
    this.remotePeerId,
    {
      onGenericMessageSendRequested: this.delegate.onGenericMessageSendRequested,
      onTrackSent: (info) => this.delegate.onTrackSent(info),
    },
    this.analyticsDelegate,
  );
  protected receiveConnection = new P2pReceiveConnection(
    this.selfPeerId,
    this.remotePeerId,
    {
      onGenericMessageSendRequested: this.delegate.onGenericMessageSendRequested,
      onMessageReceived: this.delegate.onMessageReceived,
      onTrackReceived: this.delegate.onTrackReceived,
    },
    this.analyticsDelegate,
  );
  constructor(
    protected selfPeerId: RemotePeerId,
    protected remotePeerId: RemotePeerId,
    protected delegate: P2pMediaConnectionDelegate,
    protected analyticsDelegate: FloofAnalyticsDelegate | undefined,
  ) {
    autoBind(this);
    void this.connect();
  }
  public sendDataChannelMessage = async (message: DataChannelMessage) =>
    await this.sendConnection.sendDataChannelMessage(message);
  public receiveGenericMessage = async (message: P2pGenericSignalingMessage) => {
    const connection =
      message.recipientDirection === 'receive' ? this.receiveConnection : this.sendConnection;
    await connection.receiveGenericMessage(message);
  };
  public sendTrack = async (track: MediaStreamTrack | undefined, mediaType: MediaType) =>
    this.sendConnection.replaceOrRemoveTrack(mediaType, track);

  private connect = async () =>
    await Promise.all([this.sendConnection, this.receiveConnection].map(({ connect }) => connect()));
  public disconnect = () =>
    [this.sendConnection, this.receiveConnection].map(({ disconnect }) => disconnect());
}
