import { uniqueId } from 'lodash';
import { noop } from 'lodash';

export class ResourcePool<Resource, Args extends any[] | []> {
  private pool: Resource[] = [];
  private active: { [id: string]: Resource } = {};
  private create: (...args: Args) => Resource;
  private destroy: (resource: Resource) => void;
  private idleTimeoutMs: number;
  private minIdle: number;
  private evictionTimeoutHandle: number | undefined = undefined;

  constructor({
    create,
    destroy = noop,
    idleTimeoutMs = 0,
    minIdle = 0,
  }: {
    create: (...args: Args) => Resource;
    destroy?: (resource: Resource) => void;
    idleTimeoutMs?: number;
    minIdle?: number;
  }) {
    this.create = create;
    this.destroy = destroy;
    this.idleTimeoutMs = idleTimeoutMs;
    this.minIdle = minIdle;
    this.ensureIdleResources({ desiredIdleResourceCount: this.minIdle, delayFn: requestIdleCallback });
  }

  public acquire(...args: Args) {
    this.ensureIdleResources({ desiredIdleResourceCount: 1, createArgs: args });
    const id = uniqueId();
    const resource = this.pool.shift()!;
    this.active[id] = resource;
    return { id, resource };
  }

  public release(id: string) {
    if (!this.active[id]) return;

    const resource = this.active[id];
    delete this.active[id];

    this.pool.push(resource);
    this.scheduleEvictionCheck();
  }

  public peekFirstActiveResource() {
    return Object.values(this.active)[0];
  }

  private scheduleEvictionCheck() {
    clearInterval(this.evictionTimeoutHandle);
    this.evictionTimeoutHandle = window.setTimeout(() => this.evictIdlePoolResources(), this.idleTimeoutMs);
  }

  private evictIdlePoolResources(keepNumIdleResources: number = this.minIdle) {
    if (!this.pool.length) return;

    const evicted = this.pool.splice(0, this.pool.length - keepNumIdleResources);
    evicted.forEach((resource) => {
      this.destroy(resource);
    });
  }

  private ensureIdleResources({
    desiredIdleResourceCount,
    delayFn = invokeFnWithoutDelay,
    createArgs = [] as Args,
  }: {
    desiredIdleResourceCount: number;
    delayFn?: typeof invokeFnWithoutDelay;
    createArgs?: Args;
  }) {
    delayFn(() => {
      while (this.pool.length < desiredIdleResourceCount) {
        const resource = this.create(...createArgs);
        this.pool.push(resource);
      }
    });
  }

  public drain() {
    Object.keys(this.active).forEach((resourceId) => this.release(resourceId));
    this.evictIdlePoolResources(0);
  }
}

const invokeFnWithoutDelay = (fn: () => any) => fn();
