import { compact, size, uniq, values } from 'lodash';

import { SessionHistoryId } from 'common/models/db/session-history.interface';
import {
  isEventSessionInitiator,
  isInstantMeetingSessionInitiator,
  isSpaceSessionInitiator,
  isTeamSessionInitiator,
  SessionInitiator,
} from 'common/models/db/session-initiatior.interface';
import { SessionId } from 'common/models/db/vo.interface';
import { getSessionTypeById } from 'pages/vo/vo-react/features/common/session-selectors';
import { VoPeer } from 'pages/vo/vo-react/features/sessions/sessions.types';
import { getUserDisplayNameById } from 'pages/vo/vo-react/features/users/users.slice';
import { clientAssertExhaustedType } from 'utils/client-utils';

import { createGlobalSelector } from '../../redux/create-slice';
import { getNameForEvent } from '../calendar/calendar.slice';
import {
  getInstantMeetingNameById,
  getInstantMeetingUsersById,
} from '../instant-meetings/instant-meetings.slice';
import {
  getPeersForSessionHistory,
  getStoredNameForSessionHistory,
} from '../session-history/session-history.slice';
import {
  getJoinedSessionPeersBySessionId,
  getSessionInitiatorForSession,
  getSessionNameById,
  getSessionPeersBySessionId,
} from '../sessions/sessions.slice';
import { getSessionById } from '../sessions/sessions.slice';
import { getSpaceNameById } from '../spaces/spaces.slice';
import { getTeamNameById } from '../teams/teams.slice';

export const getNameForSession = createGlobalSelector((state, sessionId: SessionId) => {
  const sessionName = getSessionNameById(state, sessionId);
  if (sessionName) return sessionName;
  const sessionInitiator = getSessionInitiatorForSession(state, sessionId);
  if (!sessionInitiator) return;
  if (isTeamSessionInitiator(sessionInitiator)) {
    const sessionType = getSessionTypeById(state, sessionId);
    if (!sessionType) return 'Breakout room';
    return sessionType === 'conference'
      ? 'Breakout room'
      : sessionType === 'walkie-talkie'
      ? 'Walkie-talkie'
      : clientAssertExhaustedType(sessionType);
  }
  if (isInstantMeetingSessionInitiator(sessionInitiator)) {
    const instantMeetingName = getInstantMeetingNameById(state, sessionInitiator.id);
    if (instantMeetingName) return instantMeetingName;
    const userIds = getInstantMeetingUsersById(state, sessionInitiator.id);
    const displayNames = uniq(compact(userIds.map((userId) => getUserDisplayNameById(state, userId))));
    if (displayNames.length > 0) {
      const name = displayNames.length === 1 ? `${displayNames[0]}’s meeting` : displayNames.join(', ');
      return name;
    }
  }
  const sessionInitiatorName = getNameForSessionInitiator(state, sessionInitiator);
  if (sessionInitiatorName) return sessionInitiatorName;
  const session = getSessionById(state, sessionId);
  if (session?.name) return session.name;
  const joinedPeers = getJoinedSessionPeersBySessionId(state, sessionId);
  const name =
    getNameFromPeers(joinedPeers) ?? getNameFromPeers(values(getSessionPeersBySessionId(state, sessionId)));
  return name;
});

const getNameFromPeers = (peers: VoPeer[]) => {
  const displayNames = uniq(compact((peers ?? []).map((peer) => peer.displayName)));
  if (!size(displayNames)) return;
  const name = displayNames.length === 1 ? `${displayNames[0]}’s meeting` : displayNames.join(', ');
  return name;
};

export const getNameForSessionHistory = createGlobalSelector((state, sessionHistoryId: SessionHistoryId) => {
  const titledName = getStoredNameForSessionHistory(state, sessionHistoryId);
  if (titledName) return titledName;
  const peers = getPeersForSessionHistory(state, sessionHistoryId);
  const name = getNameFromPeers(peers);
  return name;
});

export const getNameForSessionInitiator = createGlobalSelector(
  (state, sessionInitiator: SessionInitiator) => {
    if (isInstantMeetingSessionInitiator(sessionInitiator))
      return getInstantMeetingNameById(state, sessionInitiator.id);
    if (isSpaceSessionInitiator(sessionInitiator)) return getSpaceNameById(state, sessionInitiator.id);
    if (isTeamSessionInitiator(sessionInitiator)) return getTeamNameById(state, sessionInitiator.id);
    if (isEventSessionInitiator(sessionInitiator)) return getNameForEvent(state, sessionInitiator.id);
    clientAssertExhaustedType(sessionInitiator);
  },
);
