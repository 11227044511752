export interface Deferred<TValue> extends Promise<TValue> {
  resolve(value: TValue): void;
  reject(reason: string | Error): void;
  reject(): void;
}

export function createDeferred<TValue>(): Deferred<TValue> {
  let resolver: any;
  let rejecter: any;
  const deferred: Deferred<TValue> = new Promise((res, rej) => {
    resolver = res;
    rejecter = rej;
  }) as any;
  deferred.resolve = resolver;
  deferred.reject = rejecter;
  return deferred;
}
