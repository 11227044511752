export function displayNameToInitials(displayName) {
  const initials = (displayName || '')
    .split(' ')
    .map((word) => word[0])
    .join('')
    .replace(/[^a-zA-Z]/g, '')
    .toUpperCase();
  if (initials.length >= 3) return `${initials[0]}${initials[initials.length - 1]}`;
  return initials;
}
