import { memoize, partial } from 'lodash';
import { Store } from 'redux';
import { Observable, distinctUntilChanged, from, map, shareReplay } from 'rxjs';

export const makeHotObservableFromStore = memoize(<S extends Store>(store: S) =>
  // eslint-disable-next-line rxjs/no-sharereplay
  from(store).pipe(shareReplay(1)),
);

export const observeStoreWithSelector = <S extends Store, R, Args extends any[]>(
  store: S,
  selectorFn: (state: ReturnType<S['getState']>, ...args: Args) => R,
  ...args: Args
): Observable<R> => {
  const partialedSelector = (partial as any)(selectorFn, partial.placeholder, ...args);
  return makeHotObservableFromStore(store).pipe(map(partialedSelector) as any, distinctUntilChanged());
};
