import { noop } from 'lodash';

import {
  allJoinSoundIds,
  allLeaveSoundIds,
  OUTGOING_DECLINED_URL,
  OUTGOING_RING_URL,
  PTT_URL,
  RINGTONE_URL,
  SoundId,
  WAVE_URL,
} from 'pages/vo/vo-react/features/sound-effects/sound-effects.types';
import { soundsRootFolder } from 'utils/client-utils';

const sounds: { [id in SoundId]?: HTMLAudioElement } = {};

export const preloadSounds = () => {
  const createAudio = (url: string, { canLoop = false } = {}) => {
    const audio = new Audio();
    audio.addEventListener('canplaythrough', noop, false);
    audio.src = url;
    audio.volume = 0.5;
    if (canLoop) audio.loop = true;
    return audio;
  };

  allJoinSoundIds.map((id) => (sounds[id] = createAudio(`${soundsRootFolder}/pop/${id}.ogg`)));
  allLeaveSoundIds.map((id) => (sounds[id] = createAudio(`${soundsRootFolder}/whoosh/${id}.ogg`)));
  sounds['incoming-ringtone'] = createAudio(RINGTONE_URL, { canLoop: true });
  sounds['outgoing-ring'] = createAudio(OUTGOING_RING_URL);
  sounds['outgoing-declined'] = createAudio(OUTGOING_DECLINED_URL);
  sounds['ptt'] = createAudio(PTT_URL);
  sounds['wave'] = createAudio(WAVE_URL);
};

export const playSound = (soundId: SoundId, speakerDeviceId?: string) => {
  const sound = sounds[soundId];
  if (!sound) return console.error(`soundEffects:playSound(): unknown soundId: ${soundId}`);
  if (!speakerDeviceId) return;
  sound['setSinkId']?.(speakerDeviceId);
  sound.currentTime = 0;
  void sound.play();
};

export const stopSound = (soundId: SoundId) => {
  const sound = sounds[soundId];
  if (!sound) return console.error(`soundEffects:stopSound(): unknown soundId: ${soundId}`);
  void sound.pause();
};
