export const fontLarge = `
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
`;

export const fontBody = `
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
`;

export const fontSmall = `
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
`;

export const fontLargeProps = {
  fontSize: '18px',
  letterSpacing: '0',
  lineHeight: '26px',
};

export const fontBodyProps = {
  fontSize: '15px',
  letterSpacing: '0',
  lineHeight: '22px',
};

export const fontSmallProps = {
  fontSize: '13px',
  letterSpacing: '0',
  lineHeight: '18px',
};

export const monospaceFontFamily = "Roboto Mono, Monaco, Menlo, Consolas, 'Courier New', monospace";
