import { eachDayOfInterval, hoursToMilliseconds, startOfDay } from 'date-fns';
import { isEqual, mapValues } from 'lodash';
import {
  filter,
  groupBy,
  map,
  mapTo,
  merge,
  mergeMap,
  NEVER,
  of,
  pairwise,
  scan,
  startWith,
  takeUntil,
  timer,
  withLatestFrom,
} from 'rxjs';
import { tag } from 'rxjs-spy/cjs/operators';

import { distinctUntilChangedDeep, ofAction, ofActionPayload } from 'common/utils/custom-rx-operators';
import {
  deserializeDateTimeBlock,
  makeDateTimeBlockForClosestDayIndex,
  makeTimeFromComponents,
} from 'common/utils/date-time';

import { EpicWithDeps } from '../../redux/app-store';
import { getSelfUserId } from '../auth/auth.slice';
import { getTimeZoneOffsetForSelf } from '../common/get-time-zone-offset-for-self';

import {
  updateTimeForScheduledBlock,
  saveScheduleBlocks,
  getScheduledBlocksForDayIndexAndCalendarId,
  removeScheduledBlock,
  addScheduledBlockToCalendar,
  addDefaultScheduledBlocksForDay,
  removeAllScheduledBlocksForDay,
  setIsUiShowingCalendarWithBoundary,
  listenToCalendarDay,
  addCalendarIdForUserIdOrgIdPair,
  unsetCalendarIdForUserIdOrgIdPair,
} from './calendar.slice';
import { makeScheduledBlockFromInstance } from './calendar.utils';

export const triggerScheduledBlockSaveEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofAction(updateTimeForScheduledBlock),
    tag('calendar/triggerScheduledBlockSave'),
    mapTo(saveScheduleBlocks()),
  );

export const addDefaultScheduledBlocksForDayEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofAction(addDefaultScheduledBlocksForDay),
    tag('calendar/addDefaultScheduledBlocksForDayEpic'),
    mergeMap(({ payload: { dayIndex, calendarId } }) =>
      merge(
        of(
          addScheduledBlockToCalendar({
            calendarId,
            block: makeScheduledBlockFromInstance({
              type: 'break',
              text: 'Break',
              ...makeDateTimeBlockForClosestDayIndex({
                dayIndex,
                start: makeTimeFromComponents({ hours: 12 }),
                end: makeTimeFromComponents({ hours: 13 }),
              }),
            }),
          }),
        ),
        of(
          addScheduledBlockToCalendar({
            calendarId,
            block: makeScheduledBlockFromInstance({
              type: 'range',
              ...makeDateTimeBlockForClosestDayIndex({
                dayIndex,
                start: makeTimeFromComponents({ hours: 9 }),
                end: makeTimeFromComponents({ hours: 17 }),
              }),
            }),
          }),
        ),
      ),
    ),
  );

export const removeAllScheduledBlocksForDayEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofAction(removeAllScheduledBlocksForDay),
    tag('calendar/removeAllScheduledBlocksForDayEpic'),
    mergeMap(({ payload: { dayIndex, calendarId } }) =>
      merge(
        ...getScheduledBlocksForDayIndexAndCalendarId(state$.value, { dayIndex, calendarId }).map((block) =>
          of({ blockId: block.id, calendarId }),
        ),
      ),
    ),
    map(({ blockId, calendarId }) => removeScheduledBlock({ blockId, calendarId })),
  );

/**
 * Whenever the UI shows a specific boundary, it emits an action, which this
 * epic listens for and responds to. It keeps track of which UTC days are needed
 * to be listened to (since events are bucketed by UTC day in our Firebase DB),
 * and listens for all event changes on those days.
 */
export const setIsUiShowingCalendarWithBoundaryEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(setIsUiShowingCalendarWithBoundary),
    tag('calendar/setIsUiShowingCalendarWithBoundaryEpic'),
    groupBy(({ calendarId }) => calendarId),
    mergeMap((calendarGroup$) =>
      calendarGroup$.pipe(
        map(({ boundary, ...etc }) => ({
          days: eachDayOfInterval(
            deserializeDateTimeBlock(
              mapValues(boundary, (date) => date - getTimeZoneOffsetForSelf(state$.value)),
            ),
          ),
          ...etc,
        })),
        // Convert day to serializable day format so groupBy & distinctUntilDeep work
        mergeMap(({ days, ...etc }) => merge(days.map((day) => ({ dayTs: Number(day), ...etc })))),
        groupBy(({ dayTs }) => dayTs),
        mergeMap((dayGroup$) =>
          dayGroup$.pipe(
            withLatestFrom(
              dayGroup$.pipe(
                scan((numListenersForDay, { isShowing }) => numListenersForDay + (isShowing ? 1 : -1), 0),
                map((numListenersForDay) =>
                  numListenersForDay === 0 ? ('destroy' as const) : ('create' as const),
                ),
              ),
            ),
            // Remove isShowing since it will break the distinctUntilChangedDeep
            // (and is no longer needed since its worth has been distilled into
            // type)
            map(([{ isShowing, ...etc }, type]) => ({ ...etc, type })),
            distinctUntilChangedDeep(),
            map(({ calendarId, dayTs, orgId, type }) =>
              listenToCalendarDay({ payload: { calendarId, dayTs, orgId }, type }),
            ),
          ),
        ),
      ),
    ),
  );

export const listenToTodayEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(addCalendarIdForUserIdOrgIdPair),
    filter(({ userId }) => userId === getSelfUserId(state$.value)),
    mergeMap(({ calendarId, userId, orgId }) =>
      timer(0, hoursToMilliseconds(1)).pipe(
        map(() => startOfDay(Date.now() - getTimeZoneOffsetForSelf(state$.value)).valueOf()),
        startWith(undefined as any),
        pairwise(),
        distinctUntilChangedDeep(),
        mergeMap(([prevDayTs, currDayTs]) =>
          merge(
            of(
              listenToCalendarDay({
                payload: { calendarId, dayTs: currDayTs, orgId },
                type: 'create',
              }),
            ),
            !prevDayTs
              ? NEVER
              : of(
                  listenToCalendarDay({
                    payload: { calendarId, dayTs: prevDayTs, orgId },
                    type: 'destroy',
                  }),
                ),
          ),
        ),
        takeUntil(
          action$.pipe(
            ofActionPayload(unsetCalendarIdForUserIdOrgIdPair),
            filter((payload) => isEqual(payload, { userId, orgId })),
          ),
        ),
      ),
    ),
  );
