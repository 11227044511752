import { MediaType } from 'common/models/connection.interface';
import { PeerTrackInfoWithoutTransportIds } from 'common/models/db/room.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import { PeerMessage } from 'common/models/peer-message.interface';
import { UniqueType } from 'common/utils/ts-utils';

export const defaultWsPort = 443;
export const defaultRtpMinPort = 40000;
export const defaultRtpMaxPort = 49999;

export const allTransports = ['p2p', 'sfu'] as const;
export type Transport = (typeof allTransports)[number];
export const getOtherTransport = (transport: Transport) => (transport === 'p2p' ? 'sfu' : 'p2p');
export type TransportContents = MediaType | 'data';

export type ProvisionalTrackInfo = PeerTrackInfoWithoutTransportIds;
export type TrackInfo = ProvisionalTrackInfo & { track: MediaStreamTrack };

export type OnTrackSentInfo = OnP2pTrackSentInfo | OnSfuTrackSentInfo;
export type OnP2pTrackSentInfo = {
  transport: 'p2p';
  remotePeerId: RemotePeerId;
  localTrackId: string;
  trackTransportId: string | null;
};
export type OnSfuTrackSentInfo = { transport: 'sfu'; localTrackId: string; trackTransportId: string };

export type Direction = 'send' | 'receive';

export type P2pEventType =
  | 'connection-state-change'
  | 'ice-connection-state-change'
  | 'signaling-state-change';

export type MediasoupProducerId = UniqueType<string, 'ProducerId'>;
export type MediasoupConsumerId = UniqueType<string, 'ConsumerId'>;
export type MediasoupTransportId = UniqueType<string, 'TransportId'>;

export type FloofMessages = {
  requests: {
    'server-to-client': {
      sfu: {
        method: 'req-s2c-sfu-new-consumer';
        data: {
          peerId: RemotePeerId;
          producerId: MediasoupProducerId;
          id: MediasoupConsumerId;
          kind: any;
          rtpParameters: any;
          appData: any;
          type: any;
          producerPaused: boolean;
        };
      };
    };
    'client-to-server': {
      room: {
        method: 'req-c2s-room-send-peer-message';
        data: {
          peerMessage: PeerMessage;
        };
        response: any;
      };
      p2p: {
        method: 'req-c2s-p2p-generic-signaling-message';
        data: {
          peerId: RemotePeerId;
          message: P2pGenericSignalingMessage;
        };
        response: any;
      };
      sfu:
        | {
            method: 'req-c2s-sfu-apply-network-throttle';
            data: {
              uplink: number;
              downlink: number;
              rtt: number;
              secret: string;
            };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-close-producer';
            data: {
              producerId: MediasoupProducerId;
            };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-connect-webrtc-transport';
            data: { dtlsParameters: any; transportId: MediasoupTransportId };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-create-webrtc-transport';
            data: {
              forceTcp: boolean;
              producing: boolean;
              consuming: boolean;
              sctpCapabilities: any;
            };
            response: {
              id: MediasoupTransportId;
              iceParameters: any;
              iceCandidates: any;
              dtlsParameters: any;
              sctpParameters: any;
            };
          }
        | {
            method: 'req-c2s-sfu-get-consumer-stats';
            data: { consumerId: MediasoupConsumerId };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-get-producer-stats';
            data: { producerId: MediasoupProducerId };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-get-router-rtp-capabilities';
            data: {};
            response: {
              codecs?: any[];
              headerExtensions?: any[];
            };
          }
        | {
            method: 'req-c2s-sfu-get-transport-stats';
            data: {
              transportId: MediasoupTransportId;
            };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-join';
            data: { rtpCapabilities: any; sctpCapabilities: any };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-pause-consumer';
            data: { consumerId: MediasoupConsumerId };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-pause-producer';
            data: { producerId: MediasoupProducerId };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-produce';
            data: { transportId: MediasoupTransportId; kind: any; rtpParameters: any; appData: any };
            response: { id: MediasoupTransportId };
          }
        | {
            method: 'req-c2s-sfu-request-consumer-keyframe';
            data: { consumerId: MediasoupConsumerId };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-reset-network-throttle';
            data: { secret: string };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-resume-consumer';
            data: { consumerId: MediasoupConsumerId };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-resume-producer';
            data: { producerId: MediasoupProducerId };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-set-consumer-preferred-layers';
            data: { consumerId: MediasoupConsumerId; spatialLayer: number; temporalLayer: number };
            response: any;
          }
        | {
            method: 'req-c2s-sfu-set-consumer-priority';
            data: {
              consumerId: MediasoupConsumerId;
              priority: number;
            };
            response: any;
          };
    };
  };
  notifications: {
    'server-to-client': {
      room:
        | {
            method: 'note-s2c-room-peer-joined';
            data: {
              peerId: RemotePeerId;
            };
          }
        | {
            method: 'note-s2c-room-peer-left';
            data: {
              peerId: RemotePeerId;
            };
          }
        | {
            method: 'note-s2c-room-peer-sent-message';
            data: {
              peerId: RemotePeerId;
              peerMessage: {
                peerMessage: PeerMessage;
              };
            };
          };
      p2p: {
        method: 'note-s2c-p2p-recv-generic-signaling-message';
        data: {
          peerId: RemotePeerId;
          message: P2pGenericSignalingMessage;
        };
      };
      sfu:
        | {
            method: 'note-s2c-sfu-active-speaker';
            data: {
              peerId: RemotePeerId;
              volume?: number;
            };
          }
        | {
            method: 'note-s2c-sfu-audio-volumes';
            data: {
              volumes: { volume: number; peerId: RemotePeerId }[];
            };
          }
        | {
            method: 'note-s2c-sfu-consumer-closed';
            data: {
              consumerId: MediasoupConsumerId;
            };
          }
        | {
            method: 'note-s2c-sfu-consumer-layers-changed';
            data: {
              consumerId: MediasoupConsumerId;
              spatialLayer?: number | null;
              temporalLayer?: number | null;
            };
          }
        | {
            method: 'note-s2c-sfu-consumer-paused';
            data: {
              consumerId: MediasoupConsumerId;
            };
          }
        | {
            method: 'note-s2c-sfu-consumer-resumed';
            data: {
              consumerId: MediasoupConsumerId;
            };
          }
        | {
            method: 'note-s2c-sfu-consumer-score';
            data: {
              consumerId: MediasoupConsumerId;
              score: {
                // (From mediasoup/Consumer.d.ts)
                // The score of the RTP stream of the consumer.
                score: number;
                // The score of the currently selected RTP stream of the producer.
                producerScore: number;
                // The scores of all RTP streams in the producer ordered by encoding (just
                // useful when the producer uses simulcast).
                producerScores: number[];
              };
            };
          }
        | {
            method: 'note-s2c-sfu-data-consumer-closed';
            data: {
              consumerId: MediasoupConsumerId;
            };
          }
        | {
            method: 'note-s2c-sfu-downlink-bwe';
            data: {
              desiredBitrate: number;
              effectiveDesiredBitrate: number;
              availableBitrate: number;
            };
          }
        | {
            method: 'note-s2c-sfu-producer-score';
            data: {
              producerId: MediasoupProducerId;
              score: {
                score: number;
                producerScore: number;
                producerScores: number[];
              };
            };
          };
    };
    'client-to-server': {
      room: {};
      p2p: {};
      sfu: {};
    };
  };
};

export type P2pGenericSignalingMessage =
  | P2pGenericSignalingMessageOffer
  | P2pGenericSignalingMessageAnswer
  | P2pGenericSignalingMessageIceCandidate;

interface P2pGenericSignalingMessageAbstract {
  recipientDirection: 'send' | 'receive';
  id: number;
}

export interface P2pGenericSignalingMessageOffer extends P2pGenericSignalingMessageAbstract {
  type: 'offer';
  offer: RTCSessionDescription;
}
export interface P2pGenericSignalingMessageAnswer extends P2pGenericSignalingMessageAbstract {
  type: 'answer';
  answer: RTCSessionDescription;
}
export interface P2pGenericSignalingMessageIceCandidate extends P2pGenericSignalingMessageAbstract {
  type: 'ice-candidate';
  iceCandidate: RTCIceCandidate;
}
