import { isAfter, isBefore, isSameDay } from 'date-fns';
import { filter, last } from 'lodash';

import { EventId, EventInstanceId, EventInstanceStartEndDate } from '../models/db/event.interface';
import { combineTimeAndDay } from '../utils/date-time';

export const getEventInstanceId = (eventId: EventId, instanceStartTs: number) =>
  `ei|${eventId}|${instanceStartTs}` as EventInstanceId;

export function filterPastEventInstances({
  eventInstances,
}: {
  eventInstances: EventInstanceStartEndDate[];
}) {
  const [eventStartDate] = eventInstances;
  const todaysEventInstanceStartDate = combineTimeAndDay({
    time: eventStartDate.instanceStartDate,
    day: new Date(),
  });

  const now = new Date();
  return filter(eventInstances, ({ instanceStartDate }) => {
    if (!isSameDay(now, instanceStartDate) && isBefore(instanceStartDate, now)) return false;
    if (isSameDay(now, instanceStartDate) && isAfter(now, todaysEventInstanceStartDate)) return false;
    return true;
  }) as EventInstanceStartEndDate[];
}

export function getEventIdAndInstanceStartTsFromEventInstanceId(eventInstanceId: EventInstanceId) {
  if (!eventInstanceId) return { eventId: undefined, eventInstanceStartTs: undefined };
  const segments = eventInstanceId.split('|');
  const eventId = segments.slice(1, -1).join('|') as EventId;
  const eventInstanceStartTs = parseInt(last(segments)!, 10);
  return {
    eventId,
    eventInstanceStartTs,
  };
}
