import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { StyleSheetManager } from 'styled-components';

import { CurrentWindowContextProvider } from 'utils/react/current-window-context';
import { ReactChildren } from 'utils/react-utils';

export const BaseReactContainer = ({ win = window, children }: { win?: Window; children: ReactChildren }) => (
  <I18nProvider i18n={i18n}>
    <StyleSheetManager target={win.document.head}>
      <CurrentWindowContextProvider win={win}>{children}</CurrentWindowContextProvider>
    </StyleSheetManager>
  </I18nProvider>
);
