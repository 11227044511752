import { Observable } from 'rxjs';

// https://ncjamieson.com/how-to-use-requestidlecallback/
export const whenIdle = () =>
  new Observable<void>((observer) => {
    const handle = (window as any).requestIdleCallback(() => {
      observer.next();
      observer.complete();
    });
    return () => (window as any).cancelIdleCallback(handle);
  });
