import { some } from 'lodash';

import {
  BrowserPermissionKey,
  SystemPermissionKey,
} from 'pages/vo/vo-react/features/permissions/permissions.types';
import { isElectron, isMac } from 'utils/react/user-agent';

import { sendIpc } from '../../ipc/send-ipc';
import { createSlice } from '../../redux/create-slice';

export type PermissionsSlice = {
  browser: {
    [key in BrowserPermissionKey]: boolean;
  };
  system: {
    [key in SystemPermissionKey]: boolean;
  };
};

const IS_BROWSER_PERMISSIVE = isElectron;
const IS_SYSTEM_PERMISSIVE = !isMac || !isElectron;

const initialState: PermissionsSlice = {
  browser: {
    microphone: IS_BROWSER_PERMISSIVE,
    camera: IS_BROWSER_PERMISSIVE,
  },
  system: {
    microphone: IS_SYSTEM_PERMISSIVE,
    camera: IS_SYSTEM_PERMISSIVE,
    screen: IS_SYSTEM_PERMISSIVE,
    accessibility: IS_SYSTEM_PERMISSIVE,
  },
};
const { createAction, createThunk, createReducer, createSelector } = createSlice('permissions', initialState);

export const setBrowserPermission =
  createAction<{ name: BrowserPermissionKey; isPermitted: boolean }>('setBrowserPermission');
export const setSystemPermission =
  createAction<{ name: SystemPermissionKey; isPermitted: boolean }>('setSystemPermission');
export const listenToSystemPermissions = createAction<SystemPermissionKey[]>('listenToSystemPermissions');
export const stopListeningToSystemPermissions = createAction<SystemPermissionKey[]>(
  'stopListeningToSystemPermissions',
);
export const checkSystemPermission = createThunk(
  'checkSystemPermission',
  async (dispatch, _getState, name: SystemPermissionKey) => {
    dispatch(setSystemPermission({ name, isPermitted: await sendIpc('checkPermission', name) }));
  },
);

export default createReducer()
  .on(setBrowserPermission, (state, { payload: { name, isPermitted } }) => {
    state.browser[name] = isPermitted;
  })
  .on(setSystemPermission, (state, { payload: { name, isPermitted } }) => {
    state.system[name] = isPermitted;
  });

export const getBrowserPermission = createSelector(
  (state, name: BrowserPermissionKey) => state.permissions.browser[name],
);
export const getSystemPermission = createSelector(
  (state, name: SystemPermissionKey) => state.permissions.system[name],
);
export const getAreAllSystemPermissionsGranted = createSelector(
  (state) => !some(state.permissions.system, (val) => !val),
);
export const getAllSystemPermissions = createSelector((state) => state.permissions.system);
export const getAllBrowserPermissions = createSelector((state) => state.permissions.browser);

export const getCurrentPlatformPermission = createSelector(
  (state, name: SystemPermissionKey | BrowserPermissionKey) => {
    if (isElectron) return state.permissions.system[name] ?? true;
    if (name !== 'camera' && name !== 'microphone')
      throw new Error(`getCurrentPlatformPermission: invalid browser key: ${name}`);
    return state.permissions.browser[name] ?? true;
  },
);
