import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { BasePopUiComponent } from 'pages/vo/vo-react/components/ui-kit/base-pop-ui-component';
import { Button } from 'pages/vo/vo-react/components/ui-kit/button';
import { ErrorMessage } from 'pages/vo/vo-react/components/ui-kit/error-message';
import { FlexBox } from 'pages/vo/vo-react/components/ui-kit/flex-box';
import { Form } from 'pages/vo/vo-react/components/ui-kit/form';
import { Label } from 'pages/vo/vo-react/components/ui-kit/label';
import { WebsitePopLogo } from 'pages/vo/vo-react/components/ui-kit/pop-logo';
import { RelativeLink } from 'pages/vo/vo-react/components/ui-kit/relative-link';
import { LargeText } from 'pages/vo/vo-react/components/ui-kit/text';
import { TextBox } from 'pages/vo/vo-react/components/ui-kit/textbox';
import { attemptSignIn } from 'pages/vo/vo-react/features/auth/auth.slice';
import { initiateGoogleLoginCallback } from 'utils/initiate-google-login-callback';
import { popColor } from 'utils/react/colors';
import { useDispatch } from 'utils/react/use-dispatch';

import { LocalhostDevHelper } from '../../components/localhost-dev-helper';

const SignIn = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSigningIn, setIsSigningIn] = useState(false);

  return (
    <FlexBox direction="column" justifyContent="flex-start" alignItems="center" gap="8px" width="100%">
      <LocalhostDevHelper />
      <WebsitePopLogo position="absolute" top="15px" left="26px" />
      <LargeText bold fontSize="44px" lineHeight="53px" marginTop="40px" marginBottom="74px">
        Sign in
      </LargeText>

      <FlexBox direction="column" gap="12px" width="400px">
        <Form
          onSubmit={async ({ email, password }: { email: string; password: string }) => {
            setIsSigningIn(true);
            const { isOk, error } = await dispatch(
              attemptSignIn({ email, password, to: location.state?.from?.pathname }),
            );
            if (!isOk) {
              console.error(error); // todo: display to client.
              setIsSigningIn(false);
            }
          }}
          direction="column"
          gap="20px"
          background={popColor('background')}
          border={`1px solid ${popColor('foreground', 0.05)}`}
          padding="32px"
          borderRadius="10px"
          noValidate
        >
          <Label text="Email">
            <TextBox
              inputType="email"
              placeholder="Email"
              register="email"
              validation={{
                required: { value: true, message: 'Enter a valid email to sign in.' },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Enter a valid email to sign in.',
                },
              }}
            ></TextBox>
            <ErrorMessage register="email" />
          </Label>
          <Label text="Password">
            <TextBox
              inputType="password"
              placeholder="Password"
              register="password"
              validation={{ required: { value: true, message: 'Enter a password to sign in.' } }}
            ></TextBox>
            <ErrorMessage register="password" />
          </Label>
          <Button inputType="submit" disabled={isSigningIn}>
            Sign in
          </Button>
          <BasePopUiComponent textAlign="center">
            Don't have an account? <RelativeLink to="../signup">Sign up for free</RelativeLink>
          </BasePopUiComponent>
        </Form>
        <FlexBox
          background={popColor('background', 0.2)}
          padding="24px"
          borderRadius="10px"
          justifyContent="center"
        >
          <Button
            type="secondary"
            icon={IconGoogle}
            width="290px"
            justifyContent="center"
            gap="4px"
            onClick={initiateGoogleLoginCallback}
          >
            Sign in with Google
          </Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default SignIn;
