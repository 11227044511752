/* eslint sort-keys-fix/sort-keys-fix: "error" */
import { configureStore } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { combineEpics, createEpicMiddleware, Epic as ReduxObservableEpic } from 'redux-observable';
import { ThunkDispatch, ThunkAction } from 'redux-thunk';
import { NEVER, Observable, Subject } from 'rxjs';

import { concealFromGlobalConsole, exposeToGlobalConsole } from 'utils/react/expose-to-global-console';

import activityReducer, * as activity from '../features/activity/activity.slice';
import calendarReducer, * as calendar from '../features/calendar/calendar.slice';
import captionsReducer, * as captions from '../features/captions/captions.slice';
import chatReducer, * as chat from '../features/chat/chat.slice';
import desktopWindowsReducer, * as desktopWindows from '../features/desktop-windows/desktop-windows.slice';
import devicesReducer, * as device from '../features/devices/devices.slice';
import drawReducer, * as draw from '../features/draw/draw.slice';
import floofReducer, * as floof from '../features/floof/floof.slice';
import hudReducer, * as hud from '../features/hud/hud.slice';
import instantMeetingsReducer, * as instantMeeting from '../features/instant-meetings/instant-meetings.slice';
import layoutReducer, * as layout from '../features/layout/layout.slice';
import mediaReducer, * as media from '../features/media/media.slice';
import orgsReducer, * as org from '../features/orgs/orgs.slice';
import permissionsReducer, * as permissions from '../features/permissions/permissions.slice';
import ringsReducer, * as rings from '../features/rings/rings.slice';
import * as router from '../features/router/router.slice';
import screensReducer, * as screens from '../features/screens/screens.slice';
import sessionGroupsReducer, * as sessionGroup from '../features/session-groups/session-groups.slice';
import sessionHistoryReducer, * as sessionHistory from '../features/session-history/session-history.slice';
import sessionsReducer, * as session from '../features/sessions/sessions.slice';
import settingsReducer, * as settings from '../features/settings/settings.slice';
import shortcutsReducer, * as shortcuts from '../features/shortcuts/shortcuts.slice';
import spacesReducer, * as space from '../features/spaces/spaces.slice';
import teamsReducer, * as team from '../features/teams/teams.slice';
import toastsReducer, * as toasts from '../features/toasts/toasts.slice';
import usersReducer, * as user from '../features/users/users.slice';
import versionReducer, * as version from '../features/version/version.slice';
import zoomReducer, * as zoom from '../features/zoom/zoom.slice';

import combinedEpics from './app-combined-epics';
import { STORE_RESET_ACTION } from './reset-state-enhancer';
import {
  CombinedReducerType,
  makeSharedEnhancers,
  makeSharedMiddlewareFactory,
  makeSharedReducers,
  setSharedStore,
} from './shared-store';

let _reduxStore: ReturnType<typeof createReduxStore>;

export type Actionish = { type: any } | ThunkAction<any, any, any, any>;

export type EpicWithDeps = ReduxObservableEpic<
  any,
  Actionish | Observable<Actionish>,
  RootReduxState,
  { ngxsStore: any; ngxsActions$: any; injector: any }
>;

export type Epic = ReduxObservableEpic<any, Actionish | Observable<Actionish>, RootReduxState>;

/**
 * A little hacky epic to give us global access to the redux action$ stream that
 * is already maintained by redux-observable.
 */
export const createActionObservableCapturerEpic = () => {
  const caputuredAction$ = new Subject<Action<any>>();
  return {
    action$: caputuredAction$.asObservable(),
    actionObservableCapturerEpic: (action$: Observable<Action<any>>) => {
      action$.subscribe(caputuredAction$);
      return NEVER;
    },
  };
};

export const makeAppReducers = () => ({
  ...makeSharedReducers('app'),
  activity: activityReducer,
  calendar: calendarReducer,
  captions: captionsReducer,
  chat: chatReducer,
  desktopWindows: desktopWindowsReducer,
  devices: devicesReducer,
  draw: drawReducer,
  floof: floofReducer,
  hud: hudReducer,
  instantMeetings: instantMeetingsReducer,
  layout: layoutReducer,
  media: mediaReducer,
  orgs: orgsReducer,
  permissions: permissionsReducer,
  rings: ringsReducer,
  screens: screensReducer,
  sessionGroups: sessionGroupsReducer,
  sessionHistory: sessionHistoryReducer,
  sessions: sessionsReducer,
  settings: settingsReducer,
  shortcuts: shortcutsReducer,
  spaces: spacesReducer,
  teams: teamsReducer,
  toasts: toastsReducer,
  users: usersReducer,
  version: versionReducer,
  zoom: zoomReducer,
});

export const createReduxStore = (options: { preloadedState?: Partial<RootReduxState> } = {}) => {
  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      ngxsActions$: NEVER,
      ngxsStore: {
        dispatch: () => NEVER,
        select: () => NEVER,
        selectSnapshot: () => ({}),
        snapshot: () => ({}),
      },
    } as any,
  });
  const { action$, actionObservableCapturerEpic } = createActionObservableCapturerEpic();

  const store = configureStore({
    enhancers: makeSharedEnhancers(),
    middleware: makeSharedMiddlewareFactory({ extraArgs: { action$ }, extraMiddleware: [epicMiddleware] }),
    preloadedState: options.preloadedState ?? undefined,
    reducer: makeAppReducers() as unknown as CombinedReducerType<ReturnType<typeof makeAppReducers>>,
  });

  Object.defineProperty(window, 'reduxStore', {
    get() {
      console.warn(
        'window.reduxStore is being renamed window.appStore to disambiguate between appStore and websiteStore',
      );
      return store;
    },
  });
  _reduxStore = store;

  exposeToGlobalConsole({ appStore: store });
  exposeStoresToGlobal();

  setSharedStore(store, destroyAppStore);

  epicMiddleware.run(combineEpics(actionObservableCapturerEpic, combinedEpics));
  return store;
};

const destroyAppStore = () => {
  _reduxStore = null as any;
  concealFromGlobalConsole('stores', 'appStore');
};

export const getAppStore = (throwErrorOnUninitialized: boolean = true) => {
  if (!_reduxStore && throwErrorOnUninitialized)
    throw Error('Redux store is being accessed before creation.');
  return _reduxStore;
};

export const getOrCreateAppStore = (preloadedState?: Partial<RootReduxState>) => {
  if (!getAppStore(false)) createReduxStore();
  return _reduxStore;
};

export const __test__setAppStore = (store: ReduxStore) => {
  _reduxStore = store;
};

export const resetState = (state?: RootReduxState) =>
  getAppStore().dispatch({ payload: state, type: STORE_RESET_ACTION });

export type ReduxStore = ReturnType<typeof getAppStore>;
export type RootReduxState = {
  [R in keyof ReturnType<typeof makeAppReducers>]: ReturnType<ReturnType<typeof makeAppReducers>[R]>;
};
export type RootReduxDispatch = ThunkDispatch<
  RootReduxState,
  {
    action$: Observable<Action<any>>;
  },
  Action<any>
>;

export const getDispatch = () => getAppStore().dispatch as RootReduxDispatch;

export const snapshotState = <R>(selectorFn: (state: RootReduxState) => R) =>
  selectorFn(getAppStore().getState());

export const exposeStoresToGlobal = () => {
  exposeToGlobalConsole({
    stores: {
      activity,
      calendar,
      captions,
      chat,
      desktopWindows,
      device,
      draw,
      floof,
      hud,
      instantMeeting,
      layout,
      media,
      // navigation,
      org,
      permissions,
      rings,
      router,
      screens,
      session,
      sessionGroup,
      sessionHistory,
      settings,
      shortcuts,
      space,
      team,
      toasts,
      user,
      version,
      zoom,
    },
  });
};
