import * as CSS from 'csstype';
import {
  cloneElement,
  Children,
  Component,
  ComponentProps,
  FC,
  HTMLAttributes,
  isValidElement,
  ReactNode,
} from 'react';

import { OrgId } from 'common/models/db/vo.interface';
import { getOrgIconUrlById, getOrgNameById } from 'pages/vo/vo-react/features/orgs/orgs.slice';
import { popColor } from 'utils/react/colors';
import { useSelector } from 'utils/react/use-selector';

import { Avatar } from './ui-kit/avatar';
import { BaseProps } from './ui-kit/base-pop-ui-component';
import { FlexBox, FlexBoxProps } from './ui-kit/flex-box';

export const OrgIconComponent = ({
  orgId,
  ...rest
}: Omit<ComponentProps<typeof UrlIconComponent>, 'url'> & { orgId: OrgId }) => {
  const iconUrl = useSelector(getOrgIconUrlById, orgId);
  const name = useSelector(getOrgNameById, orgId);
  return (
    <UrlIconComponent {...rest}>
      {iconUrl ? (
        <UrlIconComponent url={iconUrl} boxShadow={`0 0 0 1px inset ${popColor('foreground', 0.2)}`} />
      ) : (
        <UrlIconComponent boxShadow={`0 0 0 1px inset ${popColor('foreground', 0.2)}`}>
          <Avatar hashableId={orgId} displayName={name} width="100%" height="100%" />
        </UrlIconComponent>
      )}
    </UrlIconComponent>
  );
};

export const BorderlessIconComponent = ({
  icon,
  color = popColor('grey2'),
  ...etc
}: {
  icon: JSX.Element | AutoImportedIconComponentType;
  color?: CSS.Properties['color'];
} & ComponentProps<typeof UrlIconComponent>) => (
  <UrlIconComponent {...etc}>
    {interpretPassedIconAsElement({
      icon,
      size: 24,
      color,
    })}
  </UrlIconComponent>
);

export type AutoImportedIconComponentType = FC<BaseProps & { size?: string }>;

type UrlIconComponentProps = {
  url?: string;
  size?: string;
  children?: ReactNode | ReactNode[];
} & HTMLAttributes<HTMLElement>;

export const UrlIconComponent = ({ url, children, ...etc }: UrlIconComponentProps & FlexBoxProps) => (
  <FlexBox
    alignItems="center"
    justifyContent="center"
    overflow="hidden"
    borderRadius="8px"
    width="44px"
    height="44px"
    {...etc}
  >
    {Children.count(children) ? children : <img src={url} width="100%" />}
  </FlexBox>
);

type PassedIconProps = {
  icon: JSX.Element | Component | AutoImportedIconComponentType;
  size: string | number;
};
type OptionalPassedIconProps = Partial<PassedIconProps>;

// Take either a Component
export const interpretPassedIconAsElement = ({ icon, ...etc }: OptionalPassedIconProps & BaseProps) => {
  if (!icon) return null;
  if (isValidElement(icon)) {
    return cloneElement(icon, etc ?? {});
  }
  const Icon = icon as any;
  return <Icon {...etc} />;
};
