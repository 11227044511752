import { differenceInMinutes } from 'date-fns';
import { sortedIndexBy, uniqueId } from 'lodash';
import { Dispatch } from 'redux';

import { SessionId } from 'common/models/db/vo.interface';
import { AnyPeerId } from 'common/models/db/vo.interface';
import { isShallowArrayEqual } from 'utils/is-shallow-array-equal';

import { createSlice } from '../../redux/create-slice';

export type ChatMessage = TextChatMessage | EmojiChatMessage;

export type TextChatMessage = {
  id: string;
  type: 'text';
  ts: number;
  peerId: AnyPeerId;
  text: string;
  isSubsequent?: true;
};

export type EmojiChatMessage = {
  id: string;
  ts: number;
  type: 'emoji';
  peerId: AnyPeerId;
  emoji: string;
  isSubsequent?: true;
};

export type ChatSlice = {
  messagesById: { [id: string]: ChatMessage };
  messageIdsBySessionId: { [sessionId: string]: string[] };
};

const initialState: ChatSlice = {
  messagesById: {},
  messageIdsBySessionId: {},
};
export const { createReducer, createSelector, createMemoizedSelector, createAction } = createSlice(
  'chat',
  initialState,
);

export const createMessage =
  ({
    sessionId,
    message,
  }: {
    sessionId: SessionId;
    message: Omit<EmojiChatMessage, 'id' | 'ts'> | Omit<TextChatMessage, 'id' | 'ts'>;
  }) =>
  (dispatch: Dispatch) => {
    const id = uniqueId(message.peerId);
    dispatch(
      addMessage({
        sessionId,
        message: {
          ...(message as any),
          id,
          ts: Date.now(),
        },
      }),
    );
  };
export const addMessage = createAction<{ sessionId: SessionId; message: ChatMessage }>('addMessage');

export default createReducer().on(addMessage, (state, { payload: { sessionId, message } }) => {
  state.messagesById[message.id] = message;
  if (!state.messageIdsBySessionId[sessionId]) state.messageIdsBySessionId[sessionId] = [];

  const insertIndex = sortedIndexBy(
    state.messageIdsBySessionId[sessionId],
    // NB: notice we're inserting the message at the beginning so we can easily
    // query it with this selector.
    message.id,
    (messageId) => getChatMessageById({ chat: state }, { id: messageId }).ts,
  );
  state.messageIdsBySessionId[sessionId].splice(insertIndex, 0, message.id);

  const previousChatId = state.messageIdsBySessionId[sessionId][insertIndex - 1] as string | undefined;
  const previousChat = !!previousChatId && getChatMessageById({ chat: state }, { id: previousChatId });
  const wasPreviousChatSamePeerAndRecent =
    previousChat &&
    previousChat.peerId === message.peerId &&
    Math.abs(differenceInMinutes(message.ts, previousChat.ts)) < 5;
  if (wasPreviousChatSamePeerAndRecent) {
    getChatMessageById({ chat: state }, { id: message.id }).isSubsequent = true;
  }
});

export const getMessageIdsForSessionId = createSelector(
  (state, { sessionId }: { sessionId: SessionId }) => state.chat.messageIdsBySessionId[sessionId] ?? [],
);

export const getAllMessagesById = createSelector((state) => state.chat.messagesById);

export const getChatMessageById = createSelector(
  (state, { id }: { id: string }) => state.chat.messagesById[id],
);

export const getPeerIdForChatMessageById = createSelector(
  (state, { id }: { id: string }) => getChatMessageById(state, { id })?.peerId,
);

export const getIsSubsequentForChatMessageById = createSelector(
  (state, { id }: { id: string }) => getChatMessageById(state, { id })?.isSubsequent,
);

export const getMessagesForSessionId = createMemoizedSelector(
  getMessageIdsForSessionId,
  getAllMessagesById,
  (messageIds, messagesById) => messageIds.map((messageId) => messagesById[messageId]),
  {
    memoizeOptions: {
      resultEqualityCheck: isShallowArrayEqual,
    },
  },
);

export const getChatMessageIdsAfterTsForSessionId = createMemoizedSelector(
  getMessagesForSessionId,
  (_state: any, { ts }: { ts: number }) => ts,
  (messages, ts) => {
    const splitIndex = sortedIndexBy(messages, { ts } as ChatMessage, (message) => message.ts);
    return messages.map((message) => message.id).slice(splitIndex);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isShallowArrayEqual,
    },
  },
);
