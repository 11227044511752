import { useEffect, useLayoutEffect } from 'react';
import { createGlobalStyle } from 'styled-components';

import { popColor } from 'utils/react/colors';
import { useCurrentWindow } from 'utils/react/use-current-window';
import { useSelector } from 'utils/react/use-selector';

import { getIsSystemDarkMode } from '../../features/environment/environment.slice';

import fontStylesheet from 'assets/fonts/fonts.css?inline';

export const FontStylesheet = () => {
  const win = useCurrentWindow();

  useLayoutEffect(() => {
    const style = win.document.createElement('style');
    style.innerText = fontStylesheet;
    win.document.head.appendChild(style);
    return () => void win.document.head.removeChild(style);
  }, [win]);

  return null;
};

export const GlobalStyleWithoutFonts = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  *:focus {
    outline: 0 !important;
  }

  * {
    box-sizing: border-box;
  }

  *:focus {
    outline: 0 !important;
  }

  a {
    color: ${popColor('primary1')};
  }

  body {
    margin: 0;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    color: var(--pop-foreground-color);
    font-family: var(--pop-font-family, inherit);
    overflow: hidden;
  }

  button {
    font-family: var(--pop-font-family, inherit);
    &:not(:disabled) {
      cursor: pointer;
    }
  }

  html {
    --pop-font-family: 'Lato', arial, sans-serif;
    --pop-foreground-color: rgb(0, 0, 0);
    --pop-foreground-color-rgb: 0, 0, 0;
    --pop-grey-1-color: rgb(58, 62, 67);
    --pop-grey-1-color-rgb: 58, 62, 67;
    --pop-grey-2-color: rgb(105, 110, 117);
    --pop-grey-2-color-rgb: 105, 110, 117;
    --pop-grey-3-color: rgb(192, 197, 203);
    --pop-grey-3-color-rgb: 192, 197, 203;
    --pop-grey-4-color: rgb(224, 226, 230);
    --pop-grey-4-color-rgb: 224, 226, 230;
    --pop-grey-5-color: rgb(243, 245, 248);
    --pop-grey-5-color-rgb: 243, 245, 248;
    --pop-background-color: rgb(255, 255, 255);
    --pop-background-color-rgb: 255, 255, 255;
    --pop-primary-1-color: rgb(0, 87, 209);
    --pop-primary-1-color-rgb: 0, 87, 209;
    --pop-primary-2-color: rgb(23, 109, 229);
    --pop-primary-2-color-rgb: 23, 109, 229;
    --pop-primary-3-color: rgb(115, 173, 255);
    --pop-primary-3-color-rgb: 115, 173, 255;
    --pop-primary-4-color: rgb(227, 239, 255);
    --pop-primary-4-color-rgb: 227, 239, 255;
    --pop-secondary-1-color: rgb(0, 136, 23);
    --pop-secondary-1-color-rgb: 0, 136, 23;
    --pop-secondary-2-color: rgb(192, 238, 200);
    --pop-secondary-2-color-rgb: 192, 238, 200;
    --pop-tertiary-1-color: rgb(214, 54, 81);
    --pop-tertiary-1-color-rgb: 214, 54, 81;
    --pop-tertiary-2-color: rgb(255, 182, 194);
    --pop-tertiary-2-color-rgb: 255, 182, 194;
    --pop-permanent-black-color: rgb(0, 0, 0);
    --pop-permanent-black-color-rgb: 0, 0, 0;
    --pop-permanent-yellow-color: rgb(255, 187, 0);
    --pop-permanent-yellow-color-rgb: 255, 187, 0;
    --pop-permanent-white-color: rgb(255, 255, 255);
    --pop-permanent-white-color-rgb: 255, 255, 255;
    --pop-draw-0-color: rgb(79, 187, 61);
    --pop-draw-0-color-rgb: 79, 187, 61;
    --pop-draw-1-color: rgb(230, 166, 0);
    --pop-draw-1-color-rgb: 230, 166, 0;
    --pop-draw-2-color: rgb(241, 117, 117);
    --pop-draw-2-color-rgb: 241, 117, 117;
    --pop-draw-3-color: rgb(95, 130, 221);
    --pop-draw-3-color-rgb: 95, 130, 221;
    --pop-draw-4-color: rgb(207, 95, 203);
    --pop-draw-4-color-rgb: 207, 95, 203;
    --pop-draw-5-color: rgb(119, 119, 119);
    --pop-draw-5-color-rgb: 119, 119, 119;
    --pop-draw-6-color: rgb(206, 210, 0);
    --pop-draw-6-color-rgb: 206, 210, 0;
    --pop-draw-7-color: rgb(0, 204, 204);
    --pop-draw-7-color-rgb: 0, 204, 204;
    --pop-draw-8-color: rgb(155, 151, 194);
    --pop-draw-8-color-rgb: 155, 151, 194;
  }

  html.dark {
    --pop-foreground-color: rgb(255, 255, 255);
    --pop-foreground-color-rgb: 255, 255, 255;
    --pop-grey-1-color: rgb(243, 245, 248);
    --pop-grey-1-color-rgb: 243, 245, 248;
    --pop-grey-2-color: rgb(169, 173, 178);
    --pop-grey-2-color-rgb: 169, 173, 178;
    --pop-grey-3-color: rgb(110, 115, 121);
    --pop-grey-3-color-rgb: 110, 115, 121;
    --pop-grey-4-color: rgb(57, 65, 71);
    --pop-grey-4-color-rgb: 57, 65, 71;
    --pop-grey-5-color: rgb(41, 49, 53);
    --pop-grey-5-color-rgb: 41, 49, 53;
    --pop-background-color: rgb(27, 33, 36);
    --pop-background-color-rgb: 27, 33, 36;
    --pop-primary-1-color: rgb(38, 165, 255);
    --pop-primary-1-color-rgb: 38, 165, 255;
    --pop-primary-2-color: rgb(0, 138, 237);
    --pop-primary-2-color-rgb: 0, 138, 237;
    --pop-primary-3-color: rgb(25, 85, 128);
    --pop-primary-3-color-rgb: 25, 85, 128;
    --pop-primary-4-color: rgb(25, 48, 64);
    --pop-primary-4-color-rgb: 25, 48, 64;
    --pop-secondary-1-color: rgb(58, 166, 76);
    --pop-secondary-1-color-rgb: 58, 166, 76;
    --pop-secondary-2-color: rgb(39, 86, 52);
    --pop-secondary-2-color-rgb: 39, 86, 52;
    --pop-tertiary-1-color: rgb(255, 76, 107);
    --pop-tertiary-1-color-rgb: 255, 76, 107;
    --pop-tertiary-2-color: rgb(115, 46, 57);
    --pop-tertiary-2-color-rgb: 115, 46, 57;
    --pop-permanent-black-color: rgb(0, 0, 0);
    --pop-permanent-black-color-rgb: 0, 0, 0;
    --pop-permanent-yellow-color: rgb(255, 187, 0);
    --pop-permanent-yellow-color-rgb: 255, 187, 0;
    --pop-permanent-white-color: rgb(255, 255, 255);
    --pop-permanent-white-color-rgb: 255, 255, 255;
  }
`;

export const WebsiteGlobalStyle = createGlobalStyle`
  body {
    background: ${popColor('primary3')};
    // The app sets overflow hidden, which makes sense for a SPA. On the website
    // it makes less sense, so reset it.
    overflow: unset;
  }
`;

export const DarkModeClass = () => {
  // Later this will be a pref, for now, just use what the system tells us.
  const isSystemDarkMode = useSelector(getIsSystemDarkMode);
  const win = useCurrentWindow();

  useEffect(() => {
    if (isSystemDarkMode) {
      win.document.documentElement.classList.add('dark');
    } else {
      win.document.documentElement.classList.remove('dark');
    }
  }, [isSystemDarkMode]);

  return null;
};

export const GlobalStyle = () => (
  <>
    <FontStylesheet />
    <GlobalStyleWithoutFonts />
    <DarkModeClass />
  </>
);
