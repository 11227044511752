import { once } from 'lodash';

import { Rectangle } from 'common/models/geometry.interface';
import {
  areBoundsClose,
  evenRoundedRect,
  fitRectWithinRect,
  rectRelativeToOuterRect,
  rectToFlatRect,
} from 'common/utils/geometry-utils';
import { assertExhaustedType } from 'common/utils/ts-utils';
import CropVideoFrameWorker from 'utils/workers/crop-video-frame?worker';

import { getEnhancedTrackForTrack } from './audio-context';
import { exposeToGlobalConsole } from './expose-to-global-console';
import { RefCountable } from './ref-countable';

export const makeElectronScreenShareTrack = async ({
  sourceId,
  captureRect,
  screenBounds,
}: {
  sourceId: string;
  captureRect: Rectangle;
  screenBounds: Rectangle;
}) => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      mandatory: {
        chromeMediaSourceId: sourceId,
        chromeMediaSource: 'desktop',
      },
    },
  } as any);
  const track = stream.getVideoTracks()[0];

  const isFullScreen = areBoundsClose(captureRect, screenBounds, 2);
  if (isFullScreen) return track;
  const relativeBounds = { ...screenBounds, origin: { x: 0, y: 0 } };
  const relativeCaptureRect = fitRectWithinRect(
    evenRoundedRect(rectRelativeToOuterRect(captureRect, screenBounds)),
    relativeBounds,
  );

  const processor = new (window as any).MediaStreamTrackProcessor({ track });
  const generator = new (window as any).MediaStreamTrackGenerator({ kind: 'video' });
  const { readable } = processor;
  const { writable } = generator;

  const worker = new CropVideoFrameWorker();

  track.addEventListener('ended', () => {
    worker.terminate();
  });

  worker.postMessage(
    {
      readable,
      writable,
      rect: rectToFlatRect(relativeCaptureRect),
    },
    [readable, writable],
  );

  return generator as MediaStreamTrack;
};

export const makeBrowserScreenShareTrack = async () => {
  const stream = await navigator.mediaDevices['getDisplayMedia']();
  return stream.getVideoTracks()[0];
};

export const makeAudioTrack = async ({
  deviceId,
  shouldUseAutoGainControl,
}: {
  deviceId: string;
  shouldUseAutoGainControl?: boolean;
}) => {
  const stream = await navigator.mediaDevices.getUserMedia({
    audio: {
      deviceId: { ideal: deviceId },
      echoCancellation: { ideal: true },
      noiseSuppression: { ideal: true },
      autoGainControl: {
        ideal: shouldUseAutoGainControl ?? true,
      },
    } as MediaTrackConstraints,
  });
  return stream.getAudioTracks()[0];
};

export const getDefaultCameraConstraints = () => ({ width: { ideal: 1280 }, height: { ideal: 720 } });

export const makeCameraTrack = async ({
  deviceId,
  constraints,
}: {
  deviceId: string;
  constraints?: MediaTrackConstraints;
}) => {
  const stream = await navigator.mediaDevices.getUserMedia({
    video: {
      deviceId: { ideal: deviceId },
      ...getDefaultCameraConstraints(),
      ...constraints,
    },
  });
  const track = stream.getVideoTracks()[0];
  return track;
};

type TrackCreationInfo =
  | {
      type: 'microphone' | 'camera';
      deviceId: string;
    }
  | {
      type: 'screen';
    }
  | {
      type: 'screen';
      sourceId: string;
      captureRect: Rectangle;
      screenBounds: Rectangle;
    };

function makeTrack(options: TrackCreationInfo) {
  switch (options.type) {
    case 'microphone':
      return makeAudioTrack({ deviceId: options.deviceId });
    case 'camera':
      return makeCameraTrack({ deviceId: options.deviceId });
    case 'screen':
      return 'sourceId' in options ? makeElectronScreenShareTrack(options) : makeBrowserScreenShareTrack();
    default:
      assertExhaustedType(options);
  }
}

function makeIdForTrack(options: TrackCreationInfo) {
  switch (options.type) {
    case 'microphone':
    case 'camera':
      return `${options.type}-${options.deviceId}`;
    case 'screen':
      if ('sourceId' in options)
        return `screen-${options.sourceId}-${options.captureRect.origin.x},${options.captureRect.origin.y}-${options.captureRect.size.width}x${options.captureRect.size.height}`;
      return 'screen-browser';
    default:
      assertExhaustedType(options);
  }
}

export function getRefCountedTrack(options: TrackCreationInfo) {
  const id = makeIdForTrack(options);
  const track = refCountedTracks.increment(id, options);
  return {
    track,
    release: once(() => {
      refCountedTracks.decrement(id);
    }),
  };
}

const refCountedTracks = new RefCountable({
  create: (id: string, options: TrackCreationInfo) => makeTrack(options),
  destroy: (track) =>
    void track.then((track) => {
      const enhancedTrack = getEnhancedTrackForTrack(track);
      if (enhancedTrack) enhancedTrack.destroy();
      track.stop();
    }),
});

exposeToGlobalConsole({ refCountedTracks });
