import { isBoolean } from 'lodash';
import { mergeMap, map, filter, NEVER, from, tap, skip } from 'rxjs';

import { ignoreElements, ofActionPayload, switchMapIfTruthy } from 'common/utils/custom-rx-operators';
import { whenIdle } from 'common/utils/when-idle';
import { isDesktop } from 'utils/client-utils';
import { db } from 'utils/firebase-db-wrapper-client';

import { sendIpc } from '../../ipc/send-ipc';
import { EpicWithDeps } from '../../redux/app-store';
import { setSelfUserId } from '../users/users.slice';

import { setLocalSetting, setSyncedSetting } from './settings.slice';

// TODO: ask J, what is the difference between UserSettings and ServerSettings

export const ingestServerSettingsEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(setSelfUserId),
    switchMapIfTruthy((userId) =>
      db
        .from(`users/${userId as string}/personal/settings`)
        .whenChild('added', 'changed')
        .pipe(
          mergeMap(({ key, val }) =>
            // Translate the old nested enabledMediaOnStart to two flat booleans.
            key === 'enableMediaOnStart'
              ? [
                  { key: 'shouldEnableMicrophoneOnStart', val: val!['mic'] },
                  { key: 'shouldEnableCameraOnStart', val: val!['camera'] },
                ]
              : [{ key, val }],
          ),
          filter(({ val }) => isBoolean(val)),
          map(({ key: name, val: value }) => setSyncedSetting({ name: name as any, value })),
        ),
    ),
  );

export const ingestDesktopIsAutoLaunchEpic: EpicWithDeps = () =>
  !isDesktop
    ? NEVER
    : whenIdle().pipe(
        mergeMap(() => from(sendIpc('getAutoLaunch'))),
        map((isAutoLaunch) => setLocalSetting({ name: 'isAutoLaunch', value: isAutoLaunch })),
      );

export const setDesktopIsAutoLaunchEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(setLocalSetting),
    filter(({ name }) => name === 'isAutoLaunch') as any,
    // Skip the first set from `ingestDesktopIsAutoLaunchEpic`
    skip(1),
    tap(({ value: shouldAutoLaunch }: { value: boolean }) => {
      void sendIpc('setAutoLaunch', shouldAutoLaunch);
    }),
    ignoreElements({ silenceDevEpicWarning: true }),
  );

// TODO: actually sync the synced settings?
