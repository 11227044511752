import { update } from 'firebase/database';
import { size, filter as lodashFilter } from 'lodash';
import { map } from 'rxjs';

import { FloofMediaServerAssigned } from 'common/models/db/media-server.interface';
import {
  PeerAudioTrackInfo,
  PeerVideoTrackInfo,
  DbPeer,
  RemotePeerId,
  SessionId,
} from 'common/models/db/vo.interface';
import { pluck } from 'common/utils/custom-rx-operators';
import { $anyFixMe } from 'common/utils/ts-utils';
import { serverTimestamp } from 'utils/firebase-app';
import { db, RxFirebaseDbWrapper } from 'utils/firebase-db-wrapper-client';

export const observeConnectedRemotePeerCountForSession = (sessionId: SessionId, selfPeerId: RemotePeerId) =>
  db
    .from(`sessions/${sessionId as string}/peers`)
    .whenChanged()
    .pipe(
      pluck('val'),
      map((peers) =>
        size(lodashFilter(peers, (peer, peerId) => peerId !== selfPeerId && !!peer.isConnected)),
      ),
    );

// export const observePeerForSession = (sessionId: SessionId, peerId: RemotePeerId) =>
//   db
//     .from(`sessions/${sessionId as string}/peers/${peerId as string}`)
//     .whenChanged()
//     .pipe(
//       pluck('val'),
//       filterIsTruthy(),
//       map(
//         (peer) =>
//           ({
//             peerId,
//             avatarUrl: peer.public?.avatarUrl,
//             displayName: peer.public?.displayName,
//             isCameraEnabled: peer.live?.isMediaEnabled.camera,
//             isInactive: peer.live?.isInactive,
//             isJoined: isConnectedToRoomProvider(peer),
//             isKnockMicEnabled: peer.live?.isKnockMicEnabled,
//             isMicEnabled: peer.live?.isMediaEnabled.mic,
//             isScreenShareEnabled: peer.live?.isMediaEnabled.screen,
//             lastInactivityUpdateTs: peer.live?.lastInactivityUpdateTs,
//             sessionId,
//             userId: peer.public?.fbUserId,
//             knockStatus: peer.live?.knockStatus,
//           } as VoFloofPeer),
//       ),
//     );

export const observePeerData = (sessionId: SessionId, peerId: RemotePeerId) =>
  db
    .from(`sessions/${sessionId as string}/peers/${peerId as string}`)
    .whenChild('added', 'changed', 'removed');

export const observePeerIsConnected = (sessionId: SessionId, peerId: RemotePeerId) =>
  db
    .from(`sessions/${sessionId as string}/peers/${peerId as string}/isConnected`)
    .whenChanged()
    .pipe(pluck('val'));

export const observePeerTracks = (sessionId: SessionId, peerId: RemotePeerId) =>
  db
    .from<any, DbPeer['tracks']>(`sessions/${sessionId as string}/peers/${peerId as string}/tracks`)
    .whenChild('added', 'changed', 'removed');

export const observeHostnameForSessionId$ = (sessionId: SessionId) =>
  (db.from(`sessions/${sessionId as string}/mediaServer`) as RxFirebaseDbWrapper<FloofMediaServerAssigned>)
    .from('hostname')
    .whenChanged()
    .pipe(pluck('val'));

export const generatePeerIdForSession = (sessionId: SessionId) =>
  db.from(`sessions/${sessionId as string}/peers`).getPushKeyWithPrefix('p') as RemotePeerId;

export const upsertPeerTrackInfo = async ({
  peerId,
  sessionId,
  trackInfo,
}: {
  peerId: RemotePeerId;
  sessionId: SessionId;
  trackInfo: { logicalTrackId: string } & (
    | Partial<Omit<PeerVideoTrackInfo, 'ts' | 'logicalTrackId'>>
    | Partial<Omit<PeerAudioTrackInfo, 'ts' | 'logicalTrackId'>>
  );
}) => {
  const trackDbRef = db.from(
    `sessions/${sessionId as string}/peers/${peerId as string}/tracks/${
      trackInfo.logicalTrackId
    }` as $anyFixMe,
  );

  void update(trackDbRef.ref(), {
    ...(!(await trackDbRef.exists()) && { ts: serverTimestamp() }),
    ...trackInfo,
  });
};

export const insertTrackTransportId = ({
  selfPeerId,
  sessionId,
  logicalTrackId,
  trackTransportId,
  ...etc
}: {
  selfPeerId: RemotePeerId;
  sessionId: SessionId;
  logicalTrackId: string;
  trackTransportId: string;
} & ({ transport: 'sfu' } | { transport: 'p2p'; remotePeerId: RemotePeerId })) => {
  const transportIdsRef = db.from(
    `sessions/${sessionId as string}/peers/${
      selfPeerId as string
    }/tracks/${logicalTrackId}/trackTransportIds/${etc.transport}` as $anyFixMe,
  );

  if (etc.transport === 'sfu') {
    return transportIdsRef.set(trackTransportId);
  } else if (etc.transport === 'p2p') {
    return update(transportIdsRef.ref(), {
      [etc.remotePeerId]: trackTransportId,
    });
  }
};

export const removePeerTrackInfo = async ({
  peerId,
  sessionId,
  logicalTrackId,
}: {
  peerId: RemotePeerId;
  sessionId: SessionId;
  logicalTrackId: string;
}) =>
  db
    .from(`sessions/${sessionId as string}/peers/${peerId as string}/tracks/${logicalTrackId}` as $anyFixMe)
    .remove();
