import { ComponentProps } from 'react';
import styled, { css } from 'styled-components';

import { $anyFixMe } from 'common/utils/ts-utils';
import { blockAllForwardedPropsConfig } from 'utils/react-utils';

import { focusStyle } from './a11y';
import { BasePopUiComponent, BaseProps } from './base-pop-ui-component';

export const FlexBox = styled(BasePopUiComponent).withConfig({
  ...blockAllForwardedPropsConfig(['direction', 'interMargin', 'inline']),
  displayName: 'FlexBox',
})<
  {
    direction?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
    inline?: boolean;
    interMargin?: number;
    width?: any;
  } & BaseProps
>`
  ${({ inline, width }) =>
    inline
      ? css`
          display: inline-flex;
        `
      : css`
          display: flex;
          ${!width && 'width: 100%;'}
        `}

  ${({ direction }) => css`
    flex-direction: ${direction ?? 'row'};
  `};

  ${({ interMargin, direction }) =>
    interMargin &&
    css`
      > :not(:last-child) {
        ${!direction || direction === 'row' ? 'margin-right' : 'margin-bottom'}: ${interMargin}px;
      }
    `};
`;

export type FlexBoxProps = ComponentProps<typeof FlexBox>;

export const FlexBoxWithFocusVisibleStyles = styled(FlexBox).withConfig({
  ...blockAllForwardedPropsConfig(['alternateFocusBorderColor']),
})<{ alternateFocusBorderColor?: string }>`
  &:focus-visible {
    ${focusStyle()}
    ${({ alternateFocusBorderColor }) =>
      alternateFocusBorderColor &&
      css`
        border-color: ${alternateFocusBorderColor};
      `}
  }
` as $anyFixMe; // not sure why this isn't working — maybe this will be fixed in later TS.

export const NonEmptyFlexBox = styled(FlexBox)`
  &:empty {
    display: none;
  }
`;

export const FourCornersFlexBox = ({
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  gap,
  ...etc
}: {
  topLeft?: JSX.Element;
  topRight?: JSX.Element;
  bottomLeft?: JSX.Element;
  bottomRight?: JSX.Element;
  gap?: string;
} & FlexBoxProps) => (
  <FlexBox direction="column" justifyContent="space-between" {...etc}>
    <FlexBox flex="1 1" justifyContent="space-between">
      <FlexBox inline gap={gap} flexWrap="wrap" alignItems="flex-start">
        {topLeft}
      </FlexBox>
      <FlexBox inline gap={gap} flexWrap="wrap" direction="row-reverse" alignItems="flex-start">
        {topRight}
      </FlexBox>
    </FlexBox>
    <FlexBox flex="1 1" gap={gap} alignItems="flex-end" justifyContent="space-between">
      <FlexBox inline gap={gap} flexWrap="wrap-reverse">
        {bottomLeft}
      </FlexBox>
      <FlexBox inline gap={gap} flexWrap="wrap-reverse" direction="row-reverse">
        {bottomRight}
      </FlexBox>
    </FlexBox>
  </FlexBox>
);
