import { useEffect } from 'react';
import { createGlobalState } from 'react-use';

export const useAreKeyListenersSuspended = () => {
  const [requestCount] = useRefCountedKeyListenerSuspendRequests();
  return requestCount > 0;
};

const useRefCountedKeyListenerSuspendRequests = createGlobalState<number>(0);

export const useSuspendKeyListeners = () => {
  const [_, setRequestCount] = useRefCountedKeyListenerSuspendRequests();

  useEffect(() => {
    setRequestCount((requestCount) => requestCount + 1);
    return () => void setRequestCount((requestCount) => requestCount - 1);
  }, [setRequestCount]);
};
