import { NEVER } from 'rxjs';

import { isDesktopApp } from 'utils/client-utils';

export const makeKeyedMetaSelector =
  <Keys extends string, Selectors extends (...args: any[]) => any, M extends { [k in Keys]: Selectors }>(
    map: M,
  ) =>
  <S, Key extends keyof M>(state: S, key: Key): ReturnType<M[Key]> =>
    map[key](state);

export const desktopAppEpic = <T>(epic: T): T => {
  if (isDesktopApp) return epic;
  return (() => NEVER) as any;
};
