import { find, flatten, identity, last } from 'lodash';
import { RouteObject, generatePath, useParams, useMatches } from 'react-router-dom';
import { Observable } from 'rxjs';

import { FirstArgument } from 'common/utils/ts-utils';
import { getRouter } from 'utils/get-router';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';

import { PageId } from '../../route-definitions';

export type PageRouteObject = Omit<RouteObject, 'children'> & {
  pageId?: PageId;
  children?: PageRouteObject[];
};

/**
 * This magical function recursively calls itself to find a given page ID, and
 * then creates a URL based on the tree lineage from the root to the leaf node
 * with that page ID.
 */
export const generateUrlForPageIdWithParams = (
  pageId: PageId,
  params?: any,
  partialUrl = '',
  routes = getRouter().routes,
): any =>
  find(
    flatten(
      routes.map((route) => {
        const url =
          route.path && route.path !== '*'
            ? [partialUrl, route.path.replace('/*', '')].join('/')
            : partialUrl;
        if (route.handle?.pageId === pageId) {
          try {
            return generatePath(url, params);
          } catch (error) {
            return;
          }
        }
        if (!route.children) return;
        return generateUrlForPageIdWithParams(pageId, params, url, route.children);
      }),
    ),
    identity,
  );

export const useGenerateUrlForPageIdWithCurrentParams = (pageId: PageId, params: any) =>
  generateUrlForPageIdWithParams(pageId, { ...useParams(), ...params });

export const usePageId = () => {
  const match = last(useMatches());
  return (match?.handle as any)?.pageId as PageId | undefined;
};

export const getCurrentPageId = () =>
  last(getRouter().state.matches)?.route.handle?.pageId as PageId | undefined;

export const getCurrentUrlParams = () => last(getRouter().state.matches)?.params;

export const observeLocationChanges = () =>
  new Observable<FirstArgument<FirstArgument<ReturnType<typeof getRouter>['subscribe']>>>((observer) => {
    const unsubscribe = getRouter().subscribe((state) => {
      observer.next(state);
    });
    return () => unsubscribe;
  });

exposeToGlobalConsole({ generateUrlForPageIdWithParams });
