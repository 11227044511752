import { ReactChildren } from 'utils/react-utils';

import { FlexBox, FlexBoxProps } from './flex-box';
import { BodyText } from './text';

export const Label = ({
  text,
  children,
  ...rest
}: { text: string; children: ReactChildren } & FlexBoxProps) => (
  <FlexBox as="label" direction="column" interMargin={8} {...rest}>
    <BodyText bold>{text}</BodyText>
    {children}
  </FlexBox>
);
