import { identity, isArray } from 'lodash';
import { filter, ignoreElements } from 'rxjs';

import { log } from 'common/utils/custom-rx-operators';

import { EpicWithDeps } from '../redux/app-store';

window['shouldLogRedux'] = false;
// window['shouldLogRedux'] = true;

export const debugAllReduxActionsEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    filter(
      (action) =>
        window['shouldLogRedux'] &&
        (!isArray(window['shouldLogRedux']) || !window['shouldLogRedux'].includes(action.type)),
    ),
    log('redux', identity),
    ignoreElements(),
  );
