import {
  Action,
  CaseReducer,
  createAction as toolkitCreateAction,
  createSelector,
  PayloadAction,
  PayloadActionCreator,
  PrepareAction,
} from '@reduxjs/toolkit';
import { TypedActionCreator } from '@reduxjs/toolkit/dist/mapBuilders';
import produce, { applyPatches, current, enablePatches, original, produceWithPatches } from 'immer';
import { isEqual, isObject, isString, isUndefined, merge } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import type { Observable } from 'rxjs';

import { debugCheck } from 'utils/debug-check';
import { isShallowArrayEqual } from 'utils/is-shallow-array-equal';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';
import { UseDispatchActionMeta } from 'utils/react/use-dispatch';
import { WithoutEmptyObject } from 'utils/react-utils';
import { storage } from 'utils/storage';

import type { RootReduxState, RootReduxDispatch } from './app-store';
import { createGlobalAsyncAction } from './create-async-action';

export const MARK_AS_REDUX_SELECTOR = 'MARK_AS_REDUX_SELECTOR';

export type UndoableState = {
  undo: {
    [stackId: string]: {
      pointer: number;
      stack: any[][];
    };
  };
};

export const defaultUndoableState = {
  undo: {},
};

const createDefaultUndoStack = () => ({
  pointer: 0,
  stack: [],
});

type WithUndoOption<State, ActionType> =
  | boolean
  | string
  | ((state: State, action: ActionType) => boolean | string);

export type Thunk<S, PayloadType, ReturnType> = (
  dispatch: RootReduxDispatch,
  getState: () => S,
  payload: PayloadType,
  extraArgument: {
    injector: any;
    ngxsStore: any;
    ngxsActions$: any;
    action$: Observable<Action<any>>;
  },
) => ReturnType;

enablePatches();

export function createGlobalSelector<
  SelectorFn extends (state: WithoutEmptyObject<RootReduxState>, ...args: any[]) => any,
>(selectorFn: SelectorFn) {
  return markAsReduxSelector(selectorFn);
}

export const createGlobalParametricMemoizedSelector = ((...args: any[]) => {
  const outputSelector = (createCachedSelector as any)(...args);
  return (...args: any[]) => markAsReduxSelector(outputSelector(...args));
}) as typeof createCachedSelector;

const createGlobalSelectorWithSelectorCreator = (selectorCreator: (...args: any[]) => any) =>
  ((...args: any[]) => markAsReduxSelector(selectorCreator(...args))) as typeof createSelector;

export const markAsReduxSelector = <T>(selectorFn: T) => {
  (selectorFn as any)[MARK_AS_REDUX_SELECTOR] = true;
  return selectorFn;
};

const globalCreatedActionTypes = new Set();
export const createGlobalAction = <PayloadType = void, Type extends string = string>(type: Type) => {
  debugCheck(!globalCreatedActionTypes.has(type), `Unexpected duplicated global action type ${type}.`);
  globalCreatedActionTypes.add(type);
  const action = toolkitCreateAction<PayloadType, Type>(type);

  // So we can access, e.g., globals.actions.restartApp
  exposeToGlobalConsole({ actions: { ...(window as any)['globals'].actions, [type]: action } });

  return action;
};

export const createGlobalThunk =
  <S = RootReduxState, PayloadType = void, ReturnType = void>(
    _name: string,
    thunk: Thunk<S, PayloadType, ReturnType>,
  ) =>
  (payload: PayloadType) => {
    const outer = (dispatch: any, getState: any, extraArgument: any) =>
      thunk(dispatch, getState, payload, {
        ...(extraArgument ?? {}),
        meta: (outer as any).meta,
      });
    return outer;
  };

export type Selector<S, R, Args extends unknown[]> = (state: S, ...args: Args) => R;

export function createSlice<
  StateType,
  T extends string,
  UndoActionPayload,
  K extends (keyof StateType | { key: keyof StateType; version: number })[],
>(
  this: void,
  name: T,
  initialState?: StateType,
  options: {
    undoStackKeyFn?: (state: StateType, action: PayloadAction<UndoActionPayload>) => string;
    persistKeys?: K;
  } = {},
) {
  type StateSlice = { [k in T]: StateType };

  const createdActionTypes = new Set();

  function createAction<PayloadType = void, SubType extends string = string>(
    this: void,
    subType: SubType,
  ): PayloadActionCreator<
    PayloadType,
    `${T}/${SubType}`,
    (...args: any[]) => { payload: PayloadType; meta: UseDispatchActionMeta }
  >;
  function createAction<PA extends PrepareAction<any>, SubType extends string = string>(
    this: void,
    subType: SubType,
    prepare: PA,
  ): PayloadActionCreator<
    ReturnType<PA>['payload'],
    `${T}/${SubType}`,
    (...args: any[]) => ReturnType<PA> & { meta: UseDispatchActionMeta }
  >;
  function createAction(subType: any, prepare?: any) {
    debugCheck(
      !createdActionTypes.has(subType),
      `Unexpected duplicated action type ${subType} in ${name} slice.`,
    );
    createdActionTypes.add(subType);
    return toolkitCreateAction(`${name}/${subType}` as const, prepare);
  }

  function createThunk<S = { [t in T]: StateType }, PayloadType = void, ReturnType = void>(
    this: void,
    // Maybe someday I'll figure out what to do with this for debugging.
    name: string,
    thunk: Thunk<S, PayloadType, ReturnType>,
  ) {
    return createGlobalThunk<S, PayloadType, ReturnType>(name, thunk);
  }
  const wrappedCreateSelector = <R, Args extends unknown[]>(
    selectorFn: Selector<StateSlice, R, Args>,
  ): Selector<StateSlice, R, Args> => markAsReduxSelector(selectorFn);

  const undoAction = createAction<UndoActionPayload>('undo' as const);
  const redoAction = createAction<UndoActionPayload>('redo' as const);
  const getIsRedoable = wrappedCreateSelector((state, payload: UndoActionPayload) => {
    const stackKey = `${options.undoStackKeyFn!(state[name], undoAction(payload))}-redo`;
    return (state[name] as UndoableState).undo[stackKey]?.pointer > 0;
  });
  const makePersistStorageKey = (key: string | number) => `persisted-${name}-${key}`;

  function getPersistedValue<V extends K[number]>(this: void, key: V) {
    return storage.get(makePersistStorageKey(key as any) as any) as StateType[V] | undefined;
  }

  return {
    createParametricMemoizedSelector: createGlobalParametricMemoizedSelector,

    createMemoizedSelector: createGlobalMemoizedSelector,

    getPersistedValue,

    createSelector: wrappedCreateSelector,

    // Pull out this func to do some TS overloading magic.
    createAction,

    undoActions: {
      undo: undoAction,
      redo: redoAction,
      getIsRedoable,
    },

    createAsyncAction<PayloadType = void, ResponseType = void>(this: void, subType: string) {
      debugCheck(
        !createdActionTypes.has(subType),
        `Unexpected duplicated action type ${subType} in ${name} slice.`,
      );
      createdActionTypes.add(subType);
      return createGlobalAsyncAction<PayloadType, ResponseType>(`${name}/${subType}`);
    },

    createThunk,

    createToggleThunk<ThunkPayload = void>(
      this: void,
      {
        actionCreator,
        selector,
      }: {
        actionCreator: (payload: boolean) => any;
        selector: Selector<StateSlice, boolean, [ThunkPayload, { meta: UseDispatchActionMeta }]>;
      },
    ) {
      return createThunk(
        `makeToggleThunk/${actionCreator.name}`,
        (dispatch: RootReduxDispatch, getState, payload: ThunkPayload, extraArgument) => {
          const action = actionCreator(!selector(getState(), payload, extraArgument as any));
          action.meta = (extraArgument as any)?.meta;
          dispatch(action);
        },
      );
    },

    createReducer(this: void) {
      const handlers: Record<
        string,
        { caseReducer: (...args: any) => any; options: { withUndo?: WithUndoOption<StateType, any> } }
      > = {};
      const frozenInitialState = produce(
        merge(
          { ...initialState },
          options.undoStackKeyFn && defaultUndoableState,
          options.persistKeys &&
            options.persistKeys.reduce((memo, maybeVersionedKey) => {
              const { key, version } = isObject(maybeVersionedKey)
                ? maybeVersionedKey
                : { key: maybeVersionedKey as keyof StateType, version: undefined };
              const item = getPersistedValue(key as any);
              const storedVersion = getPersistedValue(`${key}-version` as any);
              if (item !== null && item !== undefined && storedVersion === version) memo[key] = item;
              return memo;
            }, {} as Partial<StateType>),
        ),
        () => {},
      );

      const reducer = (state = frozenInitialState, action: Action): StateType => {
        if (!handlers[action.type]) return state! as StateType;
        const {
          caseReducer,
          options: { withUndo },
        } = handlers[action.type];

        const handler = !options.persistKeys
          ? caseReducer
          : (draftState: any, action: any) => {
              caseReducer(draftState, action);

              const originalState = original(draftState);
              const changedState = current(draftState);
              options.persistKeys!.forEach((maybeVersionedKey) => {
                const { key, version } = isObject(maybeVersionedKey)
                  ? maybeVersionedKey
                  : { key: maybeVersionedKey as keyof StateType, version: undefined };
                if (originalState[key] === changedState[key]) return;
                storage.set(`persisted-${name}-${key}` as any, changedState[key]);
                if (!isUndefined(version)) storage.set(`persisted-${name}-${key}-version` as any, version);
              });
            };

        const undoStackId = intepretWithUndoOption(withUndo as any, state, action);
        if (undoStackId) {
          const [nextState, _forwardPatch, reversePatch] = produceWithPatches(state, (draftState) =>
            handler(draftState, action),
          );
          return produce(nextState, (draftState: UndoableState) => {
            debugCheck(!!draftState.undo, 'Expected reducer employing `withUndo` to extend UndoableState.');
            if (!draftState.undo[undoStackId]) draftState.undo[undoStackId] = createDefaultUndoStack();
            // We track the reversePatches of the undo action via the
            // `${undoStackId}-redo` because consumers can pick and choose which
            // actions to include `withUndo`. If you naively store the
            // forwardPatch to reverse the undo (aka redo), there's a subtle
            // issue because you'd need to replay all actions between the
            // originating action and the undo action to get back to what the
            // user thinks the state is. Instead, we just undo the state change
            // we made with the undo, ensuring the state is back to where it was
            // when the user clicked undo.
            if (!draftState.undo[`${undoStackId}-redo`])
              draftState.undo[`${undoStackId}-redo`] = createDefaultUndoStack();
            draftState.undo[undoStackId].pointer++;
            draftState.undo[undoStackId].stack.length = draftState.undo[undoStackId].pointer;
            draftState.undo[undoStackId].stack[draftState.undo[undoStackId].pointer - 1] = reversePatch;
          }) as any;
        }

        return handler ? (produce(state, (draftState) => handler(draftState, action)) as any) : state;
      };

      const on = <ActionCreator extends TypedActionCreator<string>>(
        actionCreator: ActionCreator,
        caseReducer: CaseReducer<StateType, ReturnType<ActionCreator>>,
        options: { withUndo?: WithUndoOption<StateType, ReturnType<ActionCreator>> } = {},
      ) => {
        handlers[actionCreator.type] = {
          caseReducer,
          options,
        };
        return reducer as ExtendedReducer;
      };

      // The undoStackKeyFn allows you to dispatch undo or redo actions and line
      // it up with whichever undo stack you're targeting. This will correspond
      // with one or many of the keys that are being tracked by the withUndo
      // property of reducers.
      if (options.undoStackKeyFn) {
        const undoForStackKey = (state: UndoableState, stackKey: string) => {
          const undo = state.undo[stackKey];
          if (undo.pointer === 0) return;
          undo.pointer--;
          const reversePatches = undo.stack[undo.pointer];
          applyPatches(state, reversePatches);
        };

        on(
          undoAction,
          (state, action) => {
            undoForStackKey(state as any, options.undoStackKeyFn!(state as any, action as any));
          },
          {
            withUndo: (state, action) => `${options.undoStackKeyFn!(state, action as any)}-redo`,
          },
        );

        on(redoAction, (state, action) => {
          undoForStackKey(state as any, `${options.undoStackKeyFn!(state as any, action as any)}-redo`);
        });
      }

      type ExtendedReducer = typeof reducer & { on: typeof on };
      (reducer as any).on = on;
      return reducer as ExtendedReducer;
    },
  };
}

// Just some ergonomics magic to take an overloaded variable and do the right
// thing with it.
const intepretWithUndoOption = (
  withUndo: WithUndoOption<any, any>,
  state: any,
  action: any,
): false | string => {
  if (!withUndo) return false;
  if (withUndo === true) return 'default';
  if (isString(withUndo)) return withUndo;
  const result = withUndo(state, action);
  return intepretWithUndoOption(result as any, state, action);
};

/**
 * This will work exactly the same as the default createSelector for
 * memoization, except when comparing a shallow array, in which case it will
 * recurse one level and then check reference equality for each element. This is
 * useful for avoiding re-renders but you need to calculate an array of IDs in a
 * selector.
 */
export const createShallowArrayEqualSelector = createSelectorCreator(defaultMemoize, isShallowArrayEqual);
export const createIsEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const createGlobalMemoizedSelector = createGlobalSelectorWithSelectorCreator(createSelector);

export const createGlobalMemoizedIsEqualSelector =
  createGlobalSelectorWithSelectorCreator(createIsEqualSelector);
export const createGlobalMemoizedShallowArrayEqualSelector = createGlobalSelectorWithSelectorCreator(
  createShallowArrayEqualSelector,
);
