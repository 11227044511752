import { fromEvent, merge, Observable, mergeMap, takeUntil, filter } from 'rxjs';

import { AnyPeerId, RemotePeerId, SessionId } from 'common/models/db/vo.interface';
import { ofActionPayload } from 'common/utils/custom-rx-operators';

import { leaveSession } from '../sessions/sessions.slice';

import { connectToFloofSession, peerJoined, peerLeft } from './floof.slice';

export const mergeMapDuringSession =
  <T extends Observable<any>, R>(obsFn: (sessionId: SessionId) => Observable<R>) =>
  (input$: T) =>
    input$.pipe(
      ofActionPayload(connectToFloofSession),
      mergeMap(({ sessionId }) =>
        obsFn(sessionId).pipe(
          takeUntil(
            merge(
              input$.pipe(
                ofActionPayload(leaveSession),
                filter(({ sessionId: leavingSessionId }) => leavingSessionId === sessionId),
              ),
              fromEvent(window, 'beforeunload'),
            ),
          ),
        ),
      ),
    );

export const mergeMapJoinedRemotePeer = <T extends Observable<any>, R>(
  obsFn: (payload: { sessionId: SessionId; peerId: RemotePeerId }) => Observable<R>,
) => mergeMapJoinedPeer<T, R>(obsFn as any, ['self']);

export const mergeMapJoinedPeer =
  <T extends Observable<any>, R>(
    obsFn: (payload: { sessionId: SessionId; peerId: AnyPeerId }) => Observable<R>,
    filteredPeerIds: AnyPeerId[] = [],
  ) =>
  (input$: T) =>
    input$.pipe(
      mergeMapDuringSession((sessionId) =>
        input$.pipe(
          ofActionPayload(peerJoined),
          filter(({ peerId }) => !filteredPeerIds.includes(peerId)),
          mergeMap(({ peerId }) =>
            obsFn({ sessionId, peerId }).pipe(
              takeUntil(
                input$.pipe(
                  ofActionPayload(peerLeft),
                  filter(({ peerId: leavingPeerId }) => peerId === leavingPeerId),
                ),
              ),
            ),
          ),
        ),
      ),
    );
