/* eslint sort-keys-fix/sort-keys-fix: "error" */
import { flatMap, values } from 'lodash';
import { combineEpics } from 'redux-observable';

import * as auth from '../features/auth/auth.epics';
import * as environment from '../features/environment/environment.epics';
import * as featureFlags from '../features/feature-flags/feature-flags.epics';
import * as router from '../features/router/router.epics';

export const sharedEpics = combineEpics(...flatMap([auth, environment, featureFlags, router], values));
