import { distinctUntilChanged, map, mergeMap, switchMap, take, tap } from 'rxjs';

import { filterIsFalsey, ignoreElements, filterIsTruthy } from 'common/utils/custom-rx-operators';
import { rectToFlatRect } from 'common/utils/geometry-utils';
import { observeUnsubscribe } from 'common/utils/observe-unsubscribe';

import { sendIpc } from '../../ipc/send-ipc';
import { EpicWithDeps } from '../../redux/app-store';
import { mergeMapDuringSession } from '../floof/floof.utils';

import { getSharedScreenId, getSharedScreenRect } from './screens.slice';

export const listenForScreenUpdatesWhileInConferenceEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    mergeMapDuringSession(() => {
      void sendIpc('toggleUpdateScreensSubscription', true);
      return observeUnsubscribe(() => {
        void sendIpc('toggleUpdateScreensSubscription', false);
      });
    }),
  );

export const showGreenBorderOnScreenShareEpic: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    map((state) => getSharedScreenId(state)),
    distinctUntilChanged(),
    filterIsTruthy(),
    mergeMap(() =>
      sendIpc('createScreenShareRectIndicator', { rect: rectToFlatRect(getSharedScreenRect(state$.value)!) }),
    ),
    switchMap((requestId) =>
      state$.pipe(
        map((state) => !!getSharedScreenId(state)),
        distinctUntilChanged(),
        filterIsFalsey(),
        tap(() => sendIpc('destroyScreenShareRectIndicator', requestId)),
        take(1),
      ),
    ),
    ignoreElements(),
  );
