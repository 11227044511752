import { filter, map, tap } from 'rxjs';

import { ignoreElements, ofActionPayload } from 'common/utils/custom-rx-operators';
import { $anyFixMe } from 'common/utils/ts-utils';
import { peerMessageReceived } from 'pages/vo/vo-react/features/floof/floof.slice';
import { getCurrentSessionId } from 'pages/vo/vo-react/features/sessions/sessions.slice';
import { getFloofConnection } from 'utils/floof-sdk/floof-sdk';

import { EpicWithDeps } from '../../redux/app-store';

import { mirrorActionToAllPeers } from './remote-actions.actions';
import { remoteActionsAllowList } from './remote-actions.utils';

export const executeRemoteActionLocallyEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(ofActionPayload(peerMessageReceived)).pipe(
    filter(
      ({ peerMessage }) =>
        peerMessage.messageType === 'remote-action' &&
        remoteActionsAllowList.includes(peerMessage.type as any),
    ),
    map(({ peerMessage }: any) => ({ type: peerMessage.type, payload: peerMessage.payload })),
  );

export const mirrorActionToAllPeersEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(mirrorActionToAllPeers),
    tap((action) => {
      const sessionId = getCurrentSessionId(state$.value);
      if (!sessionId) return;
      void getFloofConnection(sessionId)?.sendPeerMessage({
        messageType: 'remote-action',
        ...(action as $anyFixMe),
      });
    }),
    ignoreElements({ silenceDevEpicWarning: true }),
  );
