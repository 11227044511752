import React from 'react';

import { popColor } from 'utils/react/colors';

export const focusStyle = (isImportant = false) => `
  border: 1px solid ${popColor('primary2')} ${isImportant ? '!important' : ''};
  box-shadow: 0px 0px 0px 4px ${popColor('primary3', 0.25)};
`;

export const focusRingProps = {
  border: `1px solid ${popColor('primary2')}`,
  boxShadow: `0px 0px 0px 4px ${popColor('primary3', 0.25)}`,
};

export const isAriaToggleKeyEvent = (event: KeyboardEvent | React.KeyboardEvent) =>
  event.key === 'Enter' || event.key === ' ';
