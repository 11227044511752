import React from 'react';

export class ErrorBoundary extends React.Component<any, { error?: string }> {
  constructor(props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { error: error.message };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error('Error caught by Error Boundary:', error, errorInfo);
  }

  render() {
    const { name, children, ...etc } = this.props;
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        // Don't use FlexBox here in case _that's_ broken.
        <div
          style={{ padding: '10%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
          {...etc}
        >
          <h1
            style={{
              textAlign: 'center',
              fontSize: '100px',
              marginTop: '-20%',
            }}
          >
            🐛
          </h1>
          <h1>There's been a terrible accident</h1>
          {name && <h6>Name: {name}</h6>}
          <code>{this.state.error}</code>
        </div>
      );
    }

    return children;
  }
}
