import { reject } from 'lodash';

import { MediaServerType } from 'common/models/db/media-server.interface';
import { OrgId, InstantMeetingId, UserId, SessionId } from 'common/models/db/vo.interface';
import { VoInstantMeeting } from 'pages/vo/vo-react/features/instant-meetings/instant-meetings.types';

import { createSlice } from '../../redux/create-slice';

export type InstantMeetingsSlice = {
  byId: { [id: string]: VoInstantMeeting };
  allIds: InstantMeetingId[];
};

const initialState: InstantMeetingsSlice = {
  byId: {},
  allIds: [],
};
export const { createReducer, createSelector, createAction, createMemoizedSelector } = createSlice(
  'instantMeetings',
  initialState,
);

export const dbInstantMeetingCreatedOrUpdated = createAction<VoInstantMeeting>(
  'dbInstantMeetingCreatedOrUpdated',
);
export const dbInstantMeetingDeleted = createAction<InstantMeetingId>('dbInstantMeetingDeleted');

export const createAndJoinInstantMeeting = createAction<{
  mediaServerType?: MediaServerType;
  instantMeeting: Omit<VoInstantMeeting, 'id' | 'sessionGroupId'>;
}>('createAndJoinInstantMeeting');
export const updateInstantMeeting = createAction<Omit<VoInstantMeeting, 'sessionId'>>('updateInstantMeeting');
export const deleteInstantMeeting =
  createAction<{ instantMeetingId: InstantMeetingId; orgId: OrgId }>('deleteInstantMeeting');
export const removeInstantMeeting = createAction<{
  id: InstantMeetingId;
  orgId: OrgId;
  sessionId: SessionId;
}>('removeInstantMeeting');

export default createReducer()
  .on(dbInstantMeetingCreatedOrUpdated, (state, { payload: instantMeeting }) => {
    state.allIds.push(instantMeeting.id);
    state.byId[instantMeeting.id] = {
      ...(state.byId[instantMeeting.id] ?? { userIds: [] }),
      ...instantMeeting,
    };
  })
  .on(dbInstantMeetingDeleted, (state, { payload: instantMeetingId }) => {
    state.allIds = reject(state.allIds, (id) => id === instantMeetingId);
    delete state.byId[instantMeetingId];
  });

export const getInstantMeetingById = createSelector(
  (state, instantMeetingId: InstantMeetingId) => state.instantMeetings.byId[instantMeetingId],
);
export const getAllInstantMeetingIds = createSelector((state) => state.instantMeetings.allIds);
export const getInstantMeetingNameById = createSelector(
  (state, instantMeetingId: InstantMeetingId) => state.instantMeetings.byId[instantMeetingId]?.name,
);
export const getInstantMeetingUsersById = createSelector(
  (state, instantMeetingId: InstantMeetingId) => state.instantMeetings.byId[instantMeetingId]?.userIds ?? [],
);
export const getIsUserLockedOutFromInstantMeeting = createSelector(
  (state, { instantMeetingId, userId }: { instantMeetingId: InstantMeetingId; userId: UserId }) =>
    !getInstantMeetingUsersById(state, instantMeetingId)?.includes(userId),
);
export const getSessionGroupIdForInstantMeeting = createSelector(
  (state, instantMeetingId: InstantMeetingId) => state.instantMeetings.byId[instantMeetingId]?.sessionGroupId,
);
