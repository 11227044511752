import { createSlice } from '../../redux/create-slice';

export type ZoomSlice = {
  isAuthorized?: boolean;
  isRevokingAccess?: boolean;
};

const initialState: ZoomSlice = { isAuthorized: false, isRevokingAccess: false };

export const { createReducer, createSelector, createMemoizedSelector, createAction } = createSlice(
  'zoom',
  initialState,
);

export const setZoomIsAuthorized = createAction<boolean>('setZoomIsAuthorized');
export const revokeZoomAccess = createAction('revokeZoomAccess');

export const getIsZoomAuthorized = createSelector((state) => state.zoom.isAuthorized);
export const getIsZoomRevokingAccess = createSelector((state) => state.zoom.isRevokingAccess);

export default createReducer()
  .on(setZoomIsAuthorized, (state, { payload: isAuthorized }) => {
    state.isAuthorized = isAuthorized;
    state.isRevokingAccess = false;
  })
  .on(revokeZoomAccess, (state) => {
    state.isRevokingAccess = true;
  });
