import { useLayoutEffect, useState } from 'react';

import { domRectToRectangle, getZeroRectangle } from 'common/utils/geometry-utils';

import { useCurrentWindow } from './use-current-window';

export const useOffsetRect = ({ shouldUseRaf = false }: { shouldUseRaf?: boolean } = {}) => {
  const [element, setElement] = useState<HTMLElement>();
  const [rect, setRect] = useState(getZeroRectangle());
  const win = useCurrentWindow();

  useLayoutEffect(() => {
    if (!element || !win) return;
    const doSetRect = () =>
      setRect(
        domRectToRectangle({
          x: element.offsetLeft,
          y: element.offsetTop,
          width: element.offsetWidth,
          height: element.offsetHeight,
        }),
      );

    const resizeObserver = new win['ResizeObserver'](() => {
      if (shouldUseRaf) requestAnimationFrame(doSetRect);
      else doSetRect();
    });

    doSetRect();
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, [element, win, shouldUseRaf]);

  return [setElement, rect] as const;
};
