import { map, of, switchMap, timer } from 'rxjs';

import { RemotePeerId } from 'common/models/db/vo.interface';
import { emptyIceServers } from 'common/models/poe.interface';
import { apiCall } from 'pages/vo/vo-react/api';
import { isLocalhost } from 'utils/client-utils';
import { region$ } from 'utils/floof-sdk/utils/region';

export const observeIceServersForPeerId$ = (peerId: RemotePeerId) =>
  region$.pipe(
    switchMap((region) =>
      region === 'unknown-unknown'
        ? of(emptyIceServers)
        : timer(0, 60 * 1000).pipe(
            switchMap(() => apiCall('getIceServers', { iceProvider: 'poe', peerId, region })),
            map(({ isOk, iceServers }) => (isLocalhost || !isOk ? emptyIceServers : iceServers)),
          ),
    ),
  );
