import { createAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';
import { Action } from 'redux';
import { filter, firstValueFrom, map, merge, Observable } from 'rxjs';

import { ofAction } from 'common/utils/custom-rx-operators';

import type { ReduxStore } from './app-store';

/**
 * Epics can do a lot of things on their own, but they can't return information
 * easily to a component when the component asks for something. Async actions
 * allow this call and response from a component by optionally awaiting the
 * result of the dispatch in the component.
 */
export const createGlobalAsyncAction = <T, R = void>(name: string) => {
  type RequestWithId = T & { requestId: string };
  const start = createAction<RequestWithId>(`${name}/start`);
  const complete = createAction<{ request: RequestWithId; response: R }>(`${name}/complete`);
  const error = createAction<{ request: RequestWithId; error: any }>(`${name}/error`);

  const thunkCreator =
    (request: T) =>
    (
      dispatch: ReduxStore['dispatch'],
      getState: ReduxStore['getState'],
      { action$ }: { action$: Observable<Action<any>> },
    ) => {
      const requestId = uniqueId();

      const completionPromise = firstValueFrom(
        merge(action$.pipe(ofAction(complete)), action$.pipe(ofAction(error))).pipe(
          filter(({ payload: { request } }) => request.requestId === requestId),
          map(({ type, payload }) => {
            if (type === error.type) throw new Error((payload as any).error);
            return (payload as any).response as R;
          }),
        ),
      );

      dispatch(start({ requestId, ...request }));
      return completionPromise;
    };

  thunkCreator.start = start;
  thunkCreator.complete = complete;
  thunkCreator.error = error;

  return thunkCreator;
};
