import { SessionInitiator } from '../../models/db/session-initiatior.interface';
import { SessionId, UserId } from '../../models/db/vo.interface';
import { UniqueType } from '../../utils/ts-utils';

export type SessionHistoryId = UniqueType<string, 'SessionHistoryId'>;
export type SessionHistoryGroupId = UniqueType<string, 'SessionHistoryGroupId'>;
export type SessionHistoryEntryId = UniqueType<string, 'SessionHistoryEntryId'>;

export type SessionHistoryEntryType = 'drawing' | 'joined-or-left' | 'transcription';

export interface DbSessionHistory {
  sessionId: SessionId;
  sessionInitiator: SessionInitiator;
  sessionHistoryGroupId: SessionHistoryGroupId;
  startTs: number;
  orgIds: { [orgId: string]: boolean };
  name?: string;
  emoji?: string;
  entries?: {
    [sessionHistoryEntryId: string]: DbSessionHistoryEntry;
  };
}

export interface DbSessionHistoryGroup {
  sessionHistoryIds: {
    [sessionHistoryId: string]: boolean;
  };
}

export type DbSessionHistoryEntry = {
  ts: number;
  peerId: string;
  details: SessionHistoryEntryDetails;
  userId?: UserId;
  // For anonymous joins
  displayName?: string;
  avatarUrl?: string;
};

export type SessionHistoryEntryDetails =
  | SessionHistoryDetailsDrawing
  | SessionHistoryDetailsJoinedOrLeft
  | SessionHistoryDetailsTranscription;

export interface SessionHistoryDetailsDrawing {
  type: 'drawing';
  data: string;
}

export const isSessionHistoryEntryDetailsDrawing = (
  sessionHistoryEntryDetails: SessionHistoryEntryDetails,
): sessionHistoryEntryDetails is SessionHistoryDetailsDrawing =>
  sessionHistoryEntryDetails?.type === 'drawing';

export interface SessionHistoryDetailsJoinedOrLeft {
  type: 'joined-or-left';
  action: 'joined' | 'left';
  joinedOrLeftTs: number;
}

export const isSessionHistoryEntryDetailsJoinedOrLeft = (
  sessionHistoryEntryDetails: SessionHistoryEntryDetails,
): sessionHistoryEntryDetails is SessionHistoryDetailsJoinedOrLeft =>
  sessionHistoryEntryDetails?.type === 'joined-or-left';

export interface SessionHistoryDetailsTranscription {
  type: 'transcription';
  words: string;
  isFinal?: boolean;
  transcriptionType: 'speech' | 'text' | 'emoji';
}

export const isSessionHistoryEntryDetailsTranscription = (
  sessionHistoryEntryDetails: SessionHistoryEntryDetails,
): sessionHistoryEntryDetails is SessionHistoryDetailsTranscription =>
  sessionHistoryEntryDetails?.type === 'transcription';
