import { tap } from 'rxjs';

import { ignoreElements, log, ofActionPayload } from 'common/utils/custom-rx-operators';
import { getRouter } from 'utils/get-router';

import { SharedEpic } from '../../redux/shared-store';

import { pushLocation } from './router.slice';

/**
 * This epic enables us to support pushing relative paths, since the history npm
 * module doesn't seem to support them.
 */
export const pushLocationEpic: SharedEpic = (action$, state$) =>
  action$.pipe(ofActionPayload(pushLocation)).pipe(
    log('pushLocation', (relativePath) => relativePath),
    // map(({ payload: maybeRelativePathname }) =>
    //   (maybeRelativePathname ?? '/').startsWith('/')
    //     ? isDesktopApp
    //       ? maybeRelativePathname
    //       : `${getBaseName()}${maybeRelativePathname}`
    //     : reduce(
    //         maybeRelativePathname.split('/'),
    //         (memo, pathFragment) => {
    //           if (pathFragment === '..') memo.pop();
    //           else memo.push(pathFragment);
    //           return memo;
    //         },
    //         getCurrentLocation().pathname.split('/'),
    //       ).join('/'),
    // ),
    tap((relativePath) => {
      void getRouter().navigate(relativePath);
    }),
    ignoreElements({ silenceDevEpicWarning: true }),
  );
