import { Device as MediasoupDevice } from '@iteleport/mediasoup-client';
import { RtpCapabilities } from '@iteleport/mediasoup-client/lib/RtpParameters';
import autoBind from 'auto-bind';
import { DistributiveOmit } from 'react-redux';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

import { MediaType } from 'common/models/connection.interface';
import { RemotePeerId } from 'common/models/db/vo.interface';
import { MediasoupConsumerId, MediasoupProducerId, FloofMessages } from 'common/models/floof.interface';
import { filterIsTruthy } from 'common/utils/custom-rx-operators';
import { isChrome, isSafari } from 'utils/client-utils';
import { FloofAnalyticsDelegate } from 'utils/floof-sdk/floof-sdk';
import {
  MediaConnectionManager,
  MediaConnectionManagerDelegate,
} from 'utils/floof-sdk/media-connection/media-connection-manager';
import { SfuReceiveConnection } from 'utils/floof-sdk/media-connection/sfu/sfu-receive-connection';
import { SfuSendConnection } from 'utils/floof-sdk/media-connection/sfu/sfu-send-connection';

export interface SfuMediaConnectionManagerDelegate extends MediaConnectionManagerDelegate {
  onSfuSignalChannelMessageSendRequested: <T extends FloofMessages['requests']['client-to-server']['sfu']>(
    message: DistributiveOmit<T, 'response'>,
  ) => T['response'];
  onDisconnectRequested: () => void;
  onTrackSent: (info: { localTrackId: string; trackTransportId: string }) => void;
}

export class SfuMediaConnectionManager extends MediaConnectionManager {
  private mediasoupDevice?: MediasoupDevice;
  private sendConnection?: SfuSendConnection;
  private receiveConnection?: SfuReceiveConnection;
  private isSendConnectionConnected$ = new BehaviorSubject(false);
  constructor(
    protected selfPeerId: RemotePeerId,
    protected analyticsDelegate: FloofAnalyticsDelegate | undefined,
    protected delegate: SfuMediaConnectionManagerDelegate,
  ) {
    super(selfPeerId, analyticsDelegate);
    autoBind(this);
    void this.connect();
  }

  public async sendTrack(track: MediaStreamTrack | undefined, mediaType: MediaType) {
    if (!this.isSendConnectionConnected$.value)
      await firstValueFrom(this.isSendConnectionConnected$.pipe(filterIsTruthy()));
    return this.sendConnection?.sendTrack(track, mediaType);
  }

  private async connect() {
    this.mediasoupDevice = new MediasoupDevice();
    if (!this.mediasoupDevice) throw new Error('mediasoup client not found');

    const routerRtpCapabilities = (await this.delegate.onSfuSignalChannelMessageSendRequested({
      method: 'req-c2s-sfu-get-router-rtp-capabilities',
      data: {},
    })) as RtpCapabilities;
    if (!routerRtpCapabilities) return this.disconnect();

    if (!this.mediasoupDevice)
      return console.warn(
        'sfuMediaConnectionManager:connect(): no mediasoupDevice after get-router-rtp-capabilities, bailing…',
      );

    // fix ios rotation issue (from https://mediasoup.org/documentation/v3/tricks/)
    if (isSafari || isChrome)
      routerRtpCapabilities.headerExtensions = routerRtpCapabilities.headerExtensions?.filter(
        (ext) => ext.uri !== 'urn:3gpp:video-orientation',
      );

    await this.mediasoupDevice.load({ routerRtpCapabilities });
    await Promise.all([this.createSendConnection(), this.createReceiveConnection()]);

    if (!this.mediasoupDevice)
      return console.warn(
        'sfuMediaConnectionManager:connect(): no mediasoupDevice after create send/recv transports, bailing…',
      );

    await this.delegate.onSfuSignalChannelMessageSendRequested({
      method: 'req-c2s-sfu-join',
      data: {
        rtpCapabilities: this.mediasoupDevice.rtpCapabilities,
        sctpCapabilities: this.mediasoupDevice.sctpCapabilities,
      },
    });
  }

  private async createSendConnection() {
    if (!this.mediasoupDevice) return;
    this.sendConnection = new SfuSendConnection(this.selfPeerId, this.mediasoupDevice, {
      onSfuSignalChannelMessageSendRequested: (...args) =>
        this.delegate.onSfuSignalChannelMessageSendRequested(...args),
      onDisconnectRequested: (...args) => this.disconnect(...args),
      onTrackSent: (...args) => this.delegate.onTrackSent(...args),
    });
    await this.sendConnection.initialize();
    this.isSendConnectionConnected$.next(true);
  }

  private async createReceiveConnection() {
    if (!this.mediasoupDevice) return;
    this.receiveConnection = new SfuReceiveConnection(this.selfPeerId, this.mediasoupDevice, {
      onSfuSignalChannelMessageSendRequested: (...args) =>
        this.delegate.onSfuSignalChannelMessageSendRequested(...args),
      onTrackReceived: (...args) => this.delegate.onTrackReceived(...args),
      onTrackRemoved: (...args) => this.delegate.onTrackRemoved(...args),
      onDisconnectRequested: (...args) => this.disconnect(...args),
    });
    await this.receiveConnection.initialize();
  }

  public createConsumer(
    req: {
      peerId: RemotePeerId;
      producerId: MediasoupProducerId;
      id: MediasoupConsumerId;
      kind: any;
      rtpParameters: any;
      appData: any;
    },
    accept: () => void,
    reject: () => void,
  ) {
    void this.receiveConnection?.createConsumer(req, accept, reject);
  }

  public deleteConsumer(consumerId: MediasoupConsumerId) {
    this.receiveConnection?.deleteConsumer(consumerId);
  }

  public disconnect() {
    if (!this.mediasoupDevice) return;
    delete this.mediasoupDevice;

    this.isSendConnectionConnected$.next(false);
    this.sendConnection?.close();
    delete this.sendConnection;
    this.receiveConnection?.close();
    delete this.receiveConnection;
  }
}
