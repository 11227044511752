import { mapValues } from 'lodash';

const lazy = <T, K extends keyof T>(asyncComponent: () => Promise<T>, key: K | 'default' = 'default') => ({
  lazy: async () => ({ Component: (await asyncComponent())[key as K] }),
});

type RouteOption<T> = {
  lazy?: any;
  pageId?: T;
};

const transformRouteOptions = <T>(options: Record<string, RouteOption<T>>) =>
  mapValues(options, ({ pageId, ...rest }) => ({
    ...rest,
    handle: {
      pageId,
    },
  }));

const rawLazyRoutes = {
  Home: { ...lazy(() => import('./web/routes/home')), pageId: 'home' as const },
  SignUp: { ...lazy(() => import('./web/routes/sign-up')), pageId: 'sign-up' as const },
  SignIn: { ...lazy(() => import('./web/routes/sign-in')), pageId: 'sign-in' as const },
  SignOut: { ...lazy(() => import('./web/routes/sign-out')), pageId: 'sign-out' as const },
  RequireAuth: { ...lazy(() => import('utils/react/require-auth-route'), 'RequireAuth') },
  Pricing: { ...lazy(() => import('./web/routes/pricing')), pageId: 'pricing' as const },
  OpenDesktopApp: {
    ...lazy(() => import('./web/routes/open-desktop-app'), 'OpenDesktopApp'),
    pageId: 'open-desktop-app' as const,
  },
  DownloadApp: {
    ...lazy(() => import('./web/routes/download-app'), 'DownloadApp'),
    pageId: 'download-app' as const,
  },
  DownloadAppPlatform: {
    ...lazy(() => import('./web/routes/download-app'), 'DownloadAppPlatform'),
    pageId: 'download-app-platform' as const,
  },
  WebAppShell: { ...lazy(() => import('./web-app-shell')) },
  DesktopShell: {
    lazy: async () => {
      const [DesktopShell, AppError] = await Promise.all([import('./desktop-shell'), import('./app-error')]);
      return { Component: DesktopShell.default, ErrorBoundary: AppError.default };
    },
  },
  App: { ...lazy(() => import('pages/vo/vo-react/app'), 'App') },
  WebShell: { ...lazy(() => import('./web/web-shell')) },
  OrgSettings: {
    ...lazy(() => import('pages/vo/vo-react/components/details/org-settings'), 'OrgSettings'),
    pageId: 'org-settings' as const,
  },
  OrgPage: {
    ...lazy(() => import('pages/vo/vo-react/components/app-pages/org-page'), 'OrgPage'),
    pageId: 'org-page' as const,
  },
  TeamTranscript: {
    ...lazy(() => import('pages/vo/vo-react/components/details/team-transcript'), 'TeamTranscript'),
    pageId: 'team-transcript' as const,
  },
  TeamSettings: {
    ...lazy(() => import('pages/vo/vo-react/components/details/team-settings'), 'TeamSettings'),
    pageId: 'team-settings' as const,
  },
  AllTeams: {
    ...lazy(() => import('pages/vo/vo-react/components/details/all-teams'), 'AllTeams'),
    pageId: 'all-teams' as const,
  },
  SpaceSettings: {
    ...lazy(() => import('pages/vo/vo-react/components/details/space-settings'), 'SpaceSettings'),
    pageId: 'space-settings' as const,
  },
  AllSpaces: {
    ...lazy(() => import('pages/vo/vo-react/components/details/all-spaces'), 'AllSpaces'),
    pageId: 'all-spaces' as const,
  },
  NewMeeting: {
    ...lazy(() => import('pages/vo/vo-react/components/details/new-meeting'), 'NewMeeting'),
    pageId: 'new-meeting' as const,
  },
  JoinMeeting: {
    ...lazy(() => import('pages/vo/vo-react/components/details/new-meeting'), 'JoinMeeting'),
    pageId: 'join-meeting' as const,
  },
  EditAvailability: {
    ...lazy(() => import('pages/vo/vo-react/components/details/edit-availability'), 'EditAvailability'),
    pageId: 'edit-availability' as const,
  },
  CalendarSettings: {
    ...lazy(() => import('pages/vo/vo-react/components/details/calendar-settings'), 'CalendarSettings'),
    pageId: 'calendar-settings' as const,
  },
  YourCalendar: {
    ...lazy(() => import('pages/vo/vo-react/components/details/your-calendar'), 'YourCalendar'),
    pageId: 'your-calendar' as const,
  },
  SessionHistoryOverview: {
    ...lazy(
      () => import('pages/vo/vo-react/components/details/session-history-overview'),
      'SessionHistoryOverview',
    ),
    pageId: 'session-history-overview' as const,
  },
  SessionHistoryTranscript: {
    ...lazy(
      () => import('pages/vo/vo-react/components/details/session-history-transcript'),
      'SessionHistoryTranscript',
    ),
    pageId: 'session-history-transcript' as const,
  },
  Person: {
    ...lazy(() => import('pages/vo/vo-react/components/details/person'), 'Person'),
    pageId: 'person' as const,
  },
  InvitePerson: {
    ...lazy(() => import('pages/vo/vo-react/components/details/invite-person'), 'InvitePerson'),
    pageId: 'person-invite' as const,
  },
  PeopleSettings: {
    ...lazy(() => import('pages/vo/vo-react/components/details/people-settings'), 'PeopleSettings'),
  },
  AllPeople: {
    ...lazy(() => import('pages/vo/vo-react/components/details/all-people'), 'AllPeople'),
    pageId: 'all-people' as const,
  },
  ScheduleMeeting: {
    ...lazy(() => import('pages/vo/vo-react/components/schedule-meeting'), 'ScheduleMeetingSidebarAndDetail'),
    pageId: 'schedule-meeting' as const,
  },
  AudioVideoSettings: {
    ...lazy(
      () => import('pages/vo/vo-react/components/details/settings/audio-video-settings'),
      'AudioVideoSettings',
    ),
    pageId: 'settings-audio-video' as const,
  },
  ScreenSharingSettings: {
    ...lazy(
      () => import('pages/vo/vo-react/components/details/settings/screen-sharing-settings'),
      'ScreenSharingSettings',
    ),
    pageId: 'settings-screen-sharing' as const,
  },
  AppearanceSettings: {
    ...lazy(
      () => import('pages/vo/vo-react/components/details/settings/appearance-settings'),
      'AppearanceSettings',
    ),
    pageId: 'settings-appearance' as const,
  },
  SoundEffectsSettings: {
    ...lazy(
      () => import('pages/vo/vo-react/components/details/settings/sound-effects-settings'),
      'SoundEffectsSettings',
    ),
    pageId: 'settings-sound-effects' as const,
  },
  ProfileSettings: {
    ...lazy(
      () => import('pages/vo/vo-react/components/details/settings/profile-settings'),
      'ProfileSettings',
    ),
    pageId: 'settings-profile' as const,
  },
  KeyboardShortcutsSettings: {
    ...lazy(
      () => import('pages/vo/vo-react/components/details/settings/keyboard-shortcuts-settings'),
      'KeyboardShortcutsSettings',
    ),
    pageId: 'settings-keyboard-shortcuts' as const,
  },
  UpdatesSettings: {
    ...lazy(
      () => import('pages/vo/vo-react/components/details/settings/updates-settings'),
      'UpdatesSettings',
    ),
    pageId: 'settings-updates' as const,
  },
  MiscellaneousSettings: {
    ...lazy(
      () => import('pages/vo/vo-react/components/details/settings/miscellaneous-settings'),
      'MiscellaneousSettings',
    ),
    pageId: 'settings-misc' as const,
  },
  SignOutSettings: {
    ...lazy(
      () => import('pages/vo/vo-react/components/details/settings/sign-out-settings'),
      'SignOutSettings',
    ),
    pageId: 'settings-sign-out' as const,
  },
  SettingsPage: {
    ...lazy(() => import('pages/vo/vo-react/components/app-pages/settings-page'), 'SettingsPage'),
    pageId: 'settings' as const,
  },
  NewOrg: {
    ...lazy(() => import('pages/vo/vo-react/components/details/new-org'), 'NewOrg'),
    pageId: 'new-org' as const,
  },
  AppOnboarding: {
    ...lazy(() => import('pages/vo/vo-react/components/app-onboarding/app-onboarding'), 'AppOnboarding'),
    pageId: 'app-onboarding' as const,
  },
  NavigateToPersonalOrgId: {
    ...lazy(
      () => import('pages/vo/vo-react/components/navigate-to-personal-org-id'),
      'NavigateToPersonalOrgId',
    ),
  },
  OnboardingCreateNewOrg: {
    ...lazy(() => import('./web/routes/onboarding/create-new-org')),
  },
  OnboardingInvite: {
    ...lazy(() => import('./web/routes/onboarding/invite')),
  },
  OnboardingLinkedInvite: {
    ...lazy(() => import('./web/routes/onboarding/linked-invite')),
  },
  VerifyEmail: {
    ...lazy(() => import('./web/routes/verify-email')),
    pageId: 'verify-email' as const,
  },
  GoogleLoginRedirect: {
    ...lazy(() => import('./web/routes/google-login-redirect')),
    pageId: 'google-login-redirect' as const,
  },
  LinkAccount: {
    ...lazy(() => import('./web/routes/link-account')),
    pageId: 'link-account' as const,
  },
  ResetPassword: {
    ...lazy(() => import('./web/routes/reset-password')),
    pageId: 'reset-password' as const,
  },
  AcceptOrgInvite: {
    ...lazy(() => import('pages/vo/vo-react/features/urls/accept-org-invite'), 'AcceptOrgInvite'),
  },
};
export const lazyRoutes = transformRouteOptions(rawLazyRoutes);
export type PageId = Exclude<typeof lazyRoutes[keyof typeof lazyRoutes]['handle']['pageId'], undefined>;
