import { filter, map, merge, of, switchMap, takeUntil } from 'rxjs';

import { ofAction, ofActionPayload } from 'common/utils/custom-rx-operators';

import { EpicWithDeps } from '../../redux/app-store';
import { desktopAppEpic } from '../../redux/utils';
import { setShouldShowForumWindowForSessionId } from '../desktop-windows/desktop-windows.slice';
import { mergeMapDuringSession } from '../floof/floof.utils';
import { shareScreen, stopSharingScreen } from '../screens/screens.slice';

export const hideForumWindowWhenScreenSharingEpic: EpicWithDeps = desktopAppEpic((action$, state$) =>
  action$.pipe(
    mergeMapDuringSession((sessionId) =>
      action$.pipe(
        ofAction(shareScreen),
        switchMap(() =>
          merge(
            of(setShouldShowForumWindowForSessionId({ sessionId, toggle: false })),
            action$.pipe(
              ofAction(stopSharingScreen),
              map(() => setShouldShowForumWindowForSessionId({ sessionId, toggle: true })),
              takeUntil(
                action$.pipe(
                  ofActionPayload(setShouldShowForumWindowForSessionId),
                  filter((payload) => payload.sessionId === sessionId && payload.toggle === true),
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  ),
);
