import { createSlice } from '../../redux/create-slice';

export type EnvironmentSlice = {
  isSystemDarkMode: boolean;
};

const initialState: EnvironmentSlice = {
  isSystemDarkMode: false,
};
const { createReducer, createSelector, createAction } = createSlice('environment', initialState);

export const setSystemDarkMode = createAction<boolean>('setSystemDarkMode');

export default createReducer().on(setSystemDarkMode, (state, { payload: isDarkMode }) => {
  state.isSystemDarkMode = isDarkMode;
});

// TODO: These are the same for right now. Update with user-selected prefs.
export const getIsSystemDarkMode = createSelector((state) => state.environment.isSystemDarkMode);
export const getIsDarkMode = createSelector((state) => state.environment.isSystemDarkMode);
