import { useLayoutEffect, useRef } from 'react';
import { useUpdate } from 'react-use';
import { Observable } from 'rxjs';

/**
 * Re-render on any new values emitted from an observable.
 */
export function useObservable<T>(observableFn$: () => Observable<T>, initialState?: T, deps: any[] = []): T {
  const forceRender = useUpdate();

  const currentState = useRef(initialState ?? (null as any));

  useLayoutEffect(() => {
    const subscription = observableFn$().subscribe((selectedState) => {
      if (currentState.current === selectedState) return;
      currentState.current = selectedState;
      forceRender();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [...deps]);

  return currentState.current;
}
