import { ingestCaption } from '../captions/captions.slice';
import { addMessage } from '../chat/chat.slice';
import {
  addAspectWithId,
  clear,
  clearForAllPeers,
  clearAspectsOfType,
  clearFade,
  deleteAspect,
  modifyAspect,
  redo,
  setColor,
  startFade,
  undo,
} from '../draw/draw.slice';

export const remoteActionsAllowList = [
  addMessage,
  clear,
  clearForAllPeers,
  clearFade,
  clearAspectsOfType,
  setColor,
  startFade,
  addAspectWithId,
  modifyAspect,
  deleteAspect,
  redo,
  undo,
  ingestCaption,
].map((actionCreator) => actionCreator.type);
