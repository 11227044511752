import jsonStableStringify from 'fast-json-stable-stringify';
import {
  isEqual,
  pick as LodashPick,
  negate,
  isUndefined,
  isFunction,
  some,
  difference,
  isNull,
  differenceBy,
  identity,
  merge as lodashMerge,
} from 'lodash';
import {
  Observable,
  OperatorFunction,
  MonoTypeOperatorFunction,
  NEVER,
  of,
  Subscription,
  from,
  pipe,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  groupBy,
  map,
  mapTo,
  mergeMap,
  pairwise,
  scan,
  startWith,
  share,
  switchMap,
  ignoreElements as rxjsIgnoreElements,
  tap,
  withLatestFrom,
  take,
} from 'rxjs/operators';

import { $anyFixMe, cancelIdleCallback, requestIdleCallback } from './ts-utils';

export const mapNegate =
  <T>(): OperatorFunction<T, boolean> =>
  (input$: Observable<T>) =>
    input$.pipe(map((val) => !val));
export const mapIsTruthy =
  <T>(): OperatorFunction<T, boolean> =>
  (input$: Observable<T>) =>
    input$.pipe(map((val) => !!val));
export const mapIsFalsey =
  <T>(): OperatorFunction<T, boolean> =>
  (input$: Observable<T>) =>
    input$.pipe(map((val) => !val));

type Falsey = false | 0 | '' | null | undefined;
export const filterIsTruthy = () => pipe(filter(<T>(val: T | Falsey): val is T => !!val));
export const filterIsFalsey = () => pipe(filter(<T>(val: T): val is Extract<T, Falsey> => !val));

type NonUndefined<T> = T extends undefined ? never : T;
export const filterIsNotUndefined =
  <T>(): OperatorFunction<T, NonUndefined<T>> =>
  (input$: Observable<T>) =>
    input$.pipe(filter(negate(isUndefined)));
export const filterIsNotNull =
  <T>(): OperatorFunction<T | null, T> =>
  (input$: Observable<T | null>) =>
    input$.pipe(filter(negate(isNull)));
export const reject =
  <T>(predicate: (value: T, index: number) => boolean): MonoTypeOperatorFunction<T> =>
  (input$: Observable<T>) =>
    input$.pipe(filter((...args) => !predicate(...args)));

export const tapIfTruthy =
  <T>(...args: any[]): MonoTypeOperatorFunction<T> =>
  (input$: Observable<T>) =>
    input$.pipe(filterIsTruthy(), tap(...args));
export const tapIfFalsey =
  <T>(...args: any[]): MonoTypeOperatorFunction<T> =>
  (input$: Observable<T>) =>
    input$.pipe(filterIsFalsey(), tap(...args));

export const pick =
  <T extends Object, K extends keyof T>( // eslint-disable-line @typescript-eslint/ban-types
    ...args: K[]
  ): OperatorFunction<T, { [k in K]: T[k] }> =>
  (input$: Observable<T>) =>
    input$.pipe(map((obj) => LodashPick(obj, ...args)));

export const pluck =
  <T extends Object, K extends keyof T>( // eslint-disable-line @typescript-eslint/ban-types
    key: K,
  ): OperatorFunction<T, T[K]> =>
  (input$: Observable<T>) =>
    input$.pipe(map((obj) => obj[key]));

export const distinctUntilChangedDeep =
  <T>(): OperatorFunction<T, T> =>
  (input$: Observable<T>) =>
    input$.pipe(distinctUntilChanged(isEqual));
export const distinctUntilIsTruthyChanged =
  <T>(): OperatorFunction<T, T> =>
  (input$: Observable<T>) =>
    input$.pipe(distinctUntilChanged((x, y) => !!x === !!y));

/**
 * Usage:
 * ```
 * .pipe(
 *   log('here'),
 *   log(111, 'here'),
 *   log(111, ({ id }) => id, 'here'),
 *   log(() => `Something dynamic: ${checkThing()}`),
 *   log(() => ['multiple', 'log', 'args']),
 * )
 * ```
 */
export const log =
  <T>(...thingsToLogOrFunc: (((input: T) => any) | string | number)[]): OperatorFunction<T, T> =>
  (input$: Observable<T>) =>
    input$.pipe(
      tap((obsValue: any) => {
        console.log(
          ...thingsToLogOrFunc.map((thingToLogOrFunc) =>
            isFunction(thingToLogOrFunc) ? thingToLogOrFunc(obsValue) : thingToLogOrFunc,
          ),
        );
      }),
    );

export const ofAction =
  <T extends (...args: any[]) => any>(...actionCreators: T[]): MonoTypeOperatorFunction<ReturnType<T>> =>
  (input$: Observable<ReturnType<T>>) =>
    input$.pipe(
      filter((action) => some(actionCreators, (actionCreator) => (actionCreator as any).match(action))),
    ) as any;

export const ofActionPayload =
  <P, T extends (...args: any[]) => { payload: P }>(
    ...actionCreators: T[]
  ): OperatorFunction<ReturnType<T>, ReturnType<T>['payload']> =>
  (input$: Observable<any>) =>
    input$.pipe(ofAction(...(actionCreators as any)), pluck('payload')) as any;

export function partitionDifference<T>(): OperatorFunction<T[], { added: T[]; deleted: T[] }> {
  return (input$: Observable<T[]>) =>
    input$.pipe(
      pairwise(),
      map(([oldValues, newValues]) => {
        const added = difference(newValues, oldValues);
        const deleted = difference(oldValues, newValues);
        return { added, deleted };
      }),
    );
}

/**
 * Abstract away the easily-encountered foot-gun when trying to branch a
 * switchMap on a condition. Using this helper, the inner observable is canceled
 * when the predicate becomes false.
 *
 * Usage:
 * ```
 * obs.pipe(
 *   switchMapConditional((val) => val === 1, (val) => )
 * )
 */
export const switchMapConditional =
  <T, R>(predicate: (valToTest: T) => boolean, mapFn: (val: T) => Observable<R>): OperatorFunction<T, R> =>
  (input$: Observable<T>) =>
    input$.pipe(switchMap((valToTest) => (predicate(valToTest) ? mapFn(valToTest) : NEVER)));

/**
 * Subscribes or unsubscribes to the inner observable depending on whether the
 * input is defined. See {@link switchMapConditional} for more.
 */
export const switchMapExists =
  <T, R>(mapFn: (val: NonNullable<T>) => Observable<R>): OperatorFunction<T, R> =>
  (input$: Observable<T>) =>
    input$.pipe(switchMapConditional(negate(isUndefined), mapFn) as any);

/**
 * Subscribes or unsubscribes to the inner observable depending on whether the
 * input is truthy. See {@link switchMapConditional} for more.
 */
export const switchMapIfTruthy =
  <T, R>(mapFn: (passedVal: NonNullable<T>) => Observable<R>): OperatorFunction<T, R> =>
  (input$: Observable<T>) =>
    input$.pipe(switchMapConditional((val) => !!val, mapFn as any));

/**
 * Subscribes or unsubscribes to the inner observable depending on whether the
 * input is truthy. See {@link switchMapConditional} for more.
 */
export const switchMapToNeverUnlessTruthy =
  <T>(): MonoTypeOperatorFunction<T> =>
  (input$: Observable<T>) =>
    input$.pipe(switchMapConditional((val) => !!val, of));

export const refCountCreateDestroy =
  <T extends { payload: Record<string, any>; type: 'create' | 'destroy' }>(): OperatorFunction<T, T> =>
  (input$: Observable<T>) =>
    input$.pipe(
      groupBy(({ payload }) => jsonStableStringify(payload)),
      mergeMap((group$) =>
        group$.pipe(
          withLatestFrom(
            group$.pipe(
              scan((numCreateRequests, { type }) => numCreateRequests + (type === 'create' ? 1 : -1), 0),
              tap(
                (numCreateRequests) =>
                  numCreateRequests < 0 && console.warn(`refCount: ${numCreateRequests}`),
              ),
              map((numCreateRequests) =>
                numCreateRequests <= 0 ? ('destroy' as const) : ('create' as const),
              ),
            ),
          ),
          // Ignore the type from the current observable, and replace with
          // refCounted type
          map(([{ payload }, type]) => ({ payload, type } as $anyFixMe)),
          distinctUntilChangedDeep(),
        ),
      ),
      share(),
    );

/**
 * Usage:
 *
 * ```
 * of([{ request: 'start', location: 'San Francisco' }, { request: 'stop', location}]).pipe(
 *   simpleRefCount({
 *     increment: ({ request }) => request === 'start',
 *     decrement: ({ request }) => request === 'stop',
 *     groupBy: ({ location }) => location,
 *   }, ({ location }) =>
 *     timer(0, 1000).pipe(
 *       map(() => fetch(`https://some.weather.api/${location}`))
 *     )
 *   )
 * )
 * ```
 */
type RefCountConfig<T> = {
  increment?: (value: T) => boolean;
  decrement?: (value: T) => boolean;
  groupBy?: (value: T) => any;
};
const defaultRefCountConfig = {
  increment: identity,
  decrement: negate(identity as any),
  groupBy: () => 'default',
};
export function simpleRefCount<T, R extends Observable<any>>(obsFn: (value: T) => R): OperatorFunction<T, R>;
export function simpleRefCount<T, R extends Observable<any>>(
  config: RefCountConfig<T>,
  obsFn: (value: T) => R,
): OperatorFunction<T, R>;
export function simpleRefCount<T>(configOrObsFn: any, maybeObsFn?: any) {
  const { increment, decrement, groupBy } = lodashMerge(
    {},
    defaultRefCountConfig,
    !isFunction(configOrObsFn) && configOrObsFn,
  );
  const obsFn = maybeObsFn ?? configOrObsFn;

  return (observable$: Observable<T>) =>
    new Observable((subscriber) => {
      const groups: Record<any, { count: number; innerSubscription: Subscription }> = {};
      const getOrCreateGroup = (key: any) => {
        if (groups[key]) return groups[key];
        return (groups[key] = { count: 0, innerSubscription: Subscription.EMPTY });
      };

      const outerSubscription = observable$.subscribe({
        next(value) {
          const shouldIncrement = increment(value);
          const shouldDecrement = decrement(value);

          if (!(shouldIncrement || shouldDecrement)) return;

          const group = getOrCreateGroup(groupBy(value));
          if (shouldIncrement) group.count++;
          else if (shouldDecrement) group.count--;

          const isInnerSubscriptionActive = !group.innerSubscription.closed;

          if (group.count > 0 && !isInnerSubscriptionActive) {
            // eslint-disable-next-line rxjs/no-nested-subscribe
            group.innerSubscription = obsFn(value).subscribe({
              next(value) {
                subscriber.next(value);
              },
            });
          } else if (group.count <= 0 && isInnerSubscriptionActive) {
            group.innerSubscription.unsubscribe();
          }
        },
        error() {
          Object.values(groups).map(({ innerSubscription }) => innerSubscription.unsubscribe());
        },
        complete() {
          Object.values(groups).map(({ innerSubscription }) => innerSubscription.unsubscribe());
          subscriber.complete();
        },
      });

      return () => {
        outerSubscription.unsubscribe();
      };
    });
}

export const waitForBrowserIdle =
  <T>(): MonoTypeOperatorFunction<T> =>
  (input$: Observable<T>) =>
    input$.pipe(switchMap((payload) => browserIdleOnce$().pipe(mapTo(payload))));

export function browserIdleOnce$(): Observable<void> {
  return new Observable<void>((observer) => {
    // requestIdleCallback gets
    const handle = requestIdleCallback(() => {
      observer.next();
      observer.complete();
    });
    return () => cancelIdleCallback(handle);
  });
}

export const invertListenerPayload =
  <R, T extends { payload: R; type: 'create' | 'destroy' }>(): MonoTypeOperatorFunction<T> =>
  (input$: Observable<T>) =>
    input$.pipe(
      map(({ payload, type }) => ({ payload, type: type === 'destroy' ? 'create' : 'destroy' } as T)),
    );

export const pairwiseDifference =
  <T extends any[]>(): OperatorFunction<T, [T, T]> =>
  (input$: Observable<T>) =>
    input$.pipe(
      startWith([]),
      pairwise(),
      map(([prev, curr]) => [
        differenceBy(curr, prev, jsonStableStringify) as T,
        differenceBy(prev, curr, jsonStableStringify) as T,
      ]),
    );

export const waitUntilTruthy =
  <T>(fn$: (arg: T) => Observable<any>): MonoTypeOperatorFunction<T> =>
  (input$: Observable<T>) =>
    input$.pipe(
      switchMap((input) =>
        fn$(input).pipe(
          filterIsTruthy(),
          map(() => input),
          take(1),
        ),
      ),
    );

export const ignoreElements = (options?: { silenceDevEpicWarning: boolean }) => {
  const stack = new Error().stack;
  // TODO: is there some way to check if this is dev from src/common?
  return options?.silenceDevEpicWarning
    ? pipe(rxjsIgnoreElements())
    : pipe(
        tap((...args: any[]) => {
          if (args[0]?.type && args[0]?.payload) {
            console.warn(
              'An epic emission that looked like an action was swallowed, possibly unintentionally.',
              stack,
            );
          }
        }),
        rxjsIgnoreElements(),
      );
};

const NO_EMISSION = Symbol('NO_EMISSION');
export const finalizeWithLastValue =
  <T>(finalizeFn: (lastVal: T) => void): MonoTypeOperatorFunction<T> =>
  (obs$: Observable<T>) =>
    new Observable((subscriber) => {
      let lastVal: T | typeof NO_EMISSION = NO_EMISSION;

      const outerSubscription = obs$.subscribe({
        next: (val) => {
          lastVal = val;
          subscriber.next(val);
        },
        error: () => {
          subscriber.error();
        },
        complete: () => {
          subscriber.complete();
        },
      });

      return () => {
        if (lastVal !== NO_EMISSION) {
          finalizeFn(lastVal);
        }
        outerSubscription.unsubscribe();
      };
    });

export const mapSelector =
  <State, Selector extends (state: State, ...args: RestArgs) => any, const RestArgs extends any[]>(
    selector: Selector,
    ...args: RestArgs
  ): OperatorFunction<State, ReturnType<Selector>> =>
  (input$: Observable<State>) =>
    input$.pipe(
      map((state: State) => selector(state, ...args)),
      distinctUntilChanged(),
    );

/**
 * Similar to `scan`, but emits a tuple of the original input and the accumulated value.
 *
 * @param project - The accumulator function.
 * @param seed - The initial accumulated value.
 * @returns A function that returns an Observable emitting tuples of `[OriginalInput, ProjectedValue]`.
 */
export function scanWith<T, R>(
  project: (previous: R, current: T) => R,
  seed: R,
): OperatorFunction<T, [T, R]> {
  return (input$) =>
    input$.pipe(
      scan(
        (acc, current) => [current, project(acc[1], current)] as [T, R],
        [undefined as unknown as T, seed],
      ),
    );
}
