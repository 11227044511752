import { last } from 'lodash';
import { map, filter, merge, NEVER, tap, mergeMap, of } from 'rxjs';

import {
  isInstantMeetingSessionInitiator,
  isSpaceSessionInitiator,
  isTeamSessionInitiator,
} from 'common/models/db/session-initiatior.interface';
import {
  ignoreElements,
  distinctUntilChangedDeep,
  filterIsTruthy,
  ofAction,
  ofActionPayload,
} from 'common/utils/custom-rx-operators';
import { isDesktopApp } from 'utils/client-utils';
import { legacyStorage } from 'utils/storage';

import { EpicWithDeps } from '../../redux/app-store';
import { getNavigableOrgIdForSessionInitiator } from '../common/session-selectors';
import { getPersonalOrgId, selfAddedOrgId, selfRemovedOrgId } from '../orgs/orgs.slice';
import { pushLocation } from '../router/router.slice';
import { getSessionInitiatorForSession, joinKnownSession } from '../sessions/sessions.slice';
import {} from 'history';

import { navigatedToPage } from './navigation.slice';
import {
  generateUrlForPageIdWithParams,
  getCurrentPageId,
  getCurrentUrlParams,
  observeLocationChanges,
} from './navigation.utils';

export const locationChangeEpic: EpicWithDeps = () =>
  observeLocationChanges().pipe(
    map(({ matches }) => last(matches)),
    filterIsTruthy(),
    map(({ params, route: { handle } }) => ({ params, pageId: handle?.pageId })),
    distinctUntilChangedDeep(),
    filter(({ pageId }) => !!pageId),
    map(({ pageId, params }) => navigatedToPage({ pageId, params })),
  );

/**
 * When joining any meeting, this epic navigates the user to the correct URL.
 */
export const navigateToSessionInitiatorWhenJoinedEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(joinKnownSession),
    mergeMap(({ sessionId, shouldSkipNavigation }) => {
      const sessionInitiator = getSessionInitiatorForSession(state$.value, sessionId);
      const orgId = getNavigableOrgIdForSessionInitiator(state$.value, sessionInitiator)!;
      const url = isInstantMeetingSessionInitiator(sessionInitiator)
        ? generateUrlForPageIdWithParams('org-page', {
            orgId,
            instantMeetingId: sessionInitiator.id,
          })
        : isTeamSessionInitiator(sessionInitiator)
        ? shouldSkipNavigation
          ? undefined
          : generateUrlForPageIdWithParams('org-page', {
              orgId,
              teamId: sessionInitiator.id,
            })
        : isSpaceSessionInitiator(sessionInitiator)
        ? generateUrlForPageIdWithParams('org-page', {
            orgId,
            spaceId: sessionInitiator.id,
          })
        : undefined;
      if (!url) return NEVER;
      return of(pushLocation(url));
    }),
  );

export const navigateToOrgWhenCreatedEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofAction(selfAddedOrgId),
    filter(() => getCurrentPageId() === 'new-org'),
    map(({ payload: orgId }) =>
      pushLocation(
        generateUrlForPageIdWithParams('org-page', {
          orgId,
        }),
      ),
    ),
  );

export const navigateToPersonalOrgWhenNavigatedOrgDeletedEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofAction(selfRemovedOrgId),
    filter(({ payload: orgId }) => getCurrentUrlParams()?.orgId === orgId),
    map(() =>
      pushLocation(
        generateUrlForPageIdWithParams('all-people', {
          orgId: getPersonalOrgId(state$.value),
        }),
      ),
    ),
  );

export const saveAndRestoreLastPageOnDesktopAppLoadEpic: EpicWithDeps = (action$, state$, { injector }) => {
  if (!isDesktopApp) return NEVER;
  if (isDesktopApp) return NEVER;
  const save$ = action$.pipe(
    ofActionPayload(navigatedToPage),
    filterIsTruthy(),
    tap(({ pageId }) => legacyStorage.set('lastPageId', pageId)),
    tap(({ params }) => legacyStorage.set('lastParams', params)),
    ignoreElements(),
  );
  const restore$ = action$.pipe(
    ofActionPayload(selfAddedOrgId),
    filter((orgId) => orgId === legacyStorage.get('lastParams')?.orgId),
    map(() =>
      pushLocation(
        generateUrlForPageIdWithParams(legacyStorage.get('lastPageId'), legacyStorage.get('lastParams')),
      ),
    ),
  );
  return merge(save$, restore$);
};
