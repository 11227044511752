import { QualifiedPeerIdObject, serializeQualifiedPeerId } from 'common/models/db/vo.interface';
import { createSlice } from 'pages/vo/vo-react/redux/create-slice';

type CaptionsSlice = {
  byPeerId: {
    [serializedPeerId: string]: Caption;
  };
};

const initialState: CaptionsSlice = {
  byPeerId: {},
};

const { createReducer, createSelector, createAction, createParametricMemoizedSelector } = createSlice(
  'captions',
  initialState,
);

type Caption = { final: string; candidate: string };
export const gotSelfCaption = createAction<{ caption: Caption }>('gotSelfCaption');
export const ingestCaption = createAction<{ caption: Caption } & QualifiedPeerIdObject>('ingestCaption');
export const clearCaption = createAction<QualifiedPeerIdObject>('clearCaption');

export default createReducer()
  .on(ingestCaption, (state, { payload }) => {
    state.byPeerId[serializeQualifiedPeerId(payload)] = payload.caption;
  })
  .on(clearCaption, (state, { payload }) => {
    delete state.byPeerId[serializeQualifiedPeerId(payload)];
  });

export const getCaption = createSelector(
  (state, peerIdObject: QualifiedPeerIdObject) =>
    state.captions.byPeerId[serializeQualifiedPeerId(peerIdObject)],
);

export const getCaptionsForPeerIds = createParametricMemoizedSelector(
  (state: { captions: CaptionsSlice }) => state.captions.byPeerId,
  (state: { captions: CaptionsSlice }, qualifiedPeerIds: QualifiedPeerIdObject[]) => qualifiedPeerIds,
  // Only accept remote peer ids so we don't need a sessionId for self
  (byPeerId, qualifiedPeerIds) =>
    qualifiedPeerIds.map((qualifiedPeerId) => byPeerId[serializeQualifiedPeerId(qualifiedPeerId)]),
)((state, qualifiedPeerIds) => qualifiedPeerIds.map(serializeQualifiedPeerId).join('|'));
