import { differenceInMilliseconds, getDay } from 'date-fns';
import { RRule } from 'rrule';

import { OrgId, UserId } from 'common/models/db/vo.interface';
import { db } from 'utils/firebase-db-wrapper-client';

import { ScheduledBlockInstance, ScheduledBlockType } from './calendar.types';

export const getUserOrgIdPairString = ({ userId, orgId }: { userId: UserId; orgId: OrgId }) =>
  `${userId}|${orgId}`;

export const getUserOrgIdsFromIdPairString = (idPair: string) => {
  const [userId, orgId] = idPair.split('|');
  return { userId: userId as UserId, orgId: orgId as OrgId };
};

// Rrule weekday indices start on Monday, JS starts on Sunday. Convert between
// them.
export const getRruleWeekdayFromDayIndex = (dayIndex: number) => (dayIndex + 6) % 7;
export const getDayIndexFromRruleWeekday = (dayIndex: number) => (dayIndex + 1) % 7;

export const makeScheduledBlockFromInstance = ({
  type,
  start,
  end,
  text,
}: Omit<ScheduledBlockInstance, 'id'>) => ({
  id: db.getPushKeyWithPrefix('cal' as any),
  type,
  rule: new RRule({
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: getRruleWeekdayFromDayIndex(getDay(start)),
    byhour: start.getUTCHours(),
    byminute: start.getUTCMinutes(),
    bysecond: start.getUTCSeconds(),
  }).toString(),
  text,
  durationMs: differenceInMilliseconds(end, start),
});

/**
 * Scheduled blocks of type `range` (exclusively "working hours" in the product,
 * for now), should always be rendered in the back of the calendar view, with
 * other scheduled blocks rendering on top of it. Rather than sorting this every
 * time in the component, sort it on insert for efficiency.
 */
export const addToListInScheduledBlockRenderOrder = <T>({
  list,
  element,
  type,
}: {
  list: T[];
  element: T;
  type: ScheduledBlockType;
}) => {
  if (type === 'range') {
    list.unshift(element);
    return;
  }
  list.push(element);
};
