import { omit, pick, uniqueId } from 'lodash';
import { BehaviorSubject, firstValueFrom, fromEvent, map } from 'rxjs';

import { MediaType } from 'common/models/connection.interface';
import {
  PeerAudioTrackInfo,
  PeerVideoTrackInfo,
  RemotePeerId,
  SessionId,
} from 'common/models/db/vo.interface';
import { ProvisionalTrackInfo, TrackInfo } from 'common/models/floof.interface';
import { filterIsTruthy } from 'common/utils/custom-rx-operators';
import { toRxjsCallback } from 'common/utils/rxjs-callback';
import { debugCheck } from 'utils/debug-check';

import {
  insertTrackTransportId,
  observePeerIsConnected,
  removePeerTrackInfo,
  upsertPeerTrackInfo,
} from '../utils/db';

/**
 * A corollary to the remote PeerObserver, SelfPeerBroadcaster puts out data
 * that remote peers will in turn observe with their PeerObservers.
 */
export class SelfPeerBroadcaster {
  private tracks: { [logicalTrackId: string]: (ProvisionalTrackInfo & { track: undefined }) | TrackInfo } =
    {};
  private localTrackIdToLogicalTrackId: { [localTrackId: string]: string } = {};
  private isSelfPeerConnected$ = new BehaviorSubject(false);
  private isSelfPeerConnectedObserver = toRxjsCallback({
    obs$: observePeerIsConnected(this.sessionId, this.selfPeerId),
    cb: (isConnected) => this.isSelfPeerConnected$.next(!!isConnected),
  });

  constructor(
    private selfPeerId: RemotePeerId,
    private sessionId: SessionId,
    private delegate: {
      onTrack: (logicalTrackId: string, trackInfo: TrackInfo) => void;
      onProvisionalTrack: (logicalTrackId: string, trackInfo: ProvisionalTrackInfo) => void;
      onTrackRemoved: (logicalTrackId: string) => void;
    },
  ) {}

  public disconnect() {
    this.isSelfPeerConnectedObserver.disconnect();
  }

  public broadcastMediaTrack({
    track,
    ...etc
  }: {
    track: MediaStreamTrack | Promise<MediaStreamTrack>;
  } & {
    mediaType: MediaType;
    isEnabled: boolean;
    deviceId: string;
  }) {
    const logicalTrackId = uniqueId(`track-${this.selfPeerId}-`);

    this.upsertTrackInfo({ logicalTrackId, ...etc });

    // Once we have the track, we can (maybe) set the width and height.
    void Promise.resolve(track).then(async (resolvedTrack) => {
      this.localTrackIdToLogicalTrackId[resolvedTrack.id] = logicalTrackId;
      const size = resolvedTrack.kind === 'video' ? await getSizeFromTrack(resolvedTrack) : {};

      await firstValueFrom(this.isSelfPeerConnected$.pipe(filterIsTruthy()));
      this.upsertTrackInfo({ logicalTrackId, track: resolvedTrack, ...size });
    });

    return logicalTrackId;
  }

  public setTrackIsEnabled(logicalTrackId: string, isEnabled: boolean) {
    this.upsertTrackInfo({ logicalTrackId, isEnabled });
  }

  public setTrackTransportId({
    localTrackId,
    ...etc
  }: { localTrackId: string | null; trackTransportId: string } & (
    | { transport: 'sfu' }
    | { transport: 'p2p'; remotePeerId: RemotePeerId }
  )) {
    const logicalTrackId = this.localTrackIdToLogicalTrackId[localTrackId];

    debugCheck(!!logicalTrackId, 'Expected to have id before setting track transport id');

    void (async () => {
      await firstValueFrom(this.isSelfPeerConnected$.pipe(filterIsTruthy()));
      await insertTrackTransportId({
        selfPeerId: this.selfPeerId,
        sessionId: this.sessionId,
        logicalTrackId,
        ...etc,
      });
    })();
  }

  public withdrawMediaTrack(logicalTrackId: string) {
    void (async () => {
      await firstValueFrom(this.isSelfPeerConnected$.pipe(filterIsTruthy()));
      await removePeerTrackInfo({
        peerId: this.selfPeerId,
        sessionId: this.sessionId,
        logicalTrackId,
      });
    })();

    this.delegate.onTrackRemoved(logicalTrackId);
  }

  private upsertTrackInfo(
    trackInfo: {
      logicalTrackId: string;
      track?: MediaStreamTrack;
    } & Partial<PeerAudioTrackInfo | PeerVideoTrackInfo>,
  ) {
    this.tracks[trackInfo.logicalTrackId] = {
      ...this.tracks[trackInfo.logicalTrackId],
      ...trackInfo,
    } as any;

    if (this.tracks[trackInfo.logicalTrackId].track) {
      this.delegate.onTrack(trackInfo.logicalTrackId, this.tracks[trackInfo.logicalTrackId]);
    } else {
      this.delegate.onProvisionalTrack(trackInfo.logicalTrackId, this.tracks[trackInfo.logicalTrackId]);
    }
    void (async () => {
      await firstValueFrom(this.isSelfPeerConnected$.pipe(filterIsTruthy()));
      await upsertPeerTrackInfo({
        peerId: this.selfPeerId,
        sessionId: this.sessionId,
        trackInfo: omit(trackInfo, 'track'),
      });
    })();
  }
}

/**
 * This is complicated because there's a bug/feature/weirdness of WebRTC within
 * Chrome that reports the size of the track incorrectly before the track is
 * fully ready to flow with media; in fact, it seems to always report a size
 * that matches the size of the primary display and also matches the max size
 * available in the capabilities object for the track. Taking advantage of that,
 * here we check to see if the size matches the max size and if it does, then we
 * do the slower option of creating a video element and waiting for it to resize
 * to match the track size.
 */
const getSizeFromTrack = async (track: MediaStreamTrack) => {
  const capabilities = track.getCapabilities();
  const getSize = () => pick(track.getSettings(), 'width', 'height');
  const { width, height } = getSize();
  if (capabilities.width?.max === width && capabilities.height?.max === height) {
    const video = document.createElement('video');
    video.srcObject = new MediaStream([track]);
    return await firstValueFrom(fromEvent(video, 'resize').pipe(map(() => getSize())));
  }
  return { width, height };
};
