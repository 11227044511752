import {
  isSessionHistoryEntryDetailsDrawing,
  isSessionHistoryEntryDetailsJoinedOrLeft,
  isSessionHistoryEntryDetailsTranscription,
  SessionHistoryDetailsDrawing,
  SessionHistoryDetailsJoinedOrLeft,
  SessionHistoryDetailsTranscription,
  SessionHistoryEntryDetails,
  SessionHistoryEntryId,
  SessionHistoryId,
} from 'common/models/db/session-history.interface';
import { SessionInitiator } from 'common/models/db/session-initiatior.interface';
import { SessionId } from 'common/models/db/vo.interface';
import { CalendarEntry } from 'pages/vo/vo-react/features/calendar/calendar.types';
import { VoPeer } from 'pages/vo/vo-react/features/sessions/sessions.types';

export interface SessionHistory extends CalendarEntry {
  id: SessionHistoryId;
  sessionInitiator: SessionInitiator;
  sessionId: SessionId;
  startTs: number;
  endTs?: number;
  entries: { [sessionHistoryEntryId: string]: SessionHistoryEntry };
  name?: string;
}

export interface SessionHistoryEntry {
  id: SessionHistoryEntryId;
  sessionHistoryId: SessionHistoryId;
  ts: number;
  peer: SessionHistoryPeer;
  details: SessionHistoryEntryDetails;
}

export interface SessionHistoryPeer extends VoPeer {}

export const SHORT_DELAY_THRESHOLD_SECONDS = 5;
export const LONG_DELAY_THRESHOLD_MINUTES = 5;

export interface ProcessedTranscript {
  isShortDelay?: boolean;
  isLongDelay?: boolean;
  isNewDay?: boolean;
  isDifferentPeer?: boolean;
  transcript: SessionHistoryEntryTranscription;
}

// Frontend equivalents for the universal (backend/frontend) details type

export interface SessionHistoryEntryJoinedOrLeft extends SessionHistoryEntry {
  details: SessionHistoryDetailsJoinedOrLeft;
}
export const isSessionHistoryEntryJoinedOrLeft = (
  sessionHistoryEntry: SessionHistoryEntry,
): sessionHistoryEntry is SessionHistoryEntryJoinedOrLeft =>
  isSessionHistoryEntryDetailsJoinedOrLeft(sessionHistoryEntry.details);
export interface SessionHistoryEntryTranscription extends SessionHistoryEntry {
  details: SessionHistoryDetailsTranscription;
}
export const isSessionHistoryEntryTranscription = (
  sessionHistoryEntry: SessionHistoryEntry,
): sessionHistoryEntry is SessionHistoryEntryTranscription =>
  isSessionHistoryEntryDetailsTranscription(sessionHistoryEntry.details);
export interface SessionHistoryEntryDrawing extends SessionHistoryEntry {
  details: SessionHistoryDetailsDrawing;
}
export const isSessionHistoryEntryDrawing = (
  sessionHistoryEntry: SessionHistoryEntry,
): sessionHistoryEntry is SessionHistoryEntryDrawing =>
  isSessionHistoryEntryDetailsDrawing(sessionHistoryEntry.details);
