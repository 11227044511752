import { find, isEqual, keys, pickBy } from 'lodash';

import { KeyCombo, ShortcutName } from 'common/models/keyboard-shortcuts.interface';

import { createSlice } from '../../redux/create-slice';

/**
 * Keep track of user-configurable shortcuts. Hardcoded application shortcuts
 * are managed at the component level.
 */
export type ShortcutsSlice = {
  keyboard: {
    [id in ShortcutName]?: {
      keyCombo: KeyCombo;
      isGlobal?: true;
    };
  };
};

const initialState: ShortcutsSlice = {
  keyboard: {
    microphone: {
      keyCombo: {
        key: 'a',
        modifiers: ['ctrl', 'meta'],
      },
      isGlobal: true,
    },
    control: {
      keyCombo: {
        key: 'z',
        modifiers: ['ctrl', 'meta'],
      },
      isGlobal: true,
    },
  },
};

export const {
  createReducer,
  createSelector,
  createThunk,
  createToggleThunk,
  createAction,
  createMemoizedSelector,
  getPersistedValue,
} = createSlice('shortcuts', initialState, {
  persistKeys: [{ key: 'keyboard', version: 1 }],
});

export const setShortcut =
  createAction<{ name: ShortcutName; keyCombo: KeyCombo; isGlobal: boolean }>('setShortcut');
export const onKeyboardShortcut = createAction<ShortcutName>('onKeyboardShortcut');
export const startShortcutListener =
  createAction<{ name: ShortcutName; desktopWindowId: string | null }>('startShortcutListener');
export const stopShortcutListener =
  createAction<{ name: ShortcutName; desktopWindowId: string | null }>('stopShortcutListener');

export default createReducer().on(setShortcut, (state, { payload: { name, keyCombo, isGlobal } }) => {
  state.keyboard[name] = {
    keyCombo,
    ...(isGlobal && { isGlobal }),
  };
});

export const getShortcut = createSelector((state, name: ShortcutName) => state.shortcuts.keyboard[name]);

export const getShortcutKeyCombo = createSelector(
  (state, name: ShortcutName) => state.shortcuts.keyboard[name]?.keyCombo,
);

export const getIsShortcutGlobal = createSelector(
  (state, name: ShortcutName) => !!state.shortcuts.keyboard[name]?.isGlobal,
);

export const getKeyboardShortcuts = createSelector((state) => state.shortcuts.keyboard);
export const getNonGlobalKeyboardShortcuts = createMemoizedSelector(getKeyboardShortcuts, (shortcuts) =>
  pickBy(shortcuts, (shortcut) => shortcut && !shortcut.isGlobal),
);
export const getGlobalKeyboardShortcuts = createMemoizedSelector(getKeyboardShortcuts, (shortcuts) =>
  pickBy(shortcuts, (shortcut) => shortcut?.isGlobal),
);
export const getIsShortcutKeyComboInvalid = createMemoizedSelector(
  getKeyboardShortcuts,
  (_state: any, name: ShortcutName, keyCombo?: KeyCombo) => ({ name, keyCombo }),
  (shortcuts, { name, keyCombo }) =>
    (keyCombo?.key && !keyCombo?.modifiers.length) ||
    find(shortcuts, (shortcut, aName) => isEqual(shortcut?.keyCombo, keyCombo) && aName !== name),
);
export const getNamesForShortcutKeyCombo = createMemoizedSelector(
  getKeyboardShortcuts,
  (_state: any, keyCombo: KeyCombo) => keyCombo,
  (shortcuts, keyCombo) => keys(pickBy(shortcuts, (shortcut) => isEqual(shortcut?.keyCombo, keyCombo))),
);
