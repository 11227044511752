// Heavily inspired by
// https://github.com/reduxjs/react-redux/blob/9021feb9ff573b01b73084f1a7d10b322e6f0201/src/hooks/useSelector.ts

import { isFunction, isObject, isUndefined } from 'lodash';
import { useCallback, useDebugValue, useMemo, useRef } from 'react';
import { useStore } from 'react-redux';
import { Subject, firstValueFrom, merge } from 'rxjs';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';

import { filterIsNotUndefined } from 'common/utils/custom-rx-operators';
import { observeStoreWithSelector } from 'pages/vo/vo-react/redux/observe-state';
import { EqualityFn, getDebugStackTrace, isDev, referenceEqualityFn } from 'utils/client-utils';
import { getIsMidViewTransition, startViewTransition } from 'utils/view-transition';

import { useCurrentWindow } from './use-current-window';
import { useUpdateRef } from './use-update-ref';

const DEFAULT_OPTIONS = {
  equalityFn: referenceEqualityFn,
  suspense: false,
  transition: false as boolean | EqualityFn,
};

const defaultCheckTransition = (a: any, b: any, equalityFn = referenceEqualityFn) =>
  !isUndefined(a) && !isUndefined(b) && !equalityFn(a, b);

const splitSelectorArgsAndOptions = (...args: any[]) => {
  const selectorArgs = args.slice(0, args.length - 1);
  const maybeOptions = args[args.length - 1];

  if (
    maybeOptions &&
    isObject(maybeOptions) &&
    ('suspense' in maybeOptions || 'equalityFn' in maybeOptions || 'transition' in maybeOptions)
  )
    return {
      selectorArgs,
      options: {
        ...DEFAULT_OPTIONS,
        ...maybeOptions,
      },
    };

  return {
    selectorArgs: Array.prototype.concat.call(selectorArgs, [maybeOptions]),
    options: DEFAULT_OPTIONS,
  };
};

export type SelectorOptions = {
  equalityFn?: EqualityFn;
  suspense?: boolean;
  transition?: boolean | EqualityFn;
};

/* eslint-disable no-redeclare */
export function useSelector<U extends any[], S extends (state: any, ...etc: U) => any>(
  selectorFn: S,
  ...args: [...U, SelectorOptions]
): ReturnType<S>;
export function useSelector<U extends any[], S extends (state: any, ...etc: U) => any>(
  selectorFn: S,
  ...args: U
): ReturnType<S>;
export function useSelector(selectorFn: any, ...args: any[]) {
  const store = useStore();
  const { selectorArgs, options } = splitSelectorArgsAndOptions(...args);
  const stableSelectorFn = useUpdateRef(selectorFn);
  const equalityFn = options.equalityFn as EqualityFn;
  const { getTransitionAwareState, subscribeToStoreWithTransitions, getStoreSnapshotWithTransitionChanges } =
    useTransitionedStateHelpers(options.transition as any, options.equalityFn as any);

  const stableTrace = useMemo(() => {
    if (isDev) return getDebugStackTrace();
    return undefined;
  }, []);

  const partialedSelector = useCallback(
    (state: any) => {
      try {
        const selectedState = stableSelectorFn.current(state, ...selectorArgs);
        if (options.suspense && selectedState === undefined) throw new Error('Data not yet loaded');
        return getTransitionAwareState(selectedState);
      } catch (error: any) {
        if (!options.suspense) {
          if (isDev) {
            console.error('Encountered an error within a selector state subscription', stableTrace);
            console.error(error);
          }
        }

        const safeSelector = (...args) => {
          try {
            return stableSelectorFn.current(...args);
          } catch (error: any) {
            return undefined;
          }
        };
        // Uncomment the next line to debug Suspense.
        // console.log(`Suspense throwing for selector ${selectorFn.name}: ${error.stack}`);

        // Throw this promise for React Suspense to grab and wait on.
        throw firstValueFrom(
          observeStoreWithSelector(store, safeSelector, ...args).pipe(filterIsNotUndefined()),
        );
      }
    },
    [stableSelectorFn, store, options.suspense, getTransitionAwareState, ...selectorArgs],
  );

  const selectedState = useSyncExternalStoreWithSelector(
    subscribeToStoreWithTransitions,
    getStoreSnapshotWithTransitionChanges,
    undefined,
    partialedSelector,
    equalityFn,
  );

  useDebugValue(selectedState);
  return selectedState;
}

// For automatic view transitions (ultimately via the startViewTransition API),
// we need to figure out when state is changing, trigger the API and act like
// nothing has changed until we find out that we're ready to execute the inner
// callback of the transition.
const useTransitionedStateHelpers = (shouldTransition: boolean | EqualityFn, equalityFn: EqualityFn) => {
  const store = useStore();
  const transitionStarted$ = useMemo(() => new Subject<void>(), []);
  const isTransitionWaitingRef = useRef(false);
  const staleStateRef = useRef();
  const shouldTransitionRef = useUpdateRef(shouldTransition);
  const shouldArtificiallyTouchStateInNextSnapshot = useRef(false);
  const win = useCurrentWindow();

  const maybeTriggerTransition = useCallback(
    (freshState: any) => {
      const checkNeedsTransition = isFunction(shouldTransitionRef.current)
        ? shouldTransitionRef.current
        : (...args: [any, any]) => defaultCheckTransition(...args, equalityFn);
      if (checkNeedsTransition(staleStateRef.current, freshState)) {
        isTransitionWaitingRef.current = true;
        startViewTransition(() => {
          shouldArtificiallyTouchStateInNextSnapshot.current = true;
          // Notify the store subscription that the transition has started and
          // we need to rerender the store _now_.
          transitionStarted$.next();
        }, win);
        return true;
      }
      return false;
    },
    [staleStateRef, shouldTransitionRef, transitionStarted$, win],
  );

  return {
    subscribeToStoreWithTransitions: useCallback(
      (listener: any) => {
        const subscription = merge(store, transitionStarted$).subscribe(listener);
        return () => subscription.unsubscribe();
      },
      [store, transitionStarted$],
    ),
    // We override getState as the snapshot provider for useSyncExternalStore
    // because we need to fake a state change during a transition in order to
    // convince react to rerender synchronously. If the state change happens
    // after our very narrow window of getIsMidTransition being true, the
    // transition wouldn't work, so we have to do some goofy things to ensure an
    // update _now_.
    getStoreSnapshotWithTransitionChanges: useCallback(() => {
      if (shouldArtificiallyTouchStateInNextSnapshot.current) {
        shouldArtificiallyTouchStateInNextSnapshot.current = false;
        return { ...store.getState() };
      }
      return store.getState();
    }, [store, shouldArtificiallyTouchStateInNextSnapshot]),
    getTransitionAwareState: useCallback(
      (freshState: any) => {
        if (!shouldTransitionRef.current) return freshState;

        if (isTransitionWaitingRef.current) {
          if (!getIsMidViewTransition()) {
            return staleStateRef.current;
          }
          // If we were waiting for a transition and now we're executing it,
          // clear the wait flag and continue to return state as normal.
          isTransitionWaitingRef.current = false;
        } else if (maybeTriggerTransition(freshState)) {
          return staleStateRef.current;
        }
        staleStateRef.current = freshState;
        return freshState;
      },
      [shouldTransitionRef, staleStateRef, maybeTriggerTransition],
    ),
  };
};
