import { getSelfUserId } from 'pages/vo/vo-react/features/auth/auth.slice';
import { getAllRings } from 'pages/vo/vo-react/features/rings/rings.slice';

import { createGlobalMemoizedSelector } from '../../redux/create-slice';

export const getAllDeclinedRingsSentBySelf = createGlobalMemoizedSelector(
  getAllRings,
  getSelfUserId,
  (allRings, selfUserId) =>
    allRings.filter(({ senderUserId, state }) => senderUserId === selfUserId && state === 'declined'),
);
