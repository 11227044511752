import { secondsToMilliseconds } from 'date-fns';
import {
  merge,
  fromEvent,
  map,
  of,
  debounceTime,
  distinctUntilChanged,
  startWith,
  combineLatest,
  tap,
  filter,
} from 'rxjs';

import {
  mapIsTruthy,
  mapSelector,
  ofActionPayload,
  switchMapIfTruthy,
} from 'common/utils/custom-rx-operators';
import { getIsCurrentSessionWalkieTalkie } from 'pages/vo/vo-react/features/common/session-selectors';
import { sendIpc } from 'pages/vo/vo-react/ipc/send-ipc';
import { isDesktopApp } from 'utils/client-utils';

import { EpicWithDeps } from '../../redux/app-store';
import { getCurrentSessionId } from '../sessions/sessions.slice';

import { onActivityDetected, setIsIdleFromPop, setIsOffline, setIsSelfInactive } from './activity.slice';

export const setIsOfflineEpic: EpicWithDeps = () =>
  merge(
    of(navigator.onLine),
    fromEvent(window, 'online').pipe(map(() => true)),
    fromEvent(window, 'offline').pipe(map(() => false)),
  ).pipe(map((isOnline) => setIsOffline(!isOnline)));

export const detectInactivityDuringSessionEpic: EpicWithDeps = (action$, state$) => {
  // TODO: Improve this: simplify the need for pairwise to keep track of the
  // previous requestId
  const activity$ = merge(action$.pipe(ofActionPayload(onActivityDetected)), of(undefined));
  const doInactivityDetection$ = () =>
    merge(
      activity$.pipe(map(() => false)),
      activity$.pipe(
        debounceTime(secondsToMilliseconds(30)),
        map(() => true),
      ),
    ).pipe(
      distinctUntilChanged(),
      map((isSelfInactive) => setIsSelfInactive(isSelfInactive)),
    );

  return state$.pipe(
    filter(() => isDesktopApp),
    map((state) => getIsCurrentSessionWalkieTalkie(state)),
    distinctUntilChanged(),
    tap((isWalkieTalkieSession) => sendIpc('toggleActivityDetection', isWalkieTalkieSession)),
    switchMapIfTruthy(() => doInactivityDetection$()),
  );
};

export const setIsIdleFromPopEpic: EpicWithDeps = (action$, state$) => {
  const keydown$ = fromEvent(window.document, 'keydown');
  const hadKeydownRecently$ = merge(
    keydown$.pipe(map(() => true)),
    keydown$.pipe(
      debounceTime(30 * 1000),
      map(() => false),
    ),
  );

  const isMouseInWindow$ = merge(
    fromEvent(window.document, 'mouseenter').pipe(map(() => true)),
    fromEvent(window.document, 'mouseleave').pipe(map(() => false)),
  );

  return combineLatest([
    state$.pipe(mapSelector(getCurrentSessionId), mapIsTruthy()),
    hadKeydownRecently$.pipe(startWith(false)),
    isMouseInWindow$.pipe(startWith(false)),
  ]).pipe(
    map(
      ([isConnected, hasKeyDownRecently, isMouseInWindow]) =>
        !isConnected && !hasKeyDownRecently && !isMouseInWindow,
    ),
    distinctUntilChanged(),
    map((isIdleFromPop) => setIsIdleFromPop(isIdleFromPop)),
  );
};
