import {
  NEVER,
  filter,
  finalize,
  fromEvent,
  groupBy,
  ignoreElements,
  map,
  merge,
  mergeMap,
  skip,
  tap,
} from 'rxjs';

import { AnyPeerId } from 'common/models/db/vo.interface';
import { Point } from 'common/models/geometry.interface';
import {
  LocalIagoEvent,
  isControlChangeEvent,
  isCursorChangeEvent,
  isKeyboardEvent,
  isPointerEvent,
} from 'common/models/iago-event.interface';
import { IagoMessage } from 'common/models/peer-message.interface';
import {
  filterIsNotUndefined,
  filterIsTruthy,
  mapSelector,
  ofActionPayload,
  scanWith,
  simpleRefCount,
  switchMapIfTruthy,
} from 'common/utils/custom-rx-operators';
import {
  pointAddPoint,
  pointDivideSize,
  pointMultiplySize,
  pointSubtractPoint,
} from 'common/utils/geometry-utils';
import { assertExhaustedType } from 'common/utils/ts-utils';
import { peerLeft, peerMessageReceived } from 'pages/vo/vo-react/features/floof/floof.slice';
import {
  onIagoLocalEvent,
  renderIagoEvent,
  toggleBroadcastKeyboardEventsToTrackId,
  broadcastEvent,
  iagoBroadcastBufferProxy,
  cleanupHostState,
} from 'pages/vo/vo-react/features/iago/iago-state';
import {
  getActiveGuestScreenTrackId,
  getIsSelfScreenShareGuest,
  getMediaDescriptionForTrackId,
  getScreenTrackIdsForPeerIds,
  getSessionIdForTrackId,
} from 'pages/vo/vo-react/features/media/media.slice';
import {
  getIsScreenShared,
  getScreenInfoForId,
  getSharedScreenIdAndRect,
} from 'pages/vo/vo-react/features/screens/screens.slice';
import { getSyncedSetting, setSyncedSetting } from 'pages/vo/vo-react/features/settings/settings.slice';
import { EpicWithDeps } from 'pages/vo/vo-react/redux/app-store';
import { isDesktop, isLocalhost } from 'utils/client-utils';
import { debugWarn } from 'utils/debug-check';
import { getFloofConnection } from 'utils/floof-sdk/floof-sdk';
import { storage } from 'utils/storage';

import { sendIpc } from '../../ipc/send-ipc';
import { getSelfScreenTrackIdsForMousePosition } from '../media/get-self-screen-track-ids-for-mouse-position';

import { sendEventToIagoFirehose } from './iago-firehose';

export const remoteIagoEventEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(peerMessageReceived),
    filter(({ peerMessage }) => peerMessage.messageType === 'iago'),
    tap(({ peerMessage, peerId: eventPeerId }) => {
      const { event } = peerMessage as IagoMessage;
      let hostPeerId: AnyPeerId;
      if (isKeyboardEvent(event) || isPointerEvent(event)) {
        const { trackId } = event;
        const mediaDescription = getMediaDescriptionForTrackId(state$.value, trackId);
        if (!mediaDescription) return debugWarn(`mediaDescription not found for ${trackId}`);
        hostPeerId = mediaDescription.peerId;
      } else if (isControlChangeEvent(event) || isCursorChangeEvent(event)) {
        hostPeerId = eventPeerId;
      } else {
        assertExhaustedType(event);
      }
      renderIagoEvent({
        event,
        eventPeerId,
        hostPeerId,
      });

      if (hostPeerId === 'self') {
        let location: Point | undefined = undefined;
        if (isPointerEvent(event) || (isKeyboardEvent(event) && 'location' in event)) {
          const { deviceId: screenId } = getMediaDescriptionForTrackId(state$.value, event.trackId);
          const idAndRect = getSharedScreenIdAndRect(state$.value);
          if (!idAndRect) return;
          const { id, rect: bounds } = idAndRect;
          if (id !== screenId) return;
          location = pointAddPoint(pointMultiplySize(event.location, bounds.size), bounds.origin);
        }

        // Do the denormalizing
        void sendIpc('ingestIagoEvent', eventPeerId, {
          ...event,
          ...(location && {
            location,
          }),
        });
      }
    }),
    ignoreElements(),
  );

function sanitizeEventForBroadcast(event: LocalIagoEvent, selfPeerId: AnyPeerId, trackId: string) {
  if (isControlChangeEvent(event))
    return { ...event, peerId: event.peerId === 'local' ? selfPeerId : event.peerId };
  if (isKeyboardEvent(event) || isPointerEvent(event)) return { ...event, trackId };
  return event;
}

export const localNativeIagoEventEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    // filter(() => !getSyncedSetting(state$.value, 'isDrawing')),
    ofActionPayload(onIagoLocalEvent),
    // Make this available to components for use outside of Redux.
    tap(sendEventToIagoFirehose),
    map((event) => {
      if (getActiveGuestScreenTrackId(state$.value)) {
        if (isKeyboardEvent(event) && event.blockState) {
          const trackId = getActiveGuestScreenTrackId(state$.value);
          if (!trackId) return;
          const sessionId = getSessionIdForTrackId(state$.value, trackId);
          if (!sessionId) return;
          return broadcastEvent({ event: { ...event, trackId }, sessionId });
        }
        return;
      }

      const trackIds =
        'location' in event
          ? getSelfScreenTrackIdsForMousePosition(state$.value, event.location)
          : getScreenTrackIdsForPeerIds(state$.value, { peerIds: ['self'] });

      trackIds.forEach((trackId) => {
        const sessionId = getSessionIdForTrackId(state$.value, trackId);
        const { deviceId: screenId } = getMediaDescriptionForTrackId(state$.value, trackId);
        const screenInfo = getScreenInfoForId(state$.value, screenId);
        if (!screenInfo) return;
        const { bounds } = screenInfo;
        // Ensure we're telling remote users when remote users take control,
        // since we (the host) are the system of record for who's in control.
        if ('role' in event && event.role === 'remote' && !isControlChangeEvent(event)) return;
        if ('location' in event)
          event.location = pointDivideSize(pointSubtractPoint(event.location, bounds.origin), bounds.size);

        renderIagoEvent({
          event,
          eventPeerId: 'self',
          hostPeerId: 'self',
        });

        const floofSdk = getFloofConnection(sessionId);
        void floofSdk?.sendPeerMessage({
          messageType: 'iago',
          event: sanitizeEventForBroadcast(event, floofSdk.getSelfPeerId(), trackId),
        });
      });
      return undefined as any;
    }),
    filterIsTruthy(),
  );

export const toggleIagoEventListenerWhileScreenSharingEpic: EpicWithDeps = (action$, state$) =>
  !isDesktop
    ? NEVER
    : merge(
        state$.pipe(
          mapSelector((state) => getIsScreenShared(state)),
          skip(1),
          tap((isScreenShareHost) => {
            void sendIpc('toggleIagoEventListener', { isActive: isScreenShareHost, role: 'host' });
          }),
          ignoreElements(),
        ),
        state$.pipe(
          mapSelector((state) => getIsSelfScreenShareGuest(state)),
          skip(1),
          tap((isScreenShareGuest) => {
            void sendIpc('toggleIagoEventListener', { isActive: isScreenShareGuest, role: 'guest' });
          }),
          switchMapIfTruthy(() =>
            state$.pipe(
              mapSelector(getActiveGuestScreenTrackId),
              tap((trackId) => sendIpc('setShouldBlockKeyboardEvents', !!trackId)),
              finalize(() => sendIpc('setShouldBlockKeyboardEvents', false)),
            ),
          ),
          ignoreElements(),
        ),
      );

export const toggleBroadcastKeyboardEventsToTrackIdEpic: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(toggleBroadcastKeyboardEventsToTrackId),
    simpleRefCount(
      {
        increment: ({ isEnabled }) => isEnabled,
        decrement: ({ isEnabled }) => !isEnabled,
        groupBy: ({ trackId }) => trackId,
      },
      ({ win, trackId }) => {
        const sessionId = getSessionIdForTrackId(state$.value, trackId);
        return merge(fromEvent<KeyboardEvent>(win, 'keydown'), fromEvent<KeyboardEvent>(win, 'keyup')).pipe(
          map((event) =>
            broadcastEvent({
              sessionId,
              event: {
                type: 'keyboard',
                isDown: event.type === 'keydown',
                browserCodeKey: {
                  code: event.code,
                  key: event.key,
                },
                trackId,
              },
            }),
          ),
        );
      },
    ),
  );

export const broadcastEventsToPeersEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(broadcastEvent),
    groupBy(({ sessionId, event: { trackId } }) => `${sessionId}-${trackId}`),
    // If the broadcaster wants to send a keyboard event, don't require a location
    // since that doesn't really make sense for the frontend. Here we'll keep
    // track of the latest mouse moves and just send that last one with the
    // keyboard event.
    mergeMap((groupedByTrackId$) =>
      groupedByTrackId$.pipe(
        scanWith((memo, { event }) => (isPointerEvent(event) ? event.location : memo), {
          x: 0.5,
          y: 0.5,
        }),
        mergeMap(async ([{ event, sessionId }, lastMouseLocation]) => {
          const peerMessage = {
            messageType: 'iago' as const,
            event: {
              location: lastMouseLocation,
              ...event,
            },
          };

          if (!isLocalhost) {
            return void getFloofConnection(sessionId)?.sendPeerMessage(peerMessage);
          } else {
            if (!storage.get('should-block-local-iago-events')) {
              await iagoBroadcastBufferProxy.proxy(() => {
                void getFloofConnection(sessionId)?.sendPeerMessage(peerMessage);
              });
            }
            if (storage.get('should-mirror-local-iago-events')) {
              return peerMessageReceived({ peerId: 'self', sessionId, peerMessage });
            }
            return undefined;
          }
        }),
        filterIsNotUndefined(),
      ),
    ),
  );

export const cleanupHostStateOnPeerLeaveEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(peerLeft),
    tap(({ peerId }) => cleanupHostState({ hostPeerId: peerId })),
    ignoreElements(),
  );

export const ensureScreenShareHostStartsOffControllingAndNotDrawingEpic: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    mapSelector(getIsScreenShared),
    filterIsTruthy(),
    filter(() => getSyncedSetting(state$.value, 'isDrawing')),
    map(() => setSyncedSetting({ name: 'isDrawing', value: false })),
  );
