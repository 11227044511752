import { forEach, zipObject } from 'lodash';
import { Subscription } from 'rxjs';

import { MediaType } from 'common/models/connection.interface';
import {
  PeerAudioTrackInfo,
  PeerTrackInfoWithoutTransportIds,
  PeerVideoTrackInfo,
  RemotePeerId,
  SessionId,
} from 'common/models/db/vo.interface';
import { Transport } from 'common/models/floof.interface';
import { allMediaTypes } from 'utils/client-utils';

import { observePeerTracks } from '../utils/db';

import { LogicalTrackGroup } from './logical-track';

interface PeerObserverDelegate {
  onTrack: (
    logicalTrackId: string,
    trackInfo: PeerTrackInfoWithoutTransportIds & { track: MediaStreamTrack },
  ) => void;
  onProvisionalTrack: (logicalTrackId: string, trackInfo: PeerTrackInfoWithoutTransportIds) => void;
  onTrackRemoved: (logicalTrackId: string) => void;
  getPreferredTransport: (mediaType: MediaType) => Transport;
}

/**
 * Connect to the database and notify the parent when a remote peer has new
 * media or we've joined or left (while accounting for multiple transports).
 */
export class PeerObserver {
  private dbSubscription: Subscription;
  private delegate: PeerObserverDelegate;
  private mediaCaches = zipObject(
    allMediaTypes,
    allMediaTypes.map(
      (mediaType) =>
        new LogicalTrackGroup<SinglePeerTrackInfo>({
          onTrackChanged: (logicalTrackId, trackInfo) => {
            if (!trackInfo) return this.delegate.onTrackRemoved(logicalTrackId);
            if (!trackInfo.track) {
              return this.delegate.onProvisionalTrack(logicalTrackId, trackInfo);
            }
            this.delegate.onTrack(logicalTrackId, trackInfo as any);
          },
          getPreferredTransport: () => this.delegate.getPreferredTransport(mediaType),
        }),
    ),
  ) as { [mediaType in MediaType]: LogicalTrackGroup<SinglePeerTrackInfo> };

  constructor({
    selfPeerId,
    remotePeerId,
    sessionId,
    delegate,
  }: {
    selfPeerId: RemotePeerId;
    remotePeerId: RemotePeerId;
    sessionId: SessionId;
    delegate: PeerObserverDelegate;
  }) {
    this.delegate = delegate;
    this.dbSubscription = observePeerTracks(sessionId, remotePeerId).subscribe(
      ({ key: logicalTrackId, val: trackInfo, eventType }) => {
        console.log('received track info', { remotePeerId, logicalTrackId, trackInfo, eventType });
        if (eventType === 'added') {
          this.mediaCaches[trackInfo.mediaType].createLogicalTrack(
            logicalTrackId,
            massageMultiPeerTrackInfoForSinglePeer(selfPeerId, trackInfo),
          );
        } else if (eventType === 'changed') {
          this.mediaCaches[trackInfo.mediaType].updateLogicalTrack(
            logicalTrackId,
            massageMultiPeerTrackInfoForSinglePeer(selfPeerId, trackInfo),
          );
        } else if (eventType === 'removed') {
          this.mediaCaches[trackInfo.mediaType].removeLogicalTrack(logicalTrackId);
          this.delegate.onTrackRemoved(logicalTrackId);
        }
      },
    );
  }

  public setPreferredTransportForMedia = ({
    mediaType,
    transport,
  }: {
    mediaType: MediaType;
    transport: Transport;
  }) => this.mediaCaches[mediaType].setPreferredTransport(transport);
  public assignMediaTrackForTransport = ({
    transport,
    track,
    mediaType,
    trackTransportId,
  }: {
    transport: Transport;
    track: MediaStreamTrack;
    mediaType: MediaType;
    trackTransportId: string;
  }) => this.mediaCaches[mediaType].assignTrackOnTransport({ transport, track, trackTransportId });

  public destroy() {
    forEach(this.mediaCaches, (mediaCache) => mediaCache.destroy());
    this.dbSubscription.unsubscribe();
  }
}

const massageMultiPeerTrackInfoForSinglePeer = (
  remotePeerId: RemotePeerId,
  { trackTransportIds, ...etc }: PeerVideoTrackInfo | PeerAudioTrackInfo,
) => ({
  trackTransportIds: {
    sfu: trackTransportIds?.sfu,
    p2p: trackTransportIds?.p2p?.[remotePeerId],
  },
  ...etc,
});

type SinglePeerTrackInfo = ReturnType<typeof massageMultiPeerTrackInfoForSinglePeer>;
