import { memoize } from 'lodash';

const colors = {
  fontFamily: '--pop-font-family',
  foreground: '--pop-foreground-color',
  grey1: '--pop-grey-1-color',
  grey2: '--pop-grey-2-color',
  grey3: '--pop-grey-3-color',
  grey4: '--pop-grey-4-color',
  grey5: '--pop-grey-5-color',
  background: '--pop-background-color',
  primary1: '--pop-primary-1-color',
  primary2: '--pop-primary-2-color',
  primary3: '--pop-primary-3-color',
  primary4: '--pop-primary-4-color',
  secondary1: '--pop-secondary-1-color',
  secondary2: '--pop-secondary-2-color',
  tertiary1: '--pop-tertiary-1-color',
  tertiary2: '--pop-tertiary-2-color',
  permanentBlack: '--pop-permanent-black-color',
  permanentYellow: '--pop-permanent-yellow-color',
  permanentWhite: '--pop-permanent-white-color',
  draw0: '--pop-draw-0-color',
  draw1: '--pop-draw-1-color',
  draw2: '--pop-draw-2-color',
  draw3: '--pop-draw-3-color',
  draw4: '--pop-draw-4-color',
  draw5: '--pop-draw-5-color',
  draw6: '--pop-draw-6-color',
  draw7: '--pop-draw-7-color',
  draw8: '--pop-draw-8-color',
};

export function popColor(colorKey: keyof typeof colors, alpha?: number) {
  if (alpha !== undefined) return `rgba(var(${colors[colorKey]}-rgb), ${alpha})`;
  return `var(${colors[colorKey]})`;
}

const userColors = [
  '#DE419A',
  '#B568C8',
  '#501BC0',
  '#0C59C5',
  '#6574FD',
  '#0096B5',
  '#1BA088',
  '#00A900',
  '#589D2D',
  '#B88D00',
  '#FF6200',
  '#C01B2B',
  '#764C39',
  '#657479',
];

export const drawColors = [
  '#4FBB3D',
  '#E6A600',
  '#5F82DD',
  '#CF5FCB',
  '#CED200',
  '#00CCCC',
  '#F17575',
  '#AAAAAA',
  '#9B97C2',
];

export const screenShareDrawColors = ['#0F5', '#FA0', '#20F', '#F0F', '#FF0', '#0FF', '#F00'];

// Lifted from https://stackoverflow.com/a/17087158
function asciiSumHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash += str.charCodeAt(i);
  }
  return hash;
}

export const getUserColorForId = memoize((anyId: string) => {
  const asciiSum = asciiSumHash(anyId);
  return userColors[asciiSum % userColors.length];
});

export const getDrawColorForId = memoize((anyId: string) => {
  const asciiSum = asciiSumHash(anyId);
  return userColors[asciiSum % userColors.length];
});
