import { reduce, reject } from 'lodash';

import { OrgId, SpaceId } from 'common/models/db/vo.interface';
import { VoSpace } from 'pages/vo/vo-react/features/spaces/spaces.types';

import { createSlice } from '../../redux/create-slice';

export type SpacesSlice = {
  byId: { [id: string]: VoSpace };
  allIds: SpaceId[];
};

const initialState: SpacesSlice = {
  byId: {},
  allIds: [],
};
export const { createReducer, createSelector, createAction, createMemoizedSelector } = createSlice(
  'spaces',
  initialState,
);

export const dbSpaceCreatedOrUpdated = createAction<VoSpace>('dbSpaceCreatedOrUpdated');
export const dbSpaceDeleted = createAction<{ spaceId: SpaceId; orgId: OrgId }>('dbSpaceDeleted');

export const createSpace = createAction<Omit<VoSpace, 'id' | 'sessionGroupId'>>('createSpace');
export const updateSpace = createAction<Omit<VoSpace, 'sessionId'>>('updateSpace');
export const deleteSpace = createAction<{ spaceId: SpaceId; orgId: OrgId }>('deleteSpace');

export default createReducer()
  .on(dbSpaceCreatedOrUpdated, (state, { payload: space }) => {
    state.allIds.push(space.id);
    state.byId[space.id] = {
      ...(state.byId[space.id] ?? { userIds: [] }),
      ...space,
    };
  })
  .on(dbSpaceDeleted, (state, { payload: { spaceId } }) => {
    state.allIds = reject(state.allIds, (id) => id === spaceId);
    delete state.byId[spaceId];
  });

export const getAllSpaceIds = createSelector((state) => state.spaces.allIds);
export const getSpaceById = createSelector((state, spaceId: SpaceId) => state.spaces.byId[spaceId]);
export const getSpacesById = createSelector((state) => state.spaces.byId);
export const getSpaceNameById = createSelector((state, spaceId: SpaceId) => state.spaces.byId[spaceId]?.name);
export const getSpaceEmojiById = createSelector(
  (state, spaceId: SpaceId) => state.spaces.byId[spaceId]?.emoji,
);
export const getAllSpaceNamesByIds = createMemoizedSelector(getSpacesById, (spacesById) =>
  reduce(
    spacesById,
    (memo, { name }, spaceId) => {
      memo[spaceId] = name;
      return memo;
    },
    {} as { [spaceId: string]: string },
  ),
);

export const getSessionGroupIdForSpace = createSelector(
  (state, spaceId: SpaceId) => state.spaces.byId[spaceId]?.sessionGroupId,
);
