import { secondsToMilliseconds } from 'date-fns';
import { map, switchMap, timer } from 'rxjs';
import { tag } from 'rxjs-spy/operators/tag';

import { ofActionPayload } from 'common/utils/custom-rx-operators';

import { EpicWithDeps } from '../../redux/app-store';

import { addToast, removeToast } from './toasts.slice';

export const addToastEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(addToast),
    tag('toasts/addToast'),
    switchMap(({ durationMs }) =>
      timer(durationMs ?? secondsToMilliseconds(2)).pipe(map(() => removeToast())),
    ),
  );
