import { filter, find, some } from 'lodash';

import { createGlobalSelector } from '../../redux/create-slice';
import { getSelfUserId } from '../auth/auth.slice';
import { getAllRings } from '../rings/rings.slice';

export const getIncomingRing = createGlobalSelector((state) => {
  const selfUserId = getSelfUserId(state);
  if (!selfUserId) return undefined;
  const rings = getAllRings(state);
  const ringsFromMe = filter(rings, (ring) => ring.senderUserId === selfUserId && ring.state !== 'declined');
  const ringsToMe = filter(rings, (ring) => ring.receiverUserId === selfUserId && ring.state === 'ringing');
  return find(ringsToMe, (ringToMe) => {
    const isRingToMeDuplicate = some(
      ringsFromMe,
      (ringFromMe) => ringFromMe.receiverUserId === ringToMe.senderUserId,
    );
    // Politeness is borrowed from
    // https://blog.mozilla.org/webrtc/perfect-negotiation-in-webrtc/, where to
    // avoid 'glare' (where both sides initiate something simultaneously), the
    // polite side accepts the impolite side's offer, while the impolite side
    // sticks to their guns and declines the polite side's offer.
    const isSelfPolite = selfUserId > ringToMe.senderUserId;
    const isPoliteAndDuplicate = isSelfPolite && isRingToMeDuplicate;
    return !isPoliteAndDuplicate;
  });
});
