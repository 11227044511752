import { useRef } from 'react';

import { referenceEqualityFn } from 'utils/client-utils';

const initial = Symbol('initial');

/**
 * Use to see if a value has changed since last render.
 *
 * e.g.
 * ```
 * const someTransientValue = useSelector(getValue);
 * const didTransientValueChange = useDidValueChange(someTransientValue);
 * ```
 */
export const useDidValueChange = <T>(value: T, equalityFn = referenceEqualityFn) => {
  const prevValue = useRef<typeof initial | T>(initial);
  const frozenPrevValue = prevValue.current;

  prevValue.current = value;

  if (equalityFn(frozenPrevValue, value) || frozenPrevValue === initial) {
    return false;
  }
  return true;
};
