import { minutesToMilliseconds, secondsToMilliseconds } from 'date-fns';
import {
  debounceTime,
  defer,
  distinctUntilChanged,
  from,
  map,
  merge,
  mergeMap,
  NEVER,
  of,
  retry,
  switchMap,
  tap,
  timer,
} from 'rxjs';

import { selectedSettings } from 'common/config/firebase-config';
import {
  filterIsFalsey,
  filterIsTruthy,
  mapIsTruthy,
  ofAction,
  ignoreElements,
  mapSelector,
  switchMapIfTruthy,
  mapIsFalsey,
} from 'common/utils/custom-rx-operators';
import { getCurrentSessionId } from 'pages/vo/vo-react/features/sessions/sessions.slice';
import {
  getAreNativeVersionsReady,
  getClientAvailableVersion,
  getDidDownloadTakeLong,
  getDidNativeUpdateDownload,
  getDidUserRequestUpdate,
  getHasWaitedMaxTimeAfterUpdateReady,
  getHasWaitedMinTimeAfterUpdateReady,
  getIsClientUpdateAvailable,
  getIsMajorUpdateReady,
  getIsNativeUpdateAvailable,
  getIsNativeUpdateAvailableAndIsNotDownloaded,
  getNativeAvailableVersion,
  setAvailableVersion,
  setDidDownloadTakeLong,
  setDidNativeUpdateDownload,
  setDidUserRequestUpdate,
  setHasWaitedMaxTimeAfterUpdateReady,
  setHasWaitedMinTimeAfterUpdateReady,
  setInstalledVersion,
  userRequestedRestartAfterDownloadComplete,
} from 'pages/vo/vo-react/features/version/version.slice';
import { createGlobalAction } from 'pages/vo/vo-react/redux/create-slice';
import {
  isApp,
  isDesktopApp,
  isLinux,
  isLocalhost as isTrulyLocalHost,
  isMac,
  isWindows,
} from 'utils/client-utils';
import { db } from 'utils/firebase-db-wrapper-client';
import { getPopPlatform, isElectron } from 'utils/react/user-agent';

import { sendIpc } from '../../ipc/send-ipc';
import { EpicWithDeps } from '../../redux/app-store';
import { getIsIdleFromPop, getIsOffline } from '../activity/activity.slice';

// For local debugging, switch out the following two lines:
const isLocalhost = isTrulyLocalHost;
// const isLocalhost = isTrulyLocalHost && false;

// All the logic on this page is based on /docs/versions.mermaid.js

const shouldWeRestart = createGlobalAction('shouldWeRestart');
const needRestartButCanWeRestart = createGlobalAction('needRestartButCanWeRestart');
const showLinuxDesktopAppUpdateAlert = createGlobalAction('showLinuxDesktopAppUpdateAlert');
const showDownloadTookLongButIsNowDoneAlert = createGlobalAction('showDownloadTookLongButIsNowDoneAlert');
const restartApp = createGlobalAction('restartApp');

const platformTypesToObserve = ['client', ...(isElectron ? (['native'] as const) : [])] as const;

export const setClientAvailableVersionEpic: EpicWithDeps = () =>
  merge(
    ...platformTypesToObserve.map((platformType) => {
      const platform = platformType === 'client' ? 'client' : getPopPlatform();
      return db
        .from(`appUpdate/${platform}/latest`)
        .whenChanged()
        .pipe(
          map(({ val: version }) => version),
          mergeMap((version) =>
            from(
              db
                .from(`appUpdate/${platform}/releaseNotes/${(version ?? '').replace(/\./g, '-') as any}`)
                .value(),
            ).pipe(
              filterIsTruthy(),
              map(({ releaseDate }) => ({
                version,
                releaseDate,
              })),
            ),
          ),
          map(({ version, releaseDate: releaseTs }) =>
            setAvailableVersion({ type: platformType, version, releaseTs }),
          ),
        );
    }),
  );

export const setDesktopInstalledVersionEpic: EpicWithDeps = (action$, _state$, { ngxsStore }) =>
  !isDesktopApp
    ? NEVER
    : from(sendIpc('getAppVersion')).pipe(
        map((version) => setInstalledVersion({ type: 'native', version: version! })),
      );

export const triggersEpic: EpicWithDeps = (action$, state$, { ngxsStore }) => {
  const isClientIdle$ = state$.pipe(mapSelector(getIsIdleFromPop));

  const isClientDisconnected$ = state$.pipe(
    map((state) => getCurrentSessionId(state)),
    mapIsTruthy(),
    distinctUntilChanged(),
    filterIsFalsey(),
  );

  const isClientUpdateAvailable$ = state$.pipe(
    map((state) => getIsClientUpdateAvailable(state)),
    distinctUntilChanged(),
    filterIsTruthy(),
  );

  const didNativeUpdateDownload$ = state$.pipe(
    map((state) => getDidNativeUpdateDownload(state)),
    distinctUntilChanged(),
    filterIsTruthy(),
  );

  const hasWaitedMinTimeAfterUpdateReady$ = state$.pipe(
    map((state) => getHasWaitedMinTimeAfterUpdateReady(state)),
    distinctUntilChanged(),
    filterIsTruthy(),
  );

  const hasWaitedMaxTimeAfterUpdateReady$ = state$.pipe(
    map((state) => getHasWaitedMaxTimeAfterUpdateReady(state)),
    distinctUntilChanged(),
    filterIsTruthy(),
  );

  return merge(
    isClientIdle$,
    isClientDisconnected$,
    isClientUpdateAvailable$,
    didNativeUpdateDownload$,
    hasWaitedMinTimeAfterUpdateReady$,
    hasWaitedMaxTimeAfterUpdateReady$,
  ).pipe(map(() => shouldWeRestart()));
};

export const userRequestedUpdateEpic: EpicWithDeps = (action$, state$, { ngxsActions$ }) =>
  action$.pipe(
    ofAction(setDidUserRequestUpdate),
    map(() =>
      isLinux && isDesktopApp && !getIsClientUpdateAvailable(state$.value)
        ? showLinuxDesktopAppUpdateAlert()
        : shouldWeRestart(),
    ),
  );

export const shouldWeRestartEpic: EpicWithDeps = (action$, state$, { ngxsActions$ }) =>
  action$.pipe(
    ofAction(shouldWeRestart),
    map(() => {
      if (isApp && getIsNativeUpdateAvailable(state$.value)) {
        if (getDidNativeUpdateDownload(state$.value) || getHasWaitedMaxTimeAfterUpdateReady(state$.value))
          return needRestartButCanWeRestart();
        return;
      }
      if (getIsClientUpdateAvailable(state$.value)) {
        // On launch, it takes a while before we find out what our native app
        // version is, as well as whether there's a native update available. To
        // avoid a double-restart, we check to make sure we've got the native
        // app version data before restarting for client updates.
        if (isApp && !getAreNativeVersionsReady(state$.value)) return;
        return needRestartButCanWeRestart();
      }
    }),
    filterIsTruthy(),
  );

export const needRestartEpic: EpicWithDeps = (action$, state$, { ngxsStore }) =>
  action$.pipe(
    ofAction(needRestartButCanWeRestart),
    map(() => {
      if (getDidUserRequestUpdate(state$.value))
        if (getDidDownloadTakeLong(state$.value)) return showDownloadTookLongButIsNowDoneAlert();
        else return restartApp();
      if (getCurrentSessionId(state$.value)) return;
      if (getHasWaitedMinTimeAfterUpdateReady(state$.value)) return;
      if (getIsIdleFromPop(state$.value) && !isLocalhost) return restartApp(); // TODO: Port to React
      if (getIsMajorUpdateReady(state$.value) && !isLocalhost) return restartApp();
    }),
    filterIsTruthy(),
  );

export const waitAfterUpdateReadyEpic: EpicWithDeps = (action$, state$, { ngxsActions$ }) =>
  merge(
    state$.pipe(
      map((state) => getIsNativeUpdateAvailable(state)),
      distinctUntilChanged(),
      switchMap((isAvailable) =>
        !isAvailable
          ? NEVER
          : state$.pipe(
              map((state) => getNativeAvailableVersion(state)),
              distinctUntilChanged(),
              filterIsTruthy(),
            ),
      ),
    ),
    state$.pipe(
      map((state) => getIsClientUpdateAvailable(state)),
      distinctUntilChanged(),
      switchMap((isAvailable) =>
        !isAvailable
          ? NEVER
          : state$.pipe(
              map((state) => getClientAvailableVersion(state)),
              distinctUntilChanged(),
              filterIsTruthy(),
            ),
      ),
    ),
  ).pipe(
    switchMap(() =>
      merge(
        of(setHasWaitedMinTimeAfterUpdateReady(false)),
        of(setHasWaitedMaxTimeAfterUpdateReady(false)),
        of(setDidDownloadTakeLong(false)),
        timer(minutesToMilliseconds(10)).pipe(map(() => setHasWaitedMinTimeAfterUpdateReady(true))),
        timer(minutesToMilliseconds(20)).pipe(map(() => setHasWaitedMaxTimeAfterUpdateReady(true))),
      ),
    ),
  );

export const restartAppEpic: EpicWithDeps = (action$, state$, { ngxsStore }) =>
  merge(
    action$.pipe(ofAction(restartApp)),
    action$.pipe(ofAction(userRequestedRestartAfterDownloadComplete)),
  ).pipe(
    tap(() => {
      const didUserRequestUpdate = !!getDidUserRequestUpdate(state$.value);
      if (getDidNativeUpdateDownload(state$.value))
        void sendIpc('quitAndInstall', getNativeAvailableVersion(state$.value)!);
      else if (isApp) void sendIpc('restartApp', didUserRequestUpdate);
      else window.location.reload();
    }),
    ignoreElements(),
  );

const DOWNLOAD_TOOK_LONG_MS = secondsToMilliseconds(3);
export const showDownloadTakingLongAlertEpic: EpicWithDeps = (action$, state$, { ngxsStore }) =>
  action$.pipe(
    ofAction(setDidUserRequestUpdate),
    debounceTime(DOWNLOAD_TOOK_LONG_MS),
    switchMap(() => (getDidNativeUpdateDownload(state$.value) ? NEVER : of(setDidDownloadTakeLong(true)))),
  );

export const downloadUpdateIfAvailableEpic: EpicWithDeps = (action$, state$, { ngxsStore }) => {
  if (!isApp || (!isMac && !isWindows)) return NEVER;
  if (isLocalhost) return NEVER;
  return state$
    .pipe(
      mapSelector(getIsOffline),
      mapIsFalsey(),
      switchMapIfTruthy(() => state$.pipe(mapSelector(getIsNativeUpdateAvailableAndIsNotDownloaded))),
      switchMapIfTruthy(() =>
        defer(async () => {
          const response = await sendIpc(
            'downloadUpdate',
            `${
              selectedSettings.firebaseCloudFunctionUrlOrigin
            }/electronAutoUpdaterResponder/${getPopPlatform()}`,
          );
          if (!response.isOk) throw new Error(response.error);
        }).pipe(
          retry({
            delay: (error, retryCount) => {
              console.log({ retryCount });
              return timer(secondsToMilliseconds(Math.min(Math.pow(2, retryCount), 60 * 60)));
            },
          }),
        ),
      ),
    )
    .pipe(map(() => setDidNativeUpdateDownload()));
};
