/* eslint sort-keys-fix/sort-keys-fix: "error" */
import { flatMap, map } from 'lodash';
import { combineEpics } from 'redux-observable';

import * as debug from '../epics/debug.epics';
import * as firebase from '../epics/firebase.epics';
import * as rejoin from '../epics/rejoin.epics';
import * as windows from '../epics/windows.epics';
import * as activity from '../features/activity/activity.epics';
import * as calendar from '../features/calendar/calendar.epics';
import * as captions from '../features/captions/captions.epics';
import * as chat from '../features/chat/chat.epics';
import * as devices from '../features/devices/devices.epics';
import * as draw from '../features/draw/draw.epics';
import * as floof from '../features/floof/floof.epics';
import * as hud from '../features/hud/hud.epics';
import * as iago from '../features/iago/iago.epics';
import * as instantMeetings from '../features/instant-meetings/instant-meetings.epics';
import * as layout from '../features/layout/layout.epics';
import * as media from '../features/media/media.epics';
import * as navigation from '../features/navigation/navigation.epics';
import * as orgs from '../features/orgs/orgs.epics';
import * as permissions from '../features/permissions/permissions.epics';
import * as remoteActions from '../features/remote-actions/remote-actions.epics';
import * as rings from '../features/rings/rings.epics';
import * as router from '../features/router/router.epics';
import * as screens from '../features/screens/screens.epics';
import * as sessions from '../features/sessions/sessions.epics';
import * as settings from '../features/settings/settings.epics';
import * as shortcuts from '../features/shortcuts/shortcuts.epics';
import * as soundEffects from '../features/sound-effects/sound-effects.epics';
import * as toasts from '../features/toasts/toasts.epics';
import * as users from '../features/users/users.epics';
import * as version from '../features/version/version.epics';
import * as zoom from '../features/zoom/zoom.epics';
import * as ipc from '../ipc/ipc.epics';

import { sharedEpics } from './shared-epics';

export default combineEpics(
  sharedEpics,
  ...flatMap(
    [
      activity,
      calendar,
      captions,
      chat,
      debug,
      devices,
      draw,
      firebase,
      floof,
      hud,
      iago,
      instantMeetings,
      layout,
      navigation,
      orgs,
      media,
      permissions,
      remoteActions,
      rejoin,
      rings,
      router,
      screens,
      sessions,
      settings,
      soundEffects,
      toasts,
      users,
      version,
      windows,
      zoom,
      ipc,
      shortcuts,
    ],
    (epics) =>
      map(
        epics,
        (epic: any) =>
          (...args: any[]) =>
            epic(...args)
              .pipe
              // tap((val: any) => {
              //   if (val._subscribe) debugger;
              //   return val;
              // }),
              (),
      ),
  ),
);
