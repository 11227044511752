import { noop } from 'lodash';
import { useCallback, useRef } from 'react';
import { useId } from 'react-aria';
import { createPortal } from 'react-dom';

import { popColor } from 'utils/react/colors';
import { useCurrentWindow } from 'utils/react/use-current-window';
import { useKeyPressEvent } from 'utils/react/use-key-press-event';
import { useSuspendKeyListeners } from 'utils/react/use-suspend-key-listeners';
import { zIndex } from 'utils/react/z-index';

import { Button, ButtonProps } from './button';
import { FlexBox, FlexBoxProps, NonEmptyFlexBox } from './flex-box';
import { LargeText } from './text';

const MODAL_ELEMENT_ID = 'modal-root';

const useModalRoot = () => {
  const currentWindow = useCurrentWindow();
  const modalRootRef = useRef<HTMLElement | null>();
  if (!modalRootRef.current) modalRootRef.current = currentWindow.document.getElementById(MODAL_ELEMENT_ID);
  return modalRootRef.current;
};

export const Modal = ({
  onDismiss = noop,
  title,
  children,
  shouldHideCloseButton,
  ...etc
}: { onDismiss?: () => void; title?: string; shouldHideCloseButton?: boolean } & FlexBoxProps) => {
  useSuspendKeyListeners();
  const modalRoot = useModalRoot();
  const didClickBackground = useCallback(
    (event: Event) => {
      event?.stopPropagation();
      onDismiss();
    },
    [onDismiss],
  );
  const didClickModal = useCallback((event: Event) => {
    event.stopPropagation();
  }, []);
  const ariaTitleId = useId();

  useKeyPressEvent({ filterKey: 'Escape', onKeyDown: onDismiss, shouldOverrideSuspension: true });

  if (!modalRoot) return null;
  return createPortal(
    <>
      {/*
        Contain focus within modal, ref: https://www.w3.org/TR/wai-aria-practices/examples/dialog-modal/dialog.html
        Also, Electron has a bug with contain enabled, so disable it for now.
        TODO: Figure out how to copyToClipboard on browser with contain={true}, setting to false for now.
      */}
      {/* Currently broken in React 18 StrictMode: https://github.com/adobe/react-spectrum/issues/779 */}
      {/* <FocusScope contain={false} autoFocus restoreFocus> */}
      <FlexBox
        inline
        padding="32px"
        background={popColor('background')}
        borderRadius="6px"
        direction="column"
        width="350px"
        onClick={didClickModal}
        aria-modal
        aria-labelledby={ariaTitleId}
        position="relative"
        role="dialog"
        {...etc}
      >
        {title && (
          <FlexBox
            direction="row"
            alignItems="center"
            justifyContent="center"
            position="relative"
            height="32px"
            margin="0 0 12px 0"
          >
            <LargeText id={ariaTitleId} bold textAlign="center" flexGrow="1">
              {title}
            </LargeText>
            {!shouldHideCloseButton && <CloseButton right="0" top="0" onClick={onDismiss} />}
          </FlexBox>
        )}
        {!title && !shouldHideCloseButton && <CloseButton right="20px" top="20px" onClick={onDismiss} />}
        {children}
      </FlexBox>
      {/* </FocusScope> */}
      <FlexBox
        onClick={didClickBackground}
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        zIndex="-1"
      />
    </>,
    modalRoot,
  );
};

const CloseButton = ({ ...etc }: ButtonProps) => (
  <Button
    small
    type="transparent"
    icon={IconClose}
    position="absolute"
    color={popColor('grey2')}
    aria-label="Close"
    {...etc}
  />
);

export const Modals = () => (
  <NonEmptyFlexBox
    direction="column"
    interMargin={12}
    id={MODAL_ELEMENT_ID}
    zIndex={zIndex('modal')}
    position="absolute"
    top="0"
    bottom="0"
    left="0"
    right="0"
    alignItems="center"
    justifyContent="center"
    background={popColor('permanentBlack', 0.25)}
  />
);
