import wrapFetchWithRetry from 'fetch-retry';
import { getAuth } from 'firebase/auth';
import { reduce } from 'lodash';
import { useCallback, useState } from 'react';
import { firstValueFrom, Observable } from 'rxjs';

import { selectedSettings } from 'common/config/firebase-config';
import { ApiMethod, apiMethodsRequiringAuth, ApiMethodToResponseType } from 'common/models/api.interface';
import { filterIsNotUndefined } from 'common/utils/custom-rx-operators';
import { isLocalhost, isNgrok, isTestMode } from 'utils/client-utils';
import { debugCheck } from 'utils/debug-check';
import { getFirebaseApp } from 'utils/firebase-app';

import { getIsSignedIn } from './features/auth/auth.slice';
import { observeStoreWithSelector } from './redux/observe-state';
import { getSharedStore } from './redux/shared-store';

const fetchRetry = wrapFetchWithRetry(window.fetch, {
  retries: 3,
  retryDelay: 500,
});

export const apiCall = async <M extends ApiMethod>(
  resource: M,
  params: ApiMethodToResponseType[M]['options'],
  { asFormData }: { asFormData?: boolean } = {},
) => {
  const response = await fetchRetry(makeApiUrl(resource), {
    method: 'POST',
    body: makeBodyFromParams(params, { asFormData }),
    headers: {
      ...(!asFormData && {
        'Content-Type': 'application/json',
      }),
      ...(await maybeMakeAuthHeaderForResource(resource)),
    },
  });

  return (await response.json()) as ApiMethodToResponseType[M]['response'];
};

export const useApiCall = <M extends ApiMethod>(resource: M) => {
  const [response, setResponse] = useState<ApiMethodToResponseType[M]['response']>();

  const send = useCallback(async (params: ApiMethodToResponseType[M]['options']) => {
    setResponse(await apiCall(resource, params));
  }, []);

  return { response, send };
};

const maybeMakeAuthHeaderForResource = async (resource: string) => {
  if (!apiMethodsRequiringAuth.includes(resource as any)) return;
  const isSignedIn = await ensureSignedIn(`protected API call: ${resource} requires auth.`);
  return isSignedIn ? await makeAuthHeader() : {};
};

const ensureSignedIn = async (reason: string) => {
  const isSignedIn = await firstValueFrom(
    observeStoreWithSelector(getSharedStore(), getIsSignedIn).pipe(filterIsNotUndefined()),
  );
  debugCheck(isSignedIn, `Failed to ensureSignedIn: ${reason}`);
  return isSignedIn;
};

const makeAuthHeader = async () => {
  const auth = getAuth(getFirebaseApp());
  const token = await auth.currentUser!.getIdToken();

  return {
    Authorization: `Bearer ${token}`,
  };
};

export const makeBodyFromParams = (params: Record<string, any>, { asFormData }: { asFormData: boolean }) => {
  if (!asFormData) return JSON.stringify(params);
  return reduce(
    params,
    (formData, value, key) => {
      formData.append(key, value);
      return formData;
    },
    new FormData(),
  );
};

export const makeApiUrl = (resource: string) => makeCloudFunctionUrl(`api/${resource}`);
export const makeCloudFunctionUrl = (
  path: string,
  origin: string = isLocalhost || isNgrok || isTestMode
    ? selectedSettings.firebaseCloudFunctionUrlOrigin
    : '',
) => `${origin}/${path}`;

export const apiResponseAsObservable = <T extends ApiMethodToResponseType[ApiMethod]['response']>(
  apiCallPromise: Promise<T>,
): Observable<Omit<T, 'isOk' | 'error'>> =>
  new Observable((subscriber) => {
    void apiCallPromise.then(({ isOk, error, ...etc }) => {
      if (!isOk) return subscriber.error(error);
      subscriber.next(etc);
      subscriber.complete();
    });
  });
