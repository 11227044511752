import { distinctUntilChanged, filter, map, merge, mergeMap, of, skip, switchMap, take, tap } from 'rxjs';
import { tag } from 'rxjs-spy/operators/tag';

import { ignoreElements, distinctUntilChangedDeep, filterIsTruthy } from 'common/utils/custom-rx-operators';
import { getAllDeclinedRingsSentBySelf } from 'pages/vo/vo-react/features/common/get-all-declined-rings-sent-by-self';
import { deleteRing } from 'pages/vo/vo-react/features/rings/rings.slice';
import {
  playSoundEffect,
  stopSoundEffect,
} from 'pages/vo/vo-react/features/sound-effects/sound-effects.actions';
import { addToast } from 'pages/vo/vo-react/features/toasts/toasts.slice';

import { getCurrentWindow } from '../../desktop-window';
import { EpicWithDeps } from '../../redux/app-store';
import { getIncomingRing } from '../common/get-incoming-ring-with-type';
import { getNameForSession } from '../common/get-name-for-session';
import { getUserDisplayNameById } from '../users/users.slice';

export const showNotificationWhenRingingAndHidden: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    map((state) => getIncomingRing(state)!),
    filterIsTruthy(),
    switchMap(({ id, senderUserId, sessionId }) =>
      state$.pipe(
        map((state) => getNameForSession(state, sessionId)),
        distinctUntilChanged(),
        filterIsTruthy(),
        map((title) => ({
          id,
          senderUserId,
          sessionId,
          title,
        })),
        take(1),
      ),
    ),
    // According to
    // https://www.electronjs.org/docs/latest/api/browser-window#page-visibility,
    // `document.hidden` uses the Page Visibility API, which on macOS,
    // additionally checks for occlusion.
    filter(() => !!getCurrentWindow('vo')?.getWindow().document.hidden),
    tag('rings/showNotificationWhenRingingAndHidden'),
    tap(
      ({ senderUserId, title }) =>
        new Notification(
          `${getUserDisplayNameById(state$.value, senderUserId)} is inviting you to join ${title}`,
          { body: 'Click to join' },
        ),
    ),
    ignoreElements(),
  );

export const playIncomingRingEpic: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    map((state) => getIncomingRing(state)?.state),
    distinctUntilChanged(),
    skip(1),
    map((ringState) => {
      if (ringState !== 'ringing') return stopSoundEffect('incoming-ringtone');
      return playSoundEffect({ soundId: 'incoming-ringtone' });
    }),
    filterIsTruthy(),
  );

export const showToastWhenRingDeclined: EpicWithDeps = (action$, state$) =>
  state$.pipe(
    map((state) => getAllDeclinedRingsSentBySelf(state)),
    distinctUntilChangedDeep(),
    mergeMap((rings) =>
      merge(
        ...rings.map((ring) =>
          merge(
            of(
              addToast({
                message: `${getUserDisplayNameById(state$.value, ring.receiverUserId)} declined your call`,
              }),
            ),
            of(deleteRing(ring.id)),
          ),
        ),
      ),
    ),
  );
