import { partialRight, isEqualWith, isArray } from 'lodash';
import type { isEqual } from 'lodash';

/**
 * Acts as a reference equality comparator for everything except for an array,
 * in which case it'll recurse one level and compare all the elements in the
 * array to each other with reference equality.
 *
 * e.g.
 * ```
 * isShallowArrayEqual(1, 1); // true
 * isShallowArrayEqual([1, 2], [1, 2]); // true
 * isShallowArrayEqual(someObj, someObj); // true
 *
 * isShallowArrayEqual([1, 2, 3], [1, 2, 4]); // false
 * isShallowArrayEqual([1, [2]], [1, [2]]); // false
 * isShallowArrayEqual({}, {}); // false
 * ```
 *
 */
export const isShallowArrayEqual = partialRight(
  isEqualWith,
  (aVal: any, bVal: any, _indexOrKey: any, _a: any, _b: any, stack: any) => {
    const isTopLevelRun = !stack;
    // By default, isEqualWith works like isEqual, where it will recurse down an
    // object as far as it can. Here, though, we only want to recurse when the
    // passed values are arrays, otherwise we want reference equality.
    if (isTopLevelRun && isArray(aVal) && isArray(bVal)) return undefined;
    return aVal === bVal;
  },
) as typeof isEqual;
