import { isEqual } from 'lodash';
import { useRef } from 'react';

/**
 * Use to recalculate on every render, but only return a new reference if the
 * object is deeply different. This is useful if a consumer is expecting stable
 * references for its own expensive computations.
 */
export const useResultDeepMemo = <T>(factory: () => T): T => {
  const prevValue = useRef<T | undefined>(undefined);
  const newValue = factory();

  if (isEqual(newValue, prevValue.current)) {
    return prevValue.current as T;
  }

  prevValue.current = newValue;
  return newValue;
};
