import { pull } from 'lodash';

import { SessionId } from 'common/models/db/vo.interface';
import { Rectangle } from 'common/models/geometry.interface';
import { WindowId } from 'common/models/ipc-messages';
import {
  DesktopWindowType,
  TeamHudInfo,
} from 'pages/vo/vo-react/features/desktop-windows/desktop-windows.types';

import { getWindowById } from '../../desktop-window';
import { createSlice } from '../../redux/create-slice';
import { leaveSession } from '../sessions/sessions.slice';

type DesktopWindowMeta = {
  isFullscreen: boolean;
};

export type DesktopWindows = {
  boundsByNameByScreenArrangement: {
    [screenArrangement: string]: { [name in DesktopWindowType]?: Partial<Rectangle> };
  };
  teamHudByScreenArrangement: {
    [screenArrangement: string]: TeamHudInfo;
  };
  sessionIdsWithActiveForumWindows: SessionId[];
  forumWindowIdBySessionId: {
    [sessionId: string]: string;
  };
  focusedWindowId?: string;
  byWindowId: {
    [windowId: string]: DesktopWindowMeta;
  };
};

const initialState: DesktopWindows = {
  boundsByNameByScreenArrangement: {},
  teamHudByScreenArrangement: {},
  sessionIdsWithActiveForumWindows: [],
  forumWindowIdBySessionId: {},
  byWindowId: {},
};
export const { createReducer, createSelector, createAction, createThunk, createMemoizedSelector } =
  createSlice('desktopWindows', initialState, {
    persistKeys: ['boundsByNameByScreenArrangement', 'teamHudByScreenArrangement'],
  });

export const setDesktopWindowBounds = createAction<{
  screenArrangement: string;
  name: DesktopWindowType;
  bounds: Partial<Rectangle>;
}>('setDesktopWindowBounds');
export const setTeamHudInfo = createAction<{
  screenArrangement: string;
  info: TeamHudInfo;
}>('setTeamHudInfo');
export const setDesktopFocusedWindowId = createAction<WindowId>('setDesktopFocusedWindowId');

export const setShouldShowForumWindowForSessionId = createAction<{ sessionId: SessionId; toggle: boolean }>(
  'setShouldShowForumWindowForSessionId',
);

export const openOrFocusForumWindowForSessionId = createThunk(
  'openOrFocusForumWindowForSessionId',
  (dispatch, getState, { sessionId }: { sessionId: SessionId }) => {
    const isForumWindowOpen = getIsShowingForumWindowForSessionId(getState(), { sessionId });
    const forumDesktopWindowId = getForumWindowIdForSessionId(getState(), { sessionId });

    if (isForumWindowOpen && forumDesktopWindowId) {
      void getWindowById(forumDesktopWindowId)?.show();
    } else {
      dispatch(setShouldShowForumWindowForSessionId({ sessionId, toggle: true }));
    }
  },
);

export const setForumWindowIdForSessionId = createAction<{
  sessionId: SessionId;
  desktopWindowId: string | null;
}>('setForumWindowIdForSessionId');

// `Readonly` meaning here that this doesn't do anything, but rather tracks reality.
export const setIsFullscreenReadonly =
  createAction<{ windowId: WindowId; isFullscreen: boolean }>('setIsFullscreenReadonly');

// export const set =
//   createAction<{ sessionId: SessionId; toggle: boolean }>('setShouldShowForumWindowForSessionId');

export default createReducer()
  .on(setDesktopWindowBounds, (state, { payload: { screenArrangement, name, bounds } }) => {
    if (!state.boundsByNameByScreenArrangement[screenArrangement])
      state.boundsByNameByScreenArrangement[screenArrangement] = {};
    state.boundsByNameByScreenArrangement[screenArrangement][name] = {
      ...state.boundsByNameByScreenArrangement[screenArrangement][name],
      ...bounds,
    };
  })
  .on(setTeamHudInfo, (state, { payload: { info, screenArrangement } }) => {
    state.teamHudByScreenArrangement[screenArrangement] = info;
  })
  .on(setDesktopFocusedWindowId, (state, { payload: windowId }) => {
    state.focusedWindowId = windowId;
  })
  .on(setShouldShowForumWindowForSessionId, (state, { payload: { sessionId, toggle } }) => {
    if (toggle) {
      state.sessionIdsWithActiveForumWindows.push(sessionId);
    } else {
      pull(state.sessionIdsWithActiveForumWindows, sessionId);
    }
  })
  .on(setForumWindowIdForSessionId, (state, { payload: { sessionId, desktopWindowId } }) => {
    if (desktopWindowId) state.forumWindowIdBySessionId[sessionId] = desktopWindowId;
    else delete state.forumWindowIdBySessionId[sessionId];
  })
  .on(leaveSession, (state, { payload: { sessionId } }) => {
    pull(state.sessionIdsWithActiveForumWindows, sessionId);
  })
  .on(setIsFullscreenReadonly, (state, { payload }) => {
    if (!state.byWindowId[payload.windowId]) state.byWindowId[payload.windowId] = { isFullscreen: false };
    state.byWindowId[payload.windowId].isFullscreen = payload.isFullscreen;
  });

export const getDesktopWindowBounds = createSelector(
  (state, { name, screenArrangement }: { name: DesktopWindowType; screenArrangement: string }) =>
    state.desktopWindows.boundsByNameByScreenArrangement[screenArrangement]?.[name],
);
export const getTeamHudInfo = createSelector(
  (state, screenArrangement: string) => state.desktopWindows.teamHudByScreenArrangement[screenArrangement],
);
export const getFocusedDesktopWindowId = createSelector((state) => state.desktopWindows.focusedWindowId);

export const getSessionIdsShowingForumWindow = createSelector(
  (state) => state.desktopWindows.sessionIdsWithActiveForumWindows,
);

export const getIsShowingForumWindowForSessionId = createSelector(
  (state, { sessionId }: { sessionId?: SessionId }) =>
    sessionId && state.desktopWindows.sessionIdsWithActiveForumWindows.includes(sessionId),
);

export const getForumWindowIdForSessionId = createSelector(
  (state, { sessionId }: { sessionId: SessionId }) =>
    state.desktopWindows.forumWindowIdBySessionId[sessionId],
);

export const getIsWindowFullscreen = createSelector(
  (state, { windowId }: { windowId: WindowId }) => !!state.desktopWindows.byWindowId[windowId]?.isFullscreen,
);
