import { CurriedGetDefaultMiddleware } from '@reduxjs/toolkit/dist/getDefaultMiddleware';
import { noop } from 'lodash';
import { Reducer, Store } from 'redux';
import { Epic } from 'redux-observable';

import { debugWarn } from 'utils/debug-check';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';

import authReducer from '../features/auth/auth.slice';
import environmentReducer from '../features/environment/environment.slice';
import featureFlagsReducer from '../features/feature-flags/feature-flags.slice';

import resetStateEnhancer from './reset-state-enhancer';

export const makeSharedReducers = (storeName: string) => ({
  name: () => storeName as 'app' | 'website',
  auth: authReducer,
  environment: environmentReducer,
  featureFlags: featureFlagsReducer,
});

export type SharedReduxState = ReturnType<CombinedReducerType<ReturnType<typeof makeSharedReducers>>>;

let _sharedStore: Store<SharedReduxState> | null = null;
let _sharedStoreCleanupFn = noop;
export const setSharedStore = (store: Store<SharedReduxState>, cleanup: () => void) => {
  if (_sharedStore) {
    debugWarn('Cleaning up shared store in favor of new store.');
    _sharedStoreCleanupFn();
  }
  _sharedStore = store;
  _sharedStoreCleanupFn = cleanup;
};
export const getSharedStore = () => _sharedStore!;

export type SharedEpic = Epic<any, any, SharedReduxState>;

export type CombinedReducerType<ReducerMap extends Record<any, (...args: any[]) => any>> = Reducer<{
  [K in keyof ReducerMap]: ReturnType<ReducerMap[K]>;
}>;

export const makeSharedMiddlewareFactory =
  <EA>({ extraArgs, extraMiddleware = [] }: { extraArgs?: EA; extraMiddleware?: any[] } = {}) =>
  (getDefaultMiddleWare: CurriedGetDefaultMiddleware) => {
    const middlewares = getDefaultMiddleWare({
      thunk: {
        extraArgument: extraArgs ?? {},
      },
      serializableCheck: {
        ignoreState: true,
        ignoredActions: [
          'media/ingestTrack',
          'floof/trackReceived',
          'toggleBroadcastKeyboardEventsToTrackId',
          'STORE_RESET',
        ],
      },
    });

    return [...middlewares, ...extraMiddleware];
  };

export const makeSharedEnhancers = () => [resetStateEnhancer];

exposeToGlobalConsole({ getState: () => getSharedStore()?.getState() });
