import { mergeMap } from 'rxjs';

import { ignoreElements, ofActionPayload, pluck } from 'common/utils/custom-rx-operators';

import { apiCall } from '../../api';
import { EpicWithDeps } from '../../redux/app-store';

import { createOrgWithName } from './orgs.slice';

export const createOrgWithNameEpic: EpicWithDeps = (action$) =>
  action$.pipe(
    ofActionPayload(createOrgWithName),
    pluck('name'),
    mergeMap((name) =>
      apiCall('createOrg', {
        name,
      }),
    ),
    ignoreElements(),
  );
