import { omit } from 'lodash';
import { forwardRef, memo, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { BehaviorSubject, map } from 'rxjs';

import { useCurrentWindowId } from 'utils/react/use-current-window';
import { useObservable } from 'utils/react/use-observable';
import { mergeRefs } from 'utils/react-utils';

import { BasePopUiComponent, BaseProps } from './base-pop-ui-component';

export const portalRoots$ = new BehaviorSubject<{ [id: string]: HTMLElement }>({});

export const usePortalRoot = (portalId: string) =>
  useObservable(
    () => portalRoots$.pipe(map((portalRoots) => portalRoots[portalId] as HTMLElement | undefined)),
    portalRoots$.value[portalId],
    [portalId],
  );

/**
 * Generic support for React portals that allow a space warp between a deeply
 * nested component (say a button within an overflow hidden container) and an
 * element (usually an overlay or a tooltip) that would benefit from being
 * pulled to the top level of the DOM. This isn't the only place where React
 * Portals could be useful, but it's so common a use-case that I feel
 * comfortable calling this generic Portal.
 */
export const Portal = memo(
  ({ id, children, holdingElement }: { id?: string; children: any; holdingElement?: HTMLElement }) => {
    const windowId = useCurrentWindowId();
    const portalId = id ?? windowId;
    const portalRoot = usePortalRoot(portalId);
    if (!portalRoot && !holdingElement) return null;
    return createPortal(children, portalRoot ?? holdingElement!);
  },
);

export const PortalDomArea = forwardRef(({ id, ...etc }: { id?: string } & BaseProps, forwardedRef) => {
  const [element, setElement] = useState<HTMLElement>();
  const windowId = useCurrentWindowId();
  const portalId = id ?? windowId;

  useLayoutEffect(() => {
    portalRoots$.next(
      element ? { ...portalRoots$.value, [portalId]: element } : omit(portalRoots$.value, portalId),
    );
    return () => {
      portalRoots$.next(omit(portalRoots$.value, portalId));
    };
  }, [element, portalId]);

  return <BasePopUiComponent width="fit-content" ref={mergeRefs(setElement as any, forwardedRef)} {...etc} />;
});

PortalDomArea.displayName = 'PortalDomArea';
