import { pull, reject, uniq, without } from 'lodash';

import { JoinableSessionInitiator } from 'common/models/db/session-initiatior.interface';
import { OrgId, UserId } from 'common/models/db/vo.interface';
import { VoOrg } from 'pages/vo/vo-react/features/orgs/orgs.types';
import {
  dbTeamCreatedOrUpdated,
  dbTeamDeleted,
  deleteTeam,
} from 'pages/vo/vo-react/features/teams/teams.slice';

import { createSlice } from '../../redux/create-slice';
import { dbSpaceCreatedOrUpdated, dbSpaceDeleted, deleteSpace } from '../spaces/spaces.slice';

export type OrgsSlice = {
  personalOrgId?: OrgId;
  byId: { [id: string]: VoOrg };
  allIds: OrgId[];
};

const initialState: OrgsSlice = {
  byId: {},
  allIds: [],
};
export const { createReducer, createSelector, createMemoizedSelector, createAction } = createSlice(
  'orgs',
  initialState,
);

export const selfAddedOrgId = createAction<OrgId>('selfAddedOrgId');
export const selfRemovedOrgId = createAction<OrgId>('selfRemovedOrg');
export const addOrUpdateOrg =
  createAction<Omit<VoOrg, 'teamIds' | 'spaceIds' | 'userIds' | 'users'>>('addOrUpdateOrg');
export const addUserToOrg = createAction<{ userId: UserId; orgId: OrgId }>('addUserToOrg');
export const removeUserFromOrg = createAction<{ userId: UserId; orgId: OrgId }>('removeUserFromOrg');
export const setOrgName = createAction<{ orgId: OrgId; name: string }>('setOrgName');
export const createOrgWithName = createAction<{ name: string }>('createOrgWithName');
export const setOrgUserCurrentJoinableSessionInitiators = createAction<{
  userId: UserId;
  orgId: OrgId;
  currentJoinableSessionInitiators: JoinableSessionInitiator[];
}>('setOrgUserCurrentJoinableSessionInitiators');

export default createReducer()
  .on(addOrUpdateOrg, (state, { payload: org }) => {
    if (!state.allIds.includes(org.id)) state.allIds.push(org.id);
    state.byId[org.id] = {
      teamIds: [],
      spaceIds: [],
      userIds: [],
      users: {},
      ...(state.byId[org.id] as Partial<VoOrg>),
      id: org.id as OrgId,
      iconUrl: org.iconUrl,
      name: org.name,
      inviteUrlId: org.inviteUrlId,
      externalLinkageType: org.externalLinkageType,
    };
    if (org.isPersonalOrg) state.personalOrgId = org.id;
  })
  .on(selfRemovedOrgId, (state, { payload: orgId }) => {
    delete state.byId[orgId];
    pull(state.allIds, orgId);
  })
  .on(dbTeamCreatedOrUpdated, (state, { payload: { id: teamId, orgId } }) => {
    state.byId[orgId] = {
      ...state.byId[orgId],
      teamIds: uniq([...(state.byId[orgId]?.teamIds ?? []), teamId]),
    };
  })
  .on(deleteTeam, (state, { payload: { teamId, orgId } }) => {
    state.byId[orgId] = {
      ...state.byId[orgId],
      teamIds: reject(state.byId[orgId]?.teamIds ?? [], (id) => id === teamId),
    };
  })
  .on(dbTeamDeleted, (state, { payload: { teamId, orgId } }) => {
    state.byId[orgId] = {
      ...state.byId[orgId],
      teamIds: reject(state.byId[orgId]?.teamIds ?? [], (id) => id === teamId),
    };
  })
  .on(dbSpaceCreatedOrUpdated, (state, { payload: { id: spaceId, orgId } }) => {
    state.byId[orgId] = {
      ...state.byId[orgId],
      spaceIds: uniq([...(state.byId[orgId]?.spaceIds ?? []), spaceId]),
    };
  })
  .on(deleteSpace, (state, { payload: { spaceId, orgId } }) => {
    state.byId[orgId] = {
      ...state.byId[orgId],
      spaceIds: reject(state.byId[orgId]?.spaceIds ?? [], (id) => id === spaceId),
    };
  })
  .on(dbSpaceDeleted, (state, { payload: { spaceId, orgId } }) => {
    state.byId[orgId] = {
      ...state.byId[orgId],
      spaceIds: reject(state.byId[orgId]?.spaceIds ?? [], (id) => id === spaceId),
    };
  })
  .on(addUserToOrg, (state, { payload: { userId, orgId } }) => {
    state.byId[orgId].userIds.push(userId);
    state.byId[orgId].users[userId] = { currentJoinableSessionInitiators: [] };
  })
  .on(
    setOrgUserCurrentJoinableSessionInitiators,
    (state, { payload: { orgId, userId, currentJoinableSessionInitiators } }) => {
      state.byId[orgId].users[userId].currentJoinableSessionInitiators = currentJoinableSessionInitiators;
    },
  )
  .on(setOrgName, (state, { payload: { orgId, name } }) => {
    state.byId[orgId] = {
      ...state.byId[orgId],
      name,
    };
  });

export const getOrgIconUrlById = createSelector((state, orgId: OrgId) => state.orgs.byId[orgId]?.iconUrl);
export const getOrgIds = createSelector((state) => state.orgs.allIds);
export const getNonPersonalOrgIds = createSelector((state) =>
  !state.orgs.personalOrgId ? state.orgs.allIds : without(state.orgs.allIds, state.orgs.personalOrgId),
);
export const getAllUserIdsForOrgId = createSelector((state, orgId: OrgId) => state.orgs.byId[orgId]?.userIds);

export const getOrgById = createSelector((state, orgId: OrgId) => state.orgs.byId[orgId]);
export const getOrgNameById = createSelector((state, orgId: OrgId) => state.orgs.byId[orgId]?.name);
export const getTeamIdsByOrgId = createSelector((state, orgId: OrgId) => state.orgs.byId[orgId]?.teamIds);
export const getSpaceIdsByOrgId = createSelector((state, orgId: OrgId) => state.orgs.byId[orgId]?.spaceIds);
export const getOrgInviteUrlIdById = createSelector(
  (state, orgId: OrgId) => state.orgs.byId[orgId]?.inviteUrlId,
);
export const getOrgExternalLinkageTypeById = createSelector(
  (state, orgId: OrgId) => state.orgs.byId[orgId]?.externalLinkageType,
);
export const getPersonalOrgId = createSelector((state) => state.orgs.personalOrgId);
export const getOrgUserCurrentJoinableSessionInitiatorsById = createSelector(
  (state, orgId: OrgId, userId: UserId) =>
    getOrgById(state, orgId)?.users?.[userId]?.currentJoinableSessionInitiators,
);
