import { PayloadAction } from '@reduxjs/toolkit';
import { secondsToMilliseconds } from 'date-fns';
import { WritableDraft } from 'immer/dist/internal.js';
import { forEachRight, get, keys, last, pickBy, pull, pullAt, set, without } from 'lodash';

import { AnyPeerId } from 'common/models/db/vo.interface';
import { drawColors } from 'utils/react/colors';

import { createSlice } from '../../redux/create-slice';

export const WAIT_TO_START_FADE_MS = secondsToMilliseconds(2);
export const FADE_DURATION_MS = secondsToMilliseconds(1.667);

// Note: the string in `${string}-screen` is the Logical Track ID
export type DrawInstanceId = 'app' | `${string}-screen`;

export type DrawSlice = {
  currentlyTransformingAspectId?: string;
  aspectsById: { [aspectId: string]: Aspect & { peerId: AnyPeerId; instanceId: DrawInstanceId } };
  currentColorByPeerId: { [peerId: string]: string };
  aspectIdsbyInstanceId: {
    [id: string]: {
      [peerId: string]: string[];
    };
  };
  fadeStartTsByPeerDrawInstanceKey: {
    [peerDrawInstanceKey: string]: number;
  };
};

export type Aspect = LineAspect | TextAspect | ImageAspect;
type TransformableAspect = {
  rotation?: number;
  scaleX?: number;
  scaleY?: number;
  x?: number;
  y?: number;
};
export type LineAspect = {
  type: 'line';
  color: string;
  points: number[];
  isEnded?: true;
};
export type TextAspect = {
  type: 'text';
  color: string;
  text: string;
} & TransformableAspect;
export type ImageAspect = {
  type: 'image';
  objectUrl: string;
} & TransformableAspect;

export const isLineAspect = (aspect: Aspect): aspect is LineAspect => aspect.type === 'line';
export const isTextAspect = (aspect: Aspect): aspect is TextAspect => aspect.type === 'text';
export const isImageAspect = (aspect: Aspect): aspect is ImageAspect => aspect.type === 'image';
export const isTransformableAspect = (aspect: any): aspect is TransformableAspect =>
  !aspect.rotaion || aspect.scaleX || aspect.scaleY;

const ensureNestedProperty = (obj: any, paths: any, defaultValue: any) => {
  if (!get(obj, paths)) set(obj, paths, defaultValue);
};

export type AspectModification =
  | LineAppendAspectModification
  | LineEndAspectModification
  | TextAspectModification
  | TransformAspectModification;

export type TransformAspectModification = {
  type: 'transform';
  rotation?: number;
  scaleX?: number;
  scaleY?: number;
  x?: number;
  y?: number;
};
export type LineAppendAspectModification = {
  type: 'line-append';
  point: [number, number];
};
export type LineEndAspectModification = {
  type: 'line-end';
};
export type TextAspectModification = {
  type: 'text';
  text: string;
};

const initialState: DrawSlice = {
  aspectsById: {},
  aspectIdsbyInstanceId: {},
  fadeStartTsByPeerDrawInstanceKey: {},
  currentColorByPeerId: {},
};

export const { createReducer, createSelector, createMemoizedSelector, createAction, undoActions } =
  createSlice('draw', initialState, {
    undoStackKeyFn: (state, { payload: { peerId, instanceId } }: PayloadAction<PeerDrawInstance, any>) =>
      `${instanceId}-${peerId}`,
  });

export type PeerDrawInstance = { peerId: AnyPeerId; instanceId: DrawInstanceId };

const makePeerDrawInstanceKey = ({ peerId, instanceId }: PeerDrawInstance) => `${instanceId}-${peerId}`;

let aspectCount = 0;
const incrementAspectCounter = () => aspectCount++;

export const addAspect = (payload: PeerDrawInstance & { aspect: Aspect }) => (dispatch: any) => {
  const aspectId = `${payload.peerId}-${incrementAspectCounter()}`;
  dispatch(addAspectWithId({ ...payload, aspectId }));
  return aspectId;
};
export const addAspectWithId = createAction<{
  peerId: AnyPeerId;
  instanceId: DrawInstanceId;
  aspect: Aspect;
  aspectId: string;
}>('addAspectWithId');
export const modifyAspect =
  createAction<{ aspectId: string; modification: AspectModification }>('modifyAspect');
export const deleteAspect = createAction<{ aspectId: string }>('deleteAspect');
export const clear = createAction<{ peerId: AnyPeerId; instanceId: DrawInstanceId }>('clear');
// Include `peerId` so we know who the originating peer is so we don't infinite loop mirror these actions.
export const clearForAllPeers =
  createAction<{ peerId: AnyPeerId; instanceId: DrawInstanceId }>('clearForAllPeers');
export const clearAspectsOfType =
  createAction<{ peerId: AnyPeerId; instanceId: DrawInstanceId; type: Aspect['type'] }>('clearAspectsOfType');
export const setColor =
  createAction<{ peerId: AnyPeerId; color: string; instanceId: DrawInstanceId }>('setColor');
export const setCurrentlyTransformingAspectId = createAction<{ aspectId: string | null }>(
  'setCurrentlyTransformingAspectId',
);

export const startFade = createAction<{ peerId: AnyPeerId; instanceId: DrawInstanceId }>('startFade');
export const clearFade = createAction<{ peerId: AnyPeerId; instanceId: DrawInstanceId }>('clearFade');

export const { undo, redo, getIsRedoable: getIsDrawRedoable } = undoActions;

export default createReducer()
  .on(
    addAspectWithId,
    (state, { payload: { peerId, aspect, aspectId, instanceId } }) => {
      state.aspectsById[aspectId] = {
        ...aspect,
        instanceId,
        peerId,
      };
      ensureNestedProperty(state.aspectIdsbyInstanceId, [instanceId, peerId], []);
      state.aspectIdsbyInstanceId[instanceId][peerId as any].push(aspectId);
    },
    {
      withUndo: (state, { payload: { peerId, instanceId } }) =>
        makePeerDrawInstanceKey({ peerId, instanceId }),
    },
  )
  .on(
    modifyAspect,
    (state, { payload: { aspectId, modification } }) => {
      const aspect = getAspectById({ draw: state }, { aspectId })!;
      if (modification.type === 'line-append' && aspect.type === 'line') {
        aspect.points.push(...modification.point);
      } else if (modification.type === 'line-end' && aspect.type === 'line') {
        aspect.isEnded = true;
      } else if (modification.type === 'text' && aspect.type === 'text') {
        aspect.text = modification.text;
      } else if (modification.type === 'transform' && isTransformableAspect(aspect)) {
        aspect.rotation = modification.rotation ?? aspect.rotation;
        aspect.scaleX = modification.scaleX ?? aspect.scaleX;
        aspect.scaleY = modification.scaleY ?? aspect.scaleY;
      }
    },
    {
      withUndo: (state, { payload: { aspectId, modification } }) =>
        modification.type === 'transform' && makePeerDrawInstanceKey(state.aspectsById[aspectId]),
    },
  )
  .on(
    deleteAspect,
    (state, { payload: { aspectId } }) => {
      const aspect = state.aspectsById[aspectId];
      pull(state.aspectIdsbyInstanceId[aspect.instanceId]?.[aspect.peerId], aspectId);
      if (state.currentlyTransformingAspectId === aspectId) delete state.currentlyTransformingAspectId;
    },
    {
      withUndo: (state, { payload: { aspectId } }) => makePeerDrawInstanceKey(state.aspectsById[aspectId]),
    },
  )
  .on(
    clearAspectsOfType,
    (state, { payload }) => {
      const aspectIds = getAspectIdsForPeerInInstance({ draw: state }, payload);
      forEachRight(aspectIds, (aspectId, index) => {
        if (state.aspectsById[aspectId].type === payload.type) {
          pullAt(aspectIds, index);
          delete state.aspectsById[aspectId];
          if (state.currentlyTransformingAspectId === aspectId) delete state.currentlyTransformingAspectId;
        }
      });
    },
    {
      withUndo: (state, { payload: { peerId, instanceId } }) =>
        makePeerDrawInstanceKey({ peerId, instanceId }),
    },
  )
  .on(
    clear,
    (state, { payload }) => {
      internalClear(state, payload);
    },
    {
      withUndo: (state, { payload: { peerId, instanceId } }) =>
        makePeerDrawInstanceKey({ peerId, instanceId }),
    },
  )
  .on(
    clearForAllPeers,
    (state, { payload: { instanceId } }) => {
      const peerIds = getPeerIdsWithAspectsInInstance({ draw: state }, { instanceId });
      peerIds.forEach((peerId) => internalClear(state, { instanceId, peerId }));
    },
    {
      withUndo: (state, { payload: { peerId, instanceId } }) =>
        makePeerDrawInstanceKey({ peerId, instanceId }),
    },
  )
  .on(setColor, (state, { payload }) => {
    state.currentColorByPeerId[payload.peerId] = payload.color;
  })
  .on(startFade, (state, { payload }) => {
    const key = makePeerDrawInstanceKey(payload);
    state.fadeStartTsByPeerDrawInstanceKey[key] = Date.now();
  })
  .on(clearFade, (state, { payload }) => {
    const key = makePeerDrawInstanceKey(payload);
    delete state.fadeStartTsByPeerDrawInstanceKey[key];
  })
  .on(setCurrentlyTransformingAspectId, (state, { payload: { aspectId } }) => {
    if (!aspectId) return void delete state.currentlyTransformingAspectId;
    state.currentlyTransformingAspectId = aspectId;
  });

const internalClear = (state: WritableDraft<DrawSlice>, payload: PeerDrawInstance) => {
  const aspectIds = getAspectIdsForPeerInInstance({ draw: state }, payload);
  set(state.aspectIdsbyInstanceId, [payload.instanceId, payload.peerId], []);
  aspectIds.forEach((aspectId) => {
    delete state.aspectsById[aspectId];
    if (state.currentlyTransformingAspectId === aspectId) delete state.currentlyTransformingAspectId;
  });
};

export const getCurrentDrawColorForPeer = createSelector(
  (state, { peerId, instanceId }: PeerDrawInstance) =>
    state.draw.currentColorByPeerId[peerId] ?? drawColors[0],
);

export const getAspectIdsForPeerInInstance = createSelector(
  (state, { peerId, instanceId }: PeerDrawInstance) =>
    state.draw.aspectIdsbyInstanceId[instanceId]?.[peerId] ?? [],
);

export const getAspectById = createSelector((state, { aspectId }: { aspectId?: string }) =>
  aspectId ? state.draw.aspectsById[aspectId] : undefined,
);

export const getAspectTypeById = createSelector(
  (state, { aspectId }: { aspectId?: string }) => state.draw.aspectsById[aspectId!]?.type,
);

export const getAllPeerStateForInstanceId = createSelector(
  (state, { instanceId }: { instanceId: DrawInstanceId }) =>
    state.draw.aspectIdsbyInstanceId[instanceId] ?? [],
);

export const getCurrentlyTransformingAspectId = createSelector(
  (state) => state.draw.currentlyTransformingAspectId,
);

export const getPeerIdsWithAspectsInInstance = createMemoizedSelector(
  getAllPeerStateForInstanceId,
  (aspectIdsByPeer) =>
    keys(
      pickBy(aspectIdsByPeer, (aspectIds) => Array.isArray(aspectIds) && aspectIds.length > 0),
    ) as AnyPeerId[],
);

export const getAreAnyPeersDrawing = createMemoizedSelector(
  getPeerIdsWithAspectsInInstance,
  (peerIds) => !!peerIds.length,
);
export const getAreAnyRemotePeersDrawing = createMemoizedSelector(
  getPeerIdsWithAspectsInInstance,
  (peerIds) => !!without(peerIds, 'self').length,
);

export const getIsPeerDrawing = createSelector((state, { peerId, instanceId }: PeerDrawInstance) => {
  const latestAspectIdForPeer = last(getAspectIdsForPeerInInstance(state, { peerId, instanceId }));
  const aspect = state.draw.aspectsById[latestAspectIdForPeer!];
  if (!latestAspectIdForPeer || !aspect || aspect.type !== 'line') return false;
  return !aspect.isEnded;
});

export const getIsAspectOwnedByPeerId = createSelector(
  (state, { aspectId, peerId }: { aspectId: string; peerId: AnyPeerId }) =>
    state.draw.aspectsById[aspectId]?.peerId === peerId,
);

export const getFadeStartTsForPeerDrawInstance = createSelector(
  (state, { peerId, instanceId }: PeerDrawInstance) =>
    state.draw.fadeStartTsByPeerDrawInstanceKey[makePeerDrawInstanceKey({ peerId, instanceId })],
);
