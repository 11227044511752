import { WritableDraft } from 'immer/dist/internal';
import { countBy, filter, flatMap, groupBy, mapValues, omit, some, uniq } from 'lodash';

import { RemotePeerId, SessionId } from 'common/models/db/vo.interface';
import { AnyPeerId } from 'common/models/db/vo.interface';
import { ProvisionalTrackInfo, TrackInfo } from 'common/models/floof.interface';
import { Size } from 'common/models/geometry.interface';
import { assertExhaustedType } from 'common/utils/ts-utils';
import { debugCheck } from 'utils/debug-check';
import { isShallowArrayEqual } from 'utils/is-shallow-array-equal';
import { getEnhancedTrackForTrack, makeEnhancedTrackForTrack } from 'utils/react/audio-context';
import { exposeToGlobalConsole } from 'utils/react/expose-to-global-console';

import { createSlice } from '../../redux/create-slice';
import { removeLogicalTrack } from '../floof/floof.slice';

type MicrophoneMediaDescription = {
  peerId: AnyPeerId;
  type: 'microphone';
  logicalTrackId: string;
  isEnabled: boolean;
  deviceId: string;
  isProvisional?: true;
};

type CameraMediaDescription = {
  peerId: AnyPeerId;
  type: 'camera';
  logicalTrackId: string;
  width: number;
  height: number;
  deviceId: string;
  isProvisional?: true;
};

type ScreenMediaDescription = {
  peerId: AnyPeerId;
  type: 'screen';
  logicalTrackId: string;
  width: number;
  height: number;
  deviceId: string;
  isProvisional?: true;
};

export type MediaDescription = MicrophoneMediaDescription | CameraMediaDescription | ScreenMediaDescription;
export type VideoMediaDescription = CameraMediaDescription | ScreenMediaDescription;

export function isVideoDescription(description: MediaDescription): description is VideoMediaDescription {
  return description.type === 'camera' || description.type === 'screen';
}

// Could be called SessionMediaSlice 🤔️
export type MediaSlice = {
  descriptions: { [logicalTrackId: string]: MediaDescription };
  sessionIdByTrackId: { [logicalTrackId: string]: SessionId };
  stills: { [logicalTrackId: string]: string };
  activeGuestScreenTrackId?: string;
};

const initialState: MediaSlice = {
  descriptions: {},
  sessionIdByTrackId: {},
  stills: {},
};

export const {
  createReducer,
  createSelector,
  createMemoizedSelector,
  createParametricMemoizedSelector,
  createAction,
  createThunk,
} = createSlice('media', initialState);

const tracksCache: { [logicalTrackId: string]: MediaStreamTrack } = {};
const getTrack = (logicalTrackId: string | undefined) =>
  logicalTrackId ? tracksCache[logicalTrackId] : undefined;

exposeToGlobalConsole({ tracksCache });

const makeDefaultMetadataForMediaType = (type: MediaDescription['type']) => {
  switch (type) {
    case 'screen':
    case 'camera':
      return {
        width: 1,
        height: 1,
      };
    case 'microphone':
      return {
        isEnabled: false,
      };
    default:
      assertExhaustedType(type);
  }
};

export const ingestTrack = createAction<{
  peerId: AnyPeerId;
  // A little slight of hand to contain the deprecated use of 'mic' to floof-sdk (in favor of moving to 'microphone').
  trackInfo: Omit<TrackInfo, 'mediaType'> & { type: MediaDescription['type'] };
  sessionId: SessionId;
}>('ingestTrack');
export const ingestProvisionalTrack = createAction<{
  peerId: AnyPeerId;
  // A little slight of hand to contain the deprecated use of 'mic' to floof-sdk (in favor of moving to 'microphone').
  trackInfo: Omit<ProvisionalTrackInfo, 'mediaType'> & { type: MediaDescription['type'] };
  sessionId: SessionId;
}>('ingestProvisionalTrack');

export const setSizeForTrack = createAction<{ trackId: string; size: Size }>('setSizeForTrack');
export const setActiveGuestScreenTrackId = createAction<string | undefined>('setActiveGuestScreenTrackId');

export default createReducer()
  .on(ingestTrack, (state, action) => {
    internalIngestTrack(state, action);
    const {
      payload: {
        trackInfo: { logicalTrackId, track },
      },
    } = action;
    if (track.kind === 'audio' && !getEnhancedTrackForTrack(track)) {
      makeEnhancedTrackForTrack(track);
    }
    tracksCache[logicalTrackId] = track;
  })
  .on(ingestProvisionalTrack, (state, action) => {
    internalIngestTrack(state, action);
    const {
      payload: {
        trackInfo: { logicalTrackId },
      },
    } = action;
    delete tracksCache[logicalTrackId];
  })
  .on(removeLogicalTrack, (state, { payload: { logicalTrackId } }) => {
    getEnhancedTrackForTrack(getTrack(logicalTrackId))?.destroy();
    delete tracksCache[logicalTrackId];
    delete state.descriptions[logicalTrackId];
  })
  .on(setSizeForTrack, (state, { payload: { trackId, size } }) => {
    const description = state.descriptions[trackId];
    if (!isVideoDescription(description)) {
      debugCheck(`Unknown behavior to set size for track of ${description.type} type`);
      return;
    }

    description.width = size.width;
    description.height = size.height;
  })
  .on(setActiveGuestScreenTrackId, (state, { payload: trackId }) => {
    state.activeGuestScreenTrackId = trackId;
  });

const internalIngestTrack = (
  state: WritableDraft<MediaSlice>,
  {
    payload: { peerId, trackInfo, sessionId },
  }: ReturnType<typeof ingestTrack> | ReturnType<typeof ingestProvisionalTrack>,
) => {
  state.descriptions[trackInfo.logicalTrackId] = {
    ...makeDefaultMetadataForMediaType(trackInfo.type),
    peerId,
    ...(!('track' in trackInfo) && { isProvisional: true }),
    ...omit(trackInfo, 'track'),
    // Functionally useless, but helps typescript.
    type: trackInfo.type as any,
  };
  state.sessionIdByTrackId[trackInfo.logicalTrackId] = sessionId;
};

export const getAllMediaDescriptions = createSelector((state) => state.media.descriptions);

export const getSessionIdForTrackId = createSelector(
  (state, logicalTrackId: string) => state.media.sessionIdByTrackId[logicalTrackId],
);

export const getMediaDescriptionForTrackId = createSelector(
  (state, trackId: string) => state.media.descriptions[trackId],
);

export const getDescriptionsByPeerId = createMemoizedSelector(getAllMediaDescriptions, (descriptions) =>
  groupBy(descriptions, 'peerId'),
);

export const getDescriptionsForPeerId = createMemoizedSelector(
  getDescriptionsByPeerId,
  (state: { media: MediaSlice }, { peerId }: { peerId: AnyPeerId }) => peerId,
  (descriptions, peerId) => descriptions[peerId],
);

export const getDescriptionsByPeerIdAndType = createMemoizedSelector(
  getDescriptionsByPeerId,
  (descriptionsByPeerId) => mapValues(descriptionsByPeerId, (descriptions) => groupBy(descriptions, 'type')),
);

export const getMicrophoneTrack = createSelector((state, peerId: AnyPeerId) =>
  getTrack(getDescriptionsByPeerIdAndType(state)[peerId]?.['microphone']?.[0]?.logicalTrackId),
);

export const getCameraTrack = createSelector((state, peerId: AnyPeerId) =>
  getTrack(getDescriptionsByPeerIdAndType(state)[peerId]?.['camera']?.[0]?.logicalTrackId),
);

export const getLocalTrackIdByLogicalTrackId = createSelector(
  (_state, logicalTrackId: string) => getTrack(logicalTrackId)?.id,
);

export const getTrackIdsWithDisplayPrecendence = createMemoizedSelector(
  getDescriptionsByPeerId,
  (descriptionsByPeerId) =>
    flatMap(
      mapValues(descriptionsByPeerId, (descriptions) => {
        const { screen, camera, microphone } = {
          screen: [],
          camera: [],
          microphone: [],
          ...groupBy(descriptions, 'type'),
        };
        if (!screen.length && !camera.length) return [microphone[0]];
        return [...screen, ...camera];
      }),
      (descriptions) => descriptions.map(({ trackId }) => trackId),
    ),
  {
    memoizeOptions: {
      resultEqualityCheck: isShallowArrayEqual,
    },
  },
);

export const getAllTrackDescriptionsByType = createMemoizedSelector(
  getAllMediaDescriptions,
  (allDescriptions) => groupBy(allDescriptions, ({ type }) => type),
);

type NarrowMediaDescription<T, N> = T extends { type: N } ? T : never;
const makeDescriptionSelectorByMediaType = <T extends MediaDescription, K extends T['type']>(type: K) =>
  createMemoizedSelector(
    getAllTrackDescriptionsByType,
    (descriptions) => (descriptions[type] ?? []) as NarrowMediaDescription<T, K>[],
  );

export const getAllMicrophoneTracks = makeDescriptionSelectorByMediaType('microphone');
export const getAllCameraTracks = makeDescriptionSelectorByMediaType('camera');
export const getAllScreenTracks = makeDescriptionSelectorByMediaType('screen');

export const getMicrophoneTracksForPeerIds = createParametricMemoizedSelector(
  getAllMicrophoneTracks,
  (_state: { media: MediaSlice }, { peerIds }: { peerIds: AnyPeerId[] }) => peerIds,
  (microphoneDescriptions, peerIds) =>
    filter(microphoneDescriptions, ({ peerId }) => peerIds.includes(peerId)),
)((_state, { peerIds }) => peerIds.join('|'));

export const getMicrophoneTrackIdsForPeerIds = createParametricMemoizedSelector(
  getMicrophoneTracksForPeerIds,
  (tracks) => tracks.map(({ logicalTrackId }) => logicalTrackId),
)((_state, { peerIds }) => peerIds.join('|'));

export const getCameraTracksForPeerIds = createParametricMemoizedSelector(
  getAllCameraTracks,
  (_state: { media: MediaSlice }, { peerIds }: { peerIds: AnyPeerId[] }) => peerIds,
  (cameraDescriptions, peerIds) => filter(cameraDescriptions, ({ peerId }) => peerIds.includes(peerId)),
)((_state, { peerIds }) => peerIds.join('|'));

export const getCameraTrackIdsForPeerIds = createParametricMemoizedSelector(
  getCameraTracksForPeerIds,
  (tracks) => tracks.map(({ logicalTrackId }) => logicalTrackId),
)((_state, { peerIds }) => peerIds.join('|'));

export const getScreenTracksForPeerIds = createParametricMemoizedSelector(
  getAllScreenTracks,
  (_state: { media: MediaSlice }, { peerIds }: { peerIds: AnyPeerId[] }) => peerIds,
  (screenDescriptions, peerIds) => filter(screenDescriptions, ({ peerId }) => peerIds.includes(peerId)),
)((_state, { peerIds }) => peerIds.join('|'));

export const getScreenTrackIdsForPeerIds = createParametricMemoizedSelector(
  getScreenTracksForPeerIds,
  (tracks) => tracks.map(({ logicalTrackId }) => logicalTrackId),
)((_state, { peerIds }) => peerIds.join('|'));

export const getDescriptionsForTrackIds = createParametricMemoizedSelector(
  getAllMediaDescriptions,
  (_state: { media: MediaSlice }, { trackIds }: { trackIds: string[] }) => trackIds,
  (descriptions, trackIds) => filter(descriptions, ({ logicalTrackId }) => trackIds.includes(logicalTrackId)),
)((_state, { trackIds }) => trackIds.join('|'));

export const getPeerIdForTrackId = createSelector(
  (state, trackId: string) => state.media.descriptions[trackId]?.peerId,
);

export const getIsTrackProvisional = createSelector(
  (state, logicalTrackId: string) => state.media.descriptions[logicalTrackId]?.isProvisional,
);

export const getIsSelfScreenShareGuest = createMemoizedSelector(getAllScreenTracks, (screenTracks) =>
  screenTracks.some((screenTrack) => screenTrack.peerId !== 'self'),
);

export const getIsSelfCameraEnabled = createSelector(
  (state) => !!(getDescriptionsByPeerIdAndType(state)['self']?.['camera']?.length ?? false),
);

export const getIsSelfScreenEnabled = createSelector(
  (state) => !!(getDescriptionsByPeerIdAndType(state)['self']?.['screen']?.length ?? false),
);

/**
 *  Use the selector in devices.slice for `self` peerId.
 **/
export const getIsPeerMicrophoneEnabled = createSelector((state, peerId: RemotePeerId) =>
  some(
    (getDescriptionsByPeerIdAndType(state)[peerId]?.['microphone'] ?? []) as MicrophoneMediaDescription[],
    ({ isEnabled }) => isEnabled,
  ),
);

export const getActiveGuestScreenTrackId = createSelector((state) => state.media.activeGuestScreenTrackId);

// If we are constrained to only show one video (camera or screen), which is it?
export const getCanonicalVideoTrackDescriptionForPeerId = createMemoizedSelector(
  getDescriptionsByPeerIdAndType,
  (state: { media: MediaSlice }, { peerId }: { peerId: AnyPeerId }) => peerId,
  (descriptions, peerId) =>
    (descriptions[peerId]?.['camera']?.[0] ?? descriptions[peerId]?.['screen']?.[0]) as unknown as
      | CameraMediaDescription
      | ScreenMediaDescription
      | undefined,
);

export const getRemotePeerIdsWithScreenTracks = createMemoizedSelector(getAllScreenTracks, (screenTracks) =>
  uniq(screenTracks.map((screenTrack) => screenTrack.peerId).filter((peerId) => peerId !== 'self')),
);

export const getCameraTrackCountsByPeerId = createMemoizedSelector(getAllCameraTracks, (cameraTracks) =>
  countBy(cameraTracks, ({ peerId }) => peerId),
);
export const getScreenTrackCountsByPeerId = createMemoizedSelector(getAllScreenTracks, (screenTracks) =>
  countBy(screenTracks, ({ peerId }) => peerId),
);
