import { filter } from 'lodash';

import { OrgId } from 'common/models/db/vo.interface';

import { getSelfUserId, AuthSlice } from '../auth/auth.slice';
import { createSelector, getTeamIdsByOrgId, OrgsSlice } from '../orgs/orgs.slice';
import { getTeamMemberIdsByTeamId, TeamsSlice } from '../teams/teams.slice';

export const getTeamIdsForSelfByOrgId = createSelector(
  (state: { orgs: OrgsSlice; teams: TeamsSlice; auth: AuthSlice }, orgId: OrgId) => {
    const selfUserId = getSelfUserId(state);
    if (!selfUserId) return [];
    const teamIds = getTeamIdsByOrgId(state, orgId);
    return filter(teamIds, (teamId) => getTeamMemberIdsByTeamId(state, teamId).includes(selfUserId));
  },
);
