import { distinctUntilChanged, filter, map, merge, NEVER, of, pairwise, switchMap, take } from 'rxjs';

import { filterIsTruthy, ofActionPayload, pluck, waitUntilTruthy } from 'common/utils/custom-rx-operators';
import {
  getHeadSessionIdForSessionInitiator,
  getIsMemberOfLastJoinedTeam,
  getSessionGroupIdForSessionInitiator,
  getWasLastLeftSessionALobby,
} from 'pages/vo/vo-react/features/common/session-selectors';
import { getHeadItemForSessionGroupId } from 'pages/vo/vo-react/features/session-groups/session-groups.slice';
import { getSyncedSetting } from 'pages/vo/vo-react/features/settings/settings.slice';
import { getIsUserAMemberOfTeam, getTeamById } from 'pages/vo/vo-react/features/teams/teams.slice';
import { isDesktopApp } from 'utils/client-utils';

import {
  getLastJoinedTeamSessionInitiator,
  getSessionInitiatorForSession,
  joinKnownSession,
  leaveSession,
} from '../features/sessions/sessions.slice';
import { getSelfUserId, getSelfUserStatus } from '../features/users/users.slice';
import { EpicWithDeps } from '../redux/app-store';

export const becomeAvailableForRejoinWhenLeavingSessionOrStatusAvailableEpic: EpicWithDeps = (
  action$,
  state$,
) =>
  merge(
    action$.pipe(
      // If we are leaving a session, we want to rejoin the last team only if we
      // left a non-lobby session.
      ofActionPayload(leaveSession),
      filter(({ isLeavingToJoin, shouldCreateNewLobby }) => !isLeavingToJoin && !shouldCreateNewLobby),
      map(() => !getWasLastLeftSessionALobby(state$.value)),
    ),
    state$.pipe(
      // If we are going from DND/Away to Available, we want to rejoin the last
      // team only if we left a lobby session in a team.
      map((state) => getSelfUserStatus(state)),
      distinctUntilChanged(),
      pairwise(),
      filter(
        ([oldStatus, newStatus]) =>
          (oldStatus === 'away' || oldStatus === 'dnd') && newStatus === 'available',
      ),
      map(() => getWasLastLeftSessionALobby(state$.value)),
    ),
  ).pipe(
    filterIsTruthy(),
    map(() => {
      if (!getIsMemberOfLastJoinedTeam(state$.value)) return;
      const lastJoinedTeamSessionInitiator = getLastJoinedTeamSessionInitiator(state$.value);
      return joinKnownSession({
        sessionId: getHeadSessionIdForSessionInitiator(state$.value, lastJoinedTeamSessionInitiator!),
        joinableSessionInitiator: lastJoinedTeamSessionInitiator,
        shouldSkipNavigation: true,
      });
    }),
    filterIsTruthy(),
  );

export const rejoinLastTeamWalkieTalkieOnAppRestartEpic: EpicWithDeps = (action$, state$) =>
  !isDesktopApp || !getSyncedSetting(state$.value, 'shouldRejoinTeamOnRestart')
    ? NEVER
    : of(getLastJoinedTeamSessionInitiator(state$.value)!).pipe(
        filterIsTruthy(),
        waitUntilTruthy((joinableSessionInitiator) =>
          state$.pipe(map((state) => getTeamById(state, joinableSessionInitiator.id))),
        ),
        filter((joinableSessionInitiator) =>
          getIsUserAMemberOfTeam(state$.value, joinableSessionInitiator.id, getSelfUserId(state$.value)!),
        ),
        waitUntilTruthy((joinableSessionInitiator) =>
          state$.pipe(map((state) => getSessionGroupIdForSessionInitiator(state, joinableSessionInitiator))),
        ),
        switchMap((joinableSessionInitiator) =>
          state$.pipe(
            map(
              (state) =>
                getHeadItemForSessionGroupId(
                  state,
                  getSessionGroupIdForSessionInitiator(state, joinableSessionInitiator)!,
                )!,
            ),
            distinctUntilChanged(),
            filterIsTruthy(),
            pluck('sessionId'),
            // Without this wait, navigateToSessionInitiatorWhenJoinedEpic won't
            // find the correct URL
            waitUntilTruthy((sessionId) =>
              state$.pipe(map((state) => getSessionInitiatorForSession(state, sessionId))),
            ),
            map((sessionId) =>
              joinKnownSession({
                joinableSessionInitiator,
                sessionId: sessionId,
              }),
            ),
            take(1),
          ),
        ),
      );
