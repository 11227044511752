import * as semver from 'semver';
import { parse as semverParse } from 'semver-utils';

export function isMajorVersionUpdate(currentVersion: string, latestVersion: string) {
  const semCurrent = semverParse(currentVersion);
  const semUpdate = semverParse(latestVersion);
  if (!semCurrent || !semUpdate) return false;
  return semCurrent.major < semUpdate.major;
}

export function isVersionUpToDate(currentVersion?: string, latestVersion?: string) {
  if (!currentVersion || !latestVersion) return true;
  return semver.gte(currentVersion, latestVersion);
}
