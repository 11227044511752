import { useEffect, useRef } from 'react';
import { useBeforeUnload } from 'react-router-dom';

import { IpcToMain } from 'common/models/ipc-messages';
import { sendIpc } from 'pages/vo/vo-react/ipc/send-ipc';

import { useUpdateRef } from './use-update-ref';

export const useIpcEffect = (
  ipcName: keyof IpcToMain,
  {
    mount = true,
    unmount = false,
  }: {
    mount?: any;
    unmount?: any;
  } = {},
) => {
  const argsRef = useUpdateRef({ mount, unmount });
  const isMountedRef = useRef(false);

  useEffect(() => {
    void sendIpc(ipcName, argsRef.current.mount);
    isMountedRef.current = true;

    return () => {
      if (isMountedRef.current) {
        isMountedRef.current = false;
        void sendIpc(ipcName, argsRef.current.unmount);
      }
    };
  }, []);

  useBeforeUnload(() => {
    if (isMountedRef.current) {
      isMountedRef.current = false;
      void sendIpc(ipcName, argsRef.current.unmount);
    }
  });
};

export const useUpdateScreensListener = () => useIpcEffect('toggleUpdateScreensSubscription');

export const useIagoEventListener = () =>
  useIpcEffect('toggleIagoEventListener', {
    mount: { isActive: true, role: 'host' },
    unmount: { isActive: false, role: 'host' },
  });
