import {
  map,
  filter,
  merge,
  groupBy,
  mergeMap,
  distinctUntilChanged,
  of,
  take,
  timer,
  withLatestFrom,
  switchMap,
  EMPTY,
  skip,
} from 'rxjs';

import { ofAction, ofActionPayload, switchMapConditional } from 'common/utils/custom-rx-operators';
import { assertExhaustedType } from 'common/utils/ts-utils';
import { deprecatedGetActiveFloofConnection } from 'utils/floof-sdk/floof-sdk';

import { EpicWithDeps } from '../../redux/app-store';
import { mirrorActionToAllPeers } from '../remote-actions/remote-actions.actions';
import { getSyncedSetting } from '../settings/settings.slice';

import {
  clear,
  undo,
  setColor,
  addAspectWithId,
  deleteAspect,
  modifyAspect,
  getAspectById,
  redo,
  clearAspectsOfType,
  WAIT_TO_START_FADE_MS,
  FADE_DURATION_MS,
  clearFade,
  startFade,
  clearForAllPeers,
} from './draw.slice';

export const fadeDrawings: EpicWithDeps = (action$, state$) =>
  action$.pipe(
    ofActionPayload(addAspectWithId),
    // Only ever deal with fading self lines since each user is the arbiter of
    // their own draw state; self actions will be mirrored to others for replay.
    filter(({ peerId, aspect }) => peerId === 'self' && aspect.type === 'line'),
    // Simplify complex actions to { action: 'start', instanceId } and { action:
    // 'end', instanceId } for our purposes here.
    mergeMap(({ aspectId, instanceId }) =>
      merge(
        of({ action: 'start' as const, instanceId }),
        action$.pipe(
          ofActionPayload(modifyAspect),
          filter(
            ({ aspectId: modifiedAspectId, modification }) =>
              aspectId === modifiedAspectId && modification.type === 'line-end',
          ),
          map(() => ({ action: 'end' as const, instanceId })),
          // There should only ever be one 'line-end' for a line.
          take(1),
        ),
      ),
    ),
    // Handle each instance independently.
    groupBy(({ instanceId }) => instanceId),
    mergeMap((lineActionsPerInstance$) => {
      // Determine the instance type (app or screen) to watch the corresponding
      // draw mode setting.
      const instanceType = getInstanceTypeForDrawInstanceId(lineActionsPerInstance$.key);
      const drawModeForInstanceType$ = state$.pipe(
        map((state) => getSyncedSetting(state, instanceType === 'app' ? 'drawAppMode' : 'drawScreenMode')),
        distinctUntilChanged(),
      );

      return lineActionsPerInstance$.pipe(
        withLatestFrom(drawModeForInstanceType$),
        // Combine each start/end action with the latest draw mode setting.
        // n.b. this switchMap will switch only when the next start/end action
        // comes through for this instanceId (not when the draw mode changes)!
        switchMap(([{ action, instanceId }, mode]) => {
          // If a line is starting to be drawn and we're in mid-fade, clear the fade TS.
          if (action === 'start') {
            if (mode === 'fade') return of(clearFade({ peerId: 'self', instanceId }));
            return EMPTY;
          }

          if (action === 'end') {
            return merge(
              // If in fade mode:
              // Set the fade start timestamp and schedule clearing of the
              // line after the fade duration. n.b. if a 'start' action
              // interrupts, this will be cancelled via the switchMap above.
              drawModeForInstanceType$.pipe(
                switchMapConditional(
                  (mode) => mode === 'fade',
                  () =>
                    merge(
                      timer(WAIT_TO_START_FADE_MS).pipe(map(() => startFade({ peerId: 'self', instanceId }))),
                      timer(WAIT_TO_START_FADE_MS + FADE_DURATION_MS).pipe(
                        map(() => clearAspectsOfType({ peerId: 'self', instanceId, type: 'line' })),
                      ),
                    ),
                ),
              ),
              // If mode changes to persist, stop fading.
              drawModeForInstanceType$.pipe(
                skip(1),
                filter((mode) => mode === 'persist'),
                map(() => clearFade({ peerId: 'self', instanceId })),
              ),
            );
          }
          assertExhaustedType(action);
        }),
      );
    }),
  );

const getInstanceTypeForDrawInstanceId = (instanceId: string) => {
  if (instanceId.includes('screen')) return 'screen' as const;
  return 'app' as const;
};

type PayloadForAction<Action extends (...args: any[]) => { payload: any }> = ReturnType<Action>['payload'];

const convertSelfReferencesToPeerId = (
  payload:
    | PayloadForAction<typeof addAspectWithId>
    | PayloadForAction<typeof modifyAspect>
    | PayloadForAction<typeof deleteAspect>,
) => {
  const selfPeerId = deprecatedGetActiveFloofConnection().getSelfPeerId();
  return {
    ...payload,
    peerId: selfPeerId,
    ...('aspectId' in payload &&
      payload.aspectId.startsWith('self') && {
        aspectId: `${selfPeerId}${payload.aspectId.slice('self'.length)}`,
      }),
  };
};

export const mirrorDrawActionsToPeersEpic: EpicWithDeps = (action$, state$) =>
  merge(
    action$.pipe(
      ofAction(clear, clearForAllPeers, undo, redo, setColor, startFade, clearFade, clearAspectsOfType),
      filter((action) => action.payload.peerId === 'self'),
    ),
    action$.pipe(
      ofAction(addAspectWithId, modifyAspect as any, deleteAspect as any),
      filter(
        (action) => getAspectById(state$.value, { aspectId: action.payload.aspectId })?.peerId === 'self',
      ),
    ),
  ).pipe(
    map((action) => ({
      ...action,
      payload: convertSelfReferencesToPeerId(action.payload),
    })),
    map((action) => mirrorActionToAllPeers(action)),
  );
