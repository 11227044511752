import produce from 'immer';
import { useMemo } from 'react';
import { Observable, OperatorFunction, Subject, distinctUntilChanged, map, merge, scan } from 'rxjs';

import { useObservable } from './react/use-observable';

export type MachineDefinition<
  StateValue extends string | number,
  Action extends string | number,
  Context extends any,
> = {
  initialState: { value: StateValue; context?: Context };
  states: {
    [S in StateValue]: {
      on: {
        [A in Action]?: {
          next?: StateValue;
          effect?: (payload: { send: (action: Action) => void; context: Context }) => any;
        };
      };
    };
  };
};

export type State<SV extends string | number, C> = { value: SV; context?: C };
export type ExtractStateType<M> = M extends MachineDefinition<infer SV, infer A, infer C>
  ? State<SV, C>
  : never;
export type ExtractStateValueType<M> = M extends MachineDefinition<infer SV, infer A, infer C> ? SV : never;
export type ExtractActionType<M> = M extends MachineDefinition<infer SV, infer A, infer C> ? A : never;

/**
 * Convenience method for building state machines with Typescript typeahead support.
 */
export const makeStateMachineDefinition = <S extends string | number, A extends string | number, C>(
  machine: MachineDefinition<S, A, C>,
) => machine;

/**
 * Take the state machine transition function and give it a memory via the scan
 * operator.
 */
export const mapStateMachine =
  <SV extends string | number, A extends string | number, C>(
    machine: MachineDefinition<SV, A, C>,
  ): OperatorFunction<A, SV> =>
  (input$: Observable<A>) => {
    const asyncAction$ = new Subject<A>();
    return merge(input$, asyncAction$).pipe(
      scan(
        (state: State<SV, C>, action: A) =>
          transitionStateMachine({
            machine,
            send: asyncAction$.next.bind(asyncAction$),
            state,
            action,
          }),
        machine.initialState,
      ),
      map(({ value }) => value),
      distinctUntilChanged(),
    );
  };

export const useStateMachine = <S extends string | number, A extends string | number, C>(
  machine: MachineDefinition<S, A, C>,
) => {
  const action$ = useMemo(() => new Subject<A>(), []);
  const act = useMemo(() => action$.next.bind(action$) as typeof action$.next, []);
  const state = useObservable(() => action$.pipe(mapStateMachine(machine)), machine.initialState.value, [
    machine,
  ]);
  return { act, state };
};

/**
 * The heart of the state machine; given a starting state and an action, return
 * the next state.
 */
export const transitionStateMachine = <M extends MachineDefinition<any, any, any>>({
  machine,
  send,
  state,
  action,
}: {
  machine: M;
  // We need some hook to re-emit because we can't trust ourselves because we
  // don't know what state is "now" for async effects.
  send: (action: ExtractActionType<M>) => void;
  state: ExtractStateType<M>;
  action: ExtractActionType<M>;
}) =>
  produce(state, (draftState) => {
    const { next, effect } = machine.states[state.value]?.on?.[action] ?? {};
    const guard = effect?.({ send, context: draftState.context });
    if (guard !== false) {
      draftState.value = next ?? state.value;
    }
  }) as ExtractStateType<M>;
